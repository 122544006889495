import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Alert,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  IconButton,
  FormControlLabel,
  Stack,
  Checkbox,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CommentIcon from "@mui/icons-material/Comment";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatIcon from "@mui/icons-material/Chat";

const styleConstants = {
  primary: "#52A1DB",
  secondary: "#0066B2",
  darkBlue: "#004d86",
  borderRadius: "4px",
  disabled: "rgba(0, 0, 0, 0.38)",
};

const textFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: styleConstants.borderRadius,
    "& fieldset": {
      borderColor: styleConstants.primary,
    },
    "&:hover fieldset": {
      borderColor: "#4190c4",
    },
    "&.Mui-focused fieldset": {
      borderColor: styleConstants.primary,
    },
    "& input": {
      color: "black !important",
      "-webkit-text-fill-color": "black !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
      color: "black !important",
      "-webkit-text-fill-color": "black !important",
    },
    "&.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root": {
      margin: "0 !important",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "black !important",
      color: "black !important",
    },
  },
};

const tableHeaderSx = {
  backgroundColor: styleConstants.primary,
  color: "white",
  fontWeight: "bold",
};

const screeningTooltips = {
  "Breast Cancer Screening":
    "Family hx or at risk: start at 40, otherwise 50-74, biennial Mammogram",
  "Colorectal Screening": "Discuss with PCP",
  "Influenza Vaccine": "Annual",
  "COVID-19 Vaccine": "Discuss with PCP",
  "Pneumococcal Vaccine": "21-65 pap q 3 yrs. or q 5 years with hrHPV testing",
  "Herpes Zoster Vaccine": "Annual",
  "Diabetic Eye Exam": "Discuss with PCP",
  "Diabetic Foot Exam": "Annual, *If Diabetic Hgb A1c screen every 3-4 months",
  "Diabetes Screening": "Annual",
  "Cholesterol Screening": "annual",
  "STIs/HIV Screening": "55-65 yrs. discuss exam and PSA testing with PCP",
  "Cervical Cancer Screening":
    "Family hx start at 45 otherwise 50-75 yrs.: FIT q 4 yrs. or Colonoscopy q 10 yrs. 76-85 yrs. discuss with PCP",
  "Osteoporosis Screening": "Annual",
  "Prostate Screening": "DXA screening > 65 yrs. or <65 yrs. post menopause",
};

const buttonSx = {
  flex: 1,
  border: "1px solid #52A1DB",
  height: "48px",
  fontSize: "1rem",
  fontWeight: 500,
  minWidth: "150px",
  "&:hover": {
    border: "1px solid #52A1DB",
  },
  "&.Mui-disabled": {
    color: "black",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

const customSortOrder = {
  "Breast Cancer Screening": 1,
  "Cervical Cancer Screening": 2,
  "Cholesterol Screening": 3,
  "Colorectal Screening": 4,
  "COVID-19 Vaccine": 5,
  "Diabetic Eye Exam": 6,
  "Diabetic Foot Exam": 7,
  "Diabetes Screening": 8,
  "Herpes Zoster Vaccine": 9,
  "Influenza Vaccine": 10,
  "Osteoporosis Screening": 11,
  "Pneumococcal Vaccine": 12,
  "Prostate Screening": 13,
  "STIs/HIV Screening": 14,
};

const PreventiveCare = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user);
  const defaultFormData = {
    preventiveCare: {
      pastThreeYearsSubAnswers: [
        {
          screen: "Breast Cancer Screening",
          answer: "",
          date: "",
          method: "",
          recommendation: "1",
        },
        {
          screen: "Diabetic Eye Exam",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Diabetic Foot Exam",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Diabetes Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Cervical Cancer Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Cholesterol Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Colorectal Screening",
          answer: "",
          date: "",
          method: "",
          recommendation: "1",
        },
        {
          screen: "COVID-19 Vaccine",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Osteoporosis Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Pneumococcal Vaccine",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "Prostate Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
        {
          screen: "STIs/HIV Screening",
          answer: "",
          date: "",
          method: "N/A",
          recommendation: "1",
        },
      ].sort((a, b) => {
        // If both items have a defined order, use it
        if (customSortOrder[a.screen] && customSortOrder[b.screen]) {
          return customSortOrder[a.screen] - customSortOrder[b.screen];
        }
        // If only one has a defined order, prioritize it
        if (customSortOrder[a.screen]) return -1;
        if (customSortOrder[b.screen]) return 1;
        // For items without defined order, use alphabetical
        return a.screen.localeCompare(b.screen);
      }),
      comment: "",
    },
    oneTimeScreenForAbdominal: {
      isOneTimeScreenForAbdominal: "",
      isOneTimeScreenForAbdominalSubAnswer: {
        isEducationProvided: "",
        comment: "",
      },
      comment: "",
    },
    oneTimeScreenForHepatitis: {
      isoneTimeScreenForHepatitis: "",
      isoneTimeScreenForHepatitisSubAnswer: {
        isEducationProvided: "",
        comment: "",
      },
      comment: "",
    },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [changedFields, setChangedFields] = useState([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showPreventiveCareComment, setShowPreventiveCareComment] =
    useState(false);
  const [showAbdominalComment, setShowAbdominalComment] = useState(false);
  const [showHepatitisComment, setShowHepatitisComment] = useState(false);
  const [showAbdominalSubComment, setShowAbdominalSubComment] = useState(false);
  const [showHepatitisSubComment, setShowHepatitisSubComment] = useState(false);
  const [naFields, setNaFields] = useState({});

  // Add new state to track if comment boxes were manually opened
  const [manuallyOpenedComments, setManuallyOpenedComments] = useState(() => {
    const savedVisibility = localStorage.getItem("preventiveCareVisibility");
    return savedVisibility
      ? JSON.parse(savedVisibility)
      : {
          preventiveCare: false,
          abdominal: false,
          hepatitis: false,
          abdominalSub: false,
          hepatitisSub: false,
        };
  });

  // Move selectSx here where it has access to isEditing
  const selectSx = {
    "& .MuiSelect-select": {
      color: "black",
      "&.Mui-disabled": {
        WebkitTextFillColor: "black !important",
        color: "black !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "text.primary",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "text.secondary",
    },
  };

  useEffect(() => {
    if (data?.data) {
      const filteredData = {
        ...data.data,
        preventiveCare: {
          ...data.data.preventiveCare,
          pastThreeYearsSubAnswers:
            data.data.preventiveCare.pastThreeYearsSubAnswers
              .filter(
                (screening) =>
                  screening.screen !== "Fall Risk Screening" &&
                  screening.screen !== "Glaucoma Screening",
              )
              .sort((a, b) => {
                // If both items have a defined order, use it
                if (customSortOrder[a.screen] && customSortOrder[b.screen]) {
                  return customSortOrder[a.screen] - customSortOrder[b.screen];
                }
                // If only one has a defined order, prioritize it
                if (customSortOrder[a.screen]) return -1;
                if (customSortOrder[b.screen]) return 1;
                // For items without defined order, use alphabetical
                return a.screen.localeCompare(b.screen);
              }),
        },
      };
      setFormData(filteredData);
    } else {
      setFormData(defaultFormData);
    }
  }, [data]);

  useEffect(() => {
    const fetchLatestPreventiveCare = async () => {
      try {
        const token = localStorage.getItem("token");
        const yearToUse = user.year || new Date().getFullYear();

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-preventive-care`,
          {
            params: {
              memberId: user.memberId,
              year: yearToUse,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.data?.data) {
          // Format dates in the screenings array and filter out unwanted screenings
          const screenings =
            response.data.data.preventiveCare?.pastThreeYearsSubAnswers || [];
          const formattedScreenings = screenings
            .filter(
              (screening) =>
                screening.screen !== "Fall Risk Screening" &&
                screening.screen !== "Glaucoma Screening",
            )
            .map((screening) => {
              if (screening.date) {
                const date = new Date(screening.date);
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const day = String(date.getDate()).padStart(2, "0");
                const year = date.getFullYear();
                return {
                  ...screening,
                  date: `${month}/${day}/${year}`,
                };
              }
              return screening;
            });

          const processedData = {
            ...defaultFormData,
            ...response.data.data,
            preventiveCare: {
              ...defaultFormData.preventiveCare,
              ...response.data.data.preventiveCare,
              pastThreeYearsSubAnswers:
                formattedScreenings.length > 0
                  ? formattedScreenings.sort((a, b) => {
                      // If both items have a defined order, use it
                      if (
                        customSortOrder[a.screen] &&
                        customSortOrder[b.screen]
                      ) {
                        return (
                          customSortOrder[a.screen] - customSortOrder[b.screen]
                        );
                      }
                      // If only one has a defined order, prioritize it
                      if (customSortOrder[a.screen]) return -1;
                      if (customSortOrder[b.screen]) return 1;
                      // For items without defined order, use alphabetical
                      return a.screen.localeCompare(b.screen);
                    })
                  : defaultFormData.preventiveCare.pastThreeYearsSubAnswers.filter(
                      (screening) =>
                        screening.screen !== "Fall Risk Screening" &&
                        screening.screen !== "Glaucoma Screening",
                    ),
            },
            oneTimeScreenForAbdominal: {
              ...defaultFormData.oneTimeScreenForAbdominal,
              ...response.data.data.oneTimeScreenForAbdominal,
              isOneTimeScreenForAbdominalSubAnswer: {
                ...defaultFormData.oneTimeScreenForAbdominal
                  .isOneTimeScreenForAbdominalSubAnswer,
                ...response.data.data.oneTimeScreenForAbdominal
                  ?.isOneTimeScreenForAbdominalSubAnswer,
              },
            },
            oneTimeScreenForHepatitis: {
              ...defaultFormData.oneTimeScreenForHepatitis,
              ...response.data.data.oneTimeScreenForHepatitis,
              isoneTimeScreenForHepatitisSubAnswer: {
                ...defaultFormData.oneTimeScreenForHepatitis
                  .isoneTimeScreenForHepatitisSubAnswer,
                ...response.data.data.oneTimeScreenForHepatitis
                  ?.isoneTimeScreenForHepatitisSubAnswer,
              },
            },
          };

          console.log("Fresh preventive care data loaded:", processedData);
          setFormData(processedData);
          const savedCommentVisibility = localStorage.getItem(
            "preventiveCareVisibility",
          );
          if (savedCommentVisibility) {
            setManuallyOpenedComments(JSON.parse(savedCommentVisibility));
          }
          // setManuallyOpenedComments((prev) => ({
          //   preventiveCare:
          //     prev.preventiveCare ||
          //     !!processedData.preventiveCare?.comment?.trim(),
          //   abdominal:
          //     prev.abdominal ||
          //     !!processedData.oneTimeScreenForAbdominal?.isOneTimeScreenForAbdominalSubAnswer?.comment?.trim(),
          //   hepatitis:
          //     prev.hepatitis ||
          //     !!processedData.oneTimeScreenForHepatitis?.isoneTimeScreenForHepatitisSubAnswer?.comment?.trim(),
          //   abdominalSub:
          //     prev.abdominalSub ||
          //     !!processedData.oneTimeScreenForAbdominal?.isOneTimeScreenForAbdominalSubAnswer?.comment?.trim(),
          //   hepatitisSub:
          //     prev.hepatitisSub ||
          //     !!processedData.oneTimeScreenForHepatitis?.isoneTimeScreenForHepatitisSubAnswer?.comment?.trim(),
          // }));
        }
      } catch (error) {
        console.error("Error fetching latest preventive care:", error);
      }
    };

    fetchLatestPreventiveCare();
  }, []); // Run once when component mounts

  useEffect(() => {
    setShowPreventiveCareComment(!!formData.preventiveCare.comment);
    setShowAbdominalComment(!!formData.oneTimeScreenForAbdominal.comment);
    setShowHepatitisComment(!!formData.oneTimeScreenForHepatitis.comment);
    setShowAbdominalSubComment(
      !!formData.oneTimeScreenForAbdominal.isOneTimeScreenForAbdominalSubAnswer
        ?.comment,
    );
    setShowHepatitisSubComment(
      !!formData.oneTimeScreenForHepatitis.isoneTimeScreenForHepatitisSubAnswer
        ?.comment,
    );
  }, [formData]);

  const handleScreeningChange = (index, field, value) => {
    if (!isEditing) return;

    const screening = formData.preventiveCare.pastThreeYearsSubAnswers[index];

    // If the method is 'N/A', don't allow changes to method field
    if (screening.method === "N/A" && field === "method") return;

    const updatedScreenings = [
      ...formData.preventiveCare.pastThreeYearsSubAnswers,
    ];

    if (field === "date") {
      // Format date as mm/dd/yyyy
      const dateValue = value.replace(/\D/g, "");
      if (dateValue.length <= 8) {
        let formattedDate = dateValue;
        if (dateValue.length > 4) {
          formattedDate = `${dateValue.slice(0, 2)}/${dateValue.slice(
            2,
            4,
          )}/${dateValue.slice(4)}`;
        } else if (dateValue.length > 2) {
          formattedDate = `${dateValue.slice(0, 2)}/${dateValue.slice(2)}`;
        }
        updatedScreenings[index] = {
          ...updatedScreenings[index],
          [field]: formattedDate,
        };
      }
    } else {
      updatedScreenings[index] = {
        ...updatedScreenings[index],
        [field]: value,
      };
    }

    setFormData((prev) => ({
      ...prev,
      preventiveCare: {
        ...prev.preventiveCare,
        pastThreeYearsSubAnswers: updatedScreenings,
      },
    }));
  };

  const handleOneTimeScreenChange = (screenType, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [screenType]: {
        ...prev[screenType],
        [field]: value,
        // Reset sub-answer when parent answer changes
        [`${
          screenType === "oneTimeScreenForAbdominal"
            ? "isOneTimeScreenForAbdominalSubAnswer"
            : "isoneTimeScreenForHepatitisSubAnswer"
        }`]: {
          isEducationProvided: "",
          comment: "",
        },
      },
    }));
  };

  const handleOneTimeScreenSubAnswerChange = (screenType, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [screenType]: {
        ...prev[screenType],
        [`${
          screenType === "oneTimeScreenForAbdominal"
            ? "isOneTimeScreenForAbdominalSubAnswer"
            : "isoneTimeScreenForHepatitisSubAnswer"
        }`]: {
          ...prev[screenType][
            `${
              screenType === "oneTimeScreenForAbdominal"
                ? "isOneTimeScreenForAbdominalSubAnswer"
                : "isoneTimeScreenForHepatitisSubAnswer"
            }`
          ],
          // Allow deselection by comparing with current value
          isEducationProvided:
            prev[screenType][
              `${
                screenType === "oneTimeScreenForAbdominal"
                  ? "isOneTimeScreenForAbdominalSubAnswer"
                  : "isoneTimeScreenForHepatitisSubAnswer"
              }`
            ]?.isEducationProvided === value
              ? ""
              : value,
        },
      },
    }));
  };

  const handlePreventiveCareCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      preventiveCare: {
        ...prev.preventiveCare,
        comment: event.target.value,
      },
    }));
    setIsEditing(true);
  };

  const handleAbdominalCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      oneTimeScreenForAbdominal: {
        ...prev.oneTimeScreenForAbdominal,
        comment: event.target.value,
      },
    }));
    setIsEditing(true);
  };

  const handleHepatitisCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      oneTimeScreenForHepatitis: {
        ...prev.oneTimeScreenForHepatitis,
        comment: event.target.value,
      },
    }));
    setIsEditing(true);
  };

  const handleAbdominalSubCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      oneTimeScreenForAbdominal: {
        ...prev.oneTimeScreenForAbdominal,
        isOneTimeScreenForAbdominalSubAnswer: {
          ...prev.oneTimeScreenForAbdominal
            .isOneTimeScreenForAbdominalSubAnswer,
          comment: event.target.value,
        },
      },
    }));
  };

  const handleHepatitisSubCommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      oneTimeScreenForHepatitis: {
        ...prev.oneTimeScreenForHepatitis,
        isoneTimeScreenForHepatitisSubAnswer: {
          ...prev.oneTimeScreenForHepatitis
            .isoneTimeScreenForHepatitisSubAnswer,
          comment: event.target.value,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    const yearToUse = (function () {
      if (!user.year) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(user.year).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const previousData = { ...formData };

      // Format dates before sending to API
      const formattedData = {
        ...formData,
        preventiveCare: {
          ...formData.preventiveCare,
          pastThreeYearsSubAnswers:
            formData.preventiveCare.pastThreeYearsSubAnswers.map(
              (screening) => {
                if (screening.date && screening.date.includes("/")) {
                  const [month, day, year] = screening.date.split("/");
                  if (month && day && year) {
                    return {
                      ...screening,
                      date: `${year}-${month.padStart(2, "0")}-${day.padStart(
                        2,
                        "0",
                      )}`,
                    };
                  }
                }
                return screening;
              },
            ),
        },
      };

      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "PreventiveCare",
        data: formattedData,
      };

      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-preventive-care`,
        payload,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      setMessage("Preventive care updated successfully!");
      setIsEditing(false);

      const getResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-preventive-care`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (getResponse.data?.data) {
        const newData = getResponse.data.data;

        const formattedScreenings =
          newData.preventiveCare?.pastThreeYearsSubAnswers?.map((screening) => {
            if (screening.date && screening.date.includes("-")) {
              const [year, month, day] = screening.date.split("-");
              if (month && day && year) {
                return {
                  ...screening,
                  date: `${month.padStart(2, "0")}/${day.padStart(
                    2,
                    "0",
                  )}/${year}`,
                };
              }
            }
            return screening;
          }) || [];

        const processedData = {
          ...defaultFormData,
          ...newData,
          preventiveCare: {
            ...defaultFormData.preventiveCare,
            ...newData.preventiveCare,
            pastThreeYearsSubAnswers:
              formattedScreenings.length > 0
                ? formattedScreenings
                : defaultFormData.preventiveCare.pastThreeYearsSubAnswers,
          },
          oneTimeScreenForAbdominal: {
            ...defaultFormData.oneTimeScreenForAbdominal,
            ...newData.oneTimeScreenForAbdominal,
          },
          oneTimeScreenForHepatitis: {
            ...defaultFormData.oneTimeScreenForHepatitis,
            ...newData.oneTimeScreenForHepatitis,
          },
        };

        // Track changes
        const changes = [];
        if (
          JSON.stringify(processedData.preventiveCare) !==
          JSON.stringify(previousData.preventiveCare)
        ) {
          changes.push({
            field: "preventiveCare",
            previousValue: previousData.preventiveCare,
            newValue: processedData.preventiveCare,
          });
        }
        if (
          JSON.stringify(processedData.oneTimeScreenForAbdominal) !==
          JSON.stringify(previousData.oneTimeScreenForAbdominal)
        ) {
          changes.push({
            field: "oneTimeScreenForAbdominal",
            previousValue: previousData.oneTimeScreenForAbdominal,
            newValue: processedData.oneTimeScreenForAbdominal,
          });
        }
        if (
          JSON.stringify(processedData.oneTimeScreenForHepatitis) !==
          JSON.stringify(previousData.oneTimeScreenForHepatitis)
        ) {
          changes.push({
            field: "oneTimeScreenForHepatitis",
            previousValue: previousData.oneTimeScreenForHepatitis,
            newValue: processedData.oneTimeScreenForHepatitis,
          });
        }
        setChangedFields(changes);
        setFormData(processedData);
      }

      console.log(
        "submitting",
        formData.oneTimeScreenForHepatitis.isoneTimeScreenForHepatitisSubAnswer
          .comment,
      );
      // After successful submission, reset manually opened comments
      // setManuallyOpenedComments({
      //   preventiveCare: false,
      //   abdominal: false,
      //   hepatitis: false,
      //   abdominalSub: false,
      //   hepatitisSub: false,
      // });
      setManuallyOpenedComments((prevState) => {
        const updatedState = {
          preventiveCare:
            formData.preventiveCare?.comment?.trim() !== "" ? true : false,
          abdominal:
            formData.oneTimeScreenForAbdominal?.comment?.trim() !== ""
              ? true
              : false,
          hepatitis:
            formData.oneTimeScreenForHepatitis?.comment?.trim() !== ""
              ? true
              : false,
          abdominalSub:
            formData.oneTimeScreenForAbdominal?.isOneTimeScreenForAbdominalSubAnswer?.comment?.trim() !==
            ""
              ? true
              : false,
          hepatitisSub:
            formData.oneTimeScreenForHepatitis?.isoneTimeScreenForHepatitisSubAnswer?.comment?.trim() !==
            ""
              ? true
              : false,
        };
        localStorage.setItem(
          "preventiveCareVisibility",
          JSON.stringify(updatedState),
        );

        return updatedState;
      });
    } catch (error) {
      console.error("Error updating preventive care:", error);
      setMessage("Failed to update preventive care. Please try again.");
    }
  };

  const handleCancel = async () => {
    try {
      const token = localStorage.getItem("token");
      const yearToUse = user.year || new Date().getFullYear();

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-preventive-care`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (response.data?.data) {
        // Format dates in the screenings array and filter out unwanted screenings
        const screenings =
          response.data.data.preventiveCare?.pastThreeYearsSubAnswers || [];
        const formattedScreenings = screenings
          .filter(
            (screening) =>
              screening.screen !== "Fall Risk Screening" &&
              screening.screen !== "Glaucoma Screening",
          )
          .map((screening) => {
            if (screening.date) {
              const date = new Date(screening.date);
              const month = String(date.getMonth() + 1).padStart(2, "0");
              const day = String(date.getDate()).padStart(2, "0");
              const year = date.getFullYear();
              return {
                ...screening,
                date: `${month}/${day}/${year}`,
              };
            }
            return screening;
          });

        const processedData = {
          ...defaultFormData,
          ...response.data.data,
          preventiveCare: {
            ...defaultFormData.preventiveCare,
            ...response.data.data.preventiveCare,
            pastThreeYearsSubAnswers:
              formattedScreenings.length > 0
                ? formattedScreenings.sort((a, b) => {
                    // If both items have a defined order, use it
                    if (
                      customSortOrder[a.screen] &&
                      customSortOrder[b.screen]
                    ) {
                      return (
                        customSortOrder[a.screen] - customSortOrder[b.screen]
                      );
                    }
                    // If only one has a defined order, prioritize it
                    if (customSortOrder[a.screen]) return -1;
                    if (customSortOrder[b.screen]) return 1;
                    // For items without defined order, use alphabetical
                    return a.screen.localeCompare(b.screen);
                  })
                : defaultFormData.preventiveCare.pastThreeYearsSubAnswers,
          },
          oneTimeScreenForAbdominal: {
            ...defaultFormData.oneTimeScreenForAbdominal,
            ...response.data.data.oneTimeScreenForAbdominal,
            isOneTimeScreenForAbdominalSubAnswer: {
              ...defaultFormData.oneTimeScreenForAbdominal
                .isOneTimeScreenForAbdominalSubAnswer,
              ...response.data.data.oneTimeScreenForAbdominal
                ?.isOneTimeScreenForAbdominalSubAnswer,
            },
          },
          oneTimeScreenForHepatitis: {
            ...defaultFormData.oneTimeScreenForHepatitis,
            ...response.data.data.oneTimeScreenForHepatitis,
            isoneTimeScreenForHepatitisSubAnswer: {
              ...defaultFormData.oneTimeScreenForHepatitis
                .isoneTimeScreenForHepatitisSubAnswer,
              ...response.data.data.oneTimeScreenForHepatitis
                ?.isoneTimeScreenForHepatitisSubAnswer,
            },
          },
        };

        setFormData(processedData);
        const savedCommentVisibility = localStorage.getItem(
          "preventiveCareVisibility",
        );
        if (savedCommentVisibility) {
          setManuallyOpenedComments(JSON.parse(savedCommentVisibility));
        }
      }
    } catch (error) {
      console.error("Error fetching data on cancel:", error);
    }

    setIsEditing(false);
    setMessage("");
  };

  // Modify the click handlers to only toggle empty comments when editing
  const handleCommentIconClick = (commentType) => {
    setManuallyOpenedComments((prev) => ({
      ...prev,
      [commentType]: !prev[commentType],
    }));

    switch (commentType) {
      case "preventiveCare":
        setShowPreventiveCareComment((prev) => !prev);
        break;
      case "abdominal":
        setShowAbdominalComment((prev) => !prev);
        break;
      case "hepatitis":
        setShowHepatitisComment((prev) => !prev);
        break;
      case "abdominalSub":
        setShowAbdominalSubComment((prev) => !prev);
        break;
      case "hepatitisSub":
        setShowHepatitisSubComment((prev) => !prev);
        break;
    }
  };

  useEffect(() => {
    console.log(
      "manual",
      manuallyOpenedComments,
      formData.oneTimeScreenForHepatitis.isoneTimeScreenForHepatitisSubAnswer
        .comment,
    );
  }, [manuallyOpenedComments]);

  const handleNAChange = (screening, isChecked) => {
    setNaFields((prev) => ({
      ...prev,
      [screening]: isChecked,
    }));
  };

  const editableScreenings = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Osteoporosis Screening",
  ];

  const renderNAField = (screening) => {
    const isEditable = editableScreenings.includes(screening);

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={naFields[screening] || false}
            onChange={(e) => handleNAChange(screening, e.target.checked)}
            disabled={!isEditable} // Disable checkbox if not in editableScreenings
          />
        }
        label="NA"
      />
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      {/* {changedFields.length > 0 && (
        <Alert 
          severity="info" 
          sx={{ 
            mb: 3,
            '& .MuiAlert-message': {
              width: '100%'
            }
          }}
          onClose={() => setChangedFields([])}
        >
          <Typography variant="subtitle1" gutterBottom>
            Updated Fields:
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table size="small">
              <TableBody>
                {changedFields.map((change, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>
                      {change.field}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography color="error" variant="body2">
                          Previous: {JSON.stringify(change.previousValue, null, 2)}
                        </Typography>
                        <Typography color="success.main" variant="body2">
                          New: {JSON.stringify(change.newValue, null, 2)}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Alert>
      )} */}

      <Paper
        sx={{
          p: 3,
          border: `1px solid ${styleConstants.secondary}`,
          borderRadius: styleConstants.borderRadius,
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textAlign: "left",
              color: "black",
              fontWeight: "normal",
            }}
          >
            In the past three years have you had?
          </Typography>
          <IconButton
            onClick={() => handleCommentIconClick("preventiveCare")}
            sx={{
              color: "#0066B2",
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        {manuallyOpenedComments.preventiveCare && (
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: `1px solid ${styleConstants.primary}`,
              borderRadius: styleConstants.borderRadius,
              p: 2,
            }}
          >
            <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
            <TextField
              fullWidth
              multiline
              rows={1}
              value={formData.preventiveCare.comment || ""}
              onChange={handlePreventiveCareCommentChange}
              disabled={!isEditing}
              sx={{
                ...textFieldSx,
                "& .MuiInputBase-input": {
                  color: "black",
                },
                "& .Mui-disabled": {
                  WebkitTextFillColor: "black !important",
                  color: "black !important",
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
            />
          </Box>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderSx}>Screen</TableCell>
                <TableCell sx={tableHeaderSx}>Answer</TableCell>
                <TableCell sx={tableHeaderSx}>Date</TableCell>
                <TableCell sx={tableHeaderSx}>Method</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...formData.preventiveCare.pastThreeYearsSubAnswers]
                .sort((a, b) => {
                  // If both items have a defined order, use it
                  if (customSortOrder[a.screen] && customSortOrder[b.screen]) {
                    return (
                      customSortOrder[a.screen] - customSortOrder[b.screen]
                    );
                  }
                  // If only one has a defined order, prioritize it
                  if (customSortOrder[a.screen]) return -1;
                  if (customSortOrder[b.screen]) return 1;
                  // For items without defined order, use alphabetical
                  return a.screen.localeCompare(b.screen);
                })
                .map((screening, index) => (
                  <TableRow
                    key={screening.screen}
                    sx={{
                      "& td": {
                        borderBottom: "1px solid rgba(224, 224, 224, 0.4)",
                        padding: "8px 16px",
                      },
                    }}
                  >
                    <TableCell sx={{ width: "30%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {screening.screen}
                        <Tooltip
                          title={screeningTooltips[screening.screen]}
                          arrow
                          placement="right"
                        >
                          <IconButton size="small">
                            <InfoIcon fontSize="small" color="action" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <Select
                        fullWidth
                        size="small"
                        value={screening.answer || ""}
                        onChange={(e) =>
                          handleScreeningChange(index, "answer", e.target.value)
                        }
                        disabled={!isEditing}
                        displayEmpty
                        renderValue={(value) => {
                          if (!value) return "Select";
                          const valueMap = {
                            0: "No",
                            1: "Yes",
                            2: "Not Applicable",
                            3: "Don't Know",
                          };
                          return valueMap[value] || value;
                        }}
                        sx={selectSx}
                      >
                        <MenuItem value="">
                          <em>Select</em>
                        </MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                        <MenuItem value="Not Applicable">
                          Not Applicable
                        </MenuItem>
                        <MenuItem value="Don't Know">Don't Know</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell sx={{ width: "25%" }}>
                      <TextField
                        size="small"
                        value={screening.date || ""}
                        onChange={(e) => {
                          // Direct format handling for manual input
                          const inputDate = e.target.value;
                          handleScreeningChange(index, "date", inputDate);
                        }}
                        disabled={!isEditing}
                        placeholder="mm/dd/yyyy"
                        inputProps={{
                          maxLength: 10,
                          style: {
                            color: "black",
                            WebkitTextFillColor: "black",
                          },
                        }}
                        sx={{
                          marginTop: "0px !important",
                          backgroundColor: "white",
                          "& input": {
                            "&::placeholder": {
                              content: '"mm/dd/yyyy"',
                            },
                            "&.Mui-disabled": {
                              WebkitTextFillColor: "black !important",
                              color: "black !important",
                            },
                          },
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "black !important",
                            color: "black !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: "0px !important",
                          },
                          "& textarea": {
                            height: "100% !important",
                            minHeight: "60px !important",
                            lineHeight: "1.5",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ width: "25%" }}>
                      <TextField
                        fullWidth
                        size="small"
                        value={screening.method || ""}
                        onChange={(e) =>
                          handleScreeningChange(index, "method", e.target.value)
                        }
                        disabled={!isEditing || screening.method === "N/A"}
                        placeholder="Enter method"
                        variant="standard"
                        inputProps={{
                          style: {
                            color: "black",
                            WebkitTextFillColor: "black",
                          },
                        }}
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottomColor:
                              screening.method === "N/A"
                                ? "transparent"
                                : "rgba(0, 0, 0, 0.12)",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor:
                              screening.method === "N/A"
                                ? "transparent"
                                : "rgba(0, 0, 0, 0.42)",
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottomColor:
                                screening.method === "N/A"
                                  ? "transparent"
                                  : "rgba(0, 0, 0, 0.42)",
                            },
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "black !important",
                            color: "black !important",
                          },
                          "& input.Mui-disabled": {
                            WebkitTextFillColor: "black !important",
                            color: "black !important",
                          },
                          "& .MuiOutlinedInput-root": {
                            padding: "0px !important",
                          },
                          "& textarea": {
                            height: "100% !important",
                            minHeight: "60px !important",
                            lineHeight: "1.5",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ mt: 4, p: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ color: "black", fontWeight: 500 }}>
              One time screen for Abdominal Aortic Aneurysm if male with history
              of smoking, age 65-75
            </Typography>
            <IconButton
              onClick={() => handleCommentIconClick("abdominal")}
              sx={{
                color: "#0066B2",
              }}
              disabled={!isEditing}
            >
              <ChatIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "1"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "1"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForAbdominal
                      .isOneTimeScreenForAbdominal === "1"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForAbdominal",
                  "isOneTimeScreenForAbdominal",
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "1"
                    ? ""
                    : "1",
                )
              }
              disabled={!isEditing}
            >
              YES
            </Button>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "2"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "2"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForAbdominal
                      .isOneTimeScreenForAbdominal === "2"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForAbdominal",
                  "isOneTimeScreenForAbdominal",
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "2"
                    ? ""
                    : "2",
                )
              }
              disabled={!isEditing}
            >
              NO
            </Button>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "3"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "3"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForAbdominal
                      .isOneTimeScreenForAbdominal === "3"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForAbdominal",
                  "isOneTimeScreenForAbdominal",
                  formData.oneTimeScreenForAbdominal
                    .isOneTimeScreenForAbdominal === "3"
                    ? ""
                    : "3",
                )
              }
              disabled={!isEditing}
            >
              NA
            </Button>
          </Box>
          {manuallyOpenedComments.abdominal && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #0066B2",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                mt: 1,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
              <TextField
                fullWidth
                multiline
                rows={1}
                value={formData.oneTimeScreenForAbdominal.comment || ""}
                onChange={handleAbdominalCommentChange}
                disabled={!isEditing}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    padding: "0px !important",
                    "& fieldset": {
                      borderColor: "#0066B2",
                    },
                  },
                  "& textarea": {
                    height: "100% !important",
                    minHeight: "60px !important",
                    lineHeight: "1.5",
                    color: "black",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "black !important",
                    color: "black !important",
                  },
                }}
              />
            </Box>
          )}

          {/* Abdominal Sub-question */}
          {formData.oneTimeScreenForAbdominal.isOneTimeScreenForAbdominal ===
            "2" && (
            <Box sx={{ mt: 2, ml: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                >
                  → Education Provided/recommendation to discuss with Provider
                </Typography>
                <IconButton
                  onClick={() => handleCommentIconClick("abdominalSub")}
                  sx={{
                    color: "#0066B2",
                  }}
                  disabled={!isEditing}
                >
                  <ChatIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "1"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "1"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForAbdominal
                          .isOneTimeScreenForAbdominalSubAnswer
                          ?.isEducationProvided === "1"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForAbdominal",
                      "1",
                    )
                  }
                  disabled={!isEditing}
                >
                  YES
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "2"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "2"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForAbdominal
                          .isOneTimeScreenForAbdominalSubAnswer
                          ?.isEducationProvided === "2"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForAbdominal",
                      "2",
                    )
                  }
                  disabled={!isEditing}
                >
                  NO
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "3"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer
                        ?.isEducationProvided === "3"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForAbdominal
                          .isOneTimeScreenForAbdominalSubAnswer
                          ?.isEducationProvided === "3"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForAbdominal",
                      "3",
                    )
                  }
                  disabled={!isEditing}
                >
                  NA
                </Button>
              </Box>
              {manuallyOpenedComments.abdominalSub && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    border: "1px solid #0066B2",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    mt: 1,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={1}
                    value={
                      formData.oneTimeScreenForAbdominal
                        .isOneTimeScreenForAbdominalSubAnswer?.comment || ""
                    }
                    onChange={handleAbdominalSubCommentChange}
                    disabled={!isEditing}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                        padding: "0px !important",
                        "& fieldset": {
                          borderColor: "#0066B2",
                        },
                        "& input": {
                          color: "black !important",
                          "-webkit-text-fill-color": "black !important",
                        },
                      },
                      "& textarea": {
                        height: "100% !important",
                        minHeight: "60px !important",
                        lineHeight: "1.5",
                        color: "black !important",
                        "-webkit-text-fill-color": "black !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "black !important",
                        color: "black !important",
                      },
                      "& textarea": {
                        height: "100% !important",
                        minHeight: "60px !important",
                        lineHeight: "1.5",
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "black", fontWeight: 500 }}>
              One time screen for Hepatitis C if born between 1945-1965
            </Typography>
            <IconButton
              onClick={() => handleCommentIconClick("hepatitis")}
              sx={{
                color: "#0066B2",
              }}
              disabled={!isEditing}
            >
              <ChatIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "1"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "1"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForHepatitis
                      .isoneTimeScreenForHepatitis === "1"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForHepatitis",
                  "isoneTimeScreenForHepatitis",
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "1"
                    ? ""
                    : "1",
                )
              }
              disabled={!isEditing}
            >
              YES
            </Button>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "2"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "2"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForHepatitis
                      .isoneTimeScreenForHepatitis === "2"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForHepatitis",
                  "isoneTimeScreenForHepatitis",
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "2"
                    ? ""
                    : "2",
                )
              }
              disabled={!isEditing}
            >
              NO
            </Button>
            <Button
              variant="outlined"
              sx={{
                ...buttonSx,
                color:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "3"
                    ? "black"
                    : styleConstants.primary,
                bgcolor:
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "3"
                    ? styleConstants.primary
                    : "#e3edf4",
                "&:hover": {
                  bgcolor:
                    formData.oneTimeScreenForHepatitis
                      .isoneTimeScreenForHepatitis === "3"
                      ? styleConstants.primary
                      : "#e3edf4",
                },
              }}
              onClick={() =>
                handleOneTimeScreenChange(
                  "oneTimeScreenForHepatitis",
                  "isoneTimeScreenForHepatitis",
                  formData.oneTimeScreenForHepatitis
                    .isoneTimeScreenForHepatitis === "3"
                    ? ""
                    : "3",
                )
              }
              disabled={!isEditing}
            >
              NA
            </Button>
          </Box>
          {manuallyOpenedComments.hepatitis && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #0066B2",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                mt: 1,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
              <TextField
                fullWidth
                multiline
                rows={1}
                value={formData.oneTimeScreenForHepatitis.comment || ""}
                onChange={handleHepatitisCommentChange}
                disabled={!isEditing}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    padding: "0px !important",
                    "& fieldset": {
                      borderColor: "#0066B2",
                    },
                  },
                  "& textarea": {
                    height: "100% !important",
                    minHeight: "60px !important",
                    lineHeight: "1.5",
                    color: "black",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "black !important",
                    color: "black !important",
                  },
                }}
              />
            </Box>
          )}

          {formData.oneTimeScreenForHepatitis.isoneTimeScreenForHepatitis ===
            "2" && (
            <Box sx={{ mt: 2, ml: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                    fontWeight: 400,
                  }}
                >
                  → Education Provided/recommendation to discuss with Provider
                </Typography>
                <IconButton
                  onClick={() => handleCommentIconClick("hepatitisSub")}
                  sx={{
                    color: "#0066B2",
                  }}
                  disabled={!isEditing}
                >
                  <ChatIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: "flex", gap: 2, justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "1"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "1"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForHepatitis
                          .isoneTimeScreenForHepatitisSubAnswer
                          ?.isEducationProvided === "1"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForHepatitis",
                      "1",
                    )
                  }
                  disabled={!isEditing}
                >
                  YES
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "2"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "2"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForHepatitis
                          .isoneTimeScreenForHepatitisSubAnswer
                          ?.isEducationProvided === "2"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForHepatitis",
                      "2",
                    )
                  }
                  disabled={!isEditing}
                >
                  NO
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonSx,
                    color:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "3"
                        ? "black"
                        : styleConstants.primary,
                    bgcolor:
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer
                        ?.isEducationProvided === "3"
                        ? styleConstants.primary
                        : "#e3edf4",
                    "&:hover": {
                      bgcolor:
                        formData.oneTimeScreenForHepatitis
                          .isoneTimeScreenForHepatitisSubAnswer
                          ?.isEducationProvided === "3"
                          ? styleConstants.primary
                          : "#e3edf4",
                    },
                  }}
                  onClick={() =>
                    handleOneTimeScreenSubAnswerChange(
                      "oneTimeScreenForHepatitis",
                      "3",
                    )
                  }
                  disabled={!isEditing}
                >
                  NA
                </Button>
              </Box>

              {manuallyOpenedComments.hepatitisSub && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    border: "1px solid #0066B2",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    mt: 1,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={1}
                    value={
                      formData.oneTimeScreenForHepatitis
                        .isoneTimeScreenForHepatitisSubAnswer?.comment || ""
                    }
                    onChange={handleHepatitisSubCommentChange}
                    disabled={!isEditing}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                        padding: "0px !important",
                        "& fieldset": {
                          borderColor: "#0066B2",
                        },
                        "& input": {
                          color: "black !important",
                          "-webkit-text-fill-color": "black !important",
                        },
                      },
                      "& textarea": {
                        height: "100% !important",
                        minHeight: "60px !important",
                        lineHeight: "1.5",
                        color: "black !important",
                        "-webkit-text-fill-color": "black !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "black !important",
                        color: "black !important",
                      },
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
            sx={{ width: isMobile ? "100%" : "auto" }}
          >
            Edit
          </Button>
        ) : (
          <Stack
            direction={isMobile ? "column" : "row"}
            spacing={2}
            sx={{
              width: isMobile ? "100%" : "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              sx={{ minWidth: "120px" }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              sx={{ minWidth: "120px" }}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default PreventiveCare;
