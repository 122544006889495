import { AUTH_TYPES } from "../types/auth_types";

const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/memberPortal`;

export const authActions = {
  login: (email, password) => async (dispatch) => {
    dispatch({ type: AUTH_TYPES.LOGIN_REQUEST });
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Login failed");

      localStorage.setItem("token", response.headers.get("Authorization"));
      localStorage.setItem("user", JSON.stringify(data.data));

      dispatch({ type: AUTH_TYPES.LOGIN_SUCCESS, payload: data.data });
      return data;
    } catch (error) {
      dispatch({ type: AUTH_TYPES.LOGIN_FAILURE, payload: error.message });
      throw error;
    }
  },

  register: (userData) => async (dispatch) => {
    dispatch({ type: AUTH_TYPES.REGISTER_REQUEST });
    try {
      const response = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Registration failed");

      dispatch({ type: AUTH_TYPES.REGISTER_SUCCESS });
      return data;
    } catch (error) {
      dispatch({ type: AUTH_TYPES.REGISTER_FAILURE, payload: error.message });
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return { type: AUTH_TYPES.LOGOUT };
  },

  // FORGOT PASSWORD
  sendOtp: (email) => async (dispatch) => {
    dispatch({ type: AUTH_TYPES.SEND_OTP_REQUEST });
    try {
      const response = await fetch(
        `${BASE_URL}/updatePassword/forgot/sendOTP`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        },
      );
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Failed to send OTP");

      dispatch({ type: AUTH_TYPES.SEND_OTP_SUCCESS });
      return data;
    } catch (error) {
      dispatch({ type: AUTH_TYPES.SEND_OTP_FAILURE, payload: error.message });
      throw error;
    }
  },

  validateOtp: (email, otp) => async (dispatch) => {
    dispatch({ type: AUTH_TYPES.VALIDATE_OTP_REQUEST });
    try {
      const response = await fetch(
        `${BASE_URL}/updatePassword/forgot/validateOTP`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp }),
        },
      );
      const data = await response.json();
      if (!response.ok)
        throw new Error(data.message || "OTP validation failed");

      dispatch({ type: AUTH_TYPES.VALIDATE_OTP_SUCCESS });
      return data;
    } catch (error) {
      dispatch({
        type: AUTH_TYPES.VALIDATE_OTP_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  },

  updatePassword:
    (email, newPassword, otp, currentPassword) => async (dispatch) => {
      dispatch({ type: AUTH_TYPES.UPDATE_PASSWORD_REQUEST });
      try {
        if (!newPassword) {
          throw new Error("New password is required");
        }
        if (!otp) {
          throw new Error("OTP is required");
        }

        const payload = {
          email: String(email).trim(),
          newPassword: String(newPassword).trim(),
          otp: Number(otp),
          roleId: "11",
          isForgotPassword: true,
        };

        console.log("Update Password Payload:", payload); // For debugging

        const response = await fetch(`${BASE_URL}/updatePassword/forgot`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Failed to update password");
        }

        dispatch({ type: AUTH_TYPES.UPDATE_PASSWORD_SUCCESS });
        return data;
      } catch (error) {
        dispatch({
          type: AUTH_TYPES.UPDATE_PASSWORD_FAILURE,
          payload: error.message,
        });
        throw error;
      }
    },
};
