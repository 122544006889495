import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Paper,
  Button,
  Alert,
  Divider,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { LiteracySection } from "./LiteracySection";
import { HousingSection } from "./HousingSection";
import { SocialSection } from "./SocialSection";
import { EmploymentSection } from "./EmploymentSection";
import { SubstanceSection } from "./SubstanceSection";
import { AdvanceCareSection } from "./AdvanceCareSection";
import { RecommendationsSection } from "./RecommendationsSection";
import { FoodSection } from "./FoodSection";
import _ from "lodash";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Sdoh = ({ data }) => {
  const user = useSelector((state) => state?.auth?.user ?? {});
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [isNewAssessment, setIsNewAssessment] = useState(false);
  const [changedFields, setChangedFields] = useState([]);
  const [originalData, setOriginalData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeTab, setActiveTab] = useState(0);

  const initialFormData = {
    school: {
      lessThanThirdGrade: "",
      lessThanEighthGrade: "",
      lessThanTwelthGrade: "",
      completedTwelthGrade: "",
      attendedOrgraduated: "",
      comment: "",
    },
    fillingMedicalForm: {
      notAtAllConfident: "",
      notVeryConfident: "",
      confident: "",
      veryConfident: "",
      comment: "",
    },
    writtenInfo: {
      veryDifficult: "",
      someWhatDifficult: "",
      easy: "",
      veryEasy: "",
      comment: "",
    },
    prescriptionBottle: {
      veryDifficult: "",
      someWhatDifficult: "",
      easy: "",
      veryEasy: "",
      comment: "",
    },
    currentlyLiveSituation: {
      currentlyLiveSituation: "",
      comment: "",
    },
    currentLive: {
      home: "",
      apartment: "",
      assistedLiving: "",
      nursingHome: "",
      homeless: "",
      homelessSubAnswer: {
        describe: "",
      },
      other: "",
      otherSubAnswer: {
        describe: "",
      },
      comment: "",
    },
    thinkAboutPlace: {
      bugsAntsMice: "",
      mold: "",
      leadPaintOrPipes: "",
      lackOfHeat: "",
      ovenOrStove: "",
      waterLeaks: "",
      none: "",
      comment: "",
    },
    currentlyLivingWith: {
      alone: "",
      aloneSubAnswer: {
        describe: "",
      },
      spouse: "",
      partner: "",
      relative: "",
      family: "",
      friend: "",
      personalCareWorker: "",
      comment: "",
    },
    lastTwelveMonths: {
      isLastTwelveMonths: "",
      lastTwelveMonthsSubAnswers: {
        lastTwelveMonthSelect: "",
        comment: "",
      },
      comment: "",
    },
    dailyLiving: {
      isDailyLiving: "",
      comment: "",
    },
    social: {
      rateYourHealth: {
        excellent: "",
        good: "",
        fair: "",
        poor: "",
        comment: "",
      },
      socialActivities: {
        often: "",
        sometimes: "",
        almostNever: "",
        never: "",
        comment: "",
      },
      relyOn: {
        isRelyOn: "",
        comment: "",
      },
      goOutToMeet: {
        often: "",
        sometimes: "",
        never: "",
        comment: "",
      },
      careGiver: {
        isCareGiver: "",
        isCareGiverSubAnswer: {
          describe: "",
        },
        comment: "",
      },
    },
    employement: {
      currentlyEmployed: {
        isCurrentlyEmployed: "",
        comment: "",
      },
      interestedEmployment: {
        isInterestedEmployment: "",
        comment: "",
      },
      volunteerCurrently: {
        isVolunteerCurrently: "",
        comment: "",
      },
    },
    substanceUse: {
      tobaccoUse: {
        current: "",
        currentSubAnswer: {
          type: {
            cigarettes: "",
            cigarettesSubAnswer: {
              oneToThree: "",
              halfAPack: "",
              onePack: "",
              moreThanOne: "",
              other: "",
              otherSubAnswer: {
                describe: "",
              },
              comment: "",
              interestedInSmoking: {
                interestedInSmoking: "",
                comment: "",
              },
              thingsPreventing: {
                educationNeeded: "",
                socialSupport: "",
                environmentalFactors: "",
                stress: "",
                selfConfidence: "",
                lackOfMotivation: "",
                notAPriority: "",
                other: "",
                none: "",
                comment: "",
              },
            },
            cigars: "",
            chewingTobacco: "",
            vaping: "",
            other: "",
            otherSubAnswer: {
              describe: "",
            },
            comment: "",
          },
          smokingCessation: {
            smokingCessation: "",
            comment: "",
          },
        },
        former: "",
        formerSubAnswer: {
          when: {
            withinLastYear: "",
            withinLastThreeYear: "",
            moreYearAgo: "",
            comment: "",
          },
          type: {
            cigarettes: "",
            cigarettesSubAnswer: {
              oneToThree: "",
              halfAPack: "",
              onePack: "",
              moreThanOne: "",
              other: "",
              otherSubAnswer: {
                describe: "",
              },
              comment: "",
            },
            cigars: "",
            chewingTobacco: "",
            vaping: "",
            other: "",
            otherSubAnswer: {
              describe: "",
            },
            comment: "",
          },
        },
        never: "",
        comment: "",
      },
      alcoholUse: {
        current: "",
        currentSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "",
            comment: "",
          },
        },
        former: "",
        formerSubAnswer: {
          howMnyTimes: {
            isNone: "",
            oneOrMore: "",
            oneOrMoreSubAnswers: {
              howOften: {
                never: "",
                monthly: "",
                twoTimesMonth: "",
                twoTimesWeek: "",
                fourTimesWeek: "",
                comment: "",
              },
              howManyDrinks: {
                oneOrTwo: "",
                threeOrFour: "",
                fiveOrSix: "",
                sevenEight: "",
                tenOrMore: "",
                comment: "",
              },
              sixOrMore: {
                never: "",
                lessThanMonthly: "",
                monthly: "",
                weekly: "",
                daily: "",
                comment: "",
              },
              auditScore: "",
              weeklyAverage: "",
              comment: "",
            },
            comment: "",
          },
          currentlyInRecovery: {
            isCurrentlyInRecovery: "",
            comment: "",
          },
        },
        never: "",
        comment: "",
      },
      educationProvided: {
        isEducationProvided: "",
        comment: "",
      },
    },
    educationGuidlines: {
      pastYear: {
        isNone: "",
        oneOrMore: "",
        oneOrMoreSubAnswers: {
          educationOrResources: "",
          comment: "",
        },
        comment: "",
      },
    },
    advanceCarePlanning: {
      healthCareProxy: {
        isHealthCareProxy: "",
        isHealthCareProxySubAnswer: {
          name: "",
          relationship: "",
        },
        comment: "",
      },
      powerAttorney: {
        isPowerAttorney: "",
        isPowerAttorneySubAnswer: {
          name: "",
          relationship: "",
        },
        comment: "",
      },
      advanceDirective: {
        isAdvanceDirective: "",
        isAdvanceDirectiveSubAnswer: {
          kept: "",
        },
        comment: "",
      },
      educatedOnAdvanceCarePlanning: {
        isEducatedOnAdvanceCarePlanning: "",
        comment: "",
      },
      declinesDiscussion: {
        isDeclinesDiscussion: "",
        comment: "",
      },
    },
    food: {
      foodWouldRunOut: {
        oftenTrue: "",
        oftenTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        sometimesTrue: "",
        sometimesTrueSubAnswers: {
          foodWeBought: "",
          dontHaveMoney: "",
          comment: "",
        },
        neverTrue: "",
        comment: "",
      },
    },
    recommendations: {
      recommendationsSubAnswer: [
        { smokingOrTobacco: "", comment: "" },
        { substanceUse: "", comment: "" },
        { foodDisparity: "", comment: "" },
        { literacy: "", comment: "" },
        { socialSupport: "", comment: "" },
        { advanceCare: "", comment: "" },
        { transportation: "", comment: "" },
        { careManagement: "", comment: "" },
        { followUp: "", comment: "" },
      ],
      comment: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-selfAssessment`,
          {
            params: {
              memberId: user.memberId,
              year: user.year || new Date().getFullYear(),
            },
            headers: { Authorization: token },
          },
        );

        if (response.data?.data) {
          const mergedData = mergeWithInitialForm(response.data.data);
          setFormData(mergedData);
          setOriginalData(mergedData);
          setIsNewAssessment(false);
        } else {
          setFormData(initialFormData);
          setOriginalData(initialFormData);
          setIsNewAssessment(true);
        }
      } catch (error) {
        console.error("Error fetching self-assessment:", error);
        setMessage("Failed to fetch self-assessment data.");
      }
    };

    if (user.memberId) {
      fetchData();
    }
  }, [user.memberId, user.year]);

  const mergeWithInitialForm = (data) => {
    // Ensure recommendations array structure is preserved
    const mergedData = _.merge({}, initialFormData, data);

    // Force array structure for recommendations
    if (
      mergedData.recommendations &&
      !Array.isArray(mergedData.recommendations.recommendationsSubAnswer)
    ) {
      mergedData.recommendations.recommendationsSubAnswer = [
        { smokingOrTobacco: "", comment: "" },
        { substanceUse: "", comment: "" },
        { foodDisparity: "", comment: "" },
        { literacy: "", comment: "" },
        { socialSupport: "", comment: "" },
        { advanceCare: "", comment: "" },
        { transportation: "", comment: "" },
        { careManagement: "", comment: "" },
        { followUp: "", comment: "" },
      ];
    }

    return mergedData;
  };

  const findChangedFields = (oldData, newData, path = "") => {
    const changes = [];

    const compareValues = (oldVal, newVal, fieldPath) => {
      if (_.isObject(oldVal) && _.isObject(newVal)) {
        Object.keys(newVal).forEach((key) => {
          const newPath = fieldPath ? `${fieldPath}.${key}` : key;
          compareValues(oldVal[key], newVal[key], newPath);
        });
      } else if (!_.isEqual(oldVal, newVal)) {
        changes.push({
          field: fieldPath,
          oldValue: oldVal,
          newValue: newVal,
        });
      }
    };

    compareValues(oldData, newData, path);
    return changes;
  };

  const handleSubmit = async () => {
    const yearToUse = (function () {
      if (!user.year) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(user.year).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const changes = findChangedFields(originalData, formData);
      setChangedFields(changes);

      const sanitizeData = (obj) => {
        const newObj = { ...obj };
        Object.keys(newObj).forEach((key) => {
          if (
            key === "null" ||
            key === "difficultyReading" ||
            key === "concernAboutTransportation"
          ) {
            delete newObj[key];
          } else if (key === "recommendations") {
            // Ensure recommendations structure is correct
            newObj[key] = {
              recommendationsSubAnswer: Array.isArray(
                newObj[key]?.recommendationsSubAnswer,
              )
                ? newObj[key].recommendationsSubAnswer
                : [
                    { smokingOrTobacco: "", comment: "" },
                    { substanceUse: "", comment: "" },
                    { foodDisparity: "", comment: "" },
                    { literacy: "", comment: "" },
                    { socialSupport: "", comment: "" },
                    { advanceCare: "", comment: "" },
                    { transportation: "", comment: "" },
                    { careManagement: "", comment: "" },
                    { followUp: "", comment: "" },
                  ],
              comment: newObj[key]?.comment || "",
            };
          } else if (typeof newObj[key] === "object" && newObj[key] !== null) {
            newObj[key] = sanitizeData(newObj[key]);
          }
        });
        return newObj;
      };

      const sanitizedFormData = sanitizeData(formData);

      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "Self-Assessment and Social History",
        data: sanitizedFormData,
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-self-assessment`,
        payload,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      const freshDataResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-selfAssessment`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: { Authorization: token },
        },
      );

      if (freshDataResponse.data?.data) {
        const mergedData = mergeWithInitialForm(freshDataResponse.data.data);
        setFormData(mergedData);
        setOriginalData(mergedData);
      }

      setMessage("Self-Assessment information updated successfully!");
      setIsEditing(false);
      setIsNewAssessment(false);
    } catch (error) {
      console.error("Error updating self-assessment:", error);
      setMessage("Failed to update self-assessment. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data?.data && !Array.isArray(data.data)) {
      setFormData(data.data);
    } else {
      setFormData(initialFormData);
    }
    setIsEditing(false);
    setMessage("");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!data) return null;

  return (
    <Container maxWidth="lg" sx={{ mt: 5 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 4 }}
        >
          {message}
        </Alert>
      )}

      {/* {changedFields.length > 0 && (
        <Alert severity="info" sx={{ mb: 4 }}>
          <Typography variant="subtitle2">Changed Fields:</Typography>
          {changedFields.map((change, index) => (
            <Typography key={index} variant="body2">
              {change.field}: {JSON.stringify(change.oldValue)} → {JSON.stringify(change.newValue)}
            </Typography>
          ))}
        </Alert>
      )} */}

      <Paper sx={{ mt: 4, p: 3 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
        >
          <Tab label="Literacy" />
          <Tab label="Housing & Transportation" />
          <Tab label="Social" />
          <Tab label="Employment" />
          <Tab label="Substance Use" />
          <Tab label="Advance Care Planning" />
          <Tab label="Food" />
        </Tabs>

        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <LiteracySection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 1 && (
            <HousingSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 2 && (
            <SocialSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 3 && (
            <EmploymentSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 4 && (
            <SubstanceSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 5 && (
            <AdvanceCareSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
          {activeTab === 6 && (
            <FoodSection
              formData={formData}
              isEditing={isEditing}
              setFormData={setFormData}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          {!isEditing ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(true)}
              fullWidth={isMobile}
              sx={{ maxWidth: isMobile ? "100%" : "200px" }}
            >
              Edit
            </Button>
          ) : (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              width={isMobile ? "100%" : "auto"}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Sdoh;
