import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

export const EmploymentSection = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState({});

  // Add this style definition at the top of the component
  const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      color: "#000000 !important", // Keep label text black even when disabled
    },
    "&.Mui-disabled": {
      color: "#000000 !important", // Keep text black in disabled state
    },
  };

  // Unified comment section style
  const commentBoxStyle = {
    mt: 2,
    mb: 2,
    border: "1px solid #52A1DB",
    borderRadius: "4px",
    p: 1.5,
    backgroundColor: "white",
    width: "100%",
  };

  const commentTextFieldStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& fieldset": {
        borderColor: "#52A1DB",
      },
      "& textarea": {
        padding: "8px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
  };

  const handleChange = (field, subField, value) => {
    if (!isEditing) return;

    const isSelected = formData.employement[field][subField] === value;

    setFormData((prev) => ({
      ...prev,
      employement: {
        ...prev.employement,
        [field]: {
          ...prev.employement[field],
          [subField]: isSelected ? "" : value,
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      employement: {
        ...prev.employement,
        [field]: {
          ...prev.employement[field],
          comment: value,
        },
      },
    }));
  };

  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  // Add comment click handler
  const handleCommentClick = (field) => {
    console.log("handle comment click is called");
    // if (!isEditing) return;
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };
  useEffect(() => {
    console.log("comment", showCommentSection);
  }, [showCommentSection]);

  // Update renderCommentSection
  const renderCommentSection = (field) => {
    const hasComment = formData.employement?.[field]?.comment;
    return (
      <Box
        sx={{
          ...commentBoxStyle,
          display: showCommentSection[field]
            ? // ||(!showCommentSection[field] && hasComment && !isEditing)
              "block"
            : "none",
        }}
      >
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={formData.employement?.[field]?.comment || ""}
          onChange={(e) => handleCommentChange(field, e.target.value)}
          disabled={!isEditing}
          sx={commentTextFieldStyle}
        />
      </Box>
    );
  };

  // Add this style for radio buttons
  const radioStyle = {
    "&.Mui-checked": {
      color: "#52A1DB", // Always use blue for checked state
    },
    "&.Mui-disabled.Mui-checked": {
      color: "#52A1DB", // Keep blue even when disabled (non-edit mode)
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
    },
  };

  return (
    <Box>
      {/* Currently Employed */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Are you currently employed?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("currentlyEmployed")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={
              formData.employement?.currentlyEmployed?.isCurrentlyEmployed || ""
            }
            sx={{ justifyContent: "flex-start" }}
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handleChange(
                          "currentlyEmployed",
                          "isCurrentlyEmployed",
                          option.value,
                        ),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("currentlyEmployed")}
      </Box>

      {/* Interested in Employment */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Are you interested in employment?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("interestedEmployment")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={
              formData.employement?.interestedEmployment
                ?.isInterestedEmployment || ""
            }
            sx={{ justifyContent: "flex-start" }}
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handleChange(
                          "interestedEmployment",
                          "isInterestedEmployment",
                          option.value,
                        ),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("interestedEmployment")}
      </Box>

      {/* Volunteer */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Do you volunteer currently?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("volunteerCurrently")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={
              formData.employement?.volunteerCurrently?.isVolunteerCurrently ||
              ""
            }
            sx={{ justifyContent: "flex-start" }}
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handleChange(
                          "volunteerCurrently",
                          "isVolunteerCurrently",
                          option.value,
                        ),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("volunteerCurrently")}
      </Box>
    </Box>
  );
};
