import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Checkbox,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

export const AdvanceCareSection = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState({});

  // Unified comment section style
  const commentBoxStyle = {
    mt: 2,
    mb: 2,
    border: "1px solid #52A1DB",
    borderRadius: "4px",
    p: 1.5,
    backgroundColor: "white",
    width: "100%",
  };

  // Update TextField styling to keep text black in non-edit mode
  const textFieldStyle = {
    "& .MuiInputBase-input": {
      color: "#000000 !important", // Always keep text black
    },
    "& .MuiInputLabel-root": {
      color: "#000000", // Keep label text black
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(0, 0, 0, 0.42)", // Standard underline color
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid", // Keep the underline visible when disabled
    },
  };

  // Update comment text field style
  const commentTextFieldStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& fieldset": {
        borderColor: "#52A1DB",
      },
      "& textarea": {
        padding: "8px",
        fontSize: "14px",
        color: "#000000 !important", // Keep comment text black
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",
      color: "#000000 !important", // Keep text black
    },
  };

  // Update FormControlLabel style for radio buttons and checkboxes
  const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      color: "#000000 !important", // Keep label text black even when disabled
    },
    "&.Mui-disabled": {
      color: "#000000 !important", // Keep text black in disabled state
    },
  };

  // Add radio button styling
  const radioStyle = {
    "&.Mui-checked": {
      color: "#52A1DB", // Always use blue for checked state
    },
    "&.Mui-disabled.Mui-checked": {
      color: "#52A1DB", // Keep blue even when disabled (non-edit mode)
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
    },
  };

  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const handleHealthCareProxyChange = (value) => {
    if (!isEditing) return;

    const isSelected =
      formData.advanceCarePlanning.healthCareProxy.isHealthCareProxy === value;

    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        healthCareProxy: {
          ...prev.advanceCarePlanning.healthCareProxy,
          isHealthCareProxy: isSelected ? "" : value,
        },
      },
    }));
  };

  const handlePowerAttorneyChange = (value) => {
    if (!isEditing) return;

    const isSelected =
      formData.advanceCarePlanning.powerAttorney.isPowerAttorney === value;

    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        powerAttorney: {
          ...prev.advanceCarePlanning.powerAttorney,
          isPowerAttorney: isSelected ? "" : value,
        },
      },
    }));
  };

  const handleAdvanceDirectiveChange = (value) => {
    if (!isEditing) return;

    const isSelected =
      formData.advanceCarePlanning.advanceDirective.isAdvanceDirective ===
      value;

    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        advanceDirective: {
          ...prev.advanceCarePlanning.advanceDirective,
          isAdvanceDirective: isSelected ? "" : value,
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        [field]: {
          ...prev.advanceCarePlanning[field],
          comment: value,
        },
      },
    }));
  };

  const handleProxySubAnswerChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        healthCareProxy: {
          ...prev.advanceCarePlanning.healthCareProxy,
          isHealthCareProxySubAnswer: {
            ...prev.advanceCarePlanning.healthCareProxy
              .isHealthCareProxySubAnswer,
            [field]: value,
          },
        },
      },
    }));
  };

  const handleAttorneySubAnswerChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        powerAttorney: {
          ...prev.advanceCarePlanning.powerAttorney,
          isPowerAttorneySubAnswer: {
            ...prev.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer,
            [field]: value,
          },
        },
      },
    }));
  };

  // Add comment click handler
  const handleCommentClick = (field) => {
    // if (!isEditing) return;
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Update renderCommentSection
  const renderCommentSection = (field) => {
    const hasComment = formData.advanceCarePlanning?.[field]?.comment;
    console.log("render comm", showCommentSection[field]);

    return (
      <Box
        sx={{
          ...commentBoxStyle,
          display: showCommentSection[field]
            ? // ||(!showCommentSection[field] && hasComment && !isEditing)
              "block"
            : "none",
        }}
      >
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={formData.advanceCarePlanning?.[field]?.comment || ""}
          onChange={(e) => handleCommentChange(field, e.target.value)}
          disabled={!isEditing}
          sx={commentTextFieldStyle}
        />
      </Box>
    );
  };

  // Update Checkbox styling to match
  const checkboxStyle = {
    "&.Mui-checked": {
      color: "#52A1DB",
    },
    "&.Mui-disabled.Mui-checked": {
      color: "#52A1DB",
    },
  };

  return (
    <Box>
      {/* Healthcare Proxy */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography
            sx={{
              color: "#000000",
              flex: 1,
              textAlign: "left",
              fontWeight: "medium",
            }}
          >
            Do you have a Healthcare Proxy?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("healthCareProxy")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={
              formData.advanceCarePlanning.healthCareProxy.isHealthCareProxy
            }
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handleHealthCareProxyChange(option.value),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>

          {formData.advanceCarePlanning.healthCareProxy.isHealthCareProxy ===
            "1" && (
            <Box sx={{ mt: 2, textAlign: "left" }}>
              <TextField
                fullWidth
                label="Name"
                variant="standard"
                value={
                  formData.advanceCarePlanning.healthCareProxy
                    .isHealthCareProxySubAnswer?.name || ""
                }
                onChange={(e) =>
                  handleProxySubAnswerChange("name", e.target.value)
                }
                disabled={!isEditing}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Relationship"
                variant="standard"
                value={
                  formData.advanceCarePlanning.healthCareProxy
                    .isHealthCareProxySubAnswer?.relationship || ""
                }
                onChange={(e) =>
                  handleProxySubAnswerChange("relationship", e.target.value)
                }
                disabled={!isEditing}
                sx={textFieldStyle}
              />
            </Box>
          )}
        </FormControl>
        {renderCommentSection("healthCareProxy")}
      </Box>

      {/* Power of Attorney */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography
            sx={{
              color: "#000000",
              flex: 1,
              textAlign: "left",
              fontWeight: "medium",
            }}
          >
            Do you have a Durable Power of Attorney?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("powerAttorney")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={formData.advanceCarePlanning.powerAttorney.isPowerAttorney}
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handlePowerAttorneyChange(option.value),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>

          {formData.advanceCarePlanning.powerAttorney.isPowerAttorney ===
            "1" && (
            <Box sx={{ mt: 2, textAlign: "left" }}>
              <TextField
                fullWidth
                label="Name"
                variant="standard"
                value={
                  formData.advanceCarePlanning.powerAttorney
                    .isPowerAttorneySubAnswer?.name || ""
                }
                onChange={(e) =>
                  handleAttorneySubAnswerChange("name", e.target.value)
                }
                disabled={!isEditing}
                sx={{ ...textFieldStyle, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Relationship"
                variant="standard"
                value={
                  formData.advanceCarePlanning.powerAttorney
                    .isPowerAttorneySubAnswer?.relationship || ""
                }
                onChange={(e) =>
                  handleAttorneySubAnswerChange("relationship", e.target.value)
                }
                disabled={!isEditing}
                sx={textFieldStyle}
              />
            </Box>
          )}
        </FormControl>
        {renderCommentSection("powerAttorney")}
      </Box>

      {/* Advance Directive */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography
            sx={{
              color: "#000000",
              flex: 1,
              textAlign: "left",
              fontWeight: "medium",
            }}
          >
            Do you have an Advance Directive?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("advanceDirective")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            row
            value={
              formData.advanceCarePlanning.advanceDirective.isAdvanceDirective
            }
          >
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
              { value: "3", label: "Don't Know" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, () =>
                        handleAdvanceDirectiveChange(option.value),
                      )
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>

          {formData.advanceCarePlanning.advanceDirective.isAdvanceDirective ===
            "1" && (
            <Box sx={{ mt: 2, textAlign: "left" }}>
              <TextField
                fullWidth
                label="Where is it kept?"
                variant="standard"
                value={
                  formData.advanceCarePlanning.advanceDirective
                    .isAdvanceDirectiveSubAnswer?.kept || ""
                }
                onChange={(e) => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    advanceCarePlanning: {
                      ...prev.advanceCarePlanning,
                      advanceDirective: {
                        ...prev.advanceCarePlanning.advanceDirective,
                        isAdvanceDirectiveSubAnswer: {
                          kept: e.target.value,
                        },
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={textFieldStyle}
              />
            </Box>
          )}
        </FormControl>
        {renderCommentSection("advanceDirective")}
      </Box>

      {/* Additional Checkboxes */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormControlLabel
            sx={{ ...formControlLabelStyle, flex: 1 }}
            control={
              <Checkbox
                checked={
                  formData.advanceCarePlanning.educatedOnAdvanceCarePlanning
                    .isEducatedOnAdvanceCarePlanning === "1"
                }
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    advanceCarePlanning: {
                      ...prev.advanceCarePlanning,
                      educatedOnAdvanceCarePlanning: {
                        ...prev.advanceCarePlanning
                          .educatedOnAdvanceCarePlanning,
                        isEducatedOnAdvanceCarePlanning:
                          prev.advanceCarePlanning.educatedOnAdvanceCarePlanning
                            .isEducatedOnAdvanceCarePlanning === "1"
                            ? ""
                            : "1",
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={checkboxStyle}
              />
            }
            label="Member educated on advance care planning"
          />
          <IconButton
            onClick={() => handleCommentClick("educatedOnAdvanceCarePlanning")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {renderCommentSection("educatedOnAdvanceCarePlanning")}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            sx={{ ...formControlLabelStyle, flex: 1 }}
            control={
              <Checkbox
                checked={
                  formData.advanceCarePlanning.declinesDiscussion
                    .isDeclinesDiscussion === "1"
                }
                onChange={() => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    advanceCarePlanning: {
                      ...prev.advanceCarePlanning,
                      declinesDiscussion: {
                        ...prev.advanceCarePlanning.declinesDiscussion,
                        isDeclinesDiscussion:
                          prev.advanceCarePlanning.declinesDiscussion
                            .isDeclinesDiscussion === "1"
                            ? ""
                            : "1",
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={checkboxStyle}
              />
            }
            label="Declined discussion at this time"
          />
          <IconButton
            onClick={() => handleCommentClick("declinesDiscussion")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {renderCommentSection("declinesDiscussion")}
      </Box>
    </Box>
  );
};
