import React from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Paper,
  Checkbox,
} from "@mui/material";

const radioStyle = {
  "&.Mui-checked": {
    color: "#52A1DB", // Blue for checked state (both edit and non-edit mode)
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#52A1DB", // Keep blue for checked state in non-edit mode
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
  },
};

const toggleButtonStyle = {
  "&.Mui-selected": {
    backgroundColor: "#52A1DB",
    color: "white",
    "&:hover": {
      backgroundColor: "#52A1DB",
    },
  },
  "&.Mui-selected.Mui-disabled": {
    backgroundColor: "#52A1DB",
    color: "white",
  },
  "&.Mui-disabled": {
    color: "black",
  },
  color: "black",
};

const AlcoholAssessment = ({ formData, isEditing, setFormData }) => {
  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange, currentValue) => {
    e.stopPropagation();
    // If clicking the same radio button that's already selected, unselect it
    if (e.target.value === currentValue) {
      onChange("");
    } else {
      onChange(e.target.value);
    }
  };

  const handleAlcoholStatusChange = (status) => {
    if (!isEditing) return;
    // Create empty state for alcohol use
    const emptyAlcoholUse = {
      current: "",
      currentSubAnswer: {
        howMnyTimes: {
          isNone: "",
          oneOrMore: "",
          oneOrMoreSubAnswers: {
            howOften: {
              never: "",
              monthly: "",
              twoTimesMonth: "",
              twoTimesWeek: "",
              fourTimesWeek: "",
              comment: "",
            },
            howManyDrinks: {
              oneOrTwo: "",
              threeOrFour: "",
              fiveOrSix: "",
              sevenEight: "",
              tenOrMore: "",
              comment: "",
            },
            sixOrMore: {
              never: "",
              lessThanMonthly: "",
              monthly: "",
              weekly: "",
              daily: "",
              comment: "",
            },
            auditScore: "",
            weeklyAverage: "",
            comment: "",
          },
          comment: "",
        },
        currentlyInRecovery: {
          isCurrentlyInRecovery: "",
          comment: "",
        },
      },
      former: "",
      formerSubAnswer: {
        howMnyTimes: {
          isNone: "",
          oneOrMore: "",
          oneOrMoreSubAnswers: {
            howOften: {
              never: "",
              monthly: "",
              twoTimesMonth: "",
              twoTimesWeek: "",
              fourTimesWeek: "",
              comment: "",
            },
            howManyDrinks: {
              oneOrTwo: "",
              threeOrFour: "",
              fiveOrSix: "",
              sevenEight: "",
              tenOrMore: "",
              comment: "",
            },
            sixOrMore: {
              never: "",
              lessThanMonthly: "",
              monthly: "",
              weekly: "",
              daily: "",
              comment: "",
            },
            auditScore: "",
            weeklyAverage: "",
            comment: "",
          },
          comment: "",
        },
        currentlyInRecovery: {
          isCurrentlyInRecovery: "",
          comment: "",
        },
      },
      never: "",
      comment: "",
    };

    // If clicking the same button that's already selected, clear everything
    const currentStatus =
      formData.substanceUse.alcoholUse.current === "1"
        ? "current"
        : formData.substanceUse.alcoholUse.former === "1"
        ? "former"
        : formData.substanceUse.alcoholUse.never === "1"
        ? "never"
        : "";
    if (status === currentStatus) {
      status = ""; // This will clear the selection
    }

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...emptyAlcoholUse,
          comment: prev.substanceUse.alcoholUse.comment || "",
          ...(status && { [status]: "1" }),
        },
      },
    }));
  };

  const handleHowManyTimesChange = (value) => {
    if (!isEditing) return;
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";
    const isSelected =
      formData.substanceUse.alcoholUse[section].howMnyTimes[value] === "1";
    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            howMnyTimes: {
              ...prev.substanceUse.alcoholUse[section].howMnyTimes,
              isNone: value === "isNone" && !isSelected ? "1" : "",
              oneOrMore: value === "oneOrMore" && !isSelected ? "1" : "",
              comment:
                prev.substanceUse.alcoholUse[section].howMnyTimes.comment,
            },
          },
        },
      },
    }));
  };

  const handleAlcoholFrequencyChange = (value) => {
    if (!isEditing) return;
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";
    const isSelected =
      formData.substanceUse.alcoholUse[section].howMnyTimes.oneOrMoreSubAnswers
        .howOften[value] === "1";
    const updatedFrequency = {
      never: "",
      monthly: "",
      twoTimesMonth: "",
      twoTimesWeek: "",
      fourTimesWeek: "",
      comment:
        formData.substanceUse.alcoholUse[section].howMnyTimes
          .oneOrMoreSubAnswers.howOften.comment,
    };

    if (!isSelected) {
      updatedFrequency[value] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            howMnyTimes: {
              ...prev.substanceUse.alcoholUse[section].howMnyTimes,
              oneOrMoreSubAnswers: {
                ...prev.substanceUse.alcoholUse[section].howMnyTimes
                  .oneOrMoreSubAnswers,
                howOften: updatedFrequency,
              },
            },
          },
        },
      },
    }));
  };

  const handleDrinksPerDayChange = (value) => {
    if (!isEditing) return;
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";

    const isSelected =
      formData.substanceUse.alcoholUse[section].howMnyTimes.oneOrMoreSubAnswers
        .howManyDrinks[value] === "1";

    const updatedDrinks = {
      oneOrTwo: "",
      threeOrFour: "",
      fiveOrSix: "",
      sevenEight: "",
      tenOrMore: "",
      comment:
        formData.substanceUse.alcoholUse[section].howMnyTimes
          .oneOrMoreSubAnswers.howManyDrinks.comment,
    };

    if (!isSelected) {
      updatedDrinks[value] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            howMnyTimes: {
              ...prev.substanceUse.alcoholUse[section].howMnyTimes,
              oneOrMoreSubAnswers: {
                ...prev.substanceUse.alcoholUse[section].howMnyTimes
                  .oneOrMoreSubAnswers,
                howManyDrinks: updatedDrinks,
              },
            },
          },
        },
      },
    }));
  };

  const handleSixOrMoreChange = (value) => {
    if (!isEditing) return;
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";

    const isSelected =
      formData.substanceUse.alcoholUse[section].howMnyTimes.oneOrMoreSubAnswers
        .sixOrMore[value] === "1";

    const updatedSixOrMore = {
      never: "",
      lessThanMonthly: "",
      monthly: "",
      weekly: "",
      daily: "",
      comment:
        formData.substanceUse.alcoholUse[section].howMnyTimes
          .oneOrMoreSubAnswers.sixOrMore.comment,
    };

    if (!isSelected) {
      updatedSixOrMore[value] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            howMnyTimes: {
              ...prev.substanceUse.alcoholUse[section].howMnyTimes,
              oneOrMoreSubAnswers: {
                ...prev.substanceUse.alcoholUse[section].howMnyTimes
                  .oneOrMoreSubAnswers,
                sixOrMore: updatedSixOrMore,
              },
            },
          },
        },
      },
    }));
  };

  const handleRecoveryChange = (value) => {
    if (!isEditing) return;
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";

    const isSelected =
      formData.substanceUse.alcoholUse[section].currentlyInRecovery
        .isCurrentlyInRecovery === value;

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            currentlyInRecovery: {
              ...prev.substanceUse.alcoholUse[section].currentlyInRecovery,
              isCurrentlyInRecovery: isSelected ? "" : value,
              comment:
                prev.substanceUse.alcoholUse[section].currentlyInRecovery
                  .comment,
            },
          },
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;

    // Handle education provided comment separately
    if (field === "educationProvided") {
      setFormData((prev) => ({
        ...prev,
        substanceUse: {
          ...prev.substanceUse,
          educationProvided: {
            ...prev.substanceUse.educationProvided,
            comment: value,
          },
        },
      }));
      return;
    }

    // Handle alcohol use related comments
    const section =
      formData.substanceUse.alcoholUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        alcoholUse: {
          ...prev.substanceUse.alcoholUse,
          [section]: {
            ...prev.substanceUse.alcoholUse[section],
            howMnyTimes: {
              ...prev.substanceUse.alcoholUse[section].howMnyTimes,
              comment: value,
            },
          },
        },
      },
    }));
  };

  const handleProxySubAnswerChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        healthCareProxy: {
          ...prev.advanceCarePlanning.healthCareProxy,
          isHealthCareProxySubAnswer: {
            ...prev.advanceCarePlanning.healthCareProxy
              .isHealthCareProxySubAnswer,
            [field]: value,
          },
        },
      },
    }));
  };

  const handleAttorneySubAnswerChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      advanceCarePlanning: {
        ...prev.advanceCarePlanning,
        powerAttorney: {
          ...prev.advanceCarePlanning.powerAttorney,
          isPowerAttorneySubAnswer: {
            ...prev.advanceCarePlanning.powerAttorney.isPowerAttorneySubAnswer,
            [field]: value,
          },
        },
      },
    }));
  };

  return (
    <Box sx={{ mb: 4 }}>
      {/* <Typography variant="h6" gutterBottom align="left">
        Alcohol Assessment
      </Typography> */}

      <Paper sx={{ p: 3, mb: 3 }}>
        {/* Question sections */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "flex-start",
          }}
        >
          <Typography variant="body1" gutterBottom align="left">
            Have you ever consumed alcohol?
          </Typography>

          <ToggleButtonGroup
            exclusive
            value={
              formData.substanceUse.alcoholUse.current === "1"
                ? "current"
                : formData.substanceUse.alcoholUse.former === "1"
                ? "former"
                : formData.substanceUse.alcoholUse.never === "1"
                ? "never"
                : ""
            }
            onChange={(event, newValue) => {
              if (!isEditing) return;
              handleAlcoholStatusChange(newValue);
            }}
            disabled={!isEditing}
            sx={{ alignSelf: "flex-start" }}
          >
            <ToggleButton value="current" sx={toggleButtonStyle}>
              CURRENT
            </ToggleButton>
            <ToggleButton value="former" sx={toggleButtonStyle}>
              FORMER
            </ToggleButton>
            <ToggleButton value="never" sx={toggleButtonStyle}>
              NEVER
            </ToggleButton>
          </ToggleButtonGroup>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mb: 2,
              backgroundColor: "white", // White background for comment box
              width: "100%", // Make the comment box full width
            }}
          >
            <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
              Comments:
            </Typography>

            <TextField
              fullWidth
              multiline
              rows={1}
              variant="outlined"
              label="Comments"
              value={formData.substanceUse.alcoholUse.comment || ""}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  substanceUse: {
                    ...prev.substanceUse,
                    alcoholUse: {
                      ...prev.substanceUse.alcoholUse,
                      comment: e.target.value,
                    },
                  },
                }));
              }}
              disabled={!isEditing}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "4px",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {(formData.substanceUse.alcoholUse.current === "1" ||
          formData.substanceUse.alcoholUse.former === "1") && (
          <>
            {/* Initial question before AUDIT-C */}
            <Box
              sx={{
                mt: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                sx={{ color: "black" }}
              >
                How many times in the past year have you had 4-5 drinks or more
                in a day?
              </Typography>

              <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
                <Button
                  variant={
                    formData.substanceUse.alcoholUse[
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer"
                    ].howMnyTimes.isNone === "1"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleHowManyTimesChange("isNone")}
                  disabled={!isEditing}
                  sx={{
                    color: "black",
                    "&.Mui-selected": {
                      backgroundColor: "#52A1DB",
                      color: "white",
                    },
                    "&.MuiButton-contained": {
                      backgroundColor: "#52A1DB",
                      color: "white",
                    },
                  }}
                >
                  None
                </Button>
                <Button
                  variant={
                    formData.substanceUse.alcoholUse[
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer"
                    ].howMnyTimes.oneOrMore === "1"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleHowManyTimesChange("oneOrMore")}
                  disabled={!isEditing}
                  sx={{
                    color: "black",
                    "&.Mui-selected": {
                      backgroundColor: "#52A1DB",
                      color: "white",
                    },
                    "&.MuiButton-contained": {
                      backgroundColor: "#52A1DB",
                      color: "white",
                    },
                  }}
                >
                  1 or more
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                  backgroundColor: "white", // White background for comment box
                  width: "100%", // Make the comment box full width
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>

                <TextField
                  fullWidth
                  multiline
                  rows={1}
                  variant="outlined"
                  label="Comments"
                  value={
                    formData.substanceUse.alcoholUse[
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer"
                    ].howMnyTimes.comment || ""
                  }
                  onChange={(e) =>
                    handleCommentChange("howMnyTimes", e.target.value)
                  }
                  disabled={!isEditing}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      "& fieldset": {
                        borderColor: "#52A1DB",
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            {/* AUDIT-C Section */}
            {formData.substanceUse.alcoholUse[
              formData.substanceUse.alcoholUse.current === "1"
                ? "currentSubAnswer"
                : "formerSubAnswer"
            ].howMnyTimes.oneOrMore === "1" && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="left"
                  sx={{ color: "black" }}
                >
                  Alcohol Use Disorders Identification Test-Consumption
                  (AUDIT-C)
                </Typography>

                {/* Questions */}
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  {/* How often question */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="left"
                      sx={{ color: "black" }}
                    >
                      How often do you have a drink containing alcohol?
                    </Typography>

                    <RadioGroup sx={{ alignItems: "flex-start" }}>
                      {[
                        { value: "never", label: "Never (+0)" },
                        { value: "monthly", label: "Monthly or less (+1)" },
                        {
                          value: "twoTimesMonth",
                          label: "2 to 4 times a month (+2)",
                        },
                        {
                          value: "twoTimesWeek",
                          label: "2 to 3 times a week (+3)",
                        },
                        {
                          value: "fourTimesWeek",
                          label: "4 or more times a week (+4)",
                        },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={
                            <Radio
                              checked={(() => {
                                const section =
                                  formData.substanceUse.alcoholUse.current ===
                                  "1"
                                    ? "currentSubAnswer"
                                    : "formerSubAnswer";
                                return (
                                  formData.substanceUse.alcoholUse[section]
                                    .howMnyTimes.oneOrMoreSubAnswers.howOften[
                                    option.value
                                  ] === "1"
                                );
                              })()}
                              onClick={(e) =>
                                handleRadioClick(
                                  e,
                                  () =>
                                    handleAlcoholFrequencyChange(option.value),
                                  option.value,
                                )
                              }
                              sx={radioStyle}
                            />
                          }
                          label={option.label}
                          disabled={!isEditing}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              color: "black",
                            },
                            "& .MuiFormControlLabel-label.Mui-disabled": {
                              color: "black",
                            },
                          }}
                        />
                      ))}
                    </RadioGroup>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white", // White background for comment box
                        width: "100%", // Make the comment box full width
                      }}
                    >
                      <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                        Comments:
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={1}
                        variant="outlined"
                        label="Comments"
                        value={
                          formData.substanceUse.alcoholUse[
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer"
                          ].howMnyTimes.oneOrMoreSubAnswers.howOften.comment ||
                          ""
                        }
                        onChange={(e) => {
                          const section =
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer";
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              alcoholUse: {
                                ...prev.substanceUse.alcoholUse,
                                [section]: {
                                  ...prev.substanceUse.alcoholUse[section],
                                  howMnyTimes: {
                                    ...prev.substanceUse.alcoholUse[section]
                                      .howMnyTimes,
                                    oneOrMoreSubAnswers: {
                                      ...prev.substanceUse.alcoholUse[section]
                                        .howMnyTimes.oneOrMoreSubAnswers,
                                      howOften: {
                                        ...prev.substanceUse.alcoholUse[section]
                                          .howMnyTimes.oneOrMoreSubAnswers
                                          .howOften,
                                        comment: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        sx={{
                          mt: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: "#52A1DB",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Standard drinks question */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="left"
                      sx={{ color: "black" }}
                    >
                      How many standard drinks containing alcohol do you have on
                      a typical day?
                    </Typography>

                    <RadioGroup sx={{ alignItems: "flex-start" }}>
                      {[
                        { value: "oneOrTwo", label: "1 or 2 (+0)" },
                        { value: "threeOrFour", label: "3 or 4 (+1)" },
                        { value: "fiveOrSix", label: "5 or 6 (+2)" },
                        { value: "sevenEight", label: "7,8 or 9 (+3)" },
                        { value: "tenOrMore", label: "10 or more (+4)" },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={
                            <Radio
                              checked={(() => {
                                const section =
                                  formData.substanceUse.alcoholUse.current ===
                                  "1"
                                    ? "currentSubAnswer"
                                    : "formerSubAnswer";
                                return (
                                  formData.substanceUse.alcoholUse[section]
                                    .howMnyTimes.oneOrMoreSubAnswers
                                    .howManyDrinks[option.value] === "1"
                                );
                              })()}
                              onClick={(e) =>
                                handleRadioClick(
                                  e,
                                  () => handleDrinksPerDayChange(option.value),
                                  option.value,
                                )
                              }
                              sx={radioStyle}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ color: "black" }}>
                              {option.label}
                            </Typography>
                          }
                          disabled={!isEditing}
                        />
                      ))}
                    </RadioGroup>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white", // White background for comment box
                        width: "100%", // Make the comment box full width
                      }}
                    >
                      <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                        Comments:
                      </Typography>

                      <TextField
                        fullWidth
                        multiline
                        rows={1}
                        variant="outlined"
                        label="Comments"
                        value={
                          formData.substanceUse.alcoholUse[
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer"
                          ].howMnyTimes.oneOrMoreSubAnswers.howManyDrinks
                            .comment || ""
                        }
                        onChange={(e) => {
                          const section =
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer";
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              alcoholUse: {
                                ...prev.substanceUse.alcoholUse,
                                [section]: {
                                  ...prev.substanceUse.alcoholUse[section],
                                  howMnyTimes: {
                                    ...prev.substanceUse.alcoholUse[section]
                                      .howMnyTimes,
                                    oneOrMoreSubAnswers: {
                                      ...prev.substanceUse.alcoholUse[section]
                                        .howMnyTimes.oneOrMoreSubAnswers,
                                      howManyDrinks: {
                                        ...prev.substanceUse.alcoholUse[section]
                                          .howMnyTimes.oneOrMoreSubAnswers
                                          .howManyDrinks,
                                        comment: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        sx={{
                          mt: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: "#52A1DB",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Six or more drinks question */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="left"
                      sx={{ color: "black" }}
                    >
                      How often do you have six or more drinks on one occasion?
                    </Typography>

                    <RadioGroup sx={{ alignItems: "flex-start" }}>
                      {[
                        { value: "never", label: "Never (+0)" },
                        {
                          value: "lessThanMonthly",
                          label: "Less than Monthly (+1)",
                        },
                        { value: "monthly", label: "Monthly (+2)" },
                        { value: "weekly", label: "Weekly (+3)" },
                        { value: "daily", label: "Daily or almost daily (+4)" },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={
                            <Radio
                              checked={(() => {
                                const section =
                                  formData.substanceUse.alcoholUse.current ===
                                  "1"
                                    ? "currentSubAnswer"
                                    : "formerSubAnswer";
                                return (
                                  formData.substanceUse.alcoholUse[section]
                                    .howMnyTimes.oneOrMoreSubAnswers.sixOrMore[
                                    option.value
                                  ] === "1"
                                );
                              })()}
                              onClick={(e) =>
                                handleRadioClick(
                                  e,
                                  () => handleSixOrMoreChange(option.value),
                                  option.value,
                                )
                              }
                              sx={radioStyle}
                            />
                          }
                          label={
                            <Typography variant="body1" sx={{ color: "black" }}>
                              {option.label}
                            </Typography>
                          }
                          disabled={!isEditing}
                        />
                      ))}
                    </RadioGroup>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white", // White background for comment box
                        width: "100%", // Make the comment box full width
                      }}
                    >
                      <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                        Comments:
                      </Typography>

                      <TextField
                        fullWidth
                        multiline
                        rows={1}
                        variant="outlined"
                        label="Comments"
                        value={
                          formData.substanceUse.alcoholUse[
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer"
                          ].howMnyTimes.oneOrMoreSubAnswers.sixOrMore.comment ||
                          ""
                        }
                        onChange={(e) => {
                          const section =
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer";
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              alcoholUse: {
                                ...prev.substanceUse.alcoholUse,
                                [section]: {
                                  ...prev.substanceUse.alcoholUse[section],
                                  howMnyTimes: {
                                    ...prev.substanceUse.alcoholUse[section]
                                      .howMnyTimes,
                                    oneOrMoreSubAnswers: {
                                      ...prev.substanceUse.alcoholUse[section]
                                        .howMnyTimes.oneOrMoreSubAnswers,
                                      sixOrMore: {
                                        ...prev.substanceUse.alcoholUse[section]
                                          .howMnyTimes.oneOrMoreSubAnswers
                                          .sixOrMore,
                                        comment: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        sx={{
                          mt: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: "#52A1DB",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {/* AUDIT Score section */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      align="left"
                      sx={{ color: "black" }}
                    >
                      Audit C Score
                    </Typography>

                    <TextField
                      fullWidth
                      variant="standard"
                      value={(() => {
                        const section =
                          formData.substanceUse.alcoholUse.current === "1"
                            ? "currentSubAnswer"
                            : "formerSubAnswer";
                        const howOften =
                          formData.substanceUse.alcoholUse[section].howMnyTimes
                            .oneOrMoreSubAnswers.howOften;
                        const howManyDrinks =
                          formData.substanceUse.alcoholUse[section].howMnyTimes
                            .oneOrMoreSubAnswers.howManyDrinks;
                        const sixOrMore =
                          formData.substanceUse.alcoholUse[section].howMnyTimes
                            .oneOrMoreSubAnswers.sixOrMore;

                        let score = 0;
                        if (howOften.fourTimesWeek === "1") score += 4;
                        else if (howOften.twoTimesWeek === "1") score += 3;
                        else if (howOften.twoTimesMonth === "1") score += 2;
                        else if (howOften.monthly === "1") score += 1;

                        if (howManyDrinks.tenOrMore === "1") score += 4;
                        else if (howManyDrinks.sevenEight === "1") score += 3;
                        else if (howManyDrinks.fiveOrSix === "1") score += 2;
                        else if (howManyDrinks.threeOrFour === "1") score += 1;

                        if (sixOrMore.daily === "1") score += 4;
                        else if (sixOrMore.weekly === "1") score += 3;
                        else if (sixOrMore.monthly === "1") score += 2;
                        else if (sixOrMore.lessThanMonthly === "1") score += 1;

                        return score;
                      })()}
                      disabled
                    />

                    <Typography variant="caption" color="error" align="left">
                      Men: a score of 4 or more is considered positive
                    </Typography>
                    <Typography variant="caption" color="error" align="left">
                      Women: a score of 3 or more is considered positive
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white", // White background for comment box
                        width: "100%", // Make the comment box full width
                      }}
                    >
                      <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                        Comments:
                      </Typography>

                      <TextField
                        fullWidth
                        multiline
                        rows={1}
                        variant="outlined"
                        label="Comments"
                        value={
                          formData.substanceUse.alcoholUse[
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer"
                          ].howMnyTimes.oneOrMoreSubAnswers.comment || ""
                        }
                        onChange={(e) => {
                          const section =
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer";
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              alcoholUse: {
                                ...prev.substanceUse.alcoholUse,
                                [section]: {
                                  ...prev.substanceUse.alcoholUse[section],
                                  howMnyTimes: {
                                    ...prev.substanceUse.alcoholUse[section]
                                      .howMnyTimes,
                                    oneOrMoreSubAnswers: {
                                      ...prev.substanceUse.alcoholUse[section]
                                        .howMnyTimes.oneOrMoreSubAnswers,
                                      comment: e.target.value,
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        sx={{
                          mt: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: "#52A1DB",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Weekly Average section */}
                  {(() => {
                    const section =
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer";
                    const howOften =
                      formData.substanceUse.alcoholUse[section].howMnyTimes
                        .oneOrMoreSubAnswers.howOften;
                    const howManyDrinks =
                      formData.substanceUse.alcoholUse[section].howMnyTimes
                        .oneOrMoreSubAnswers.howManyDrinks;
                    const sixOrMore =
                      formData.substanceUse.alcoholUse[section].howMnyTimes
                        .oneOrMoreSubAnswers.sixOrMore;

                    let score = 0;
                    if (howOften.fourTimesWeek === "1") score += 4;
                    else if (howOften.twoTimesWeek === "1") score += 3;
                    else if (howOften.twoTimesMonth === "1") score += 2;
                    else if (howOften.monthly === "1") score += 1;

                    if (howManyDrinks.tenOrMore === "1") score += 4;
                    else if (howManyDrinks.sevenEight === "1") score += 3;
                    else if (howManyDrinks.fiveOrSix === "1") score += 2;
                    else if (howManyDrinks.threeOrFour === "1") score += 1;

                    if (sixOrMore.daily === "1") score += 4;
                    else if (sixOrMore.weekly === "1") score += 3;
                    else if (sixOrMore.monthly === "1") score += 2;
                    else if (sixOrMore.lessThanMonthly === "1") score += 1;

                    return score >= 3 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="left"
                          sx={{ color: "black" }}
                        >
                          Weekly Average? (how many drinks x how many days a
                          week)
                        </Typography>

                        <TextField
                          fullWidth
                          variant="standard"
                          value={
                            formData.substanceUse.alcoholUse[section]
                              .howMnyTimes.oneOrMoreSubAnswers.weeklyAverage ||
                            ""
                          }
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              substanceUse: {
                                ...prev.substanceUse,
                                alcoholUse: {
                                  ...prev.substanceUse.alcoholUse,
                                  [section]: {
                                    ...prev.substanceUse.alcoholUse[section],
                                    howMnyTimes: {
                                      ...prev.substanceUse.alcoholUse[section]
                                        .howMnyTimes,
                                      oneOrMoreSubAnswers: {
                                        ...prev.substanceUse.alcoholUse[section]
                                          .howMnyTimes.oneOrMoreSubAnswers,
                                        weeklyAverage: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            }));
                          }}
                          disabled={!isEditing}
                        />
                      </Box>
                    ) : null;
                  })()}
                </Box>
              </Box>
            )}

            {/* Recovery question */}
            <Box
              sx={{
                mt: 4,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                align="left"
                sx={{ color: "black" }}
              >
                Are you currently in recovery for alcohol or substance use?
              </Typography>

              <RadioGroup row sx={{ alignItems: "flex-start" }}>
                {[
                  { value: "1", label: "Yes" },
                  { value: "2", label: "No" },
                ].map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        checked={(() => {
                          const section =
                            formData.substanceUse.alcoholUse.current === "1"
                              ? "currentSubAnswer"
                              : "formerSubAnswer";
                          return (
                            formData.substanceUse.alcoholUse[section]
                              .currentlyInRecovery.isCurrentlyInRecovery ===
                            option.value
                          );
                        })()}
                        onClick={(e) =>
                          handleRadioClick(
                            e,
                            () => handleRecoveryChange(option.value),
                            formData.substanceUse.alcoholUse[
                              formData.substanceUse.alcoholUse.current === "1"
                                ? "currentSubAnswer"
                                : "formerSubAnswer"
                            ].currentlyInRecovery.isCurrentlyInRecovery,
                          )
                        }
                        sx={{
                          color: "black",
                          "&.Mui-checked": {
                            color: "#52A1DB",
                          },
                          "&.Mui-disabled.Mui-checked": {
                            color: "#52A1DB",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ color: "black" }}>
                        {option.label}
                      </Typography>
                    }
                    disabled={!isEditing}
                  />
                ))}
              </RadioGroup>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                  backgroundColor: "white", // White background for comment box
                  width: "100%", // Make the comment box full width
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>

                <TextField
                  fullWidth
                  multiline
                  rows={1}
                  variant="outlined"
                  label="Comments"
                  value={
                    formData.substanceUse.alcoholUse[
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer"
                    ].currentlyInRecovery.comment || ""
                  }
                  onChange={(e) => {
                    const section =
                      formData.substanceUse.alcoholUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer";
                    setFormData((prev) => ({
                      ...prev,
                      substanceUse: {
                        ...prev.substanceUse,
                        alcoholUse: {
                          ...prev.substanceUse.alcoholUse,
                          [section]: {
                            ...prev.substanceUse.alcoholUse[section],
                            currentlyInRecovery: {
                              ...prev.substanceUse.alcoholUse[section]
                                .currentlyInRecovery,
                              comment: e.target.value,
                            },
                          },
                        },
                      },
                    }));
                  }}
                  disabled={!isEditing}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      "& fieldset": {
                        borderColor: "#52A1DB",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        {/* Move education provided section outside the conditional rendering */}
        <Box
          sx={{
            mt: 3,
            ml: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    formData.substanceUse.educationProvided
                      .isEducationProvided === "1"
                  }
                  onChange={() => {
                    if (!isEditing) return;
                    setFormData((prev) => ({
                      ...prev,
                      substanceUse: {
                        ...prev.substanceUse,
                        educationProvided: {
                          ...prev.substanceUse.educationProvided,
                          isEducationProvided:
                            prev.substanceUse.educationProvided
                              .isEducationProvided === "1"
                              ? ""
                              : "1",
                        },
                      },
                    }));
                  }}
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "#52A1DB",
                    },
                    "&.Mui-disabled.Mui-checked": {
                      color: "#52A1DB",
                    },
                    "&.Mui-disabled": {
                      color: "rgba(0, 0, 0, 0.38)",
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: "black" }}>
                  Education provided regarding alcohol use
                </Typography>
              }
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                backgroundColor: "white", // White background for comment box
                width: "100%", // Make the comment box full width
              }}
            >
              <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                Comments:
              </Typography>

              <TextField
                fullWidth
                multiline
                rows={1}
                variant="outlined"
                label="Comments"
                value={formData.substanceUse.educationProvided.comment || ""}
                onChange={(e) =>
                  handleCommentChange("educationProvided", e.target.value)
                }
                disabled={!isEditing}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#52A1DB",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AlcoholAssessment;
