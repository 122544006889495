import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PatientSummary = ({ user }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssessmentData();
  }, []);

  const getActivityLabel = (score) =>
    ({
      1: "Independent",
      2: "Needs Some Help",
      3: "Needs Moderate Help",
      4: "Needs Significant Help",
      5: "Completely Dependent",
    })[score] || "Not Assessed";

  const {
    self: { data: selfData = {} } = {},
    activities: { data: activitiesData = {} } = {},
    medical: {
      data: {
        healthServices = {},
        treatments = {},
        specialists = {},
        assistiveDevices = {},
      } = {},
    } = {},
    preventive: {
      data: { preventiveCare: { pastThreeYearsSubAnswers = [] } = {} } = {},
    } = {},
    home: { data: homeData = {} } = {},
    review: { data: { neuroPsych = {} } = {} } = {},
    demographics: { data: demographicsData = {} } = {},
    family: { data: familyData = {} } = {},
  } = assessmentData || {};

  const { substanceUse: { tobaccoUse = {}, alcoholUse = {} } = {} } =
    assessmentData?.self?.data || {};
  const fetchAssessmentData = async () => {
    const yearToUse = getValidYear(user?.year);
    try {
      const token = localStorage.getItem("token");
      if (!token || !user?.memberId) throw new Error("Authentication failed");

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-memberUpdatedAssessment?memberId=${user.memberId}&year=${yearToUse}`,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );
      const data = await response.json();
      if (data.code === 200) setAssessmentData(data.data);
      else throw new Error(data.message || "Failed to fetch data");
    } catch (err) {
      setError(err.message);
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!termsAccepted) {
      setIsDialogOpen(true);
      return;
    }
    setSubmitting(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authentication token not found");

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/terms-and-conditions`,
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            memberId: user?.memberId,
            year: getValidYear(user?.year),
            termsAndConditions: true,
          }),
        },
      );

      const data = await response.json();
      if (data.code === 200) {
        setTermsAccepted(true);
        setMessage("Terms and conditions accepted successfully!");
      } else {
        throw new Error(data.message || "Failed to accept terms");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to accept terms and conditions");
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitv2 = async () => {
    if (!termsAccepted) {
      setMessage("Please accept terms and conditions first");
      return;
    }

    setSubmitting(true);
    const yearToUse = getValidYear(user?.year);

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Authentication token not found");

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/approved-selfReporting-assessment`,
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            memberId: user?.memberId,
            year: yearToUse,
          }),
        },
      );

      const data = await response.json();
      if (data.code === 200) {
        setMessage("Assessment submitted successfully");
        navigate("/MemberPortal/Profile");
      } else {
        throw new Error(data.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Failed to submit assessment");
    } finally {
      setSubmitting(false);
    }
  };

  const getValidYear = (numYear) => {
    if (!numYear) return new Date().getFullYear();
    const validYears = [2023, 2024];
    const yearStr = String(numYear).trim();
    const yearNum = Number(yearStr);
    return validYears.includes(yearNum) ||
      validYears.map(String).includes(yearStr)
      ? yearNum
      : new Date().getFullYear();
  };

  const SummaryCard = ({ title, children }) => (
    <Card sx={{ mb: 3, boxShadow: 3 }}>
      <CardHeader
        title={title}
        sx={{
          bgcolor: "#1577BB",
          color: "white",
          py: 2,
        }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );

  const getEducationLevel = (schoolData) => {
    if (schoolData?.lessThanThirdGrade === "1") return "Less than 3rd grade";
    if (schoolData?.lessThanEighthGrade === "1") return "Less than 8th grade";
    if (schoolData?.lessThanTwelthGrade === "1") return "Less than 12th grade";
    if (schoolData?.completedTwelthGrade === "1") return "Completed 12th grade";
    if (schoolData?.attendedOrgraduated === "1")
      return "Attended or graduated college";
    return "Not specified";
  };

  const getMedicalFormConfidence = (data) => {
    if (data?.notAtAllConfident === "1") return "Not at all confident";
    if (data?.notVeryConfident === "1") return "Not very confident";
    if (data?.confident === "1") return "Confident";
    if (data?.veryConfident === "1") return "Very confident";
    return "Not specified";
  };

  const renderPHQSection = (neuroPsych) => {
    const data = neuroPsych?.overPastTwoWeeks || {};
    const phq2Score = data?.phq2score?.phq2score || "N/A";
    const phq9Score =
      data?.phq2score?.threeOrMoreSubAnswer?.depressionScreening?.phq9score ||
      "N/A";

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">7. PHQ Assessment</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {/* PHQ-2 Section */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  PHQ-2 Screening
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Score and Result
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {phq2Score}
                    </Typography>
                    <Typography>
                      {data?.phq2score?.threeOrMore === "1"
                        ? "≥ 3 (Positive)"
                        : data?.phq2score?.lessThanThree === "1"
                        ? "< 3 (Negative)"
                        : "N/A"}
                    </Typography>
                  </Box>

                  {/* Question 1 */}
                  <Box>
                    <Typography variant="subtitle2">
                      1. Little interest or pleasure in doing things:
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data?.littleInterestOrPleasure || {}).map(
                        ([key, value]) =>
                          value === "1" &&
                          key !== "comment" && (
                            <Typography key={key}>
                              • {key.replace(/([A-Z])/g, " $1").trim()}
                            </Typography>
                          ),
                      )}
                      {data?.littleInterestOrPleasure?.comment && (
                        <Typography color="text.secondary">
                          Comment: {data.littleInterestOrPleasure.comment}
                        </Typography>
                      )}
                    </Stack>
                  </Box>

                  {/* Question 2 */}
                  <Box>
                    <Typography variant="subtitle2">
                      2. Feeling down, depressed, or hopeless:
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data?.feelingDownDepressed || {}).map(
                        ([key, value]) =>
                          value === "1" &&
                          key !== "comment" && (
                            <Typography key={key}>
                              • {key.replace(/([A-Z])/g, " $1").trim()}
                            </Typography>
                          ),
                      )}
                      {data?.feelingDownDepressed?.comment && (
                        <Typography color="text.secondary">
                          Comment: {data.feelingDownDepressed.comment}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* PHQ-9 Section */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  PHQ-9 Assessment
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Score
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {phq9Score}
                    </Typography>
                    <Typography variant="body2">
                      {getDepressionSeverity(phq9Score)}
                    </Typography>
                  </Box>

                  {data?.phq2score?.threeOrMore === "1" && (
                    <>
                      {/* PHQ-9 Questions */}
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          1. Little interest or pleasure in doing things
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.littleInterestOrPleasure || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          2. Feeling down, depressed, or hopeless
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(data?.feelingDownDepressed || {}).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          3. Trouble falling or staying asleep, or sleeping too
                          much
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.troubleFalling || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          4. Feeling tired or having little energy
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.feelingTired || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          5. Poor appetite or overeating
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.poorAppetite || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          6. Feeling bad about yourself - or that you are a
                          failure or have let yourself or your family down
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.feelingBadAboutYourself ||
                              {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          7. Trouble concentrating on things, such as reading
                          the newspaper or watching television
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening
                              ?.troubleConcentratingOnThings || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          8. Moving or speaking so slowly that other people
                          could have noticed. Or the opposite - being so fidgety
                          or restless that you have been moving around a lot
                          more than usual
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.movingOrSpeaking || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          9. Thoughts that you would be better off dead or of
                          hurting yourself in some way
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.hurtingYourself || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>

                      {/* Difficulty Level */}
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={commonStyles.question}
                        >
                          If you checked off any problems, how difficult have
                          these problems made it for you to do your work, take
                          care of things at home, or get along with other
                          people?
                        </Typography>
                        <Stack spacing={1} ml={2}>
                          {Object.entries(
                            data?.phq2score?.threeOrMoreSubAnswer
                              ?.depressionScreening?.difficultProblems || {},
                          ).map(
                            ([key, value]) =>
                              value === "1" &&
                              key !== "comment" && (
                                <Typography key={key} sx={commonStyles.answer}>
                                  •{" "}
                                  {capitalizeFirstLetter(
                                    key
                                      .replace(/([A-Z])/g, " $1")
                                      .toLowerCase()
                                      .trim(),
                                  )}
                                </Typography>
                              ),
                          )}
                        </Stack>
                      </Box>
                    </>
                  )}
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderADLSection = (activitiesData) => {
    const activities = activitiesData?.difficultyWithActivities || {};
    const totalScore = activities.totalScore || 0;

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">3. Activities of Daily Living</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Paper elevation={2} sx={commonStyles.paper}>
            <Typography variant="h6" color="primary" gutterBottom>
              Total Score: {totalScore}
            </Typography>
            <Grid container spacing={3}>
              {Object.entries(activities).map(([key, value]) => {
                if (
                  key !== "totalScore" &&
                  key !== "totalScoreComment" &&
                  key !== "comment"
                ) {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <Paper
                        elevation={1}
                        sx={{
                          p: 2,
                          height: "100%",
                          bgcolor: "background.default",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          gutterBottom
                        >
                          {key.replace(/([A-Z])/g, " $1").trim()}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          Score: {value.score}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {getActivityLabel(value.score)}
                        </Typography>
                      </Paper>
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAUDITSection = (substanceUse) => {
    const auditScore =
      substanceUse?.alcoholUse?.currentSubAnswer?.howMnyTimes
        ?.oneOrMoreSubAnswers?.auditScore || 0;

    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Alcohol Use Disorders Identification Test-Consumption (AUDIT-C)
        </Typography>
        <Typography>AUDIT-C Score: {auditScore}</Typography>
        <Typography variant="body2" color="textSecondary">
          Men: a score of 4 or more is considered positive
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Women: a score of 3 or more is considered positive
        </Typography>
      </Box>
    );
  };

  const isInRange = (score, range) => {
    const [min, max] = range.split("-").map(Number);
    return score >= min && score <= max;
  };

  const renderDemographicsSection = (demographics) => {
    const data = demographics?.data || {};

    return (
      <Accordion defaultExpanded sx={{ mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            bgcolor: "primary.main",
            color: "white",
            "& .MuiAccordionSummary-content": { my: 1 },
          }}
        >
          <Typography variant="h6">1. Demographics</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Personal Information
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Name
                    </Typography>
                    <Typography>{`${data.firstName || "N/A"} ${
                      data.middleName || ""
                    } ${data.lastName || ""}`}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Date of Birth
                    </Typography>
                    <Typography>
                      {data.dob
                        ? new Date(data.dob).toLocaleDateString()
                        : "N/A"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Gender at Birth
                    </Typography>
                    <Typography>
                      {data.genderAtBirth === "1" ? "Female" : "Male"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Current Gender Identity
                    </Typography>
                    <Typography>
                      {data.currentGenderIdentity || "Not Available"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Race
                    </Typography>
                    <Typography>
                      {data.race?.caucasian === "1"
                        ? "Caucasian"
                        : "Not Available"}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Ethnicity
                    </Typography>
                    <Typography>
                      {data.patientEthnicity?.hispanic === "1"
                        ? "Hispanic"
                        : "Not Available"}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Contact Information
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Address
                    </Typography>
                    <Typography>{data.address || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      City
                    </Typography>
                    <Typography>{data.city || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      State
                    </Typography>
                    <Typography>{data.state || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      ZIP
                    </Typography>
                    <Typography>{data.zip || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Phone
                    </Typography>
                    <Typography>{data.primary || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Email
                    </Typography>
                    <Typography>{data.email || "N/A"}</Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Insurance Details
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Member ID
                    </Typography>
                    <Typography>{data.memberId || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Medicare ID
                    </Typography>
                    <Typography>{data.medicareId || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Plan
                    </Typography>
                    <Typography>{data.plan || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      Program
                    </Typography>
                    <Typography>{data.program || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      LOB
                    </Typography>
                    <Typography>{data.lob || "N/A"}</Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Provider Information
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      PCP Name
                    </Typography>
                    <Typography>{data.pName || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      PCP Phone
                    </Typography>
                    <Typography>{data.pPhone || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      PCP Fax
                    </Typography>
                    <Typography>{data.pFax || "N/A"}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color={commonStyles.fieldLabel}
                    >
                      PCP NPI
                    </Typography>
                    <Typography>{data.pcpNpi || "N/A"}</Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderMedicalHistorySection = (medical) => {
    const data = medical?.data || {};

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">5. Medical History</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {/* Assistive Devices */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Assistive Devices
                </Typography>
                {data.assistiveDevices?.isNone === "2" ? (
                  <Stack spacing={2}>
                    {Object.entries(
                      data.assistiveDevices?.subAnswers || {},
                    ).map(([key, value]) => {
                      if (
                        value === "1" &&
                        !key.includes("SubAnswers") &&
                        key !== "comment"
                      ) {
                        if (key === "isOxygen") {
                          return (
                            <Box key={key}>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                Oxygen
                              </Typography>
                              <Typography>
                                {data.assistiveDevices.subAnswers
                                  .oxygenSubAnswers?.describe?.isContinuous ===
                                "1"
                                  ? "Continuous"
                                  : ""}
                                {data.assistiveDevices.subAnswers
                                  .oxygenSubAnswers?.describe?.isPRN === "1"
                                  ? "PRN"
                                  : ""}
                                {data.assistiveDevices.subAnswers
                                  .oxygenSubAnswers?.describe?.isDay === "1"
                                  ? "Day"
                                  : ""}
                                {data.assistiveDevices.subAnswers
                                  .oxygenSubAnswers?.describe?.isNight === "1"
                                  ? "Night"
                                  : ""}
                              </Typography>
                              <Typography>
                                Litres:{" "}
                                {data.assistiveDevices.subAnswers
                                  .oxygenSubAnswers?.Litres || "N/A"}
                              </Typography>
                            </Box>
                          );
                        }
                        return (
                          <Typography key={key}>
                            •{" "}
                            {key
                              .replace("is", "")
                              .replace(/([A-Z])/g, " $1")
                              .trim()}
                          </Typography>
                        );
                      }
                      return null;
                    })}
                    {data.assistiveDevices?.comment && (
                      <Typography color="text.secondary">
                        Comment: {data.assistiveDevices.comment}
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Typography>No assistive devices needed</Typography>
                )}
              </Paper>
            </Grid>

            {/* Health Services */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Health Services
                </Typography>
                <Stack spacing={2}>
                  {Object.entries(data.healthServices || {}).map(
                    ([key, value]) =>
                      value === "1" &&
                      key !== "comment" && (
                        <Typography key={key}>
                          • {key.replace(/([A-Z])/g, " $1").trim()}
                        </Typography>
                      ),
                  )}
                  {data.healthServices?.comment && (
                    <Typography color="text.secondary">
                      Comment: {data.healthServices.comment}
                    </Typography>
                  )}
                </Stack>
              </Paper>
            </Grid>

            {/* Current Treatments */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Treatments you have received in the last year
                </Typography>
                <Stack spacing={2}>
                  {Object.entries(data.treatments || {}).map(
                    ([key, value]) =>
                      value === "1" &&
                      key !== "comment" && (
                        <Box key={key}>
                          <Typography sx={commonStyles.answer}>
                            • {key.replace(/([A-Z])/g, " $1").trim()}
                          </Typography>
                        </Box>
                      ),
                  )}
                  {!Object.entries(data.treatments || {}).some(
                    ([key, value]) => value === "1" && key !== "comment",
                  ) && (
                    <Typography color="text.secondary">
                      No current treatments reported
                    </Typography>
                  )}
                  {data.treatments?.comment && (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={commonStyles.question}
                      >
                        Additional Comments:
                      </Typography>
                      <Typography sx={commonStyles.answer}>
                        {data.treatments.comment}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              </Paper>
            </Grid>

            {/* Past 12 Months History */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Past 12 Months History
                </Typography>
                <Stack spacing={2}>
                  {/* PCP Visits */}
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      PCP Visits
                    </Typography>
                    {Object.entries(
                      data.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.pcp ||
                        {},
                    ).map(
                      ([key, value]) =>
                        value === "1" &&
                        key !== "comment" && (
                          <Typography key={key}>
                            {key
                              .replace("is", "")
                              .replace(/([A-Z])/g, " $1")
                              .trim()}
                          </Typography>
                        ),
                    )}
                  </Box>

                  {/* Emergency Room Visits */}
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Emergency Room Visits
                    </Typography>
                    {Object.entries(
                      data.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                        ?.emergencyRoom || {},
                    ).map(([key, value]) => {
                      if (
                        value === "1" &&
                        !key.includes("SubAnswers") &&
                        key !== "comment"
                      ) {
                        const subAnswers =
                          data.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                            ?.emergencyRoom[`${key}SubAnswers`];
                        return (
                          <Box key={key}>
                            <Typography>
                              {key
                                .replace("is", "")
                                .replace(/([A-Z])/g, " $1")
                                .trim()}
                            </Typography>
                            {subAnswers?.describe && (
                              <Typography ml={2} color="text.secondary">
                                Description: {subAnswers.describe}
                              </Typography>
                            )}
                            {subAnswers?.comment && (
                              <Typography ml={2} color="text.secondary">
                                Comment: {subAnswers.comment}
                              </Typography>
                            )}
                          </Box>
                        );
                      }
                      return null;
                    })}
                  </Box>

                  {/* Hospital Overnight Stays */}
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Hospital Overnight Stays
                    </Typography>
                    {Object.entries(
                      data.pastTwelveMonths?.pastTwelveMonthsSubAnswers
                        ?.hospitalOvernight || {},
                    ).map(
                      ([key, value]) =>
                        value === "1" &&
                        key !== "comment" && (
                          <Typography key={key}>
                            {key
                              .replace("is", "")
                              .replace(/([A-Z])/g, " $1")
                              .trim()}
                          </Typography>
                        ),
                    )}
                  </Box>

                  {/* Prior Hospitalizations */}
                  {data.hospitalizedPrior?.isHospitalizedPrior === "1" && (
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Prior Hospitalizations
                      </Typography>
                      <Typography>
                        Description:{" "}
                        {data.hospitalizedPrior.hospitalizedPriorSubAnswers
                          ?.describe || "N/A"}
                      </Typography>
                      {data.hospitalizedPrior.comment && (
                        <Typography color="text.secondary">
                          Comment: {data.hospitalizedPrior.comment}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Stack>
              </Paper>
            </Grid>

            {/* Specialists */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Medical Specialists
                </Typography>
                {data.specialists?.isSpecialists === "1" ? (
                  <Grid container spacing={2}>
                    {data.specialists.specialistsSubAnswers.map(
                      (specialist, index) => (
                        <Grid item xs={12} md={4} key={index}>
                          <Paper
                            elevation={1}
                            sx={{
                              p: 2,
                              height: "100%",
                              bgcolor: "background.default",
                            }}
                          >
                            <Stack spacing={1}>
                              <Typography
                                variant="subtitle2"
                                sx={commonStyles.question}
                              >
                                Medical Specialist
                              </Typography>
                              <Typography sx={commonStyles.answer}>
                                {specialist.medicalSp || "N/A"}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={commonStyles.question}
                              >
                                Specialist Name
                              </Typography>
                              <Typography sx={commonStyles.answer}>
                                {specialist.specialist || "N/A"}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                sx={commonStyles.question}
                              >
                                Reason
                              </Typography>
                              <Typography sx={commonStyles.answer}>
                                {specialist.for || "N/A"}
                              </Typography>
                            </Stack>
                          </Paper>
                        </Grid>
                      ),
                    )}
                  </Grid>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No medical specialists reported
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderFamilyHistorySection = (familyHistory) => {
    const familyHistoryData = familyHistory?.data?.familyHistory || {};
    const hasHistory =
      familyHistoryData.isFamilyHistory === "1" &&
      familyHistoryData.familyHistorySubAnswers?.length > 0;

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">4. Family History</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Family Medical History
                </Typography>
                {hasHistory ? (
                  <Stack spacing={2}>
                    {familyHistoryData.familyHistorySubAnswers.map(
                      (history, index) => (
                        <Box key={index}>
                          <Typography
                            variant="subtitle2"
                            sx={commonStyles.question}
                          >
                            Family Member
                          </Typography>
                          <Typography sx={commonStyles.answer}>
                            {history.familyMember}
                          </Typography>

                          <Typography
                            variant="subtitle2"
                            sx={commonStyles.question}
                          >
                            Medical Condition
                          </Typography>
                          <Typography sx={commonStyles.answer}>
                            {history.medicalCondition}
                          </Typography>

                          {history.causeofDeath && (
                            <>
                              <Typography
                                variant="subtitle2"
                                sx={commonStyles.question}
                              >
                                Cause of Death
                              </Typography>
                              <Typography sx={commonStyles.answer}>
                                {history.causeofDeath}
                              </Typography>
                            </>
                          )}
                        </Box>
                      ),
                    )}
                    {familyHistoryData.comment && (
                      <Typography color="text.secondary">
                        Comment: {familyHistoryData.comment}
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Typography variant="body1" color="text.secondary">
                    No family history recorded
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderHomeSafetySection = (home) => {
    const data = home?.data || {};

    const getStatusValue = (obj) => {
      if (!obj) return "N/A";
      if (typeof obj === "string") return obj;
      if (obj.status) return obj.status;
      return "N/A";
    };

    const getFeelingValue = (feelingData) => {
      if (!feelingData) return "No reported feelings";
      if (feelingData.isFeelingLikeHarming === "1") return "Yes";
      return "No reported feelings";
    };

    const getAbuseValue = (abuseData) => {
      if (!abuseData) return "No reported abuse";
      if (abuseData.isAbuseByAnyone === "1") return "Yes";
      return "No reported abuse";
    };

    const getAfraidValue = (afraidData) => {
      if (!afraidData) return "No";
      if (afraidData.isAfraidOfAnyone === "1") return "Yes";
      return "No";
    };

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">8. Home Safety & Personal Goals</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {/* Home Safety Checklist */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Home Safety Checklist
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      {[
                        {
                          key: "obstaclesInHouse",
                          label: "Fall Hazards (obstacles, scatter rugs)",
                        },
                        {
                          key: "electricalCords",
                          label: "Electrical cords secured",
                        },
                        {
                          key: "noSlipMats",
                          label:
                            "Non-slip mats in Home Safety (handrails, slip mats)",
                        },
                        { key: "adequateLighting", label: "Adequate lighting" },
                        {
                          key: "handrailsOnStaircases",
                          label: "Handrails on staircases",
                        },
                      ].map((item) => (
                        <Box key={item.key}>
                          <Typography
                            variant="subtitle2"
                            sx={commonStyles.question}
                          >
                            {item.label}
                          </Typography>
                          <Typography sx={commonStyles.answer}>
                            {data.homeSafety?.[item.key]?.[
                              "is" +
                                item.key.charAt(0).toUpperCase() +
                                item.key.slice(1)
                            ] === "1"
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={2}>
                      {[
                        {
                          key: "hotWaterHeater",
                          label: "Hot water heater set at safe temperature",
                        },
                        {
                          key: "smokeDetectors",
                          label:
                            "Fire Safety (smoke/monoxide detectors, extinguishers)",
                        },
                        {
                          key: "carbonMonoxideDetectors",
                          label: "Working carbon monoxide detectors",
                        },
                        {
                          key: "establishedEscapeRoute",
                          label:
                            "Emergency preparedness plan (fire, natural disaster)",
                        },
                        {
                          key: "eventOfEmergency",
                          label: "Emergency plan in place",
                        },
                      ].map((item) => (
                        <Box key={item.key}>
                          <Typography
                            variant="subtitle2"
                            sx={commonStyles.question}
                          >
                            {item.label}
                          </Typography>
                          <Typography sx={commonStyles.answer}>
                            {data.homeSafety?.[item.key]?.[
                              "is" +
                                item.key.charAt(0).toUpperCase() +
                                item.key.slice(1)
                            ] === "1"
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Barriers to Making Changes */}
            <Grid item xs={12}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Preventing you from making changes
                </Typography>
                <Stack spacing={2}>
                  {Object.entries(data.preventFromMakingChanges || {}).map(
                    ([key, value]) => {
                      if (value === "1" && key !== "comment") {
                        return (
                          <Typography key={key} sx={commonStyles.answer}>
                            •{" "}
                            {capitalizeFirstLetter(
                              key.replace(/([A-Z])/g, " $1").trim(),
                            )}
                          </Typography>
                        );
                      }
                      return null;
                    },
                  )}
                  {data.preventFromMakingChanges?.comment && (
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={commonStyles.question}
                      >
                        Additional Comments:
                      </Typography>
                      <Typography sx={commonStyles.answer}>
                        {data.preventFromMakingChanges.comment}
                      </Typography>
                    </Box>
                  )}
                  {!Object.entries(data.preventFromMakingChanges || {}).some(
                    ([key, value]) => value === "1" && key !== "comment",
                  ) && (
                    <Typography color="text.secondary">
                      No barriers to making changes reported
                    </Typography>
                  )}
                </Stack>
              </Paper>
            </Grid>

            {/* Personal Safety Assessment */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Personal Safety Assessment
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Abuse by Someone
                    </Typography>
                    <Typography>
                      {data.abusedBySomeone?.isAbusedBySomeone === "1"
                        ? "Yes"
                        : "No reported abuse"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Feeling Like Harming
                    </Typography>
                    <Typography>
                      {data.feelingLikeHarming?.isFeelingLikeHarming === "1"
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Afraid of Anyone
                    </Typography>
                    <Typography>
                      {data.afraidOfAnyone?.isAfraidOfAnyone === "1"
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Safe Place
                    </Typography>
                    <Typography>{data.safePlace || "HOME"}</Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Need Assistance
                    </Typography>
                    <Typography>
                      {data.needAssistance ? "Yes" : "NO"}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* Quality of Life and Goals */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Quality of Life and Goals
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Improve Quality of Life
                    </Typography>
                    <Typography>
                      {data.improveQualityOfLife?.improveQualityOfLife || "N/A"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Work On It
                    </Typography>
                    <Typography>
                      {data.workOnItDropdown?.workOnItDropdown || "N/A"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Education on Home Safety
                    </Typography>
                    <Typography>
                      {data.memberEducatedonHomeSafety
                        ?.memberEducatedonHomeSafety === "1"
                        ? "Yes"
                        : "N/A"}
                    </Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const commonStyles = {
    section: {
      mb: 2,
    },
    accordion: {
      "& .MuiAccordionSummary-root": {
        bgcolor: "primary.main",
        color: "white",
        "& .MuiAccordionSummary-content": { my: 1 },
      },
    },
    paper: {
      p: 3,
      height: "100%",
    },
    sectionTitle: {
      color: "primary.main",
      mb: 2,
    },
    fieldLabel: {
      color: "text.secondary",
      fontSize: "1rem",
      fontWeight: 600,
      mb: 1,
    },
    fieldValue: {
      mb: 2,
      fontSize: "0.875rem",
    },
    question: {
      fontWeight: 600,
      fontSize: "1rem",
      color: "text.primary",
      mb: 1,
    },
    answer: {
      fontSize: "0.875rem",
      color: "text.secondary",
      ml: 2,
    },
  };

  const subAnswerMap = {
    foodWeBought: "The food we bought just didn't last",
    dontHaveMoney: "We didn't have money to get more",
  };

  const renderSDOHSection = (self) => {
    const data = self?.data || {};

    // Helper function to get education level text
    const getEducationLevelText = (school) => {
      if (school?.lessThanThirdGrade === "1") return "Less than third grade";
      if (school?.lessThanEighthGrade === "1") return "Less than eighth grade";
      if (school?.lessThanTwelthGrade === "1") return "Less than twelfth grade";
      if (school?.completedTwelthGrade === "1")
        return "Completed twelfth grade";
      if (school?.attendedOrgraduated === "1")
        return "Attended or graduated college";
      return "Not specified";
    };

    // Helper function to get difficulty reading details
    const getDifficultyReadingDetails = (reading) => {
      const difficulties = [];
      if (reading?.fillingMedicalForms === "1")
        difficulties.push("Filling medical forms");
      if (reading?.providerInstructions === "1")
        difficulties.push("Understanding provider instructions");
      if (reading?.prescriptionInstructions === "1")
        difficulties.push("Reading prescription instructions");
      return difficulties.length > 0 ? difficulties : ["None reported"];
    };

    // Helper function to get social activities text
    const getSocialActivitiesText = (activities) => {
      if (activities?.often === "1") return "Often";
      if (activities?.sometimes === "1") return "Sometimes";
      if (activities?.almostNever === "1") return "Almost never";
      if (activities?.never === "1") return "Never";
      return "Not specified";
    };

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            2. Social Determinants of Health (SDOH)
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {/* Education and Literacy */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Education and Literacy
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Education Level
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {getEducationLevelText(data.school)}
                    </Typography>
                    {data.school?.comment && (
                      <Typography sx={commonStyles.answer}>
                        Comment: {data.school.comment}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Difficulty Reading
                    </Typography>
                    {getDifficultyReadingDetails(data.difficultyReading).map(
                      (difficulty, index) => (
                        <Typography key={index} sx={commonStyles.answer}>
                          • {difficulty}
                        </Typography>
                      ),
                    )}
                    {data.difficultyReading?.comment && (
                      <Typography sx={commonStyles.answer}>
                        Comment: {data.difficultyReading.comment}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* Transportation and Living Situation */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Transportation and Living Situation
                </Typography>
                <Stack spacing={2}>
                  {/* Transportation Concerns */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Transportation Concerns
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {data.concernAboutTransportation
                        ?.isConcernAboutTransportation === "1"
                        ? "Has transportation concerns"
                        : "No transportation concerns"}
                    </Typography>
                    {data.concernAboutTransportation?.comment && (
                      <Typography sx={commonStyles.answer}>
                        Comment: {data.concernAboutTransportation.comment}
                      </Typography>
                    )}
                  </Box>

                  {/* Current Living Situation */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Current Living Situation
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data.currentLive || {}).map(
                        ([key, value]) =>
                          value === "1" &&
                          !key.includes("SubAnswer") &&
                          key !== "comment" ? (
                            <Typography key={key} sx={commonStyles.answer}>
                              •{" "}
                              {capitalizeFirstLetter(
                                key
                                  .replace(/([A-Z])/g, " $1")
                                  .toLowerCase()
                                  .trim(),
                              )}
                            </Typography>
                          ) : null,
                      )}
                    </Stack>
                  </Box>

                  {/* Living With */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Living With
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data.currentlyLivingWith || {}).map(
                        ([key, value]) =>
                          value === "1" &&
                          !key.includes("SubAnswer") &&
                          key !== "comment" ? (
                            <Typography key={key} sx={commonStyles.answer}>
                              •{" "}
                              {capitalizeFirstLetter(
                                key
                                  .replace(/([A-Z])/g, " $1")
                                  .toLowerCase()
                                  .trim(),
                              )}
                              {key === "alone" &&
                                data.currentlyLivingWith.aloneSubAnswer
                                  ?.describe &&
                                ` - ${data.currentlyLivingWith.aloneSubAnswer.describe}`}
                            </Typography>
                          ) : null,
                      )}
                    </Stack>
                  </Box>

                  {/* Housing Issues */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Housing Issues
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data.thinkAboutPlace || {}).map(
                        ([key, value]) =>
                          value === "1" && key !== "comment" ? (
                            <Typography key={key} sx={commonStyles.answer}>
                              •{" "}
                              {capitalizeFirstLetter(
                                key
                                  .replace(/([A-Z])/g, " $1")
                                  .toLowerCase()
                                  .trim(),
                              )}
                            </Typography>
                          ) : null,
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* Social Support and Activities */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Social Support and Activities
                </Typography>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Self-Rated Health
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {data.social?.rateYourHealth?.excellent === "1"
                        ? "Excellent"
                        : data.social?.rateYourHealth?.good === "1"
                        ? "Good"
                        : data.social?.rateYourHealth?.fair === "1"
                        ? "Fair"
                        : data.social?.rateYourHealth?.poor === "1"
                        ? "Poor"
                        : "Not specified"}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Social Activities Frequency
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {getSocialActivitiesText(data.social?.socialActivities)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Going Out to Meet People
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {getSocialActivitiesText(data.social?.goOutToMeet)}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Rely On Others
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {data.social?.relyOn?.isRelyOn === "1"
                        ? "Yes"
                        : data.social?.relyOn?.isRelyOn === "2"
                        ? "No"
                        : "Not specified"}
                    </Typography>
                    {data.social?.relyOn?.comment && (
                      <Typography sx={commonStyles.answer}>
                        Comment: {data.social.relyOn.comment}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Caregiver Status
                    </Typography>
                    <Typography sx={commonStyles.answer}>
                      {data.social?.careGiver?.isCareGiver === "1"
                        ? "Is a caregiver"
                        : "Not a caregiver"}
                    </Typography>
                    {data.social?.careGiver?.isCareGiverSubAnswer?.describe && (
                      <Typography sx={commonStyles.answer}>
                        Details:{" "}
                        {data.social.careGiver.isCareGiverSubAnswer.describe}
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* Food Security and Employment */}
            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={commonStyles.paper}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Food Security and Employment
                </Typography>
                <Stack spacing={2}>
                  {/* Food Security */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Food Security Status
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      {Object.entries(data.food?.foodWouldRunOut || {}).map(
                        ([key, value]) =>
                          value === "1" &&
                          !key.includes("SubAnswers") &&
                          key !== "comment" ? (
                            <Box key={key}>
                              <Typography sx={commonStyles.answer}>
                                •{" "}
                                {capitalizeFirstLetter(
                                  key
                                    .replace(/([A-Z])/g, " $1")
                                    .toLowerCase()
                                    .trim(),
                                )}
                              </Typography>
                              {key === "oftenTrue" &&
                                data.food?.foodWouldRunOut
                                  ?.oftenTrueSubAnswers && (
                                  <Stack spacing={1} ml={2}>
                                    {/* {Object.entries(data.food.foodWouldRunOut.oftenTrueSubAnswers).map(([subKey, subValue]) =>
                                  subValue === "1" && subKey !== "comment" ? (
                                    <Typography key={subKey} sx={commonStyles.answer}>
                                      - {capitalizeFirstLetter(subKey.replace(/([A-Z])/g, " $1").toLowerCase().trim())}
                                    </Typography>
                                  ) : null
                                )} */}
                                    {Object.entries(
                                      data.food.foodWouldRunOut
                                        .oftenTrueSubAnswers,
                                    )
                                      .filter(
                                        ([subKey, subValue]) =>
                                          subValue === "1" &&
                                          subKey !== "comment",
                                      )
                                      .map(([subKey]) => (
                                        <Typography
                                          key={subKey}
                                          sx={commonStyles.answer}
                                        >
                                          - {subAnswerMap[subKey] || subKey}
                                        </Typography>
                                      ))}
                                  </Stack>
                                )}
                            </Box>
                          ) : null,
                      )}
                    </Stack>
                  </Box>

                  {/* Employment */}
                  <Box>
                    <Typography variant="subtitle2" sx={commonStyles.question}>
                      Employment Status
                    </Typography>
                    <Stack spacing={1} ml={2}>
                      <Typography sx={commonStyles.answer}>
                        • Currently Employed:{" "}
                        {data.employement?.currentlyEmployed
                          ?.isCurrentlyEmployed === "1"
                          ? "Yes"
                          : "No"}
                      </Typography>
                      <Typography sx={commonStyles.answer}>
                        • Interested in Employment:{" "}
                        {data.employement?.interestedEmployment
                          ?.isInterestedEmployment === "1"
                          ? "Yes"
                          : "No"}
                      </Typography>
                      <Typography sx={commonStyles.answer}>
                        • Currently Volunteering:{" "}
                        {data.employement?.volunteerCurrently
                          ?.isVolunteerCurrently === "1"
                          ? "Yes"
                          : "No"}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderPreventiveCareSection = (preventive) => {
    const preventiveData =
      preventive?.data?.preventiveCare?.pastThreeYearsSubAnswers || [];

    return (
      <Accordion defaultExpanded sx={commonStyles.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">6. Preventive Care</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Screening</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Answer</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Method</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {preventiveData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.screen}</TableCell>
                    <TableCell>
                      {item.answer === "1" ? (
                        <Chip label="Yes" color="success" size="small" />
                      ) : item.answer === "0" ? (
                        <Chip label="No" color="error" size="small" />
                      ) : (
                        <Chip label="N/A" color="default" size="small" />
                      )}
                    </TableCell>
                    <TableCell>{item.method || "N/A"}</TableCell>
                    <TableCell>{item.date || "N/A"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 3 }}>
        {error}
      </Alert>
    );
  }

  if (!assessmentData) {
    return (
      <Alert severity="info" sx={{ m: 3 }}>
        No assessment data available.
      </Alert>
    );
  }
  const getDepressionSeverity = (score) => {
    const numScore = parseInt(score);
    if (numScore >= 0 && numScore <= 4) return "None-Minimal depression";
    if (numScore >= 5 && numScore <= 9) return "Mild depression";
    if (numScore >= 10 && numScore <= 14) return "Moderate depression";
    if (numScore >= 15 && numScore <= 19) return "Moderately Severe depression";
    if (numScore >= 20) return "Severe depression";
    return "Not assessed";
  };

  const getTreatmentSummary = (diagnoses) => {
    const treatments = [];
    if (
      diagnoses?.anxietySubAnswer?.describe?.activeSubAnswer?.treatment
        ?.medication === "1"
    )
      treatments.push("Medication");
    if (
      diagnoses?.depressionSubAnswer?.describe?.activeSubAnswer?.treatment
        ?.medication === "1"
    )
      treatments.push("Medication");
    if (
      diagnoses?.anxietySubAnswer?.describe?.activeSubAnswer?.treatment
        ?.otherTreatment === "1" ||
      diagnoses?.depressionSubAnswer?.describe?.activeSubAnswer?.treatment
        ?.otherTreatment === "1"
    )
      treatments.push("Other treatments");

    return treatments.length > 0
      ? treatments.join(", ")
      : "No active treatments reported";
  };

  // Add this helper function at the component level
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      <Stack spacing={3}>
        {renderDemographicsSection(assessmentData?.demographics)}
        {renderSDOHSection(assessmentData?.self)}
        {renderADLSection(assessmentData?.activities?.data)}
        {renderFamilyHistorySection(assessmentData?.family)}
        {renderMedicalHistorySection(assessmentData?.medical)}
        {renderPreventiveCareSection(assessmentData?.preventive)}
        {renderPHQSection(assessmentData?.review?.data?.neuroPsych)}
        {renderHomeSafetySection(assessmentData?.home)}
      </Stack>

      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "flex-end",
            "& .MuiButton-root": { minWidth: 150 },
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={submitting || termsAccepted}
          >
            {submitting ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                <span>Processing...</span>
              </Box>
            ) : (
              "Agree Terms"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitv2}
            disabled={submitting || !termsAccepted}
          >
            {submitting ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                <span>Submitting...</span>
              </Box>
            ) : (
              "Submit Assessment"
            )}
          </Button>
        </Box>
      </Paper>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">Terms & Conditions</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ p: 2 }}>
            <Typography variant="body1" paragraph>
              Your health plan has contracted with Focus Care to conduct a
              health exam on all of its Medicare members, including you. The
              health exam includes questions to help your health plan learn more
              about your current health. The exam may also find things that
              could affect your health. The results of the exam will help your
              health plan and your doctor keep you as healthy as possible.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Personal Health Information (PHI)
            </Typography>
            <Typography variant="body1" paragraph>
              Personal health information, or PHI, is information in your
              medical record that identifies the record as your record. PHI
              includes things like your date of birth, age, address, telephone
              number, and your medical history.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Privacy Protection
            </Typography>
            <Typography variant="body1" paragraph>
              Most of the time, Focus Care will not release your personal
              information without your permission. Measures are in place to
              prevent your personal information from being accidentally released
              in writing, including by use of a computer, or orally. You may
              request more information about how your personal information is
              protected.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Information Sharing
            </Typography>
            <Typography variant="body1" paragraph>
              There are times when Focus Care is allowed to release your
              personal information without your permission. For example:
            </Typography>
            <Box component="ul" sx={{ pl: 4 }}>
              <Typography component="li">
                Your medical information may be given to other health care
                providers who take care of you
              </Typography>
              <Typography component="li">
                The results of this exam will be sent to your health plan and to
                your doctor
              </Typography>
              <Typography component="li">
                Focus Care may release your personal health information to a
                'business associate'
              </Typography>
            </Box>

            <Typography variant="h6" gutterBottom>
              Business Associates
            </Typography>
            <Typography variant="body1" paragraph>
              A 'business associate' is another agency that Focus Care uses to
              do things, such as billing. We require our 'business associates'
              to have security measures in place to prevent your personal
              information from being accidentally released in writing, including
              by use of a computer, or orally.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Legal Requirements
            </Typography>
            <Typography variant="body1" paragraph>
              Focus Care may be required to release your personal health
              information, without your permission, by law, including statutes,
              regulations, or valid court orders.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Additional Permissions
            </Typography>
            <Typography variant="body1" paragraph>
              Focus Care will obtain your permission to use or release your
              personal health information for any other reason.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Important Notes
            </Typography>
            <Box component="ul" sx={{ pl: 4 }}>
              <Typography component="li">
                Focus Care will not disclose your individual personal health
                information to your employer, union, or any other employee
                organization without your permission
              </Typography>
              <Typography component="li">
                The information obtained today and any applicable lab results
                may be sent to your primary care physician (PCP)
              </Typography>
              <Typography component="li">
                You can request this information in a different language if
                needed
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ mt: 4 }} fontWeight="bold">
              By accepting these terms, you agree to allow Focus Care to release
              the results of your medical exam to your health plan and to your
              doctor.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                const token = localStorage.getItem("token");
                if (!token) throw new Error("Authentication token not found");

                const response = await fetch(
                  `${process.env.REACT_APP_API_BASE_URL}/memberPortal/terms-and-conditions`,
                  {
                    method: "PUT",
                    headers: {
                      Authorization: token,
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      memberId: user?.memberId,
                      year: getValidYear(user?.year),
                      termsAndConditions: true,
                    }),
                  },
                );

                const data = await response.json();
                if (data.code === 200) {
                  setTermsAccepted(true);
                  setIsDialogOpen(false);
                  setMessage("Terms and conditions accepted successfully!");
                } else {
                  throw new Error(data.message || "Failed to accept terms");
                }
              } catch (error) {
                console.error("Error accepting terms:", error);
                setMessage(
                  "Failed to accept terms and conditions. Please try again.",
                );
              }
            }}
          >
            Accept Terms
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PatientSummary;
