import axiosWrapper from "./axiosWrapper";
import md5 from "js-md5";

const dataCache = {}; // To store the data
const inProgress = {}; // To track requests in progress

const fetchData = async (key, endpoint) => {
  if (dataCache[key]) {
    return dataCache[key];
  }

  if (inProgress[key]) {
    return inProgress[key];
  }

  inProgress[key] = new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWrapper.get(endpoint);
      dataCache[key] = response;
      resolve(response);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      reject(error);
    } finally {
      delete inProgress[key];
    }
  });

  return inProgress[key];
};

const fetchDataWithParams = async (params, endpoint) => {
  const cacheKey = md5(JSON.stringify(params));

  if (dataCache[cacheKey]) {
    return dataCache[cacheKey];
  }

  if (inProgress[cacheKey]) {
    return inProgress[cacheKey];
  }

  inProgress[cacheKey] = new Promise(async (resolve, reject) => {
    try {
      const response = await axiosWrapper.post(endpoint, params);
      dataCache[cacheKey] = response;
      resolve(response);
    } catch (error) {
      console.error(`Error fetching data from ${endpoint}:`, error);
      reject(error);
    } finally {
      delete inProgress[cacheKey];
    }
  });

  return inProgress[cacheKey];
};

const fetchRolewiseData = async (params, endpoint) => {
  const { planId, roleId } = params;
  const rolesArray = roleId.split(","); // Split roles into an array
  let result = [];
  let rolesToFetch = []; // To track roles that need API calls
  let count = 0;

  // Check cache first for each role
  for (const role of rolesArray) {
    if (dataCache[planId] && dataCache[planId][role]) {
      // If the role data exists in cache, add it to result
      result.push(dataCache[planId][role]);
      count++;
    } else {
      // If the role data is not in cache, add the role to fetch list
      rolesToFetch.push(role);
    }
  }

  // If all roles are cached, return the result
  if (count === rolesArray.length && count !== 0 && rolesArray.length !== 0) {
    return {
      message: "Users found successfully",
      error: null,
      data: result,
    };
  }

  // If there are roles to fetch, make the API call for those roles
  if (rolesToFetch.length > 0) {
    try {
      // Make the API request
      const response = await axiosWrapper.post(endpoint, {
        planId: planId,
        roleId: rolesToFetch.join(","),
      });

      // Extract data from the API response
      const apiData = await response?.data?.data;

      // Ensure rolesToFetch is an array
      if (!Array.isArray(rolesToFetch)) {
        console.error("rolesToFetch is not an array");
        return {
          message: "Invalid roles array",
          error: "rolesToFetch should be an array",
          data: [],
        };
      }

      // Ensure apiData is an array
      if (!Array.isArray(apiData)) {
        console.error("apiData is not an array");
        return {
          message: "Invalid API data format",
          error: "The response data is not an array",
          data: [],
        };
      }

      // Split and store the response data in the cache for future requests
      rolesToFetch.forEach((rl) => {
        if (apiData) {
          // Filter the response data for the current roleId
          const roleData = apiData.filter((item) => {
            return item.roleId == rl; // Ensure this comparison works as expected
          });

          // Ensure the dataCache for the given planId exists
          if (!dataCache[planId]) {
            dataCache[planId] = {};
          }

          // Store the filtered data for the current role in the cache
          dataCache[planId][rl] = roleData;

          // Add the roleData to the result (combined from all roles)
          result.push(...roleData);
        }
      });

      // Return the final result with the newly fetched data
      return {
        message: "Users found successfully",
        error: null,
        data: result,
      };
    } catch (error) {
      // Handle any errors from the API call
      console.error("Error fetching data:", error);
      return {
        message: "An error occurred while fetching data",
        error: error.message,
        data: [],
      };
    }
  }

  // If no roles need to be fetched (everything is cached), return the result
  return {
    message: "Users found successfully",
    error: null,
    data: result,
  };
};

export const getAllClients = () => fetchData("clients", "/clients/list");
export const getLobData = () => fetchData("lobs", "/lobTypes/list");
export const getTimeZones = () =>
  fetchData("timeZones", "/settings/getTimezones");
export const getVisitTypes = () =>
  fetchData("visitTypes", "/visitTypes/get-visit-types");
export const getHraStatus = () => fetchData("hraStatus", "/hra-status");
export const getAllProgramTypes = () =>
  fetchData("programTypes", "/programTypes/list");
export const getAllPlanTypes = () => fetchData("planTypes", "/planTypes/list");
export const getSidebarMenus = () =>
  fetchData("sidebarMenus", "/sidebar/menuItems/list");
export const getAssessmentTypes = () =>
  fetchData("assessmentTypes", "/assessmentTypes/list");
export const getReportStatus = () => fetchData("reportStatus", "/reportstatus");
export const getFaxEnabled = () =>
  fetchData("faxEnabled", "/members/isFaxEnabled?tabId=10");
export const getClientsView = (id) =>
  fetchData(`clients-${id}`, `/clients/view?id=${id}`);
export const getUsersByProgramRoleId = (params) =>
  fetchDataWithParams(params, "/users/get-users-by-program-roleId");
export const getCallLogStatus = () =>
  fetchData("callLogStatus", "/call-log-status");
export const getCities = () => fetchData("allCities", "/scheduler/all-cities");
export const getUsersByRoleId = (params) =>
  fetchRolewiseData(params, "/users/get-users-by-roleId");
export const deleteCache = () => {
  // dataCache = {};
  // for (let key in dataCache) {
  //   delete dataCache[key];
  // }
  //  Object.assign(dataCache,{});
  Object.keys(dataCache).forEach((key) => delete dataCache[key]);
  for (let key in inProgress) {
    delete inProgress[key];
  }
};
