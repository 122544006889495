import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Box,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Checkbox,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  useMediaQuery,
  ThemeProvider,
  createTheme,
  IconButton,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

// Add this near the top of your file, with other component-level constants
const serviceButtonSx = {
  textTransform: "none",
  borderRadius: "4px",
  border: "1px solid #52A1DB",
  color: "#52A1DB",
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#004d86",
    },
  },
  "&.MuiButton-outlined": {
    "&:hover": {
      backgroundColor: "rgba(0, 102, 178, 0.04)",
    },
  },
  "&.Mui-disabled": {
    borderColor: "rgba(0, 0, 0, 0.12)",
    color: "rgba(0, 0, 0, 0.26)",
  },
};

// Update the color theme constant
const theme = createTheme({
  palette: {
    primary: {
      main: "#52A1DB",
      dark: "#4190c4",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
    },
  },
});

// Update textFieldSx
const textFieldSx = {
  "& .MuiInputBase-input.Mui-disabled": {
    color: "text.secondary",
    WebkitTextFillColor: "text.secondary",
    opacity: 1,
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: "text.secondary",
      opacity: 1,
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-disabled": {
      fontSize: "20px",
    },
  },
  "& .MuiInputBase-root": {
    paddingTop: "15px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#52A1DB",
    },
    "&:hover fieldset": {
      borderColor: "#4190c4",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#52A1DB",
    },
  },
  "& .MuiInput-root": {
    "&::before": {
      borderBottom: "none",
      borderBottomStyle: "none",
      border: 0,
    },
    "&::after": {
      borderBottom: "none",
      borderBottomStyle: "none",
      border: 0,
    },
    "&:hover:not(.Mui-disabled)::before": {
      borderBottom: "none",
      borderBottomStyle: "none",
      border: 0,
    },
    "&.Mui-disabled": {
      "&::before": {
        borderBottom: "none",
        borderBottomStyle: "none",
        border: 0,
      },
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      fontSize: "1.2rem",
    },
    "&.MuiInputLabel-shrink": {
      fontSize: "1.2rem",
    },
  },
};

// Add these style constants at the top with the other styles
const tableHeaderSx = {
  backgroundColor: "#52A1DB",
  color: "white",
  fontWeight: "bold",
};

// Update radioSx
const radioSx = {
  "& .MuiFormControlLabel-label": {
    color: "black",
  },
  "&.MuiRadio-root": {
    color: "#52A1DB",
    "&.Mui-checked": {
      color: "#52A1DB",
    },
    "&.Mui-disabled": {
      color: "#52A1DB",
      "& + .MuiFormControlLabel-label": {
        color: "black",
        "-webkit-text-fill-color": "black",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
    },
  },
};

// Update checkboxSx
const checkboxSx = {
  "&.MuiCheckbox-root": {
    color: "#52A1DB",
    "&.Mui-checked": {
      color: "#52A1DB",
    },
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
    },
    "&.Mui-disabled": {
      color: "#52A1DB",
    },
  },
};

// Update preventChangesButtonSx to have white text
const preventChangesButtonSx = {
  "&.MuiButton-outlined": {
    backgroundColor: "rgba(82, 161, 219, 0.1)",
    borderColor: "#52A1DB",
    color: "#000000", // Dark text for unselected state
    "&:hover": {
      borderColor: "#4190c4",
      backgroundColor: "rgba(82, 161, 219, 0.2)",
    },
  },
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "white", // White text for selected state
    "&:hover": {
      backgroundColor: "#4190c4",
    },
  },
  "&.Mui-disabled": {
    border: "1px solid rgba(82, 161, 219, 0.5)",
    "&.MuiButton-outlined": {
      backgroundColor: "rgba(82, 161, 219, 0.1)",
      color: "#000000", // Keep dark text in disabled unselected state
    },
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB",
      color: "white", // Keep white text in disabled selected state
    },
  },
};

const questionStyle = {
  borderBottom: "1px solid #ccc",
  padding: "10px 0",
  color: "#666",
  fontFamily: "'Segoe UI', 'Roboto', sans-serif",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: 1.5,
};

// Update commentTextFieldSx to ensure no underline
const commentTextFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    padding: "0px !important",
    "& fieldset": {
      borderColor: "#52A1DB",
    },
  },
  "& textarea": {
    height: "100% !important",
    minHeight: "60px !important",
    lineHeight: "1.5",
    color: "black",
    padding: "8px !important",
  },
  "& .Mui-disabled": {
    WebkitTextFillColor: "black !important",
    color: "black !important",
  },
  "& .MuiInput-root::before": {
    borderBottom: "none !important",
  },
  "& .MuiInput-root::after": {
    borderBottom: "none !important",
  },
  "& .MuiInput-root:hover:not(.Mui-disabled)::before": {
    borderBottom: "none !important",
  },
  "& .MuiInputBase-root": {
    padding: "0 !important",
  },
};

// Update subQuestionTextFieldSx to remove underline
const subQuestionTextFieldSx = {
  "& .MuiInputBase-root": {
    fontSize: "16px",
    "&:before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&.Mui-disabled": {
      "&:before": {
        borderBottom: "none",
      },
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "16px",
    padding: "0px !important",
    color: "black",
    "&.Mui-disabled": {
      color: "black",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "black",
    "&.Mui-disabled": {
      color: "black",
      "-webkit-text-fill-color": "black",
    },
  },
  "& .MuiInput-root::after": {
    borderBottom: "none",
  },
  "& .MuiInput-root::before": {
    borderBottom: "none",
  },
};

const Homesafety = ({ data }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => state?.auth?.user ?? {});
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [changedFields, setChangedFields] = useState([]);
  const [originalData, setOriginalData] = useState(null);
  const [commentVisibility, setCommentVisibility] = useState(() => {
    const savedState = localStorage.getItem("homeSafetyCommentVisibility");
    return savedState
      ? JSON.parse(savedState)
      : {
          obstaclesInHouse: false,
          noSlipMats: false,
          adequateLighting: false,
          smokeDetectors: false,
          establishedEscapeRoute: false,
          memberEducated: false,
          abusedBySomeone: false,
          feelingLikeHarming: false,
          afraidOfAnyone: false,
          improveQualityOfLife: false,
          workOnItDropdown: false,
          preventFromMakingChanges: false,
        };
  });
  const [commentValues, setCommentValues] = useState({});
  const defaultFormData = {
    fallen: {
      none: "",
      once: "",
      onceSubAnswers: {
        worryAboutFalling: {
          isWorryAboutFalling: "",
          comment: "",
        },
        fracture: {
          isFracture: "",
          comment: "",
          isFractureSubAnswers: {
            dueToFall: {
              isDueToFall: "",
              comment: "",
            },
            osteoporosisMed: {
              isOsteoporosisMed: "",
              comment: "",
            },
          },
        },
      },
      twice: "",
      comment: "",
    },
    tug: {
      notApplicable: {
        notApplicable: "",
        comment: "",
      },
    },
    directions: {
      timeInSeconds: "",
      comment: "",
    },
    fallRisk: {
      fallRisk: "",
      comment: "",
    },
    furtherEducation: {
      furtherEducation: "",
      comment: "",
    },
    homeSafety: {
      obstaclesInHouse: { isObstaclesInHouse: "", comment: "" },
      electricalCords: { isElectricalCords: "", comment: "" },
      noSlipMats: { isNoSlipMats: "", comment: "" },
      adequateLighting: { isAdequateLighting: "", comment: "" },
      handrailsOnStaircases: { isHandrailsOnStaircases: "", comment: "" },
      hotWaterHeater: { isHotWaterHeater: "", comment: "" },
      smokeDetectors: { isSmokeDetectors: "", comment: "" },
      carbonMonoxideDetectors: { isCarbonMonoxideDetectors: "", comment: "" },
      establishedEscapeRoute: { isEstablishedEscapeRoute: "", comment: "" },
      eventOfEmergency: { isEventOfEmergency: "", comment: "" },
    },
    memberEducatedonHomeSafety: {
      memberEducatedonHomeSafety: "",
      comment: "",
    },
    preventFromMakingChanges: {
      comment: "",
      educationNeeded: "",
      socialSupport: "",
      environmentalFactors: "",
      stress: "",
      selfConfidence: "",
      other: "",
      none: "",
      lackOfMotivation: "",
      notAPriority: "",
      memberNonVerbal: "",
      memberRefused: "",
    },
    improveQualityOfLife: {
      improveQualityOfLife: "",
      comment: "",
    },
    workOnItDropdown: {
      workOnItDropdown: "",
      comment: "",
    },
    abusedBySomeone: {
      isAbusedBySomeone: "",
      comment: "",
    },
    feelingLikeHarming: {
      isFeelingLikeHarming: "",
      isFeelingLikeHarmingSubAnswers: {
        whoDoYouFeel: "Who do you feel like harming?",
        atThisMoment: "",
        safePlace: "",
        assistYou: "",
      },
      comment: "",
    },
    afraidOfAnyone: {
      isAfraidOfAnyone: "",
      isAfraidOfAnyoneSubAnswers: {
        whoAreYouAfraidOf: "",
        whoIsHurtingYou: "",
        safePlace: "",
        assistYou: "",
      },
      comment: "",
    },
    pastTwelveMonths: {
      pastTwelveMonthsSubAnswers: {
        // Assuming the structure of pastTwelveMonthsSubAnswers
        // This should be populated with actual data
      },
    },
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const fetchHomeSafetyData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-home-Safety`,
          {
            params: {
              memberId: user.memberId,
              year: new Date().getFullYear(),
            },
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data?.data) {
          const data = {
            ...response.data.data,
            workOnItDropdown: {
              ...response.data.data.workOnItDropdown,
              workOnItDropdown:
                response.data.data.workOnItDropdown?.workOnItDropdown?.toString() ||
                "",
            },
          };
          setFormData(data);
          setOriginalData(data);

          // Initialize comment values from fetched data
          const newCommentValues = {};
          const newCommentVisibility = { ...commentVisibility };

          // Check home safety comments
          Object.keys(data.homeSafety || {}).forEach((key) => {
            if (data.homeSafety[key]?.comment) {
              newCommentValues[key] = true;
              newCommentVisibility[key] =
                !!data.homeSafety[key]?.comment?.trim();
            }
          });

          // Check memberEducatedonHomeSafety comment specifically
          if (data.memberEducatedonHomeSafety?.comment) {
            newCommentValues["memberEducated"] = true;
            newCommentVisibility["memberEducated"] =
              !!data.memberEducatedonHomeSafety?.comment?.trim();
          }

          // Check other section comments
          [
            "abusedBySomeone",
            "feelingLikeHarming",
            "afraidOfAnyone",
            "improveQualityOfLife",
            "workOnItDropdown",
          ].forEach((section) => {
            if (data[section]?.comment) {
              newCommentValues[section] = true;
              newCommentVisibility[section] = !!data[section]?.comment?.trim();
            }
          });

          setCommentValues(newCommentValues);
          setCommentVisibility(newCommentVisibility);
        }
      } catch (error) {
        console.error("Error fetching home safety data:", error);
        setMessage("Failed to fetch home safety data. Please try again.");
      }
    };

    fetchHomeSafetyData();
  }, [user.memberId]);

  useEffect(() => {
    localStorage.setItem(
      "homeSafetyCommentVisibility",
      JSON.stringify(commentVisibility),
    );
  }, [commentVisibility]);

  const handleOptionClick = (field) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const prevPreventFromMakingChanges = prev.preventFromMakingChanges || {};

      // If clicking 'none', deselect all other options
      if (field === "none") {
        const newPreventFromMakingChanges = {
          ...prevPreventFromMakingChanges,
          none: prevPreventFromMakingChanges.none === "1" ? "" : "1",
        };

        // If none is being selected (not deselected), clear all other options
        if (newPreventFromMakingChanges.none === "1") {
          options.forEach(({ field: optField }) => {
            if (optField !== "none") {
              newPreventFromMakingChanges[optField] = "";
            }
          });
        }

        return {
          ...prev,
          preventFromMakingChanges: newPreventFromMakingChanges,
        };
      }

      // If clicking any other option, deselect 'none'
      return {
        ...prev,
        preventFromMakingChanges: {
          ...prevPreventFromMakingChanges,
          [field]: prevPreventFromMakingChanges[field] === "1" ? "" : "1",
          none: "", // Clear 'none' when selecting other options
        },
      };
    });
  };

  const options = [
    { field: "comment", label: "comment" },
    { field: "educationNeeded", label: "Education Needed" },
    { field: "environmentalFactors", label: "Environmental Factors" },
    { field: "lackOfMotivation", label: "Lack of Motivation" },
    { field: "memberNonVerbal", label: "Member Nonverbal" },
    { field: "memberRefused", label: "Member refused to answer" },
    { field: "notAPriority", label: "Not a priority at this time" },
    { field: "other", label: "Other" },
    { field: "selfConfidence", label: "Self Confidence" },
    { field: "socialSupport", label: "Social Support" },
    { field: "stress", label: "Stress" },
    { field: "none", label: "None" },
  ];

  const handleHomeSafetyChange = (key, field, checked) => {
    if (!isEditing) return;

    setFormData((prev) => {
      // Ensure homeSafety exists
      const currentHomeSafety = prev.homeSafety || {};
      const newValue = field === "comment" ? checked : checked ? "1" : "";
      const oldValue = currentHomeSafety[key]?.[field] ?? "";

      if (oldValue !== newValue) {
        setChangedFields((prev) => [
          ...new Set([...prev, `homeSafety.${key}.${field}`]),
        ]);
      }

      return {
        ...prev,
        homeSafety: {
          ...currentHomeSafety,
          [key]: {
            ...currentHomeSafety[key],
            [field]: newValue,
          },
        },
      };
    });
  };

  const handleAbusedChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      abusedBySomeone: {
        ...(prev.abusedBySomeone || {}),
        isAbusedBySomeone:
          prev.abusedBySomeone?.isAbusedBySomeone === value ? "" : value,
      },
    }));
  };

  const handleFeelingLikeHarmingChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      feelingLikeHarming: {
        ...(prev.feelingLikeHarming || {}),
        isFeelingLikeHarming:
          prev.feelingLikeHarming?.isFeelingLikeHarming === value ? "" : value,
        isFeelingLikeHarmingSubAnswers: {
          ...(prev.feelingLikeHarming?.isFeelingLikeHarmingSubAnswers || {}),
          whoDoYouFeel: "",
          atThisMoment: "",
          safePlace: "",
          assistYou: "",
        },
      },
    }));
  };

  const handleSubAnswerChange = (key, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      feelingLikeHarming: {
        ...(prev.feelingLikeHarming || {}),
        isFeelingLikeHarmingSubAnswers: {
          ...(prev.feelingLikeHarming?.isFeelingLikeHarmingSubAnswers || {}),
          [key]: value,
        },
      },
    }));
  };

  const handleAfraidOfAnyoneChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      afraidOfAnyone: {
        ...(prev.afraidOfAnyone || {}),
        isAfraidOfAnyone:
          prev.afraidOfAnyone?.isAfraidOfAnyone === value ? "" : value,
        isAfraidOfAnyoneSubAnswers: {
          ...(prev.afraidOfAnyone?.isAfraidOfAnyoneSubAnswers || {}),
          whoAreYouAfraidOf: "",
          whoIsHurtingYou: "",
          safePlace: "",
          assistYou: "",
        },
      },
    }));
  };

  const handleAfraidSubAnswerChange = (key, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      afraidOfAnyone: {
        ...(prev.afraidOfAnyone || {}),
        isAfraidOfAnyoneSubAnswers: {
          ...(prev.afraidOfAnyone?.isAfraidOfAnyoneSubAnswers || {}),
          [key]: value,
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        comment: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-homesafety`,
        {
          memberId: user.memberId,
          year: new Date().getFullYear(),
          name: "Home safety and personal goals",
          data: formData,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      // Fetch fresh data after successful update
      const freshDataResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-home-Safety`,
        {
          params: {
            memberId: user.memberId,
            year: new Date().getFullYear(),
          },
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      if (freshDataResponse.data?.data) {
        const newData = freshDataResponse.data.data;
        const newCommentVisibility = { ...commentVisibility };
        Object.keys(newData.homeSafety || {}).forEach((key) => {
          newCommentVisibility[key] =
            !!newData.homeSafety[key]?.comment?.trim();
        });
        newCommentVisibility["memberEducated"] =
          !!newData.memberEducatedonHomeSafety?.comment?.trim();
        [
          "abusedBySomeone",
          "feelingLikeHarming",
          "afraidOfAnyone",
          "improveQualityOfLife",
          "workOnItDropdown",
        ].forEach((section) => {
          newCommentVisibility[section] = !!newData[section]?.comment?.trim();
        });
        setCommentVisibility(newCommentVisibility);
        // Keep existing comment visibility state
        setFormData(newData);
        setOriginalData(newData);
        setMessage("Home safety information updated successfully!");
        setIsEditing(false);
        setChangedFields([]);
      }
    } catch (error) {
      console.error("Error updating home safety:", error);
      setMessage("Failed to update home safety. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data?.data) {
      // Handle empty array case during cancel
      if (Array.isArray(data.data) && data.data.length === 0) {
        setFormData(defaultFormData);
      } else {
        setFormData(data.data);
      }
    } else {
      setFormData(defaultFormData);
    }
    setIsEditing(false);
    setMessage("");
  };

  // First, add this new handler for radio button clicks
  const handleRadioClick = (e, currentValue, onChange) => {
    // If clicking the same value, clear it
    if (e.target.value === currentValue) {
      e.preventDefault();
      onChange("");
    }
  };

  const handleQualityOfLifeChange = (value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      improveQualityOfLife: {
        ...prev.improveQualityOfLife,
        improveQualityOfLife: value,
      },
    }));
  };

  const handleWorkOnItChange = (value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      workOnItDropdown: {
        ...prev.workOnItDropdown,
        workOnItDropdown: value, // Keep as string
      },
    }));
  };

  const homeSafetyItems = [
    {
      key: "obstaclesInHouse",
      label: "Fall Hazards (obstacles, scatter rugs)",
      field: "isObstaclesInHouse",
    },
    {
      key: "noSlipMats",
      label: "Home Safety (handrails, slip mats)",
      field: "isNoSlipMats",
    },
    {
      key: "adequateLighting",
      label: "Adequate lighting",
      field: "isAdequateLighting",
    },
    {
      key: "smokeDetectors",
      label: "Fire Safety (smoke/monoxide detectors, extinguishers)",
      field: "isSmokeDetectors",
    },
    {
      key: "establishedEscapeRoute",
      label: "Emergency preparedness plan (fire, natural disaster)",
      field: "isEstablishedEscapeRoute",
    },
    {
      key: "memberEducated",
      label: "Member educated on home safety",
      field: "memberEducatedonHomeSafety",
    },
  ];

  const handleCommentToggle = (key) => {
    // if (!isEditing) return;
    setCommentVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleRadioChange = (section, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const newValue = field ? value : value ? "1" : "";
      const oldValue =
        prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section]?.[field] ??
        "";

      if (oldValue !== newValue) {
        setChangedFields((prev) => [
          ...new Set([
            ...prev,
            `pastTwelveMonths.pastTwelveMonthsSubAnswers.${section}.${field}`,
          ]),
        ]);
      }

      return {
        ...prev,
        pastTwelveMonths: {
          ...prev.pastTwelveMonths,
          pastTwelveMonthsSubAnswers: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
            [section]: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section],
              [field]: newValue,
            },
          },
        },
      };
    });
  };

  const renderVisitSection = (title, section) => {
    const sectionData =
      formData.pastTwelveMonths.pastTwelveMonthsSubAnswers[section];

    return (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle(section)}
            sx={{ color: "#52A1DB" }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 1,
          }}
        >
          {[
            { key: "isNone", label: "None" },
            { key: "isOne", label: "1" },
            { key: "isTwo", label: "2" },
            { key: "isThree", label: "3" },
            { key: "isFour", label: "4" },
            { key: "isFiveOrMore", label: "5 or more" },
          ].map((option) => (
            <Button
              key={option.key}
              variant={
                sectionData[option.key] === "1" ? "contained" : "outlined"
              }
              onClick={() => handleRadioChange(section, null, option.key)}
              disabled={!isEditing}
              sx={{
                ...serviceButtonSx,
                flex: "1 1 auto",
                maxWidth: "150px",
                minWidth: "100px",
              }}
            >
              {option.label}
            </Button>
          ))}
        </Box>

        {/* Comment field only shows when toggled */}
        {(commentVisibility[section] && isEditing) ||
          (!isEditing &&
            commentVisibility[section] &&
            formData?.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.[section]
              ?.comment)}
        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: "4px",
            p: 2,
            mt: 2,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Typography
            sx={{
              minWidth: "80px",
              color: "black",
              pt: 1,
              borderBottom: "none",
            }}
          >
            Comments:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            value={sectionData.comment || ""}
            onChange={(e) =>
              handleCommentChange("pastTwelveMonths", section, e.target.value)
            }
            disabled={!isEditing}
            sx={commentTextFieldSx}
          />
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            sx={{ mb: 4 }}
            onClose={() => setMessage("")}
          >
            {message}
          </Alert>
        )}

        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textAlign: "left",
            color: "#52A1DB",
            fontWeight: "normal",
          }}
        >
          Home Safety: Select all the home safety risks that apply:
        </Typography>
        {/* Home Safety Assessment Section */}
        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeaderSx}>Safety Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {homeSafetyItems.map(({ key, label, field }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  key === "memberEducated"
                                    ? formData.memberEducatedonHomeSafety
                                        ?.memberEducatedonHomeSafety === "1"
                                    : (formData?.homeSafety?.[key]?.[field] ??
                                        "") === "1"
                                }
                                onChange={(e) => {
                                  if (key === "memberEducated") {
                                    setFormData((prev) => ({
                                      ...prev,
                                      memberEducatedonHomeSafety: {
                                        ...prev.memberEducatedonHomeSafety,
                                        memberEducatedonHomeSafety: e.target
                                          .checked
                                          ? "1"
                                          : "",
                                      },
                                    }));
                                  } else {
                                    handleHomeSafetyChange(
                                      key,
                                      field,
                                      e.target.checked,
                                    );
                                  }
                                }}
                                disabled={!isEditing}
                                sx={checkboxSx}
                              />
                            }
                            label={label}
                            sx={{
                              color: "black",
                              "& .MuiFormControlLabel-label": {
                                color: "black",
                                "&.Mui-disabled": {
                                  color: "black",
                                },
                              },
                            }}
                          />
                          <IconButton
                            onClick={() => handleCommentToggle(key)}
                            sx={{
                              color: "#52A1DB",
                            }}
                            disabled={!isEditing}
                          >
                            <CommentIcon />
                          </IconButton>
                        </Box>
                        {((isEditing && commentVisibility[key]) ||
                          (!isEditing &&
                            commentVisibility[key] &&
                            ((key === "memberEducated" &&
                              formData.memberEducatedonHomeSafety?.comment) ||
                              (key !== "memberEducated" &&
                                formData?.homeSafety?.[key]?.comment)))) && (
                          <Box
                            sx={{
                              border: "1px solid #52A1DB",
                              borderRadius: "4px",
                              p: 2,
                              mt: 2,
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              sx={{
                                minWidth: "80px",
                                color: "black",
                                pt: 1,
                                borderBottom: "none",
                              }}
                            >
                              Comments:
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              rows={2}
                              variant="outlined"
                              value={
                                key === "memberEducated"
                                  ? formData.memberEducatedonHomeSafety
                                      ?.comment || ""
                                  : formData?.homeSafety?.[key]?.comment ?? ""
                              }
                              onChange={(e) => {
                                if (key === "memberEducated") {
                                  setFormData((prev) => ({
                                    ...prev,
                                    memberEducatedonHomeSafety: {
                                      ...prev.memberEducatedonHomeSafety,
                                      comment: e.target.value,
                                    },
                                  }));
                                  setCommentValues((prev) => ({
                                    ...prev,
                                    [key]: e.target.value !== "",
                                  }));
                                } else {
                                  handleHomeSafetyChange(
                                    key,
                                    "comment",
                                    e.target.value,
                                  );
                                  setCommentValues((prev) => ({
                                    ...prev,
                                    [key]: e.target.value !== "",
                                  }));
                                }
                              }}
                              disabled={!isEditing}
                              sx={commentTextFieldSx}
                            />
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "space-between",
              mb: 2,
              "& .MuiInput-root::before": {
                borderBottom: "none !important",
                borderBottomColor: "#fff !important",
              },
              "& .MuiInput-root::after": {
                borderBottom: "none !important",
                borderBottomColor: "#fff !important",
              },
            }}
          >
            <Typography variant="subtitle1">
              What is something you can work on to improve your quality of life?
            </Typography>
            <IconButton
              onClick={() => handleCommentToggle("improveQualityOfLife")}
              sx={{
                color: "#52A1DB",
                "&.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.26)", // Keep the same color when disabled
                },
              }}
              disabled={!isEditing}
            >
              <CommentIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mt: 2,
            }}
          >
            <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
              Response:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              // placeholder="Enter your response..."
              value={formData?.improveQualityOfLife?.improveQualityOfLife || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  improveQualityOfLife: {
                    ...prev.improveQualityOfLife,
                    improveQualityOfLife: e.target.value,
                  },
                }))
              }
              disabled={!isEditing}
              variant="standard"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#fff",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#fff",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#fff",
                },
                "& .MuiInput-underline.Mui-disabled:before": {
                  borderBottomColor: "#fff",
                },
                "& .MuiInputBase-input": {
                  color: "black",
                  "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.38)",
                    "-webkit-text-fill-color": "rgba(0, 0, 0, 0.38)",
                  },
                },
              }}
            />
          </Box>
          {/* Comment section */}
          {((commentVisibility.improveQualityOfLife && isEditing) ||
            (!isEditing &&
              commentVisibility.improveQualityOfLife &&
              formData?.improveQualityOfLife?.comment)) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  flex: 1,
                  display: "flex",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>

                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  // placeholder="Comments..."
                  value={formData?.improveQualityOfLife?.comment || ""}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      improveQualityOfLife: {
                        ...prev.improveQualityOfLife,
                        comment: e.target.value,
                      },
                    }))
                  }
                  disabled={!isEditing}
                  variant="outlined"
                  sx={commentTextFieldSx}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <FormControl sx={{ width: "90%" }}>
              <Select
                value={formData?.workOnItDropdown?.workOnItDropdown || ""}
                onChange={(e) => handleWorkOnItChange(e.target.value)}
                disabled={!isEditing}
                displayEmpty
                sx={{
                  textAlign: "left",
                  color: "black",
                  "& .MuiSelect-select": {
                    color: "black",
                  },
                  "& .Mui-disabled": {
                    color: "black !important",
                    "-webkit-text-fill-color": "black !important",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "black" }}>Please select</span>
                    );
                  }
                  const options = {
                    1: "I want to work on it.",
                    2: "I am already working on it but need some help.",
                    3: "I am already working on it and don't need any help.",
                    4: "I am not interested at this time.",
                  };
                  return options[selected];
                }}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="1">I want to work on it.</MenuItem>
                <MenuItem value="2">
                  I am already working on it but need some help.
                </MenuItem>
                <MenuItem value="3">
                  I am already working on it and don't need any help.
                </MenuItem>
                <MenuItem value="4">I am not interested at this time.</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              onClick={() => handleCommentToggle("workOnItDropdown")}
              sx={{ color: "#52A1DB" }}
              disabled={!isEditing}
            >
              <CommentIcon />
            </IconButton>
          </Box>

          {/* Comment section */}
          {((commentVisibility.workOnItDropdown && isEditing) ||
            (!isEditing &&
              commentVisibility.workOnItDropdown &&
              formData?.workOnItDropdown?.comment)) && (
            <Box
              sx={{
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mt: 2,
                display: "flex",
              }}
            >
              <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                Comments:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                // placeholder="Comments..."
                value={formData?.workOnItDropdown?.comment || ""}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    workOnItDropdown: {
                      ...prev.workOnItDropdown,
                      comment: e.target.value,
                    },
                  }))
                }
                disabled={!isEditing}
                variant="outlined"
                sx={commentTextFieldSx}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Typography variant="subtitle1">
              Are there things preventing you from making changes?
            </Typography>
            <IconButton
              onClick={() => handleCommentToggle("preventFromMakingChanges")}
              sx={{ color: "#52A1DB" }}
              disabled={!isEditing}
            >
              <CommentIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: 2,
              justifyContent: "flex-start",
            }}
          >
            {options
              .filter((opt) => opt.field !== "comment")
              .map(({ field, label }) => (
                <Button
                  key={field}
                  variant={
                    formData?.preventFromMakingChanges?.[field] === "1"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleOptionClick(field)}
                  disabled={!isEditing}
                  sx={preventChangesButtonSx}
                >
                  {label}
                </Button>
              ))}
          </Box>
          {/* Comment section for preventFromMakingChanges */}
          {((commentVisibility.preventFromMakingChanges && isEditing) ||
            (!isEditing &&
              commentVisibility.preventFromMakingChanges &&
              formData?.preventFromMakingChanges?.comment)) && (
            <Box
              sx={{
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mt: 2,
                display: "flex",
              }}
            >
              <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                Comments:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                // placeholder="Comments..."
                value={formData?.preventFromMakingChanges?.comment || ""}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    preventFromMakingChanges: {
                      ...(prev.preventFromMakingChanges || {}),
                      comment: e.target.value,
                    },
                  }))
                }
                disabled={!isEditing}
                variant="outlined"
                sx={commentTextFieldSx}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Personal Safety Section
          </Typography>

          {/* First Question */}
          <Box sx={{ mb: 4, textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              <Typography variant="subtitle1">
                Have you ever physically or felt emotionally abused by someone?
              </Typography>
              <IconButton
                onClick={() => handleCommentToggle("abusedBySomeone")}
                sx={{ color: "#52A1DB" }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>
            <RadioGroup
              row
              value={formData.abusedBySomeone?.isAbusedBySomeone}
              onChange={(e) => handleAbusedChange(e.target.value)}
              sx={{ justifyContent: "flex-start" }}
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.abusedBySomeone?.isAbusedBySomeone,
                        handleAbusedChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="Yes"
                disabled={!isEditing}
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.abusedBySomeone?.isAbusedBySomeone,
                        handleAbusedChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="No"
                disabled={!isEditing}
              />
            </RadioGroup>

            {/* Show comment box only when toggled or when there's a comment */}
            {((commentVisibility.abusedBySomeone && isEditing) ||
              (!isEditing &&
                commentVisibility.abusedBySomeone &&
                formData.abusedBySomeone?.comment)) && (
              <Box
                sx={{
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mt: 2,
                  display: "flex",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  // placeholder="Additional comments..."
                  value={formData.abusedBySomeone?.comment || ""}
                  onChange={(e) =>
                    handleCommentChange("abusedBySomeone", e.target.value)
                  }
                  disabled={!isEditing}
                  sx={commentTextFieldSx}
                  // sx={{
                  //   "& .MuiInput-root::after": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiInput-root::before": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiInputBase-root": {
                  //     padding: 0,
                  //   },
                  //   "& .MuiOutlinedInput-input": {
                  //     padding: "0px !important",
                  //   },
                  //   "& .MuiOutlinedInput-root textarea": {
                  //     padding: "0px !important",
                  //   },
                  // }}
                />
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Second Question */}
          <Box sx={{ mb: 4, textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              <Typography variant="subtitle1">
                Are you feeling like harming someone or yourself?
              </Typography>
              <IconButton
                onClick={() => handleCommentToggle("feelingLikeHarming")}
                sx={{ color: "#52A1DB" }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>
            <RadioGroup
              row
              value={formData.feelingLikeHarming?.isFeelingLikeHarming}
              onChange={(e) => handleFeelingLikeHarmingChange(e.target.value)}
              sx={{ justifyContent: "flex-start" }}
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.feelingLikeHarming?.isFeelingLikeHarming,
                        handleFeelingLikeHarmingChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="Yes"
                disabled={!isEditing}
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.feelingLikeHarming?.isFeelingLikeHarming,
                        handleFeelingLikeHarmingChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="No"
                disabled={!isEditing}
              />
            </RadioGroup>

            {formData.feelingLikeHarming?.isFeelingLikeHarming === "1" && (
              <Box
                sx={{
                  border: "1px solid rgba(0, 102, 178, 0.5)",
                  borderRadius: "4px",
                  p: 2,
                  mt: 2,
                  mb: 2,
                }}
              >
                {[
                  {
                    label: "Who do you feel like harming?",
                    field: "whoDoYouFeel",
                  },
                  {
                    label: "Do you feel like this at this moment?",
                    field: "atThisMoment",
                  },
                  { label: "Are you in a safe place?", field: "safePlace" },
                  {
                    label: "Would you like me to assist you to call 911?",
                    field: "assistYou",
                  },
                ].map(({ label, field }) => (
                  <Typography
                    key={field}
                    sx={{
                      fontSize: "1rem", // Increased font size
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    {label}
                    <TextField
                      fullWidth
                      value={
                        formData.feelingLikeHarming
                          .isFeelingLikeHarmingSubAnswers[field]
                      }
                      onChange={(e) =>
                        handleSubAnswerChange(field, e.target.value)
                      }
                      disabled={!isEditing}
                      variant="standard"
                      sx={subQuestionTextFieldSx}
                    />
                  </Typography>
                ))}
              </Box>
            )}

            {/* Show comment box only when toggled or when there's a comment */}
            {((commentVisibility.feelingLikeHarming && isEditing) ||
              (!isEditing &&
                commentVisibility.feelingLikeHarming &&
                formData.feelingLikeHarming?.comment)) && (
              <Box
                sx={{
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mt: 2,
                  display: "flex",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  // placeholder="Additional comments..."
                  value={formData.feelingLikeHarming?.comment || ""}
                  onChange={(e) =>
                    handleCommentChange("feelingLikeHarming", e.target.value)
                  }
                  disabled={!isEditing}
                  variant="standard"
                  sx={commentTextFieldSx}
                  // sx={{
                  //   "& .MuiInput-root::after": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiInput-root::before": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiInputBase-root": {
                  //     padding: 0,
                  //   },
                  //   "& .MuiOutlinedInput-input": {
                  //     padding: "0px !important",
                  //   },
                  //   "& .MuiOutlinedInput-root textarea": {
                  //     padding: "0px !important",
                  //   },
                  // }}
                />
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 3 }} />

          {/* Third Question */}
          <Box sx={{ textAlign: "left" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              <Typography variant="subtitle1">
                Are you afraid of anyone or is anyone hurting you?
              </Typography>
              <IconButton
                onClick={() => handleCommentToggle("afraidOfAnyone")}
                sx={{ color: "#52A1DB" }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>
            <RadioGroup
              row
              value={formData.afraidOfAnyone?.isAfraidOfAnyone}
              onChange={(e) => handleAfraidOfAnyoneChange(e.target.value)}
              sx={{ justifyContent: "flex-start" }}
            >
              <FormControlLabel
                value="1"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.afraidOfAnyone?.isAfraidOfAnyone,
                        handleAfraidOfAnyoneChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="Yes"
                disabled={!isEditing}
              />
              <FormControlLabel
                value="2"
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(
                        e,
                        formData.afraidOfAnyone?.isAfraidOfAnyone,
                        handleAfraidOfAnyoneChange,
                      )
                    }
                    sx={radioSx}
                  />
                }
                label="No"
                disabled={!isEditing}
              />
            </RadioGroup>

            {formData.afraidOfAnyone?.isAfraidOfAnyone === "1" && (
              <Box
                sx={{
                  border: "1px solid rgba(0, 102, 178, 0.5)",
                  borderRadius: "4px",
                  p: 2,
                  mt: 2,
                  mb: 2,
                }}
              >
                {[
                  {
                    label:
                      "Who are you afraid of? Are you afraid at this moment?",
                    field: "whoAreYouAfraidOf",
                  },
                  {
                    label:
                      "Who is hurting you?Are you being hurt at this moment?",
                    field: "whoIsHurtingYou",
                  },
                  { label: "Are you in a safe place?", field: "safePlace" },
                  {
                    label: "Would you like me to assist you to call 911?",
                    field: "assistYou",
                  },
                ].map(({ label, field }) => (
                  <Typography
                    key={field}
                    sx={{
                      fontSize: "1rem", // Increased font size
                      mt: 2,
                      mb: 1,
                    }}
                  >
                    {label}
                    <TextField
                      fullWidth
                      value={
                        formData.afraidOfAnyone.isAfraidOfAnyoneSubAnswers[
                          field
                        ]
                      }
                      onChange={(e) =>
                        handleAfraidSubAnswerChange(field, e.target.value)
                      }
                      disabled={!isEditing}
                      variant="standard"
                      sx={subQuestionTextFieldSx}
                    />
                  </Typography>
                ))}
              </Box>
            )}

            {/* Show comment box only when toggled or when there's a comment */}
            {((commentVisibility.afraidOfAnyone && isEditing) ||
              (!isEditing &&
                commentVisibility.afraidOfAnyone &&
                formData.afraidOfAnyone?.comment)) && (
              <Box
                sx={{
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mt: 2,
                  display: "flex",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  // placeholder="Additional comments..."
                  value={formData.afraidOfAnyone?.comment || ""}
                  onChange={(e) =>
                    handleCommentChange("afraidOfAnyone", e.target.value)
                  }
                  disabled={!isEditing}
                  variant="standard"
                  sx={commentTextFieldSx}
                  // sx={{
                  //   "& .MuiInput-root::after": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiInput-root::before": {
                  //     borderBottom: "none !important",
                  //   },
                  //   "& .MuiFormLabel-root": {
                  //     color: "black",
                  //   },
                  //   "& .MuiInputBase-root": {
                  //     padding: 0,
                  //   },
                  //   "& .MuiOutlinedInput-input": {
                  //     padding: "0px !important",
                  //   },
                  //   "& .MuiOutlinedInput-root textarea": {
                  //     padding: "0px !important",
                  //   },
                  // }}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {!isEditing ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsEditing(true)}
              fullWidth={isMobile}
              sx={{
                maxWidth: isMobile ? "100%" : "200px",
                color: "white",
                backgroundColor: "#52A1DB",
                "&:hover": {
                  backgroundColor: "#0055a3", // Slightly darker shade for hover state
                },
              }}
            >
              Edit
            </Button>
          ) : (
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              width={isMobile ? "100%" : "auto"}
            >
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancel}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};
export default Homesafety;
