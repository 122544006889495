import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/actions/auth_action";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  Assignment as DashboardIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Summarize as Note,
} from "@mui/icons-material";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  useTheme,
  alpha,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Homecards from "./Homecards";
import Myprofile from "./Myprofile";
import MedicationIcon from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Profile() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const [openSettings, setOpenSettings] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactType, setContactType] = useState("");
  const [formsModalOpen, setFormsModalOpen] = useState(false);

  const handleLogout = () => {
    try {
      dispatch(authActions.logout());
      navigate("/MemberPortal/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOpenSettings = () => {
    setOpenSettings(true);
    handleClose();
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const token = localStorage.getItem("token");

  const handleSendOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/updatePassword/change/sendOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token, // Assuming token is stored in user object
          },
          body: JSON.stringify({
            email: user.email,
            password: oldPassword,
          }),
        },
      );

      if (response.ok) {
        setOtpSent(true);
      } else {
        alert("Failed to send OTP. Please check your current password.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP. Please try again.");
    }
  };

  const handleValidateOTP = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/memberPortal/updatePassword/forgot/validateOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            otp: parseInt(otp, 10), // Convert string to number
          }),
        },
      );

      if (response.ok) {
        setOtpValidated(true);
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
      alert("Error validating OTP. Please try again.");
    }
  };

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      alert("New passwords do not match");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/memberPortal/updatePassword/change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: user.email,
            currentPassword: oldPassword,
            newPassword: newPassword,
            otp: parseInt(otp, 10), // Convert string to number
            roleId: "11",
          }),
        },
      );

      if (response.ok) {
        alert("Password updated successfully");
        handleCloseSettings();
      } else {
        alert("Failed to update password. Please try again.");
      }
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error updating password. Please try again.");
    }
  };

  const handleContactClick = (type) => {
    setContactType(type);
    setContactModalOpen(true);
  };

  const handleFormsClick = () => {
    setFormsModalOpen(true);
  };

  // Sidebar content with conditional rendering for collapsed state
  const sidebarContent = (
    <Box
      sx={{
        width: isSidebarOpen ? 250 : 65,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          minHeight: 64,
          backgroundColor: "#1577bb",
        }}
      >
        {isSidebarOpen ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            <span style={{ marginTop: "8px" }}>MEMBER PORTAL</span>
          </Box>
        ) : null}
        <IconButton
          onClick={toggleSidebar}
          sx={{
            ml: isSidebarOpen ? 1 : 0,
            transition: theme.transitions.create(["transform", "margin"], {
              duration: theme.transitions.duration.shorter,
              color: "black",
            }),
          }}
        >
          {isSidebarOpen ? (
            <MedicationIcon fontSize="medium" sx={{ color: "white" }} />
          ) : (
            <MedicationIcon fontSize="medium" sx={{ color: "white" }} />
          )}
        </IconButton>
      </Box>

      <Divider />

      <List sx={{ flexGrow: 1, backgroundColor: "#ebf7ff", color: "black" }}>
        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              My Health
            </Typography>
          </ListItem>
        )}

        <Tooltip
          title={!isSidebarOpen ? "My Info" : ""}
          placement="right"
          style={{ backgroundColor: "rgba(25, 118, 210, 0.08)" }}
        >
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Profile")}
            // component="a"
            // href="/MemberPortal/Profile"
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PersonIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="My Info" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Dashboard" : ""} placement="right">
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Dashboard")}
            // component="a"
            // href="/MemberPortal/Dashboard"
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <DashboardIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Forms" : ""} placement="right">
          <ListItem
            button
            onClick={handleFormsClick}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <Note />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Forms" />}
          </ListItem>
        </Tooltip>

        <Divider sx={{ my: 2, color: "black" }} />

        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              Contact Care Team
            </Typography>
          </ListItem>
        )}

        <Tooltip title={!isSidebarOpen ? "Email" : ""} placement="right">
          <ListItem
            button
            onClick={() => handleContactClick("email")}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <EmailIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Email" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Contact" : ""} placement="right">
          <ListItem
            button
            onClick={() => handleContactClick("contact")}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PhoneIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Contact" />}
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );

  const settingsDialog = (
    <Dialog
      open={openSettings}
      onClose={handleCloseSettings}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {/* User Info Section */}
          <Grid item xs={12}>
            <Box
              sx={{
                p: 3,
                border: 1,
                borderColor: "grey.300",
                borderRadius: 1,
                backgroundColor: "grey.50",
              }}
            >
              <Typography variant="h6" sx={{ mb: 3 }}>
                User Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }}>
                    <strong>Username:</strong> {user.username}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Member ID:</strong> {user.memberId}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Password Update Section */}
          <Grid item xs={12}>
            <Box
              sx={{
                p: 3,
                border: 1,
                borderColor: "grey.300",
                borderRadius: 1,
                backgroundColor: "grey.50",
              }}
            >
              <Typography variant="h6" sx={{ mb: 3 }}>
                Change Password
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    label="Current Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    variant="outlined"
                  />
                </Grid>

                {!otpSent && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSendOTP}
                      disabled={!oldPassword}
                      fullWidth
                    >
                      Send OTP
                    </Button>
                  </Grid>
                )}

                {otpSent && !otpValidated && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Enter OTP"
                      value={otp}
                      onChange={(e) => {
                        // Only allow numbers
                        const value = e.target.value.replace(/[^0-9]/g, "");
                        setOtp(value);
                      }}
                      variant="outlined"
                      type="number" // Change input type to number
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={handleValidateOTP}
                      disabled={!otp}
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Validate OTP
                    </Button>
                  </Grid>
                )}

                {otpValidated && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="password"
                        label="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        helperText="Minimum of 8 characters with at least one upper case letter and one number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        type="password"
                        label="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <Button onClick={handleCloseSettings} variant="outlined">
                      Cancel
                    </Button>
                    {otpValidated && (
                      <Button
                        variant="contained"
                        onClick={handlePasswordUpdate}
                        disabled={!newPassword || !confirmPassword}
                      >
                        Update Password
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );

  const ContactModal = ({ open, onClose, type }) => {
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    };

    const contactInfo = {
      name: "John Doe",
      email: "support@healthcare.com",
      phone: "(555) 123-4567",
      hours: "Mon-Fri: 9AM - 5PM EST",
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="contact-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography
            id="contact-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Contact Care Team
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PersonIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <EmailIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.email}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PhoneIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.phone}</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {contactInfo.hours}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={onClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  const FormsModal = ({ open, onClose }) => {
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      maxHeight: "80vh",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      overflow: "auto",
    };

    const formsByYear = {
      2024: [
        { name: "Annual Health Assessment", date: "Jan 15, 2024" },
        { name: "Medical Release Form", date: "Feb 1, 2024" },
      ],
      2023: [
        { name: "Insurance Update Form", date: "Dec 10, 2023" },
        { name: "Medication List", date: "Nov 5, 2023" },
        { name: "Emergency Contact Update", date: "Oct 20, 2023" },
      ],
      2022: [
        { name: "Annual Health Assessment", date: "Dec 15, 2022" },
        { name: "Consent Form", date: "Aug 30, 2022" },
      ],
    };

    return (
      <Modal open={open} onClose={onClose} aria-labelledby="forms-modal-title">
        <Box sx={modalStyle}>
          <Typography
            id="forms-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Forms History - <b>DUMMY DATA</b>
          </Typography>

          {Object.entries(formsByYear).map(([year, forms]) => (
            <Accordion key={year} sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${year}-content`}
                id={`${year}-header`}
              >
                <Typography fontWeight="bold">{year}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {forms.map((form, index) => (
                    <ListItem
                      key={index}
                      button
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.08),
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Note color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={form.name} secondary={form.date} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}

          <Button
            fullWidth
            variant="contained"
            onClick={onClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[4],
          },
        }}
      >
        {sidebarContent}
      </Drawer>

      {/* Desktop Permanent Drawer with animation */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: isSidebarOpen ? 250 : 65,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
            overflowX: "hidden",
          },
        }}
        open={isSidebarOpen}
      >
        {sidebarContent}
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AppBar
          position="static"
          color="default"
          elevation={1}
          sx={{
            backgroundColor: "#ebf7ff",
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleSidebar}
              sx={{
                display: { md: "none" },
                mr: 2,
                transform: isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shorter,
                }),
              }}
            >
              {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <img
              src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
              alt="Focus Cares"
              style={{ height: "45px" }}
            />
            <Box sx={{ flexGrow: 1 }} />

            <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleMenu}
                  color="inherit"
                  size="large"
                  aria-label="account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    minWidth: 200,
                    mt: 1,
                  },
                }}
              >
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    color: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.error.main, 0.08),
                    },
                  }}
                >
                  <LogoutIcon sx={{ mr: 2 }} /> Log out
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto",
            backgroundColor: alpha(theme.palette.primary.main, 0.02),
          }}
        >
          <Typography variant="h4" sx={{ mb: 3 }}>
            <strong>
              Welcome {user.firstName} {user.lastName}!
            </strong>
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <Homecards />
            <Myprofile />
          </Box>
        </Box>
      </Box>
      {settingsDialog}
      <ContactModal
        open={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        type={contactType}
      />
      <FormsModal
        open={formsModalOpen}
        onClose={() => setFormsModalOpen(false)}
      />
    </Box>
  );
}

export default Profile;
