import React, { useState } from "react";
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormGroup,
  IconButton,
  Button,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Add consistent styles at the top of the file
const textFieldSx = {
  "& .MuiInputBase-input": {
    color: "#000000 !important", // Always keep text black
  },
  "& .MuiInputLabel-root": {
    color: "#000000", // Keep label text black
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(0, 0, 0, 0.42)", // Standard underline color
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "solid", // Keep the underline visible when disabled
  },
};

const commentBoxSx = {
  mt: 2,
  border: "1px solid #52A1DB", // Updated to match new blue
  borderRadius: "4px",
  p: 2,
  "& .MuiTypography-root": {
    color: "#000000",
  },
  "& .MuiTextField-root": {
    "& .MuiInputBase-input": {
      color: "#000000 !important",
    },
  },
};

const radioStyle = {
  "&.Mui-checked": {
    color: "#52A1DB", // Always use blue for checked state
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#52A1DB", // Keep blue even when disabled (non-edit mode)
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
  },
};

const checkboxStyle = {
  "&.Mui-checked": {
    color: "#52A1DB", // Always use blue for checked state
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#52A1DB", // Keep blue even when disabled (non-edit mode)
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
  },
};

const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    color: "#000000 !important", // Keep label text black even when disabled
  },
  "&.Mui-disabled": {
    color: "#000000 !important", // Keep text black in disabled state
  },
};

const toggleButtonStyle = {
  "&.Mui-selected": {
    backgroundColor: "#52A1DB !important", // Selected state
    color: "white !important",
    "& .MuiTypography-root": {
      color: "white !important", // Make Typography text white when selected
    },
  },
  "&.Mui-disabled.Mui-selected": {
    backgroundColor: "#52A1DB !important", // Keep blue in disabled selected state
    color: "white !important",
    "& .MuiTypography-root": {
      color: "white !important", // Keep Typography text white when selected and disabled
    },
  },
  color: "#000000 !important", // Keep text black for unselected state
  borderColor: "#52A1DB !important", // Keep border blue
  "& .MuiTypography-root": {
    color: "#000000 !important", // Keep Typography text black for unselected state
  },
};

// Update Select component styles
const selectStyle = {
  "& .MuiSelect-select": {
    color: "#000000 !important", // Always keep text black
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#52A1DB",
  },
  "&.Mui-disabled": {
    "& .MuiSelect-select": {
      color: "#000000 !important", // Keep text black when disabled
      "-webkit-text-fill-color": "#000000 !important", // Required for some browsers
    },
  },
  "& .MuiSelect-icon": {
    color: "#000000 !important", // Keep dropdown icon black
  },
};

// Add styles for the dropdown menu (MenuItems)
const menuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenuItem-root": {
        color: "#000000 !important",
        "&.Mui-selected": {
          color: "#000000 !important",
          backgroundColor: "rgba(82, 161, 219, 0.1)",
        },
        "&:hover": {
          backgroundColor: "rgba(82, 161, 219, 0.1)",
        },
      },
    },
  },
};

export const HousingSection = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState({});

  const handleLivingSituationChange = (value) => {
    if (!isEditing) return;
    const isSelected =
      formData.currentlyLiveSituation.currentlyLiveSituation === value;
    setFormData((prev) => ({
      ...prev,
      currentlyLiveSituation: {
        ...prev.currentlyLiveSituation,
        currentlyLiveSituation: isSelected ? "" : value,
      },
    }));
  };

  const handleCurrentLiveChange = (key) => {
    if (!isEditing || !key) return;
    const isSelected = formData.currentLive[key] === "1";
    const updatedLive = {
      home: "",
      apartment: "",
      assistedLiving: "",
      nursingHome: "",
      homeless: "",
      other: "",
      otherSubAnswer: { describe: "" },
      homelessSubAnswer: { describe: "" },
    };
    if (!isSelected) {
      updatedLive[key] = "1";
    }
    setFormData((prev) => ({
      ...prev,
      currentLive: {
        ...prev.currentLive,
        ...updatedLive,
      },
    }));
  };

  const handleLivingProblemsChange = (event, key) => {
    if (!isEditing) return;

    // Special handling for "none" option
    if (key === "none") {
      // If "none" is checked, clear all other selections
      setFormData((prev) => ({
        ...prev,
        thinkAboutPlace: {
          ...prev.thinkAboutPlace,
          bugsAntsMice: "",
          mold: "",
          leadPaintOrPipes: "",
          lackOfHeat: "",
          ovenOrStove: "",
          waterLeaks: "",
          none: event.target.checked ? "1" : "",
        },
      }));
    } else {
      // If any other option is checked, uncheck "none"
      setFormData((prev) => ({
        ...prev,
        thinkAboutPlace: {
          ...prev.thinkAboutPlace,
          [key]: event.target.checked ? "1" : "",
          none: "", // Clear "none" when selecting other options
        },
      }));
    }
  };

  const handleLivingWithChange = (key) => {
    if (!isEditing) return;
    const isSelected = formData.currentlyLivingWith[key] === "1";
    const updatedLivingWith = {
      alone: "",
      spouse: "",
      partner: "",
      relative: "",
      family: "",
      friend: "",
      personalCareWorker: "",
    };
    if (!isSelected) {
      updatedLivingWith[key] = "1";
    }
    setFormData((prev) => ({
      ...prev,
      currentlyLivingWith: {
        ...prev.currentlyLivingWith,
        ...updatedLivingWith,
      },
    }));
  };

  const handleCommentChange = (section, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        comment: value,
      },
    }));
  };

  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const handleBillsChange = (value) => {
    if (!isEditing) return;
    const isSelected = formData.lastTwelveMonths.isLastTwelveMonths === value;
    setFormData((prev) => ({
      ...prev,
      lastTwelveMonths: {
        ...prev.lastTwelveMonths,
        isLastTwelveMonths: isSelected ? "" : value,
        lastTwelveMonthsSubAnswers: {
          ...prev.lastTwelveMonths.lastTwelveMonthsSubAnswers,
          lastTwelveMonthSelect:
            !isSelected && value === "1"
              ? ""
              : prev.lastTwelveMonths.lastTwelveMonthsSubAnswers
                  .lastTwelveMonthSelect,
        },
      },
    }));
  };

  const handleTransportationChange = (value) => {
    if (!isEditing) return;
    const isSelected = formData.dailyLiving.isDailyLiving === value;
    setFormData((prev) => ({
      ...prev,
      dailyLiving: {
        ...prev.dailyLiving,
        isDailyLiving: isSelected ? "" : value,
      },
    }));
  };

  // Add comment click handler
  const handleCommentClick = (field) => {
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Update the renderCommentSection
  const renderCommentSection = (field) => {
    // Show comment section if either there's a comment or the section is toggled visible
    const shouldShow = showCommentSection[field];
    // || formData[field]?.comment;

    if (!shouldShow) return null;

    return (
      <Box sx={commentBoxSx}>
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={formData[field]?.comment || ""}
          onChange={(e) => handleCommentChange(field, e.target.value)}
          disabled={!isEditing}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              color: "black",
              WebkitTextFillColor: "black",
              opacity: 1,
            },
            "& .MuiInputBase-input": {
              "&::placeholder": {
                color: "text.secondary",
                opacity: 1,
              },
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px !important",
              "& fieldset": {
                borderColor: "#52A1DB",
              },
              "&:hover fieldset": {
                borderColor: "#4190c4",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#52A1DB",
              },
            },
            "& textarea": {
              height: "100% !important",
              minHeight: "60px !important",
              lineHeight: "1.5",
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      {/* Living Situation */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Choose the statement below that best describes your current living
            situation
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("currentlyLiveSituation")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={formData.currentlyLiveSituation.currentlyLiveSituation}
            onChange={(e) => handleLivingSituationChange(e.target.value)}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  onClick={(e) =>
                    handleRadioClick(e, handleLivingSituationChange)
                  }
                  sx={radioStyle}
                />
              }
              label={
                <Typography sx={{ color: "#000000" }}>
                  I have a steady place to live
                </Typography>
              }
              disabled={!isEditing}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  onClick={(e) =>
                    handleRadioClick(e, handleLivingSituationChange)
                  }
                  sx={radioStyle}
                />
              }
              label={
                <Typography sx={{ color: "#000000" }}>
                  I have a place to live today, but I am worried about losing it
                  in the future
                </Typography>
              }
              disabled={!isEditing}
            />
            <FormControlLabel
              value="3"
              control={
                <Radio
                  onClick={(e) =>
                    handleRadioClick(e, handleLivingSituationChange)
                  }
                  sx={radioStyle}
                />
              }
              label={
                <Typography sx={{ color: "#000000" }}>
                  I do not have a steady place to live (I am temporarily staying
                  with others, in a hotel, in a shelter, living outside on the
                  street, on a beach, in a car, abandoned building, bus or train
                  station, or in a park)
                </Typography>
              }
              disabled={!isEditing}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("currentlyLiveSituation")}
      </Box>

      {/* Current Live */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Where do you currently live?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("currentLive")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <ToggleButtonGroup
            orientation="vertical"
            exclusive
            value={Object.keys(formData.currentLive).find(
              (key) => formData.currentLive[key] === "1",
            )}
            onChange={(_, key) => handleCurrentLiveChange(key)}
            disabled={!isEditing}
            sx={{ width: "100%" }}
          >
            {[
              { key: "home", label: "HOME" },
              { key: "apartment", label: "APARTMENT" },
              { key: "assistedLiving", label: "ASSISTED LIVING" },
              { key: "nursingHome", label: "NURSING HOME" },
              { key: "homeless", label: "HOMELESS" },
              { key: "other", label: "OTHER" },
            ].map((option) => (
              <ToggleButton
                key={option.key}
                value={option.key}
                sx={toggleButtonStyle}
              >
                <Typography sx={{ color: "#000000" }}>
                  {option.label}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {(formData.currentLive.other === "1" ||
            formData.currentLive.homeless === "1") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mt: 3, // Increased top margin for better spacing
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Describe..."
                value={
                  formData.currentLive.other === "1"
                    ? formData.currentLive.otherSubAnswer.describe
                    : formData.currentLive.homelessSubAnswer.describe
                }
                onChange={(e) => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    currentLive: {
                      ...prev.currentLive,
                      [formData.currentLive.other === "1"
                        ? "otherSubAnswer"
                        : "homelessSubAnswer"]: {
                        describe: e.target.value,
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={{ mt: 2 }}
              />
            </Box>
          )}
        </FormControl>
        {renderCommentSection("currentLive")}
      </Box>

      {/* Living Problems */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Think about the place you live. Do you have problems with any of the
            following? (Choose all that apply)
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("thinkAboutPlace")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <FormGroup>
            {[
              {
                key: "bugsAntsMice",
                label: "Pests such as bugs, ants or mice",
              },
              { key: "mold", label: "Mold" },
              {
                key: "leadPaintOrPipes",
                label: "Lead Paint or Pipes",
              },
              { key: "lackOfHeat", label: "Lack of Heat" },
              {
                key: "ovenOrStove",
                label: "Oven or Stove not working",
              },
              { key: "waterLeaks", label: "Water Leaks" },
              { key: "none", label: "None of the above" },
            ].map((option) => (
              <FormControlLabel
                key={option.key}
                control={
                  <Checkbox
                    checked={formData.thinkAboutPlace[option.key] === "1"}
                    onChange={(e) => handleLivingProblemsChange(e, option.key)}
                    disabled={!isEditing}
                    sx={checkboxStyle}
                  />
                }
                label={
                  <Typography sx={{ color: "#000000" }}>
                    {option.label}
                  </Typography>
                }
                sx={formControlLabelStyle}
              />
            ))}
          </FormGroup>
        </FormControl>
        {renderCommentSection("thinkAboutPlace")}
      </Box>

      {/* Living With */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Who do you currently live with?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("currentlyLivingWith")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <ToggleButtonGroup
            orientation="vertical"
            exclusive
            value={Object.keys(formData.currentlyLivingWith).find(
              (key) => formData.currentlyLivingWith[key] === "1",
            )}
            onChange={(_, key) => handleLivingWithChange(key)}
            disabled={!isEditing}
            sx={{ width: "100%" }}
          >
            {[
              { key: "alone", label: "ALONE" },
              { key: "spouse", label: "SPOUSE" },
              { key: "partner", label: "PARTNER" },
              { key: "relative", label: "RELATIVE" },
              { key: "family", label: "FAMILY" },
              { key: "friend", label: "FRIEND" },
              {
                key: "personalCareWorker",
                label: "PERSONAL CARE WORKER",
              },
            ].map((option) => (
              <ToggleButton
                key={option.key}
                value={option.key}
                sx={toggleButtonStyle}
              >
                <Typography sx={{ color: "#000000" }}>
                  {option.label}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {formData.currentlyLivingWith.alone === "1" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mt: 3, // Increased top margin for better spacing
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Describe..."
                value={
                  formData.currentlyLivingWith.aloneSubAnswer?.describe || ""
                }
                onChange={(e) => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    currentlyLivingWith: {
                      ...prev.currentlyLivingWith,
                      aloneSubAnswer: {
                        describe: e.target.value,
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={{ mt: 2 }}
              />
            </Box>
          )}
        </FormControl>
        {renderCommentSection("currentlyLivingWith")}
      </Box>

      {/* Bills */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            In the past 12 months have you had trouble paying your utility
            bills?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("lastTwelveMonths")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={formData.lastTwelveMonths.isLastTwelveMonths || ""}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  onClick={(e) => handleRadioClick(e, handleBillsChange)}
                  sx={radioStyle}
                />
              }
              label={<Typography sx={{ color: "#000000" }}>YES</Typography>}
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  onClick={(e) => handleRadioClick(e, handleBillsChange)}
                  sx={radioStyle}
                />
              }
              label={<Typography sx={{ color: "#000000" }}>NO</Typography>}
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("lastTwelveMonths")}

        {formData.lastTwelveMonths.isLastTwelveMonths === "1" && (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth variant="outlined">
              <FormLabel
                sx={{
                  mb: 1,
                  textAlign: "left",
                  color: "black",
                  "&.Mui-focused": {
                    color: "black !important", // Prevent blue color on label
                  },
                }}
              >
                Select
              </FormLabel>
              <Select
                displayEmpty
                value={
                  formData.lastTwelveMonths.lastTwelveMonthsSubAnswers
                    ?.lastTwelveMonthSelect || ""
                }
                onChange={(e) => {
                  if (!isEditing) return;
                  setFormData((prev) => ({
                    ...prev,
                    lastTwelveMonths: {
                      ...prev.lastTwelveMonths,
                      lastTwelveMonthsSubAnswers: {
                        ...prev.lastTwelveMonths.lastTwelveMonthsSubAnswers,
                        lastTwelveMonthSelect: e.target.value,
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={{
                  ...selectStyle,
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                MenuProps={menuProps}
              >
                <MenuItem value="" disabled>
                  Please Select
                </MenuItem>
                <MenuItem value="1">
                  I didn't have enough money to pay my utility bills
                </MenuItem>
                <MenuItem value="2">
                  I received government subsidies to assist with my utility
                  bills
                </MenuItem>
                <MenuItem value="3">Utilities were disconnected</MenuItem>
                <MenuItem value="4">Other</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Box>

      {/* Transportation */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Has lack of reliable transportation kept you from medical
            appointments, meetings, work or from getting things needed for daily
            living?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("dailyLiving")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={formData.dailyLiving.isDailyLiving}
            onChange={(e) => handleTransportationChange(e.target.value)}
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  onClick={(e) =>
                    handleRadioClick(e, handleTransportationChange)
                  }
                  sx={radioStyle}
                />
              }
              label={<Typography sx={{ color: "#000000" }}>Yes</Typography>}
              disabled={!isEditing}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  onClick={(e) =>
                    handleRadioClick(e, handleTransportationChange)
                  }
                  sx={radioStyle}
                />
              }
              label={<Typography sx={{ color: "#000000" }}>No</Typography>}
              disabled={!isEditing}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("dailyLiving")}
      </Box>
    </Box>
  );
};
