import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  Stack,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

const Demograph = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [changedFields, setChangedFields] = useState([]);
  const [showRaceComment, setShowRaceComment] = useState(false);
  const [showEthnicityComment, setShowEthnicityComment] = useState(false);

  const defaultFormData = {
    plan: "",
    program: "",
    lob: "",
    region: "",
    aligned: "",
    firstName: "",
    lastName: "",
    gender: "",
    genderAtBirth: "",
    currentGenderIdentity: "",
    address: "",
    addressTwo: "",
    addressThree: "",
    city: "",
    state: "",
    county: "",
    zip: "",
    dob: "",
    mStatus: "",
    memberId: "",
    medicareId: "",
    email: "",
    primary: "",
    secondary: "",
    ename: "",
    eNo: "",
    pName: "",
    pNameComment: "",
    pPhone: "",
    pFax: "",
    pFlag: "",
    pAddOne: "",
    pCity: "",
    pState: "",
    pZip: "",
    pcpcounty: "",
    pcpNpi: "",
    pId: "",
    pOfcName: "",
    race: {
      caucasian: "",
      africanAmerican: "",
      asian: "",
      latino: "",
      nativeAmerican: "",
      nativeHawaiin: "",
      alaskanNative: "",
      other: "1",
      otherSubAnswer: { describe: "" },
      comment: "",
    },
    patientEthnicity: {
      hispanic: "",
      nonHispanic: "",
      otherethnicity: "",
      prefernot: "1",
      comment: "",
    },
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const fetchLatestDemographics = async () => {
      try {
        const token = localStorage.getItem("token");
        const yearToUse = user.year || new Date().getFullYear();

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-demographics`,
          {
            params: {
              memberId: user.memberId,
              year: yearToUse,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.data?.data) {
          const processedData = {
            ...defaultFormData,
            ...response.data.data,
            race: {
              ...defaultFormData.race,
              ...response.data.data.race,
              otherSubAnswer: {
                ...defaultFormData.race.otherSubAnswer,
                ...response.data.data.race?.otherSubAnswer,
              },
            },
            patientEthnicity: {
              ...defaultFormData.patientEthnicity,
              ...response.data.data.patientEthnicity,
            },
          };
          setFormData(processedData);
          console.log("Fresh demographics data loaded:", processedData);

          // Show comments if they exist
          if (processedData.race?.comment) {
            setShowRaceComment(true);
          }
          if (processedData.patientEthnicity?.comment) {
            setShowEthnicityComment(true);
          }
        }
      } catch (error) {
        console.error("Error fetching latest demographics:", error);
      }
    };

    fetchLatestDemographics();
  }, []); // Run once when component mounts

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Phone number validation
    if (["primary", "secondary", "eNo", "pPhone", "pFax"].includes(id)) {
      // Only allow numbers and common phone number separators
      const cleanedValue = value.replace(/[^\d-+() ]/g, "");

      // Count only digits
      const digitsOnly = cleanedValue.replace(/\D/g, "");

      // If we already have 12 digits and trying to add more, prevent it
      if (digitsOnly.length > 12) {
        return;
      }

      // Always update the form with the cleaned value
      setFormData((prev) => ({
        ...prev,
        [id]: cleanedValue,
      }));
      setIsEditing(true);
      return;
    }

    // Zip code validation
    if (["zip", "pZip"].includes(id)) {
      // Only allow numbers and hyphen
      const cleanedValue = value.replace(/[^\d-]/g, "");

      // Count only digits
      const digitsOnly = cleanedValue.replace(/\D/g, "");

      // If we already have 10 digits and trying to add more, prevent it
      if (digitsOnly.length > 10) {
        return;
      }

      // Update form with cleaned value
      setFormData((prev) => ({
        ...prev,
        [id]: cleanedValue,
      }));
      setIsEditing(true);
      return;
    }

    // Special fields that can have more than 10 characters
    const specialFields = [
      "address",
      "addressTwo",
      "addressThree",
      "pAddOne",
      "pNameComment",
      "email",
      "race",
      "patientEthnicity",
      "firstName",
      "lastName",
      "ename",
      "pName",
      "pOfcName",
    ];

    // For regular fields, limit to 10 characters
    if (!specialFields.includes(id)) {
      if (value.length > 10) {
        return;
      }
    }

    // Handle all fields normally
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
    setIsEditing(true);
  };

  const handleSubmit = async () => {
    // Validate phone numbers
    const phoneFields = ["primary", "secondary", "eNo", "pPhone", "pFax"];
    const invalidPhoneNumbers = phoneFields.filter((field) => {
      const value = formData[field] || "";
      const digitsOnly = value.replace(/\D/g, "");
      return (
        value.length > 0 && (digitsOnly.length < 10 || digitsOnly.length > 12)
      );
    });

    if (invalidPhoneNumbers.length > 0) {
      setMessage(
        "Please enter valid phone/fax numbers (10-12 digits) before saving.",
      );
      return;
    }

    // Validate zip codes
    const zipFields = ["zip", "pZip"];
    const invalidZips = zipFields.filter((field) => {
      const value = formData[field] || "";
      const digitsOnly = value.replace(/\D/g, "");
      return (
        value.length > 0 && (digitsOnly.length < 5 || digitsOnly.length > 10)
      );
    });

    if (invalidZips.length > 0) {
      setMessage("Please enter valid zip codes (5-10 digits) before saving.");
      return;
    }

    const yearToUse = (function () {
      if (!user.year) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(user.year).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      const token = localStorage.getItem("token");
      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "Demographics",
        data: {
          ...formData,
          // Clear comments if their respective sections are hidden
          race: {
            ...formData.race,
            comment: showRaceComment ? formData.race?.comment : "",
          },
          patientEthnicity: {
            ...formData.patientEthnicity,
            comment: showEthnicityComment
              ? formData.patientEthnicity?.comment
              : "",
          },
        },
      };

      // Store current data for comparison
      const previousData = { ...formData };

      // PUT request to update demographics
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-demographics`,
        payload,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      setMessage("Demographics updated successfully!");
      setIsEditing(false);

      // GET request to fetch updated demographics
      const getResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-demographics`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (getResponse.data?.data) {
        const newData = getResponse.data.data;

        // Track changed fields
        const changes = [];
        Object.keys(newData).forEach((key) => {
          if (
            JSON.stringify(newData[key]) !== JSON.stringify(previousData[key])
          ) {
            changes.push({
              field: key,
              previousValue: previousData[key],
              newValue: newData[key],
            });
          }
        });
        setChangedFields(changes);

        // Update comment visibility based on whether comments exist
        setShowRaceComment(!!newData.race?.comment);
        setShowEthnicityComment(!!newData.patientEthnicity?.comment);

        // Update form with new data from GET response
        const processedData = {
          ...defaultFormData, // Start with default values
          ...newData, // Overlay with new data
          race: {
            ...defaultFormData.race,
            ...newData.race,
            otherSubAnswer: {
              ...defaultFormData.race.otherSubAnswer,
              ...newData.race?.otherSubAnswer,
            },
          },
          patientEthnicity: {
            ...defaultFormData.patientEthnicity,
            ...newData.patientEthnicity,
          },
        };

        // Update the form with the new data
        setFormData(processedData);
        console.log("Form updated with GET response data:", processedData);
      }
    } catch (error) {
      console.error("Error updating demographics:", error);
      setMessage("Failed to update demographics. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data?.data) {
      setFormData(data.data);
    }
    setIsEditing(false);
    setMessage("");
  };

  const handleRaceChange = (raceType) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const updatedRace = { ...prev.race };

      // If clicking the currently selected option
      if (updatedRace[raceType] === "1") {
        // Clear current selection
        Object.keys(updatedRace).forEach((key) => {
          if (key !== "otherSubAnswer" && key !== "comment") {
            updatedRace[key] = "";
          }
        });
        // Also clear other description if it exists
        if (updatedRace.otherSubAnswer) {
          updatedRace.otherSubAnswer.describe = "";
        }
      } else {
        // Clear all selections first
        Object.keys(updatedRace).forEach((key) => {
          if (key !== "otherSubAnswer" && key !== "comment") {
            updatedRace[key] = "";
          }
        });
        // Clear other description if selecting a non-other option
        if (raceType !== "other" && updatedRace.otherSubAnswer) {
          updatedRace.otherSubAnswer.describe = "";
        }
        // Set new selection
        updatedRace[raceType] = "1";
      }

      return {
        ...prev,
        race: updatedRace,
      };
    });
  };

  const handleEthnicityChange = (ethnicityType) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const updatedEthnicity = { ...prev.patientEthnicity };

      // If clicking the currently selected option
      if (updatedEthnicity[ethnicityType] === "1") {
        // Clear current selection
        Object.keys(updatedEthnicity).forEach((key) => {
          if (key !== "comment") {
            updatedEthnicity[key] = "";
          }
        });
        // Clear other description if it exists
        // if (ethnicityType === "otherethnicity") {
        //   updatedEthnicity.comment = "";
        // }
      } else {
        // Clear all selections first
        Object.keys(updatedEthnicity).forEach((key) => {
          if (key !== "comment") {
            updatedEthnicity[key] = "";
          }
        });
        // Clear other description if selecting a non-other option
        // if (ethnicityType !== "otherethnicity") {
        //   updatedEthnicity.comment = "";
        // }
        // Set new selection
        updatedEthnicity[ethnicityType] = "1";
      }

      return {
        ...prev,
        patientEthnicity: updatedEthnicity,
      };
    });
  };

  const handleRaceCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      race: {
        ...prev.race,
        comment: value,
      },
    }));
  };

  const handleEthnicityCommentChange = (value) => {
    console.log("event", value, isEditing);
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      patientEthnicity: {
        ...prev.patientEthnicity,
        comment: value,
      },
    }));
  };

  useEffect(() => {
    console.log("form data", formData);
  }, [formData]);

  const handleOtherRaceDescriptionChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      race: {
        ...prev.race,
        otherSubAnswer: {
          ...prev.race.otherSubAnswer,
          describe: value,
        },
      },
    }));
  };

  const raceOptions = [
    { id: "africanAmerican", label: "African American" },
    { id: "alaskanNative", label: "Alaskan Native" },
    { id: "asian", label: "Asian" },
    { id: "caucasian", label: "Caucasian" },
    { id: "latino", label: "Latino" },
    { id: "nativeAmerican", label: "Native American" },
    { id: "nativeHawaiin", label: "Native Hawaiian or other Pacific Islander" },
    { id: "other", label: "Other" },
  ];

  const ethnicityOptions = [
    { id: "hispanic", label: "Hispanic" },
    { id: "nonHispanic", label: "Non-Hispanic" },
    { id: "otherethnicity", label: "Other Ethnicity" },
    { id: "prefernot", label: "Prefer not to say" },
  ];

  // Update IconButton styling for comment icons
  const commentIconSx = {
    color: (theme) => (isEditing ? "#0066B2" : "rgba(0, 0, 0, 0.12)"),
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.12)",
    },
  };

  const styleConstants = {
    primary: "#52A1DB",
    secondary: "#0066B2",
    darkBlue: "#004d86",
    borderRadius: "4px",
    disabled: "rgba(0, 0, 0, 0.38)",
  };

  const textFieldSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: styleConstants.borderRadius,
      "& fieldset": {
        borderColor: styleConstants.primary,
      },
      "&:hover fieldset": {
        borderColor: "#4190c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: styleConstants.primary,
      },
      "& input": {
        color: "black !important",
        "-webkit-text-fill-color": "black !important",
      },
      "& .MuiOutlinedInput-root": {
        padding: "0px !important",
      },
      "& textarea": {
        height: "100% !important",
        minHeight: "60px !important",
        lineHeight: "1.5",
        color: "black !important",
        "-webkit-text-fill-color": "black !important",
      },
      "&.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root": {
        margin: "0 !important",
      },
      "& .Mui-disabled": {
        WebkitTextFillColor: "black !important",
        color: "black !important",
      },
    },
  };

  const commentTextFieldSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      padding: "0px !important",
      "& fieldset": {
        borderColor: "#0066B2",
      },
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
      color: "black",
      padding: "8px !important",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "black !important",
      color: "black !important",
    },
    "& .MuiInput-root::before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-root::after": {
      borderBottom: "none !important",
    },
    "& .MuiInput-root:hover:not(.Mui-disabled)::before": {
      borderBottom: "none !important",
    },
  };

  // Update button styling without referencing option
  const buttonSx = {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    textTransform: "none",
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB",
      color: "white",
      "&:hover": {
        backgroundColor: "#0066B2",
      },
    },
    "&.MuiButton-outlined": {
      backgroundColor: "#e3edf4",
      borderColor: "#52A1DB",
      color: "black",
      "&:hover": {
        borderColor: "#0066B2",
        color: "#0066B2",
      },
    },
    "&.Mui-disabled.MuiButton-contained": {
      backgroundColor: "#52A1DB",
      color: "white",
      borderColor: "#52A1DB",
      opacity: 1,
    },
    "&.Mui-disabled.MuiButton-outlined": {
      backgroundColor: "#e3edf4",
      color: "black",
      borderColor: "#52A1DB",
      opacity: 1,
    },
  };

  const renderRaceSection = () => (
    <Box
      sx={{
        border: "1px solid #0066B2",
        borderRadius: 1,
        p: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" align="left">
          Race
        </Typography>
        <IconButton
          onClick={() => setShowRaceComment(!showRaceComment)}
          disabled={!isEditing}
          sx={commentIconSx}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: 2,
        }}
      >
        {raceOptions.map((option) => (
          <Button
            key={option.id}
            variant={
              formData.race[option.id] === "1" ? "contained" : "outlined"
            }
            onClick={() => handleRaceChange(option.id)}
            disabled={!isEditing}
            sx={buttonSx}
          >
            {option.label}
          </Button>
        ))}
      </Box>

      {formData.race.other === "1" && (
        <TextField
          fullWidth
          label="Describe"
          value={formData.race.otherSubAnswer?.describe || ""}
          onChange={(e) => handleOtherRaceDescriptionChange(e.target.value)}
          disabled={!isEditing}
          variant="outlined"
          sx={{ mb: 2 }}
        />
      )}

      {showRaceComment && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            border: "1px solid #0066B2",
            borderRadius: "4px",
            p: 2,
            mb: 2,
            backgroundColor: "white",
          }}
        >
          <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={formData.race?.comment || ""}
            onChange={(e) => handleRaceCommentChange(e.target.value)}
            disabled={!isEditing}
            variant="outlined"
            sx={commentTextFieldSx}
          />
        </Box>
      )}
    </Box>
  );

  const renderEthnicitySection = () => (
    <Box
      sx={{
        border: "1px solid #0066B2",
        borderRadius: 1,
        p: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" align="left">
          Patient's Ethnicity
        </Typography>
        <IconButton
          onClick={() => setShowEthnicityComment(!showEthnicityComment)}
          disabled={!isEditing}
          sx={commentIconSx}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          mb: 2,
        }}
      >
        {ethnicityOptions.map((option) => (
          <Button
            key={option.id}
            variant={
              formData.patientEthnicity[option.id] === "1"
                ? "contained"
                : "outlined"
            }
            onClick={() => handleEthnicityChange(option.id)}
            disabled={!isEditing}
            sx={buttonSx}
          >
            {option.label}
          </Button>
        ))}
      </Box>
      {showEthnicityComment && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            border: "1px solid #0066B2",
            borderRadius: "4px",
            p: 2,
            mb: 2,
            backgroundColor: "white",
          }}
        >
          <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={formData.patientEthnicity?.comment || ""}
            onChange={(e) => handleEthnicityCommentChange(e.target.value)}
            disabled={!isEditing}
            variant="outlined"
            sx={commentTextFieldSx}
          />
        </Box>
      )}
    </Box>
  );

  const renderField = (field) => {
    if (field.type === "radio") {
      return (
        <FormControl>
          <RadioGroup
            row={!isMobile}
            value={formData.genderAtBirth}
            name="genderAtBirth"
          >
            <FormControlLabel
              value="1"
              control={
                <Radio
                  sx={{
                    color: "#0066B2",
                    "&.Mui-checked": {
                      color: "#0066B2",
                    },
                    "&.Mui-disabled": {
                      color: "#0066B2",
                    },
                    "&.Mui-disabled.Mui-checked": {
                      color: "#0066B2",
                    },
                  }}
                />
              }
              label="Female"
              disabled={true}
              sx={{
                "&.Mui-disabled": {
                  color: "black",
                },
                "& .MuiFormControlLabel-label.Mui-disabled": {
                  color: "black",
                },
              }}
            />
            <FormControlLabel
              value="2"
              control={
                <Radio
                  sx={{
                    color: "#0066B2",
                    "&.Mui-checked": {
                      color: "#0066B2",
                    },
                    "&.Mui-disabled": {
                      color: "#0066B2",
                    },
                    "&.Mui-disabled.Mui-checked": {
                      color: "#0066B2",
                    },
                  }}
                />
              }
              label="Male"
              disabled={true}
              sx={{
                "&.Mui-disabled": {
                  color: "black",
                },
                "& .MuiFormControlLabel-label.Mui-disabled": {
                  color: "black",
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      );
    }

    if (field.type === "select") {
      return (
        <FormControl fullWidth size={isMobile ? "small" : "medium"}>
          <Select
            id={field.id}
            value={formData[field.id] || ""}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                [field.id]: e.target.value,
              }));
              setIsEditing(true);
            }}
            disabled={!isEditing || !field.editable}
            variant="outlined"
            displayEmpty
            sx={{
              minWidth: isMobile ? "100%" : "200px",
              "&.Mui-disabled": {
                "& .MuiSelect-select": {
                  color: "rgba(0, 0, 0, 0.6)", // Softer gray
                  WebkitTextFillColor: "rgba(0, 0, 0, 0.6)",
                },
              },
            }}
          >
            <MenuItem value="" disabled>
              <em>Select</em>
            </MenuItem>
            {field.options
              .filter((option) => option.value !== "")
              .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    }

    // Update phone field validation
    const isPhoneField = [
      "primary",
      "secondary",
      "eNo",
      "pPhone",
      "pFax",
    ].includes(field.id);
    const phoneValue = formData[field.id] || "";
    const digitsOnly = phoneValue.replace(/\D/g, "");
    const isValidPhone =
      digitsOnly.length === 0 ||
      (digitsOnly.length >= 10 && digitsOnly.length <= 12);

    // Add zip field validation
    const isZipField = ["zip", "pZip"].includes(field.id);
    const zipValue = formData[field.id] || "";
    const zipDigitsOnly = zipValue.replace(/\D/g, "");
    const isValidZip =
      zipDigitsOnly.length === 0 ||
      (zipDigitsOnly.length >= 5 && zipDigitsOnly.length <= 10);

    // Special fields that can have more than 10 characters
    const specialFields = [
      "address",
      "addressTwo",
      "addressThree",
      "pAddOne",
      "pNameComment",
      "email",
      "race",
      "patientEthnicity",
      "firstName",
      "lastName",
      "ename",
      "pName",
      "pOfcName",
    ];

    return (
      <TextField
        fullWidth
        id={field.id}
        value={
          field.value !== undefined ? field.value : formData[field.id] || ""
        }
        onChange={handleInputChange}
        disabled={!isEditing || !field.editable}
        variant="outlined"
        type={field.type || "text"}
        size={isMobile ? "small" : "medium"}
        multiline={field.id === "address"}
        minRows={field.id === "address" ? 2 : 1}
        InputLabelProps={field.type === "date" ? { shrink: true } : undefined}
        error={
          (isPhoneField && phoneValue.length > 0 && !isValidPhone) ||
          (isZipField && zipValue.length > 0 && !isValidZip)
        }
        helperText={
          isPhoneField && phoneValue.length > 0 && !isValidPhone
            ? "Phone/fax number must be between 10-12 digits"
            : isZipField && zipValue.length > 0 && !isValidZip
            ? "Zip code must be between 5-10 digits"
            : ""
        }
        sx={{
          "& .MuiInputBase-input": {
            fontSize: isMobile ? "0.875rem" : "1rem",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            color: "black !important",
          },
          "& .MuiOutlinedInput-root": {
            height: "auto",
            minHeight: field.id === "address" ? "60px" : "40px",
            "& input": {
              color: "black !important",
            },
            "& .MuiInputBase-input": {
              color: "black !important",
            },
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: "black !important",
            color: "black !important",
            "-webkit-text-fill-color": "black !important",
          },
          // Add styles for Select component
          "& .MuiSelect-select": {
            color: "black !important",
            "&.Mui-disabled": {
              color: "black !important",
              "-webkit-text-fill-color": "black !important",
            },
          },
          // Override any Material-UI default styles
          "& .MuiOutlinedInput-input": {
            color: "black !important",
          },
        }}
      />
    );
  };

  const fields = [
    { id: "memberId", label: "Member ID", editable: false },
    { id: "plan", label: "Plan", editable: true },
    { id: "program", label: "Program", editable: true },
    { id: "email", label: "Email Address", editable: false },
    { id: "firstName", label: "First Name", editable: false },
    { id: "lastName", label: "Last Name", editable: false },
    {
      id: "genderAtBirth",
      label: "Gender at Birth",
      editable: false,
      type: "radio",
    },
    {
      id: "mStatus",
      label: "Marital Status",
      editable: true,
      type: "select",
      options: [
        { value: "", label: "Select Marital Status" },
        { value: "1", label: "Single" },
        { value: "2", label: "Married" },
        { value: "3", label: "Divorced" },
        { value: "4", label: "Widowed" },
        { value: "5", label: "Separated" },
        { value: "6", label: "Other" },
      ],
    },
    {
      id: "dob",
      label: "Date of Birth",
      editable: false,
      type: "date",
      value: formData.dob ? formData.dob.split("T")[0] : "",
    },
    { id: "primary", label: "Phone Number", editable: true },
    { id: "secondary", label: "Secondary Phone", editable: true },
    { id: "ename", label: "Emergency Contact Name", editable: true },
    { id: "eNo", label: "Emergency Contact Number", editable: true },
    { id: "address", label: "Address", editable: true },
    { id: "addressTwo", label: "Address Line 2", editable: true },
    { id: "addressThree", label: "Address Line 3", editable: true },
    { id: "city", label: "City", editable: true },
    { id: "state", label: "State", editable: true },
    { id: "county", label: "County", editable: true },
    { id: "zip", label: "Zip Code", editable: true },
    { id: "pName", label: "Primary Care Physician", editable: true },
    { id: "pNameComment", label: "PCP Comments", editable: true },
    { id: "pPhone", label: "PCP Phone Number", editable: true },
    { id: "pFax", label: "PCP Fax Number", editable: true },
    { id: "pAddOne", label: "PCP Address", editable: true },
    { id: "pCity", label: "PCP City", editable: true },
    { id: "pState", label: "PCP State", editable: true },
    { id: "pZip", label: "PCP Zip", editable: true },
    { id: "pcpcounty", label: "PCP County", editable: true },
    { id: "pcpNpi", label: "PCP NPI", editable: true },
    { id: "pId", label: "PCP ID", editable: true },
    { id: "pOfcName", label: "PCP Office Name", editable: true },
    {
      id: "race",
      label: "Race",
      type: "race",
      editable: true,
    },
    {
      id: "raceComment",
      label: "Race Comments",
      type: "comment",
      section: "race",
      editable: true,
    },
    {
      id: "ethnicity",
      label: "Ethnicity",
      type: "ethnicity",
      editable: true,
    },
    {
      id: "ethnicityComment",
      label: "Ethnicity Comments",
      type: "comment",
      section: "ethnicity",
      editable: true,
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3, px: isMobile ? 1 : 3 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      {/* Content first */}
      {isMobile ? (
        <Stack spacing={2}>
          {fields
            .filter(
              (field) =>
                field.type !== "race" &&
                field.type !== "ethnicity" &&
                field.type !== "comment",
            )
            .map((field) => (
              <Card key={field.id} variant="outlined">
                <CardContent
                  sx={{
                    p: 2,
                    minHeight: field.id === "address" ? "100px" : "auto",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    color="text.secondary"
                  >
                    {field.label}
                  </Typography>
                  {renderField(field)}
                </CardContent>
              </Card>
            ))}

          {/* Race Section */}
          <Card variant="outlined">
            <CardContent>{renderRaceSection()}</CardContent>
          </Card>

          {/* Ethnicity Section */}
          <Card variant="outlined">
            <CardContent>{renderEthnicitySection()}</CardContent>
          </Card>
        </Stack>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableBody>
              {fields
                .filter(
                  (field) =>
                    field.type !== "race" &&
                    field.type !== "ethnicity" &&
                    field.type !== "comment",
                )
                .map((field) => (
                  <TableRow key={field.id}>
                    <TableCell
                      sx={{
                        width: "30%",
                        border: "1px solid black",
                      }}
                    >
                      <Typography variant="subtitle1">{field.label}</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid black" }}>
                      {renderField(field)}
                    </TableCell>
                  </TableRow>
                ))}

              {/* Race Section */}
              <TableRow>
                <TableCell colSpan={2} sx={{ border: "1px solid black" }}>
                  {renderRaceSection()}
                </TableCell>
              </TableRow>

              {/* Ethnicity Section */}
              <TableRow>
                <TableCell colSpan={2} sx={{ border: "1px solid black" }}>
                  {renderEthnicitySection()}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Edit buttons at bottom */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3, // Add margin top to separate from content
        }}
      >
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
            size={isMobile ? "large" : "medium"}
          >
            Edit
          </Button>
        ) : (
          <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              size={isMobile ? "large" : "medium"}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              size={isMobile ? "large" : "medium"}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default Demograph;
