import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  Box,
  Alert,
  TableFooter,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentIcon from "@mui/icons-material/Comment";

const styleConstants = {
  primary: "#52A1DB",
  secondary: "#0066B2",
  darkBlue: "#004d86",
  borderRadius: "4px",
  disabled: "rgba(0, 0, 0, 0.38)",
};

const textFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: styleConstants.borderRadius,
    "& fieldset": {
      borderColor: styleConstants.primary,
    },
    "&:hover fieldset": {
      borderColor: "#4190c4",
    },
    "&.Mui-focused fieldset": {
      borderColor: styleConstants.primary,
    },
    "& input": {
      color: "black !important",
      "-webkit-text-fill-color": "black !important",
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
      color: "black !important",
      "-webkit-text-fill-color": "black !important",
    },
    "&.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root": {
      margin: "0 !important",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "black !important",
      color: "black !important",
    },
  },
};

const radioSx = {
  "&.MuiFormControlLabel-root": {
    margin: 0,
    color: "text.primary",
    "&.Mui-disabled": {
      color: "text.primary",
    },
  },
  "& .MuiRadio-root": {
    color: "text.secondary",
    "&.Mui-checked": {
      color: "text.secondary",
    },
    "&.Mui-disabled": {
      color: "text.secondary",
      "&.Mui-checked": {
        color: "text.secondary",
      },
    },
  },
};

const selectSx = {
  "& .MuiSelect-select": {
    color: "text.secondary",
    "&.Mui-disabled": {
      WebkitTextFillColor: "black",
      color: "black !important",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "text.primary",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "text.secondary",
  },
  "&.Mui-disabled": {
    "& .MuiSelect-select": {
      WebkitTextFillColor: "black",
      color: "black !important",
    },
  },
};

const buttonSx = {
  width: "100%",
  justifyContent: "center",
  textAlign: "center",
  textTransform: "none",
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "white",
    "&:hover": {
      backgroundColor: "#0066B2",
    },
  },
  "&.MuiButton-outlined": {
    backgroundColor: "#e3edf4",
    borderColor: "#52A1DB",
    color: "black",
    "&:hover": {
      borderColor: "#0066B2",
      color: "#0066B2",
    },
  },
  "&.Mui-disabled.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "white",
    borderColor: "#52A1DB",
    opacity: 1,
  },
  "&.Mui-disabled.MuiButton-outlined": {
    backgroundColor: "#e3edf4",
    color: "black",
    borderColor: "#52A1DB",
    opacity: 1,
  },
};

const Activities = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultFormData = {
    difficultyWithActivities: {
      bathing: { score: 0 },
      dressing: { score: 0 },
      toileting: { score: 0 },
      transferring: { score: 0 },
      continence: { score: 0 },
      feeding: { score: 0 },
      totalScore: "0",
      totalScoreComment: "",
      comment: "",
    },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [changedFields, setChangedFields] = useState([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [comment, setComment] = useState("");
  const [commentVisibility, setCommentVisibility] = useState(() => {
    const savedState = localStorage.getItem("activitiesofDailyLivingComment");
    return savedState
      ? JSON.parse(savedState)
      : {
          showEvidenceComment: false,
          showTotalScoreComment: false,
        };
  });
  const [showEvidenceComment, setShowEvidenceComment] = useState(
    commentVisibility.showEvidenceComment,
  );
  const [showTotalScoreComment, setShowTotalScoreComment] = useState(
    commentVisibility.showTotalScoreComment,
  );

  const commentTextFieldSx = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      padding: "0px !important",
      "& fieldset": {
        borderColor: "#0066B2",
      },
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
      color: "black",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "black !important",
      color: "black !important",
    },
  };

  useEffect(() => {
    const commentVisibility = {
      showEvidenceComment,
      showTotalScoreComment,
    };

    localStorage.setItem(
      "activitiesofDailyLivingComment",
      JSON.stringify(commentVisibility),
    );
  }, [showEvidenceComment, showTotalScoreComment]);

  useEffect(() => {
    if (data?.data) {
      // If data exists but is empty array, use default state
      if (Array.isArray(data.data) && data.data.length === 0) {
        setFormData(defaultFormData);
      } else {
        setFormData(data.data);
        // Initialize comment box visibility based on existing data
        setShowEvidenceComment(
          !!data?.data?.difficultyWithActivities?.comment?.trim(),
        );
        setShowTotalScoreComment(
          !!data?.data?.difficultyWithActivities?.totalScoreComment?.trim(),
        );
      }
    } else {
      // If no data property exists, use default state
      setFormData(defaultFormData);
    }
  }, [data]);

  useEffect(() => {
    const fetchLatestActivities = async () => {
      try {
        const token = localStorage.getItem("token");
        const yearToUse = user.year || new Date().getFullYear();

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-activities`,
          {
            params: {
              memberId: user.memberId,
              year: yearToUse,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.data?.data) {
          const processedData = {
            ...defaultFormData,
            ...response.data.data,
            difficultyWithActivities: {
              ...defaultFormData.difficultyWithActivities,
              ...response.data.data.difficultyWithActivities,
            },
          };
          setFormData(processedData);
          // Initialize comment box visibility based on fetched data
          setShowEvidenceComment(
            !!processedData?.difficultyWithActivities?.comment?.trim(),
          );
          setShowTotalScoreComment(
            !!processedData?.difficultyWithActivities?.totalScoreComment?.trim(),
          );

          console.log("Fresh activities data loaded:", processedData);
        }
      } catch (error) {
        console.error("Error fetching latest activities:", error);
      }
    };

    fetchLatestActivities();
  }, []);

  const handleScoreChange = (activity, value) => {
    setFormData((prevData) => ({
      ...prevData,
      difficultyWithActivities: {
        ...prevData.difficultyWithActivities,
        [activity]: { score: parseInt(value) },
      },
    }));
    setIsEditing(true);
  };

  const calculateTotalScore = () => {
    const activities = formData.difficultyWithActivities;
    return Object.keys(activities)
      .filter(
        (key) =>
          key !== "totalScore" &&
          key !== "totalScoreComment" &&
          key !== "comment",
      )
      .reduce((total, key) => total + activities[key].score, 0);
  };

  const getScoreIndication = (score) => {
    if (score === 5) return "Full Function";
    if (score >= 3 && score <= 4) return "Moderate Impairment";
    return "Severe Functional Impairment";
  };

  const handleSubmit = async () => {
    const yearToUse = (function () {
      if (!user.year) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(user.year).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      // Store current data for comparison
      const previousData = { ...formData };

      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "Activities",
        data: {
          ...formData,
          difficultyWithActivities: {
            ...formData.difficultyWithActivities,
            totalScore: calculateTotalScore().toString(),
          },
        },
      };

      const token = localStorage.getItem("token");

      // PUT request to update activities
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-activities`,
        payload,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      setMessage("Activities updated successfully!");
      setIsEditing(false);

      // GET request to fetch updated activities
      const getResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-activities`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (getResponse.data?.data) {
        const newData = getResponse.data.data;

        // Track changed fields
        const changes = [];
        Object.keys(newData.difficultyWithActivities).forEach((key) => {
          if (
            JSON.stringify(newData.difficultyWithActivities[key]) !==
            JSON.stringify(previousData.difficultyWithActivities[key])
          ) {
            changes.push({
              field: key,
              previousValue: previousData.difficultyWithActivities[key],
              newValue: newData.difficultyWithActivities[key],
            });
          }
        });
        setChangedFields(changes);

        // Update form with new data
        const processedData = {
          ...defaultFormData,
          ...newData,
          difficultyWithActivities: {
            ...defaultFormData.difficultyWithActivities,
            ...newData.difficultyWithActivities,
          },
        };
        setFormData(processedData);
        console.log("Form updated with GET response data:", processedData);

        // Update comment box visibility only after successful submission
        // Hide boxes only if they're empty
        // setShowEvidenceComment(!!newData.difficultyWithActivities.comment);
        // setShowTotalScoreComment(
        //   !!newData.difficultyWithActivities.totalScoreComment,
        // );
        setShowEvidenceComment(
          !!newData?.difficultyWithActivities?.comment?.trim(),
        );
        setShowTotalScoreComment(
          !!newData?.difficultyWithActivities?.totalScoreComment?.trim(),
        );
      }
    } catch (error) {
      console.error("Error updating activities:", error);
      setMessage("Failed to update activities. Please try again.");
    }
  };

  const handleCancel = () => {
    if (data?.data) {
      // Handle empty array case during cancel
      if (Array.isArray(data.data) && data.data.length === 0) {
        setFormData(defaultFormData);
      } else {
        setFormData(data.data);
      }
    } else {
      setFormData(defaultFormData);
    }
    setIsEditing(false);
    setMessage("");
  };

  const handleCommentChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      difficultyWithActivities: {
        ...prevData.difficultyWithActivities,
        comment: event.target.value,
      },
    }));
    setIsEditing(true);
  };

  const handleTotalScoreCommentChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      difficultyWithActivities: {
        ...prevData.difficultyWithActivities,
        totalScoreComment: event.target.value,
      },
    }));
    setIsEditing(true);
  };

  const handleCommentToggle = (type) => {
    if (type === "evidence") {
      setShowEvidenceComment((prev) => !prev);
    } else if (type === "totalScore") {
      setShowTotalScoreComment((prev) => !prev);
    }
  };

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  const activities = [
    {
      name: "bathing",
      independence:
        "(1 POINT) Bathes self completely or needs help in bathing only a single part of the body such as the back, genital area or disabled extremity.",
      dependence:
        "(2-5 POINTS) Need help with bathing more than one part of the body, getting in or out of the tub or shower. Requires total bathing.",
    },
    {
      name: "dressing",
      independence:
        "(1 POINT) Get clothes from closets and drawers and puts on clothes and outer garments complete with fasteners. May have help tying shoes.",
      dependence:
        "(2-5 POINTS) Needs help with dressing self or needs to be completely dressed.",
    },
    {
      name: "toileting",
      independence:
        "(1 POINT) Goes to toilet, gets on and off, arranges clothes, cleans genital area without help.",
      dependence:
        "(2-5 POINTS) Needs help transferring to the toilet, cleaning self or uses bedpan or commode.",
    },
    {
      name: "transferring",
      independence:
        "(1 POINT) Moves in and out of bed or chair unassisted. Mechanical transfer aids are acceptable.",
      dependence:
        "(2-5 POINTS) Needs help in moving from bed to chair or requires a complete transfer.",
    },
    {
      name: "continence",
      independence:
        "(1 POINT) Exercises complete self control over urination and defecation.",
      dependence:
        "(2-5 POINTS) Is partially or totally incontinent of bowel or bladder.",
    },
    {
      name: "feeding",
      independence:
        "(1 POINT) Gets food from plate into mouth without help. Preparation of food may be done by another person.",
      dependence:
        "(2-5 POINTS) Needs partial or total help with feeding or requires parenteral feeding.",
    },
  ];

  const renderMobileView = () => (
    <Box sx={{ width: "100%" }}>
      {activities.map((activity) => (
        <Accordion key={activity.name} sx={{ mb: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight="bold">
              {activity.name.charAt(0).toUpperCase() + activity.name.slice(1)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <Typography variant="subtitle2" color="primary">
                Independence
              </Typography>
              <Typography variant="body2">{activity.independence}</Typography>
              <Typography variant="subtitle2" color="error">
                Dependence
              </Typography>
              <Typography variant="body2">{activity.dependence}</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Score:
                </Typography>
                <Select
                  fullWidth
                  value={formData.difficultyWithActivities[activity.name].score}
                  onChange={(e) =>
                    handleScoreChange(activity.name, e.target.value)
                  }
                  disabled={!isEditing}
                  size="small"
                  sx={selectSx}
                >
                  {[...Array(6)].map((_, i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  const renderDesktopView = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "#52A1DB",
                color: "white",
                fontWeight: "bold",
                width: "15%",
              }}
            >
              Activities
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#52A1DB",
                color: "white",
                fontWeight: "bold",
                width: "35%",
              }}
            >
              Independence (1 Point)
              <Typography variant="body2">
                NO supervision, direction or personal assistance.
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#52A1DB",
                color: "white",
                fontWeight: "bold",
                width: "35%",
              }}
            >
              Dependence (2-5 Points)
              <Typography variant="body2">
                WITH supervision, direction, personal assistance or total care.
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: "#52A1DB",
                color: "white",
                fontWeight: "bold",
                width: "15%",
              }}
            >
              Score
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity, index) => (
            <TableRow
              key={activity.name}
              sx={{
                backgroundColor: index % 2 === 0 ? "#F5F9FC" : "white",
              }}
            >
              <TableCell>
                {activity.name.charAt(0).toUpperCase() + activity.name.slice(1)}
              </TableCell>
              <TableCell>{activity.independence}</TableCell>
              <TableCell>{activity.dependence}</TableCell>
              <TableCell>
                <Select
                  value={formData.difficultyWithActivities[activity.name].score}
                  onChange={(e) =>
                    handleScoreChange(activity.name, e.target.value)
                  }
                  disabled={!isEditing}
                  size="small"
                  sx={selectSx}
                  fullWidth
                >
                  {[...Array(6)].map((_, i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        align="left"
        sx={{ color: "#0066B2" }}
      >
        Katz Index of Independence in Activities of Daily Living
      </Typography>

      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" gutterBottom align="left">
          Scoring guidelines for individual tasks
        </Typography>
        <TableContainer component={Paper}>
          <Table size={isMobile ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: "#52A1DB", color: "white" }}>
                  <b>Score</b>
                </TableCell>
                <TableCell sx={{ backgroundColor: "#52A1DB", color: "white" }}>
                  <b>Indication</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>Full Function</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2-3</TableCell>
                <TableCell>Moderate Impairment</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4 or more</TableCell>
                <TableCell>Severe Functional Impairment</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ position: "relative", mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ color: "#0066B2" }}>
            Evidenced-based functional status instrument
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("evidence")}
            sx={{ color: "#0066B2" }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {showEvidenceComment && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #0066B2",
              borderRadius: "4px",
              p: 2,
            }}
          >
            <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={formData.difficultyWithActivities.comment}
              onChange={handleCommentChange}
              disabled={!isEditing}
              sx={commentTextFieldSx}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ position: "relative", mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ color: "#0066B2" }}>
            Activities Total Score
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("totalScore")}
            sx={{ color: "#0066B2" }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {showTotalScoreComment && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #0066B2",
              borderRadius: "4px",
              p: 2,
              mb: 2,
              backgroundColor: "white",
            }}
          >
            <Typography sx={{ minWidth: "80px" }}>Comments:</Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={formData.difficultyWithActivities.totalScoreComment}
              onChange={handleTotalScoreCommentChange}
              disabled={!isEditing}
              sx={commentTextFieldSx}
            />
          </Box>
        )}
      </Box>

      {isMobile ? renderMobileView() : renderDesktopView()}

      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          Total Points:{" "}
          <span style={{ color: "#0066B2" }}>{calculateTotalScore()}</span>
          {/* <Typography component="span" variant="body2" sx={{ ml: 2 }}>
            ({getScoreIndication(calculateTotalScore())})
          </Typography> */}
        </Typography>
      </Box>

      {/* Edit buttons at bottom */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 3, // Add margin top to separate from content
        }}
      >
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsEditing(true)}
            size={isMobile ? "large" : "medium"}
          >
            Edit
          </Button>
        ) : (
          <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              size={isMobile ? "large" : "medium"}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
              size={isMobile ? "large" : "medium"}
            >
              Cancel
            </Button>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default Activities;
