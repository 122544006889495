import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Sdoh from "./Sdoh";
import Demograph from "./Demograph";
import Activities from "./Activities";
import Medicalhistory from "./Medicalhistory";
import Preventive from "./Preventive";
import Phq2 from "./Phq2";
import Homesafety from "./Homesafety";
import Familyhis from "./Familyhistory";
import PatientSummary from "./PatientSummary";
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  AppBar,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  Person as PersonIcon,
  Home as HomeIcon,
  Assessment,
  MedicalServices,
  People,
  LocalHospital,
  Psychology,
  Security,
  ArrowBackIos as ArrowBackIcon,
  ArrowForwardIos as ArrowForwardIcon,
} from "@mui/icons-material";

// TabPanel component remains the same
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const API_ENDPOINTS = {
  DEMOGRAPHICS: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-demographics`,
  SELF_ASSESSMENT: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-selfAssessment`,
  MEDICAL_HISTORY: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-medical-history`,
  GET_ACTIVITIES: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-activities`,
  FAMILY_HISTORY: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-family-history`,
  HOME_SAFETY: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-home-Safety`,
  PHQ2_SCORE: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-phq-score`,
  PREVENTIVE_CARE: `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-preventive-care`,
};

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mainTabValue, setMainTabValue] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [slideDirection, setSlideDirection] = useState("none");
  const [dashboardData, setDashboardData] = useState({
    demographics: null,
    selfAssessment: null,
    medicalHistory: null,
    activities: null,
    familyHistory: null,
    homeSafety: null,
    phq2Score: null,
    preventiveCare: null,
  });
  const [errors, setErrors] = useState({
    demographics: null,
    selfAssessment: null,
    medicalHistory: null,
    activities: null,
    familyHistory: null,
    homeSafety: null,
    phq2Score: null,
    preventiveCare: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  const user = useSelector((state) => state.auth?.user ?? {});

  const getAuthToken = () => {
    const token = localStorage.getItem("token") || "";
    if (!token) {
      console.error("No Authorization token found in localStorage.");
    }
    return token;
  };

  const getRequestConfig = (year) => {
    const numYear = user.year;
    //handle any exceptions that occur due to year value
    const yearToUse = (function () {
      if (!numYear) return new Date().getFullYear();

      const validYears = [2023, 2024];
      const yearStr = String(numYear).trim();
      const yearNum = Number(yearStr);

      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${getAuthToken()}`,
      },
      params: {
        memberId: user.memberId,
        year: yearToUse,
      },
    };
  };

  useEffect(() => {
    const fetchData = async (endpoint, dataKey) => {
      try {
        const response = await axios.get(endpoint, getRequestConfig());
        setDashboardData((prev) => ({
          ...prev,
          [dataKey]: response.data,
        }));
        setErrors((prev) => ({
          ...prev,
          [dataKey]: null,
        }));
      } catch (err) {
        console.error(
          `Error fetching ${dataKey}:`,
          err.response || err.message,
        );
        setErrors((prev) => ({
          ...prev,
          [dataKey]: err.message || "Failed to fetch data",
        }));
      }
    };

    const fetchAllData = async () => {
      const endpoints = [
        { url: API_ENDPOINTS.DEMOGRAPHICS, key: "demographics" },
        { url: API_ENDPOINTS.SELF_ASSESSMENT, key: "selfAssessment" },
        { url: API_ENDPOINTS.MEDICAL_HISTORY, key: "medicalHistory" },
        { url: API_ENDPOINTS.GET_ACTIVITIES, key: "activities" },
        { url: API_ENDPOINTS.FAMILY_HISTORY, key: "familyHistory" },
        { url: API_ENDPOINTS.HOME_SAFETY, key: "homeSafety" },
        { url: API_ENDPOINTS.PHQ2_SCORE, key: "phq2Score" },
        { url: API_ENDPOINTS.PREVENTIVE_CARE, key: "preventiveCare" },
      ];

      await Promise.all(endpoints.map(({ url, key }) => fetchData(url, key)));

      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  const subTabs = [
    {
      label: "Demographics",
      icon: <PersonIcon />,
      component: errors.demographics ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.demographics}
        </Alert>
      ) : (
        <Demograph data={dashboardData.demographics} />
      ),
    },
    {
      label: "SDOH",
      icon: <HomeIcon />,
      component: errors.selfAssessment ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.selfAssessment}
        </Alert>
      ) : (
        <Sdoh data={dashboardData.selfAssessment} />
      ),
    },
    {
      label: "Activities of Daily Living",
      icon: <Assessment />,
      component: errors.activities ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.activities}
        </Alert>
      ) : (
        <Activities data={dashboardData.activities} />
      ),
    },
    {
      label: "Medical History",
      icon: <MedicalServices />,
      component: errors.medicalHistory ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.medicalHistory}
        </Alert>
      ) : (
        <Medicalhistory data={dashboardData.medicalHistory} />
      ),
    },
    {
      label: "Family History",
      icon: <People />,
      component: errors.familyHistory ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.familyHistory}
        </Alert>
      ) : (
        <Familyhis data={dashboardData.familyHistory} />
      ),
    },
    {
      label: "Preventive Care",
      icon: <LocalHospital />,
      component: errors.preventiveCare ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.preventiveCare}
        </Alert>
      ) : (
        <Preventive data={dashboardData.preventiveCare} />
      ),
    },
    {
      label: "PHQ2/PHQ9",
      icon: <Psychology />,
      component: errors.phq2Score ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.phq2Score}
        </Alert>
      ) : (
        <Phq2 data={dashboardData.phq2Score} />
      ),
    },
    {
      label: "Home Safety & Personal Goals",
      icon: <Security />,
      component: errors.homeSafety ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {errors.homeSafety}
        </Alert>
      ) : (
        <Homesafety data={dashboardData.homeSafety} />
      ),
    },
  ];

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setSlideDirection("left");
    const nextIndex = (activeSubTab + 1) % subTabs.length;
    setTimeout(() => {
      setActiveSubTab(nextIndex);
      setSlideDirection("none");
      setIsAnimating(false);
    }, 300);
  };

  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setSlideDirection("right");
    const prevIndex =
      activeSubTab === 0 ? subTabs.length - 1 : activeSubTab - 1;
    setTimeout(() => {
      setActiveSubTab(prevIndex);
      setSlideDirection("none");
      setIsAnimating(false);
    }, 300);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography ml={2} variant="h6" color="textSecondary">
          Loading dashboard data...
        </Typography>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Container maxWidth="sm" sx={{ mt: 4 }}>
  //       <Alert severity="error" variant="filled">
  //         {error.message}
  //       </Alert>
  //     </Container>
  //   );
  // }

  const getSlideAnimation = () => {
    switch (slideDirection) {
      case "left":
        return "slideLeft";
      case "right":
        return "slideRight";
      default:
        return "";
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="default"
        style={{ backgroundColor: "#fbfdff" }}
      >
        <Container maxWidth="xl">
          <Tabs
            value={mainTabValue}
            onChange={(_, newValue) => setMainTabValue(newValue)}
            centered={!isMobile}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons={isMobile ? "auto" : false}
            sx={{
              backgroundColor: theme.palette.background.paper,
              "& .MuiTab-root": {
                minWidth: isMobile ? "auto" : 120,
                px: 3,
              },
            }}
          >
            <Tab label="Self-Reporting" />
            <Tab label="Patient Summary" />
          </Tabs>
        </Container>
      </AppBar>

      <TabPanel value={mainTabValue} index={0}>
        <Container maxWidth="xl">
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" component="div">
                {subTabs[activeSubTab].label}
              </Typography>
              <Box>
                <IconButton
                  onClick={handlePrev}
                  sx={{
                    backgroundColor: "background.paper",
                    "&:hover": { backgroundColor: "action.hover" },
                    mr: 1,
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  sx={{
                    backgroundColor: "background.paper",
                    "&:hover": { backgroundColor: "action.hover" },
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Box>

            <Paper
              elevation={3}
              sx={{
                p: 3,
                position: "relative",
                overflow: "hidden",
                backgroundColor: "#fbfdff",
              }}
            >
              <Box
                className={`carousel-slide ${getSlideAnimation()}`}
                sx={{
                  transform: slideDirection === "none" ? "none" : undefined,
                  "&.slideLeft": {
                    animation: "slideLeft 0.3s ease-out",
                  },
                  "&.slideRight": {
                    animation: "slideRight 0.3s ease-out",
                  },
                  "@keyframes slideLeft": {
                    from: { transform: "translateX(100%)" },
                    to: { transform: "translateX(0)" },
                  },
                  "@keyframes slideRight": {
                    from: { transform: "translateX(-100%)" },
                    to: { transform: "translateX(0)" },
                  },
                }}
              >
                {subTabs[activeSubTab].component}
              </Box>
            </Paper>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                gap: 1,
              }}
            >
              {subTabs.map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    backgroundColor:
                      index === activeSubTab ? "aliceblue" : "grey.300",
                    transition: "background-color 0.3s",
                  }}
                />
              ))}
            </Box>
          </Box>
        </Container>
      </TabPanel>

      <TabPanel value={mainTabValue} index={1}>
        <Container maxWidth="xl">
          <Paper elevation={3} sx={{ p: 3 }}>
            <PatientSummary user={user} />
          </Paper>
        </Container>
      </TabPanel>
    </Box>
  );
};

export default Dashboard;
