import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

export const FoodSection = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [showSubAnswerCommentSection, setShowSubAnswerCommentSection] =
    useState(false);

  const buttonSx = {
    border: `1px solid ${isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)"}`,
    borderRadius: "4px",
    padding: "12px 24px",
    cursor: isEditing ? "pointer" : "default",
    backgroundColor: (selected) =>
      selected ? (isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)") : "#e3edf4",
    color: (selected) => (selected ? "white" : "#000000"),
    "&:hover": {
      backgroundColor: (selected) =>
        isEditing
          ? selected
            ? "#3d8bc2"
            : "rgba(82, 161, 219, 0.04)"
          : undefined,
    },
  };

  // Add reset function for switching tabs
  const resetFoodValues = () => {
    setFormData((prev) => ({
      ...prev,
      food: {
        ...prev.food,
        foodWouldRunOut: {
          ...prev.food.foodWouldRunOut,
          oftenTrue: "",
          sometimesTrue: "",
          neverTrue: "",
          oftenTrueSubAnswers: {
            foodWeBought: "",
            dontHaveMoney: "",
            comment: "",
          },
          sometimesTrueSubAnswers: {
            foodWeBought: "",
            dontHaveMoney: "",
            comment: "",
          },
          comment: "",
        },
      },
    }));
  };

  // Update handleFoodWorryChange to reset values when switching options
  const handleFoodWorryChange = (key) => {
    if (!isEditing) return;

    const isSelected = formData.food.foodWouldRunOut[key] === "1";

    const updatedFood = {
      oftenTrue: "",
      sometimesTrue: "",
      neverTrue: "",
    };

    if (!isSelected) {
      updatedFood[key] = "1";
    }

    // Reset sub-answers when switching main options
    setFormData((prev) => ({
      ...prev,
      food: {
        ...prev.food,
        foodWouldRunOut: {
          ...prev.food.foodWouldRunOut,
          ...updatedFood,
          oftenTrueSubAnswers: {
            foodWeBought: "",
            dontHaveMoney: "",
          },
          sometimesTrueSubAnswers: {
            foodWeBought: "",
            dontHaveMoney: "",
          },
        },
      },
    }));
  };

  const handleSubAnswerChange = (parentKey, key) => {
    if (!isEditing) return;

    const subAnswersKey = `${parentKey}SubAnswers`;
    const isSelected =
      formData.food.foodWouldRunOut[subAnswersKey][key] === "1";

    setFormData((prev) => ({
      ...prev,
      food: {
        ...prev.food,
        foodWouldRunOut: {
          ...prev.food.foodWouldRunOut,
          [subAnswersKey]: {
            ...prev.food.foodWouldRunOut[subAnswersKey],
            [key]: isSelected ? "" : "1",
          },
        },
      },
    }));
  };

  // Add handler for sub-answer comments
  const handleSubAnswerCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      food: {
        ...prev.food,
        foodWouldRunOut: {
          ...prev.food.foodWouldRunOut,
          sometimesTrueSubAnswers: {
            ...prev.food.foodWouldRunOut.sometimesTrueSubAnswers,
            comment: value,
          },
          oftenTrueSubAnswers: {
            ...prev.food.foodWouldRunOut.oftenTrueSubAnswers,
            comment: value,
          },
        },
      },
    }));
  };

  // Simplified comment handling for single comment
  const handleCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      food: {
        ...prev.food,
        foodWouldRunOut: {
          ...prev.food.foodWouldRunOut,
          comment: value,
        },
      },
    }));
  };

  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  // Unified comment section style
  const commentBoxStyle = {
    mt: 2,
    mb: 2,
    border: "1px solid #0066B2",
    borderRadius: "4px",
    p: 1.5,
    backgroundColor: "white",
    width: "100%",
  };

  const commentTextFieldStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      padding: "0px !important",
      "& fieldset": {
        borderColor: "#0066B2",
      },
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
      color: "black",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "black !important",
      color: "black !important",
    },
  };

  // Simplified comment section renderer
  const renderCommentSection = () => {
    const hasComment = formData.food.foodWouldRunOut.comment;

    return (
      <Box
        sx={{
          ...commentBoxStyle,
          display: showCommentSection
            ? // ||(!showCommentSection && hasComment && !isEditing)
              "block"
            : "none",
        }}
      >
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={formData.food.foodWouldRunOut.comment || ""}
          onChange={(e) => handleCommentChange(e.target.value)}
          disabled={!isEditing}
          sx={commentTextFieldStyle}
        />
      </Box>
    );
  };

  // Render sub-answer comment section
  const renderSubAnswerCommentSection = () => {
    const currentKey =
      formData.food.foodWouldRunOut.oftenTrue === "1"
        ? "oftenTrueSubAnswers"
        : "sometimesTrueSubAnswers";
    const hasComment = formData.food.foodWouldRunOut[currentKey]?.comment;

    return (
      <Box
        sx={{
          ...commentBoxStyle,
          display: showSubAnswerCommentSection
            ? // ||(!showSubAnswerCommentSection && hasComment && !isEditing)
              "block"
            : "none",
        }}
      >
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={formData.food.foodWouldRunOut[currentKey]?.comment || ""}
          onChange={(e) => handleSubAnswerCommentChange(e.target.value)}
          disabled={!isEditing}
          sx={commentTextFieldStyle}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        mb: 4,
        px: { xs: 2, sm: 0 },
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
          mb: 3,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            fontSize: { xs: "14px", sm: "16px" },
            textAlign: "left",
            flex: 1,
          }}
        >
          Within the past 12 months we worried whether our food would run out
          before we got money to buy more. Was that _____ for your household?
        </Typography>
        <IconButton
          onClick={() => setShowCommentSection((prev) => !prev)}
          disabled={!isEditing}
          sx={{
            color: formData.food.foodWouldRunOut.comment
              ? "#0066B2"
              : "text.secondary",
            ml: 1,
            flexShrink: 0,
          }}
        >
          <CommentIcon
            sx={{
              color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
              cursor: "pointer",
              fontSize: "20px",
              ml: 1,
            }}
          />
        </IconButton>
      </Box>

      {renderCommentSection()}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          mb: 3,
          alignItems: "flex-start",
        }}
      >
        {[
          { key: "oftenTrue", label: "Often True" },
          { key: "sometimesTrue", label: "Sometimes True" },
          { key: "neverTrue", label: "Never True" },
        ].map((option) => (
          <Box key={option.key} sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                onClick={() => isEditing && handleFoodWorryChange(option.key)}
                sx={{
                  ...buttonSx,
                  backgroundColor:
                    formData.food.foodWouldRunOut[option.key] === "1"
                      ? "#52A1DB"
                      : "#e3edf4",
                  color:
                    formData.food.foodWouldRunOut[option.key] === "1"
                      ? "white"
                      : "#000000",
                  width: "fit-content",
                  minWidth: "180px",
                  textAlign: "left",
                }}
              >
                {option.label}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      {(formData.food.foodWouldRunOut.oftenTrue === "1" ||
        formData.food.foodWouldRunOut.sometimesTrue === "1") && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            pl: { xs: 0, sm: 4 },
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: isEditing ? "#0076CE" : "rgba(0, 0, 0, 0.38)",
                display: "flex",
                alignItems: "center",
                "&::before": {
                  content: '"→"',
                  marginRight: "8px",
                },
              }}
            >
              Select all that apply:
            </Typography>
            <IconButton
              onClick={() => setShowSubAnswerCommentSection((prev) => !prev)}
              disabled={!isEditing}
              sx={{
                color: formData.food.foodWouldRunOut.subAnswerComment
                  ? "#0066B2"
                  : "text.secondary",
                ml: 1,
              }}
            >
              <CommentIcon
                sx={{
                  color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                  cursor: "pointer",
                  fontSize: "20px",
                  ml: 1,
                }}
              />
            </IconButton>
          </Box>

          {renderSubAnswerCommentSection()}

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: 2,
              width: "100%",
              mt: 2,
            }}
          >
            {[
              {
                key: "foodWeBought",
                label: "The food we bought just didn't last",
              },
              {
                key: "dontHaveMoney",
                label: "We didn't have money to get more",
              },
            ].map((option) => (
              <Box
                key={option.key}
                onClick={() =>
                  isEditing &&
                  handleSubAnswerChange(
                    formData.food.foodWouldRunOut.oftenTrue === "1"
                      ? "oftenTrue"
                      : "sometimesTrue",
                    option.key,
                  )
                }
                sx={{
                  ...buttonSx,
                  backgroundColor:
                    formData.food.foodWouldRunOut[
                      `${
                        formData.food.foodWouldRunOut.oftenTrue === "1"
                          ? "oftenTrue"
                          : "sometimesTrue"
                      }SubAnswers`
                    ]?.[option.key] === "1"
                      ? "#52A1DB"
                      : "#e3edf4",
                  color:
                    formData.food.foodWouldRunOut[
                      `${
                        formData.food.foodWouldRunOut.oftenTrue === "1"
                          ? "oftenTrue"
                          : "sometimesTrue"
                      }SubAnswers`
                    ]?.[option.key] === "1"
                      ? "white"
                      : "#000000",
                  width: { xs: "100%", sm: "auto" },
                  minWidth: { sm: "180px" },
                  textAlign: "center",
                }}
              >
                {option.label}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
