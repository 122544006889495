import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/actions/auth_action";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  Assignment as DashboardIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Settings as SettingsIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Summarize as Note,
} from "@mui/icons-material";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Box,
  Tooltip,
  useTheme,
  Button,
  alpha,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Dashboard from "./Dashboard.js";
import MedicationIcon from "@mui/icons-material/MenuOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function MainDash() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [contactType, setContactType] = useState("");
  const [formsModalOpen, setFormsModalOpen] = useState(false);

  const handleLogout = () => {
    try {
      dispatch(authActions.logout());
      navigate("/MemberPortal/Login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleContactClick = (type) => {
    setContactType(type);
    setContactModalOpen(true);
  };

  const handleFormsClick = () => {
    setFormsModalOpen(true);
  };

  // Add this ContactModal component inside MainDash
  const ContactModal = ({ open, onClose, type }) => {
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    };

    const contactInfo = {
      name: "John Doe",
      email: "support@healthcare.com",
      phone: "(555) 123-4567",
      hours: "Mon-Fri: 9AM - 5PM EST",
    };

    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="contact-modal-title"
      >
        <Box sx={modalStyle}>
          <Typography
            id="contact-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Contact Care Team
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PersonIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.name}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <EmailIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.email}</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <PhoneIcon sx={{ mr: 2 }} />
            <Typography>{contactInfo.phone}</Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {contactInfo.hours}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            onClick={onClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  // Add this FormsModal component inside MainDash
  const FormsModal = ({ open, onClose }) => {
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      maxHeight: "80vh",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      overflow: "auto",
    };

    const formsByYear = {
      2024: [
        { name: "Annual Health Assessment", date: "Jan 15, 2024" },
        { name: "Medical Release Form", date: "Feb 1, 2024" },
      ],
      2023: [
        { name: "Insurance Update Form", date: "Dec 10, 2023" },
        { name: "Medication List", date: "Nov 5, 2023" },
        { name: "Emergency Contact Update", date: "Oct 20, 2023" },
      ],
      2022: [
        { name: "Annual Health Assessment", date: "Dec 15, 2022" },
        { name: "Consent Form", date: "Aug 30, 2022" },
      ],
    };

    return (
      <Modal open={open} onClose={onClose} aria-labelledby="forms-modal-title">
        <Box sx={modalStyle}>
          <Typography
            id="forms-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            Forms History - <b>DUMMY DATA</b>
          </Typography>

          {Object.entries(formsByYear).map(([year, forms]) => (
            <Accordion key={year} sx={{ mb: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${year}-content`}
                id={`${year}-header`}
              >
                <Typography fontWeight="bold">{year}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense>
                  {forms.map((form, index) => (
                    <ListItem
                      key={index}
                      button
                      sx={{
                        "&:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.08),
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Note color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={form.name} secondary={form.date} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}

          <Button
            fullWidth
            variant="contained"
            onClick={onClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    );
  };

  // Improved sidebar content with conditional rendering for collapsed state
  const sidebarContent = (
    <Box
      sx={{
        width: isSidebarOpen ? 250 : 65,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          minHeight: 64,
          backgroundColor: "#1577bb",
        }}
      >
        {isSidebarOpen ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              color: "white",
            }}
          >
            <span style={{ marginTop: "8px" }}>MEMBER PORTAL</span>
          </Box>
        ) : null}
        <IconButton
          onClick={toggleSidebar}
          sx={{
            ml: isSidebarOpen ? 1 : 0,
            transition: theme.transitions.create(["transform", "margin"], {
              duration: theme.transitions.duration.shorter,
              color: "black",
            }),
          }}
        >
          {isSidebarOpen ? (
            <MedicationIcon fontSize="medium" sx={{ color: "white" }} />
          ) : (
            <MedicationIcon fontSize="medium" sx={{ color: "white" }} />
          )}
        </IconButton>
      </Box>

      <Divider />

      <List sx={{ flexGrow: 1, backgroundColor: "#ebf7ff", color: "black" }}>
        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              My Health
            </Typography>
          </ListItem>
        )}

        <Tooltip title={!isSidebarOpen ? "My Info" : ""} placement="right">
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Profile")}
            // component="a"
            // href="/MemberPortal/Profile"
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PersonIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="My Info" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Dashboard" : ""} placement="right">
          <ListItem
            button
            onClick={() => navigate("/MemberPortal/Dashboard")}
            // component="a"
            // href="/MemberPortal/Dashboard"
            selected
            sx={{
              minHeight: 48,
              px: 2.5,
              backgroundColor: alpha(theme.palette.primary.main, 0.12),
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <DashboardIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Dashboard" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Forms" : ""} placement="right">
          <ListItem
            button
            onClick={handleFormsClick}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <Note />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Forms" />}
          </ListItem>
        </Tooltip>

        <Divider sx={{ my: 2, color: "black" }} />

        {isSidebarOpen && (
          <ListItem sx={{ pb: 2 }}>
            <Typography variant="overline" color="black">
              Contact Care Team
            </Typography>
          </ListItem>
        )}

        <Tooltip title={!isSidebarOpen ? "Email" : ""} placement="right">
          <ListItem
            button
            onClick={() => handleContactClick("email")}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <EmailIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Email" />}
          </ListItem>
        </Tooltip>

        <Tooltip title={!isSidebarOpen ? "Contact" : ""} placement="right">
          <ListItem
            button
            onClick={() => handleContactClick("contact")}
            sx={{
              minHeight: 48,
              px: 2.5,
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: isSidebarOpen ? 56 : "auto" }}>
              <PhoneIcon />
            </ListItemIcon>
            {isSidebarOpen && <ListItemText primary="Contact" />}
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[4],
          },
        }}
      >
        {sidebarContent}
      </Drawer>

      {/* Desktop Permanent Drawer with animation */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: isSidebarOpen ? 250 : 65,
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: "border-box",
            backgroundColor: theme.palette.background.paper,
            borderRight: `1px solid ${theme.palette.divider}`,
            overflowX: "hidden",
          },
        }}
        open={isSidebarOpen}
      >
        {sidebarContent}
      </Drawer>

      {/* Main Content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AppBar
          position="static"
          color="default"
          elevation={1}
          sx={{
            backgroundColor: "#ebf7ff",
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleSidebar}
              sx={{
                display: { md: "none" },
                mr: 2,
                transform: isSidebarOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: theme.transitions.create("transform", {
                  duration: theme.transitions.duration.shorter,
                }),
              }}
            >
              {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <img
              src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
              alt="Focus Cares"
              style={{ height: "45px" }}
            />

            <Box sx={{ flexGrow: 1 }} />

            <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleMenu}
                  color="inherit"
                  size="large"
                  aria-label="account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    },
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  elevation: 3,
                  sx: {
                    minWidth: 200,
                    mt: 1,
                  },
                }}
              >
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    color: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.error.main, 0.08),
                    },
                  }}
                >
                  <LogoutIcon sx={{ mr: 2 }} /> Log out
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: "auto",
            backgroundColor: alpha(theme.palette.primary.main, 0.02),
          }}
        >
          <Dashboard />
        </Box>
      </Box>
      <ContactModal
        open={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        type={contactType}
      />
      <FormsModal
        open={formsModalOpen}
        onClose={() => setFormsModalOpen(false)}
      />
    </Box>
  );
}

export default MainDash;
