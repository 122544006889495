import React, { useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

// Add styles for consistent appearance
const textFieldSx = {
  "& .MuiInputBase-input": {
    color: "#000000 !important", // Always keep text black
  },
  "& .MuiInputLabel-root": {
    color: "#000000", // Keep label text black
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(0, 0, 0, 0.42)", // Standard underline color
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "solid", // Keep the underline visible when disabled
  },
};

const commentBoxStyle = {
  mt: 2,
  border: "1px solid #52A1DB", // Updated to match new blue
  borderRadius: "4px",
  p: 2,
};

const radioStyle = {
  "&.Mui-checked": {
    color: "#52A1DB", // Always use blue for checked state
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#52A1DB", // Keep blue even when disabled (non-edit mode)
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
  },
};

const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    color: "#000000 !important", // Keep label text black even when disabled
  },
  "&.Mui-disabled": {
    color: "#000000 !important", // Keep text black in disabled state
  },
};

export const SocialSection = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState(() => {
    const initialState = {};
    [
      "rateYourHealth",
      "relyOn",
      "socialActivities",
      "goOutToMeet",
      "careGiver",
    ].forEach((field) => {
      initialState[field] = !!formData.social?.[field]?.comment;
    });
    return initialState;
  });

  const handleHealthRatingChange = (key) => {
    if (!isEditing) return;

    const isSelected = formData.social.rateYourHealth[key] === "1";

    const updatedHealth = {
      excellent: "",
      good: "",
      fair: "",
      poor: "",
      comment: formData.social.rateYourHealth.comment,
    };

    if (!isSelected) {
      updatedHealth[key] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        rateYourHealth: updatedHealth,
      },
    }));
  };

  const handleRelyOnChange = (value) => {
    if (!isEditing) return;

    const isSelected = formData.social.relyOn.isRelyOn === value;

    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        relyOn: {
          ...prev.social.relyOn,
          isRelyOn: isSelected ? "" : value,
        },
      },
    }));
  };

  const handleSocialActivitiesChange = (key) => {
    if (!isEditing) return;

    const isSelected = formData.social.socialActivities[key] === "1";

    const updatedActivities = {
      often: "",
      sometimes: "",
      almostNever: "",
      never: "",
      comment: formData.social.socialActivities.comment,
    };

    if (!isSelected) {
      updatedActivities[key] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        socialActivities: updatedActivities,
      },
    }));
  };

  const handleMeetFrequencyChange = (key) => {
    if (!isEditing) return;

    const isSelected = formData.social.goOutToMeet[key] === "1";

    const updatedMeet = {
      often: "",
      sometimes: "",
      almostNever: "",
      never: "",
      comment: formData.social.goOutToMeet.comment,
    };

    if (!isSelected) {
      updatedMeet[key] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        goOutToMeet: updatedMeet,
      },
    }));
  };

  const handleCareGiverChange = (value) => {
    if (!isEditing) return;

    const isSelected = formData.social.careGiver.isCareGiver === value;

    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        careGiver: {
          ...prev.social.careGiver,
          isCareGiver: isSelected ? "" : value,
          isCareGiverSubAnswer:
            value === "2"
              ? { describe: "" }
              : prev.social.careGiver.isCareGiverSubAnswer,
        },
      },
    }));
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      social: {
        ...prev.social,
        [field]: {
          ...prev.social[field],
          comment: value,
        },
      },
    }));
  };

  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  // Modified comment click handler
  const handleCommentClick = (field) => {
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Modified comment section renderer - removed isVisible check
  const renderCommentSection = (field) => {
    if (!showCommentSection[field] && !formData.social?.[field]?.comment)
      return null;

    return (
      <Box sx={commentBoxStyle}>
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={formData.social?.[field]?.comment || ""}
          onChange={(e) => handleCommentChange(field, e.target.value)}
          disabled={!isEditing}
          sx={{
            ...textFieldSx,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#52A1DB",
              },
              "& textarea": {
                color: "#000000 !important",
              },
            },
          }}
        />
      </Box>
    );
  };

  return (
    <Box>
      {/* Health Rating */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            How would you rate your health compared to other persons your age?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("rateYourHealth")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.social.rateYourHealth).find(
                (key) =>
                  formData.social.rateYourHealth[key] === "1" &&
                  key !== "comment",
              ) || ""
            }
          >
            {[
              { key: "excellent", label: "Excellent" },
              { key: "good", label: "Good" },
              { key: "fair", label: "Fair" },
              { key: "poor", label: "Poor" },
            ].map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, handleHealthRatingChange)
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("rateYourHealth")}
      </Box>

      {/* Rely On */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Do you have someone you can rely on to help if you are sick or have
            problems you need to discuss?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("relyOn")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup row value={formData.social.relyOn.isRelyOn}>
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) => handleRadioClick(e, handleRelyOnChange)}
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("relyOn")}
      </Box>

      {/* Social Activities */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            During past 3 months, has your physical and or emotional health
            limited your social activities with family, friends, neighbors or
            groups?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("socialActivities")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.social.socialActivities).find(
                (key) =>
                  formData.social.socialActivities[key] === "1" &&
                  key !== "comment",
              ) || ""
            }
          >
            {[
              { key: "often", label: "Often" },
              { key: "sometimes", label: "Sometimes" },
              { key: "almostNever", label: "Almost Never" },
              { key: "never", label: "Never" },
            ].map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, handleSocialActivitiesChange)
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("socialActivities")}
      </Box>

      {/* Meet Frequency */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            How often do you go out to meet with family or friends?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("goOutToMeet")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.social.goOutToMeet).find(
                (key) =>
                  formData.social.goOutToMeet[key] === "1" && key !== "comment",
              ) || ""
            }
          >
            {[
              { key: "often", label: "Often" },
              { key: "sometimes", label: "Sometimes" },
              { key: "never", label: "Never" },
            ].map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={
                  <Radio
                    onClick={(e) =>
                      handleRadioClick(e, handleMeetFrequencyChange)
                    }
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {renderCommentSection("goOutToMeet")}
      </Box>

      {/* Caregiver */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ color: "#000000", flex: 1, textAlign: "left" }}>
            Are you currently a caregiver for someone?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("careGiver")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup value={formData.social.careGiver.isCareGiver}>
            {[
              { value: "1", label: "Yes" },
              { value: "2", label: "No" },
            ].map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    onClick={(e) => handleRadioClick(e, handleCareGiverChange)}
                    sx={radioStyle}
                  />
                }
                label={option.label}
                disabled={!isEditing}
                sx={formControlLabelStyle}
              />
            ))}
          </RadioGroup>

          {formData.social.careGiver.isCareGiver === "1" && (
            <TextField
              fullWidth
              multiline
              rows={1}
              label="Describe"
              variant="standard"
              value={
                formData.social.careGiver.isCareGiverSubAnswer?.describe || ""
              }
              onChange={(e) => {
                if (!isEditing) return;
                setFormData((prev) => ({
                  ...prev,
                  social: {
                    ...prev.social,
                    careGiver: {
                      ...prev.social.careGiver,
                      isCareGiverSubAnswer: {
                        describe: e.target.value,
                      },
                    },
                  },
                }));
              }}
              disabled={!isEditing}
              sx={{ ...textFieldSx, mt: 2 }}
            />
          )}
        </FormControl>
        {renderCommentSection("careGiver")}
      </Box>
    </Box>
  );
};
