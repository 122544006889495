import React, { useState } from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormControlLabel,
  Tooltip,
  TextField,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

const EducationGuidelines = ({ formData, isEditing, setFormData }) => {
  const [showCommentSection, setShowCommentSection] = useState({});

  // Unified comment section style
  const commentBoxStyle = {
    mt: 2,
    mb: 2,
    border: "1px solid #0066B2",
    borderRadius: "4px",
    p: 1.5,
    backgroundColor: "white",
    width: "100%",
  };

  const commentTextFieldStyle = {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      "& fieldset": {
        borderColor: "#0066B2",
      },
      "& textarea": {
        padding: "8px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",
    },
  };

  const handleSelectionChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      educationGuidlines: {
        ...prev.educationGuidlines,
        pastYear: {
          ...prev.educationGuidlines.pastYear,
          isNone: value === "None" ? "1" : "",
          oneOrMore: value === "1orMore" ? "1" : "",
          comment: "",
          oneOrMoreSubAnswers: {
            educationOrResources: "",
            comment: "",
          },
        },
      },
    }));
  };

  const currentValue =
    formData.educationGuidlines?.pastYear?.isNone === "1"
      ? "None"
      : formData.educationGuidlines?.pastYear?.oneOrMore === "1"
      ? "1orMore"
      : null;

  // Add comment click handler
  const handleCommentClick = (field) => {
    // if (!isEditing) return;
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Update renderCommentSection
  const renderCommentSection = (field) => {
    const hasComment =
      field === "oneOrMore"
        ? formData.educationGuidlines?.pastYear?.oneOrMoreSubAnswers?.comment
        : formData.educationGuidlines?.pastYear?.comment;

    return (
      <Box
        sx={{
          ...commentBoxStyle,
          display: showCommentSection[field]
            ? // ||(!showCommentSection[field] && hasComment && !isEditing)
              "block"
            : "none",
        }}
      >
        <Typography sx={{ mb: 0.5, fontSize: "14px" }}>Comments:</Typography>
        <TextField
          fullWidth
          multiline
          rows={1}
          value={
            field === "oneOrMore"
              ? formData.educationGuidlines?.pastYear?.oneOrMoreSubAnswers
                  ?.comment || ""
              : formData.educationGuidlines?.pastYear?.comment || ""
          }
          onChange={(e) => {
            if (!isEditing) return;
            if (field === "oneOrMore") {
              setFormData((prev) => ({
                ...prev,
                educationGuidlines: {
                  ...prev.educationGuidlines,
                  pastYear: {
                    ...prev.educationGuidlines.pastYear,
                    oneOrMoreSubAnswers: {
                      ...prev.educationGuidlines.pastYear.oneOrMoreSubAnswers,
                      comment: e.target.value,
                    },
                  },
                },
              }));
            } else {
              setFormData((prev) => ({
                ...prev,
                educationGuidlines: {
                  ...prev.educationGuidlines,
                  pastYear: {
                    ...prev.educationGuidlines.pastYear,
                    comment: e.target.value,
                  },
                },
              }));
            }
          }}
          disabled={!isEditing}
          sx={commentTextFieldStyle}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1, mb: 2 }}>
        <Typography
          variant="h6"
          component="h2"
          color="primary"
          sx={{
            fontWeight: "normal",
          }}
        >
          Education Guidelines
        </Typography>
        <Tooltip
          title={
            <Typography sx={{ p: 1 }}>
              Maximum Drinking Limits:
              <br />
              • For healthy men up to age 65:
              <br />
              - No more than 4 drinks in a day AND
              <br />
              - No more than 14 drinks in a week
              <br />
              <br />
              • For healthy women (and healthy men over age 65):
              <br />
              - No more than 3 drinks in a day AND
              <br />
              - No more than 7 drinks in a week
              <br />
              <br />
              Lower limits or abstinence as medically indicated if you:
              <br />
              • Take medications that interact with alcohol
              <br />
              • Have a health condition exacerbated by alcohol
              <br />
              • Are pregnant (advise abstinence)
              <br />
              <br />
              Express openness to talking about alcohol use and any concerns it
              may raise.
              <br />
              Rescreen annually.
            </Typography>
          }
        >
          <Typography
            component="span"
            sx={{
              color: "#1976d2",
              cursor: "help",
              fontSize: "1.2rem",
              mt: "2px",
            }}
          >
            ⓘ
          </Typography>
        </Tooltip>
      </Box>

      <Box sx={{ mb: 3, ml: 0 }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
            color: "text.primary",
            ml: 0,
          }}
        >
          How many times in the past year have you used a recreational drug or
          used a prescription medication for nonmedical reasons?
          <Tooltip
            title={
              <Typography sx={{ p: 1 }}>
                Recreational Drugs: Methamphetamines (speed, crystal), Cannabis
                (marijuana, pot), inhalants (paint thinner, aerosol, glue),
                tranquilizers (Valium), barbiturates, cocaine, ecstasy,
                hallucinogens (LSD, Mushrooms), or narcotics (heroin).
              </Typography>
            }
          >
            <Typography
              component="span"
              sx={{
                color: "#1976d2",
                cursor: "help",
                fontSize: "1.2rem",
                mt: "2px",
              }}
            >
              ⓘ
            </Typography>
          </Tooltip>
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
          <ToggleButtonGroup
            exclusive
            value={currentValue}
            onChange={(e, value) => handleSelectionChange(value)}
            disabled={!isEditing}
            sx={{
              width: "100%",
              maxWidth: "400px",
              ml: 0,
              "& .MuiToggleButton-root": {
                flex: 1,
                p: 2,
                justifyContent: "flex-start",
                textAlign: "left",
                paddingLeft: "16px",
                "&.Mui-selected": {
                  backgroundColor: "#1976d2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                },
              },
            }}
          >
            <ToggleButton
              value="None"
              sx={{
                backgroundColor:
                  currentValue === "None" ? "#1976d2" : "background.paper",
                color: currentValue === "None" ? "white" : "text.primary",
                textTransform: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              None
            </ToggleButton>
            <ToggleButton
              value="1orMore"
              sx={{
                backgroundColor:
                  currentValue === "1orMore" ? "#1976d2" : "background.paper",
                color: currentValue === "1orMore" ? "white" : "text.primary",
                textTransform: "none",
                border: "1px solid #e0e0e0",
              }}
            >
              1 or more
            </ToggleButton>
          </ToggleButtonGroup>
          <IconButton
            onClick={() => handleCommentClick("main")}
            disabled={!isEditing}
            sx={{
              // color: formData.educationGuidlines?.pastYear?.comment
              //   ? "#0066B2"
              //   : "text.secondary",
              color: isEditing ? "#0066B2" : "text.secondary",
            }}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {renderCommentSection("main")}

        {currentValue === "1orMore" && (
          <Box sx={{ mt: 2, ml: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
              <Box sx={{ flex: 1 }}>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      checked={
                        formData.educationGuidlines?.pastYear
                          ?.oneOrMoreSubAnswers?.educationOrResources === "1"
                      }
                      onChange={(e) => {
                        if (!isEditing) return;
                        setFormData((prev) => ({
                          ...prev,
                          educationGuidlines: {
                            ...prev.educationGuidlines,
                            pastYear: {
                              ...prev.educationGuidlines.pastYear,
                              oneOrMoreSubAnswers: {
                                ...prev.educationGuidlines.pastYear
                                  .oneOrMoreSubAnswers,
                                educationOrResources: e.target.checked
                                  ? "1"
                                  : "",
                              },
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    />
                  }
                  label={
                    <Typography sx={{ color: "text.secondary" }}>
                      Education/resources provided to the member regarding
                      substance use
                    </Typography>
                  }
                />
              </Box>
              <IconButton
                onClick={() => handleCommentClick("oneOrMore")}
                disabled={!isEditing}
                sx={{
                  // color: formData.educationGuidlines?.pastYear
                  //   ?.oneOrMoreSubAnswers?.comment
                  //   ? "#0066B2"
                  //   : "text.secondary",
                  color: isEditing ? "#0066B2" : "text.secondary",
                }}
              >
                <CommentIcon />
              </IconButton>
            </Box>
            {renderCommentSection("oneOrMore")}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EducationGuidelines;
