import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormGroup,
  useTheme,
  useMediaQuery,
  Stack,
  Card,
  CardContent,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import { createTheme } from "@mui/material/styles";

// Add these style objects at the top of the component, after the imports
const buttonSx = {
  textTransform: "none",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  borderColor: "rgba(0, 0, 0, 0.23)",
  color: (theme) => theme.palette.text.secondary,
  "&.MuiButton-contained": {
    backgroundColor: "text.secondary",
    color: "white",
    "&:hover": {
      backgroundColor: "text.primary",
    },
  },
  "&.MuiButton-outlined": {
    "&:hover": {
      borderColor: "text.primary",
      color: "text.primary",
    },
  },
  "&.Mui-disabled": {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderColor: "rgba(0, 0, 0, 0.23)",
    backgroundColor: "transparent",
    color: "text.primary",
    "&.MuiButton-contained": {
      backgroundColor: "grey.600",
      color: "white",
      border: "1px solid rgba(0, 0, 0, 0.23)",
    },
  },
};

const radioSx = {
  "&.MuiFormControlLabel-root": {
    margin: 0,
    "& .MuiTypography-root": {
      color: "#000000 !important",
    },
    "&.Mui-disabled": {
      "& .MuiTypography-root": {
        color: "#000000 !important",
        opacity: 1,
        WebkitTextFillColor: "#000000",
      },
    },
  },
  "& .MuiRadio-root": {
    color: "#52A1DB",
    "&.Mui-checked": {
      color: "#52A1DB",
    },
    "&.Mui-disabled": {
      color: "#52A1DB",
      "&.Mui-checked": {
        color: "#52A1DB",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
    },
  },
};

const textFieldSx = {
  "& .MuiInputBase-input.Mui-disabled": {
    color: "black",
    WebkitTextFillColor: "black",
    opacity: 1,
  },
  "& .MuiInputBase-input": {
    "&::placeholder": {
      color: "text.secondary",
      opacity: 1,
    },
  },
  "& .MuiOutlinedInput-root": {
    padding: "0px !important",
    "& fieldset": {
      borderColor: "#52A1DB",
    },
    "&:hover fieldset": {
      borderColor: "#4190c4",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#52A1DB",
    },
  },
  "& textarea": {
    height: "100% !important",
    minHeight: "60px !important",
    lineHeight: "1.5",
  },
};

const cardSx = {
  "& .MuiCardContent-root": {
    padding: 2,
    "&:last-child": {
      paddingBottom: 2,
    },
  },
  border: "1px solid #52A1DB",
  borderRadius: "4px",
};

const titleSx = {
  color: "#52A1DB",
  fontWeight: 500,
  mb: 2,
};

// Update the theme constant
const theme = createTheme({
  palette: {
    primary: {
      main: "#52A1DB",
      dark: "#4190c4",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
    },
  },
});

// // Add these style constants at the top of the file, before the component
// const buttonSx = {
//   minWidth: '100px',
//   textTransform: 'none',
//   mx: 1
// };

const baseServiceButtonSx = {
  ...buttonSx,
  borderColor: "#000000",
  "&.MuiButton-outlined": {
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
      borderColor: "#000000",
    },
  },
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#3d8bc2",
    },
  },
  "&.Mui-disabled": {
    color: "#000000 !important",
    borderColor: "#000000",
    backgroundColor: "transparent",
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB !important",
      color: "#ffffff !important",
      borderColor: "transparent",
    },
  },
};

// const sectionBoxSx = {
//   backgroundColor: 'white',
//   borderRadius: '8px',
//   p: 3,
//   mb: 3,
//   border: '1px solid #2196f3',
//   boxShadow: 'none'
// };

// const innerSectionBoxSx = {
//   backgroundColor: 'white',
//   borderRadius: '4px',
//   p: 2,
//   mb: 2,
//   border: '1px solid #2196f3'
// };

// const textFieldSx = {
//   '& .MuiInputBase-root': {
//     backgroundColor: 'white',
//     borderRadius: '4px',
//     '& fieldset': {
//       borderColor: '#2196f3'
//     }
//   }
// };

// Add this constant for the common TextField styling
const commentTextFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    padding: "0px !important",
    "& fieldset": {
      borderColor: "#52A1DB",
    },
  },
  "& textarea": {
    height: "100% !important",
    minHeight: "60px !important",
    lineHeight: "1.5",
    color: "black",
  },
  "& .Mui-disabled": {
    WebkitTextFillColor: "black !important",
    color: "black !important",
  },
};

const Phq2 = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [changedFields, setChangedFields] = useState([]);
  const [originalData, setOriginalData] = useState(null);
  const [showPhq2Comment, setShowPhq2Comment] = useState(false);
  const [showPhq9Comment, setShowPhq9Comment] = useState(false);
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [commentVisibility, setCommentVisibility] = useState(() => {
    const savedState = localStorage.getItem("phq2commentVisibility");
    return savedState ? JSON.parse(savedState) : {};
  });

  const defaultFormData = {
    neuroPsych: {
      isNeuroPsych: "1",
      comment: "",
      nervousFeel: {
        isNervousFeel: "",
        comment: "",
      },
      worryTooMuch: {
        isWorryTooMuch: "",
        comment: "",
      },
      feelAfraid: {
        isFeelAfraid: "",
        comment: "",
      },
      overPastTwoWeeks: {
        littleInterestOrPleasure: {
          notAtAll: "",
          several: "",
          moreThanHalfTheDays: "",
          nearlyEveryDay: "",
          comment: "",
        },
        feelingDownDepressed: {
          notAtAll: "",
          several: "",
          moreThanHalfTheDays: "",
          nearlyEveryDay: "",
          comment: "",
        },
        phq2score: {
          lessThanThree: "",
          threeOrMore: "",
          phq2score: "0",
          threeOrMoreSubAnswer: {
            depressionScreening: {
              havingLittleInterest: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingDownDepressed: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              troubleFalling: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingTired: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              poorAppetite: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingBadAboutYourself: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              troubleConcentratingOnThings: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              movingOrSpeaking: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              hurtingYourself: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              difficultProblems: {
                notDifficultAtAll: "",
                someWhatDifficult: "",
                veryDifficult: "",
                extremelyDifficult: "",
                other: "",
                notApplicable: "",
                comment: "",
              },
              phq9score: "0",
              comment: "",
            },
          },
        },
      },
    },
  };

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const fetchPhqScore = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-phq-score`,
          {
            params: {
              memberId: user.memberId,
              year: new Date().getFullYear(),
            },
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          },
        );

        if (response.data?.data) {
          const transformedData = {
            neuroPsych: {
              overPastTwoWeeks: response.data.data.overPastTwoWeeks,
            },
          };
          setFormData(transformedData);
          setOriginalData(transformedData);
          const savedVisibility = localStorage.getItem("phq2commentVisibility");
          console.log("savedvisi", savedVisibility);
          if (savedVisibility) {
            setCommentVisibility(JSON.parse(savedVisibility));
          }
        } else {
          setFormData(defaultFormData);
          setOriginalData(defaultFormData);
        }
      } catch (error) {
        console.error("Error fetching PHQ score:", error);
        setMessage("Failed to fetch assessment data. Please try again.");
      }
    };

    fetchPhqScore();
  }, [user.memberId]);

  useEffect(() => {
    setShowPhq2Comment(
      !!formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.comment,
    );
    setShowPhq9Comment(
      !!formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
        ?.depressionScreening?.comment,
    );
  }, [formData]);

  // Add helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const handleRadioChange = (section, value, isPhq9) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const updated = { ...prev };

      // For PHQ-2 questions
      if (!isPhq9) {
        if (!updated.neuroPsych?.overPastTwoWeeks?.[section]) {
          updated.neuroPsych = updated.neuroPsych || {};
          updated.neuroPsych.overPastTwoWeeks =
            updated.neuroPsych.overPastTwoWeeks || {};
          updated.neuroPsych.overPastTwoWeeks[section] = {};
        }

        // Check if clicking the same value (deselection)
        const isDeselecting =
          updated.neuroPsych.overPastTwoWeeks[section][value] === "1";

        // Clear previous values
        Object.keys(updated.neuroPsych.overPastTwoWeeks[section]).forEach(
          (key) => {
            if (key !== "comment") {
              updated.neuroPsych.overPastTwoWeeks[section][key] = "";
            }
          },
        );

        // Set new value only if not deselecting
        if (!isDeselecting) {
          updated.neuroPsych.overPastTwoWeeks[section][value] = "1";
        }

        // Clear PHQ-9 data when PHQ-2 changes
        if (
          updated.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
            ?.depressionScreening
        ) {
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening =
            {
              havingLittleInterest: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingDownDepressed: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              troubleFalling: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingTired: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              poorAppetite: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              feelingBadAboutYourself: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              troubleConcentratingOnThings: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              movingOrSpeaking: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              hurtingYourself: {
                notAtAll: "",
                several: "",
                moreThanHalfTheDays: "",
                nearlyEveryDay: "",
                comment: "",
              },
              difficultProblems: {
                notDifficultAtAll: "",
                someWhatDifficult: "",
                veryDifficult: "",
                extremelyDifficult: "",
                other: "",
                notApplicable: "",
                comment: "",
              },
              phq9score: "0",
              comment: "",
            };
        }
      }
      // For PHQ-9 questions
      else {
        if (
          !updated.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
            ?.depressionScreening?.[section]
        ) {
          updated.neuroPsych = updated.neuroPsych || {};
          updated.neuroPsych.overPastTwoWeeks =
            updated.neuroPsych.overPastTwoWeeks || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score =
            updated.neuroPsych.overPastTwoWeeks.phq2score || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer =
            updated.neuroPsych.overPastTwoWeeks.phq2score
              .threeOrMoreSubAnswer || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening =
            updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
              .depressionScreening || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
            section
          ] = {};
        }

        const currentValue =
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
            .depressionScreening[section][value];
        // Clear previous values
        Object.keys(
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
            .depressionScreening[section],
        ).forEach((key) => {
          if (key !== "comment") {
            updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
              section
            ][key] = "";
          }
        });

        // Toggle value if clicking the same option
        if (currentValue !== "1") {
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
            section
          ][value] = "1";
        }
      }

      return updated;
    });

    setChangedFields((prev) => [...new Set([...prev, section])]);
    setIsEditing(true);
  };

  const getSelectedValue = (section) => {
    const options = [
      "notAtAll",
      "several",
      "moreThanHalfTheDays",
      "nearlyEveryDay",
    ];
    if (!formData?.neuroPsych?.overPastTwoWeeks?.[section]) return "";
    return (
      options.find(
        (option) =>
          formData.neuroPsych.overPastTwoWeeks[section][option] === "1",
      ) || ""
    );
  };

  const getPhq9Value = (section) => {
    const sectionData =
      formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
        ?.depressionScreening?.[section];
    if (!sectionData) return "";
    if (sectionData.notAtAll === "1") return "notAtAll";
    if (sectionData.several === "1") return "several";
    if (sectionData.moreThanHalfTheDays === "1") return "moreThanHalfTheDays";
    if (sectionData.nearlyEveryDay === "1") return "nearlyEveryDay";
    return "";
  };

  const calculatePhq2Score = () => {
    let score = 0;
    const sections = ["littleInterestOrPleasure", "feelingDownDepressed"];

    sections.forEach((section) => {
      const sectionData = formData?.neuroPsych?.overPastTwoWeeks?.[section];
      if (!sectionData) return;

      if (sectionData.notAtAll === "1") score += 0;
      if (sectionData.several === "1") score += 1;
      if (sectionData.moreThanHalfTheDays === "1") score += 2;
      if (sectionData.nearlyEveryDay === "1") score += 3;
    });

    return score;
  };

  const calculatePhq9Score = () => {
    let score = 0;
    const depressionScreening =
      formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
        ?.depressionScreening;
    if (!depressionScreening) return 0;

    const questions = [
      "havingLittleInterest",
      "feelingDownDepressed",
      "troubleFalling",
      "feelingTired",
      "poorAppetite",
      "feelingBadAboutYourself",
      "troubleConcentratingOnThings",
      "movingOrSpeaking",
      "hurtingYourself",
    ];

    questions.forEach((question) => {
      const answer = getPhq9Value(question);
      switch (answer) {
        case "notAtAll":
          score += 0;
          break;
        case "several":
          score += 1;
          break;
        case "moreThanHalfTheDays":
          score += 2;
          break;
        case "nearlyEveryDay":
          score += 3;
          break;
        default:
          break;
      }
    });

    return score;
  };

  const getDepressionSeverity = (score) => {
    if (score <= 4) return "Non-Minimal";
    if (score <= 9) return "Mild";
    if (score <= 14) return "Moderate";
    if (score <= 19) return "Moderately Severe";
    return "Severe";
  };

  const getProposedTreatment = (score) => {
    if (score <= 4) return "None";
    if (score <= 9) return "Watchful waiting; repeat PHQ-9 at follow-up";
    if (score <= 14)
      return "Treatment plan, consider counseling, follow-up and/or pharmacotherapy";
    if (score <= 19)
      return "Active treatment with pharmacotherapy and/or psychotherapy";
    return "Immediate initiation of pharmocotherapy and, if severe impairment or poor response to therapy, expedited referral to a mental health specialist";
  };

  const handleSubmit = async () => {
    try {
      const score = calculatePhq2Score();
      const phq9Score = calculatePhq9Score();

      // Only include PHQ-2/PHQ-9 related data
      const updatedFormData = {
        neuroPsych: {
          overPastTwoWeeks: {
            littleInterestOrPleasure: formData?.neuroPsych?.overPastTwoWeeks
              ?.littleInterestOrPleasure || {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            feelingDownDepressed: formData?.neuroPsych?.overPastTwoWeeks
              ?.feelingDownDepressed || {
              notAtAll: "",
              several: "",
              moreThanHalfTheDays: "",
              nearlyEveryDay: "",
              comment: "",
            },
            phq2score: {
              lessThanThree: score < 3 ? "1" : "",
              threeOrMore: score >= 3 ? "1" : "",
              phq2score: score.toString(),
              threeOrMoreSubAnswer: {
                depressionScreening:
                  score >= 3
                    ? {
                        havingLittleInterest: formData?.neuroPsych
                          ?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
                          ?.depressionScreening?.havingLittleInterest || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingDownDepressed: formData?.neuroPsych
                          ?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
                          ?.depressionScreening?.feelingDownDepressed || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        troubleFalling: formData?.neuroPsych?.overPastTwoWeeks
                          ?.phq2score?.threeOrMoreSubAnswer?.depressionScreening
                          ?.troubleFalling || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingTired: formData?.neuroPsych?.overPastTwoWeeks
                          ?.phq2score?.threeOrMoreSubAnswer?.depressionScreening
                          ?.feelingTired || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        poorAppetite: formData?.neuroPsych?.overPastTwoWeeks
                          ?.phq2score?.threeOrMoreSubAnswer?.depressionScreening
                          ?.poorAppetite || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingBadAboutYourself: formData?.neuroPsych
                          ?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
                          ?.depressionScreening?.feelingBadAboutYourself || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        troubleConcentratingOnThings: formData?.neuroPsych
                          ?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
                          ?.depressionScreening
                          ?.troubleConcentratingOnThings || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        movingOrSpeaking: formData?.neuroPsych?.overPastTwoWeeks
                          ?.phq2score?.threeOrMoreSubAnswer?.depressionScreening
                          ?.movingOrSpeaking || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        hurtingYourself: formData?.neuroPsych?.overPastTwoWeeks
                          ?.phq2score?.threeOrMoreSubAnswer?.depressionScreening
                          ?.hurtingYourself || {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        difficultProblems: formData?.neuroPsych
                          ?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
                          ?.depressionScreening?.difficultProblems || {
                          notDifficultAtAll: "",
                          someWhatDifficult: "",
                          veryDifficult: "",
                          extremelyDifficult: "",
                          other: "",
                          notApplicable: "",
                          comment: "",
                        },
                        phq9score: phq9Score.toString(),
                        comment:
                          formData?.neuroPsych?.overPastTwoWeeks?.phq2score
                            ?.threeOrMoreSubAnswer?.depressionScreening
                            ?.comment || "",
                      }
                    : {
                        // Empty depression screening object when score < 3
                        havingLittleInterest: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingDownDepressed: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        troubleFalling: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingTired: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        poorAppetite: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        feelingBadAboutYourself: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        troubleConcentratingOnThings: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        movingOrSpeaking: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        hurtingYourself: {
                          notAtAll: "",
                          several: "",
                          moreThanHalfTheDays: "",
                          nearlyEveryDay: "",
                          comment: "",
                        },
                        difficultProblems: {
                          notDifficultAtAll: "",
                          someWhatDifficult: "",
                          veryDifficult: "",
                          extremelyDifficult: "",
                          other: "",
                          notApplicable: "",
                          comment: "",
                        },
                        phq9score: "0",
                        comment: "",
                      },
              },
            },
          },
        },
      };

      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-phq2score`,
        {
          memberId: user.memberId,
          year: new Date().getFullYear(),
          name: "phqScoreReview",
          data: updatedFormData,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      // Fetch fresh data after successful update
      const freshDataResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-phq-score`,
        {
          params: {
            memberId: user.memberId,
            year: new Date().getFullYear(),
          },
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      if (freshDataResponse.data?.data) {
        const transformedData = {
          neuroPsych: {
            overPastTwoWeeks: freshDataResponse.data.data.overPastTwoWeeks,
          },
        };
        setFormData(transformedData);
        setOriginalData(transformedData);
      }

      // After successful submission, update comment visibility
      // Hide comment boxes that are empty
      setCommentVisibility((prev) => {
        const newVisibility = { ...prev };

        // Helper function to check comment value
        const getCommentValue = (field, isPhq9 = false) => {
          if (isPhq9) {
            return formData?.neuroPsych?.overPastTwoWeeks?.phq2score
              ?.threeOrMoreSubAnswer?.depressionScreening?.[field]?.comment;
          }
          return formData?.neuroPsych?.overPastTwoWeeks?.[field]?.comment;
        };

        // Check PHQ-2 questions
        ["littleInterestOrPleasure", "feelingDownDepressed"].forEach(
          (field) => {
            newVisibility[field] = !!getCommentValue(field);
          },
        );

        // Check PHQ-9 questions
        [
          "havingLittleInterest",
          "feelingDownDepressed",
          "troubleFalling",
          "feelingTired",
          "poorAppetite",
          "feelingBadAboutYourself",
          "troubleConcentratingOnThings",
          "movingOrSpeaking",
          "hurtingYourself",
        ].forEach((field) => {
          newVisibility[field] = !!getCommentValue(field, true);
        });

        // Check special cases
        // if (
        //   !formData?.neuroPsych?.overPastTwoWeeks?.phq2score
        //     ?.threeOrMoreSubAnswer?.depressionScreening?.difficultProblems
        //     ?.comment
        // ) {
        //   newVisibility["difficultProblems"] = false;
        // }
        // if (
        //   !formData?.neuroPsych?.overPastTwoWeeks?.phq2score
        //     ?.threeOrMoreSubAnswer?.depressionScreening?.comment
        // ) {
        //   newVisibility["phq9Score"] = false;
        // }
        newVisibility["difficultProblems"] =
          !!formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer?.depressionScreening?.difficultProblems?.comment?.trim();

        // newVisibility["phq9Score"] =
        //   !!formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer?.depressionScreening?.comment?.trim();
        //always visible irrespective of data
        newVisibility["phq9Score"] = true;

        localStorage.setItem(
          "phq2commentVisibility",
          JSON.stringify(newVisibility),
        );
        console.log("newvisi", newVisibility);

        return newVisibility;
      });

      setMessage("Mental health assessment updated successfully!");
      setIsEditing(false);
      setChangedFields([]);
    } catch (error) {
      console.error("Error updating assessment:", error);
      setMessage("Failed to update assessment. Please try again.");
    }
  };

  const handleCancel = () => {
    if (originalData) {
      setFormData(originalData);
    } else {
      setFormData(defaultFormData);
    }
    setIsEditing(false);
    setMessage("");
    setChangedFields([]);
  };

  const handlePhq2CommentChange = (event) => {
    setFormData((prev) => ({
      ...prev,
      neuroPsych: {
        ...prev.neuroPsych,
        overPastTwoWeeks: {
          ...prev.neuroPsych?.overPastTwoWeeks,
          phq2score: {
            ...prev.neuroPsych?.overPastTwoWeeks?.phq2score,
            comment: event.target.value,
          },
        },
      },
    }));
    setIsEditing(true);
  };

  const handlePhq9CommentChange = (event) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      neuroPsych: {
        ...prev.neuroPsych,
        overPastTwoWeeks: {
          ...prev.neuroPsych?.overPastTwoWeeks,
          phq2score: {
            ...prev.neuroPsych?.overPastTwoWeeks?.phq2score,
            threeOrMoreSubAnswer: {
              ...prev.neuroPsych?.overPastTwoWeeks?.phq2score
                ?.threeOrMoreSubAnswer,
              depressionScreening: {
                ...prev.neuroPsych?.overPastTwoWeeks?.phq2score
                  ?.threeOrMoreSubAnswer?.depressionScreening,
                comment: event.target.value,
              },
            },
          },
        },
      },
    }));

    // Track that this field has changed
    setChangedFields((prev) => [...new Set([...prev, "phq9Comment"])]);
    setIsEditing(true);
  };

  const handleCommentClick = (questionId) => {
    setCommentVisibility((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  const getCommentForQuestion = (questionId, isPhq9) => {
    if (isPhq9) {
      return formData?.neuroPsych?.overPastTwoWeeks?.phq2score
        ?.threeOrMoreSubAnswer?.depressionScreening?.[questionId]?.comment;
    }
    // For PHQ-2 questions, get comment directly from overPastTwoWeeks
    return formData?.neuroPsych?.overPastTwoWeeks?.[questionId]?.comment;
  };

  const handleCommentChange = (questionId, value, isPhq9) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const updated = { ...prev };
      if (isPhq9) {
        // Handle PHQ-9 comments
        if (
          !updated.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
            ?.depressionScreening?.[questionId]
        ) {
          updated.neuroPsych = updated.neuroPsych || {};
          updated.neuroPsych.overPastTwoWeeks =
            updated.neuroPsych.overPastTwoWeeks || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score =
            updated.neuroPsych.overPastTwoWeeks.phq2score || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer =
            updated.neuroPsych.overPastTwoWeeks.phq2score
              .threeOrMoreSubAnswer || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening =
            updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
              .depressionScreening || {};
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
            questionId
          ] = {};
        }
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening[
          questionId
        ].comment = value;
      } else {
        // Handle PHQ-2 comments
        if (!updated.neuroPsych?.overPastTwoWeeks?.[questionId]) {
          updated.neuroPsych = updated.neuroPsych || {};
          updated.neuroPsych.overPastTwoWeeks =
            updated.neuroPsych.overPastTwoWeeks || {};
          updated.neuroPsych.overPastTwoWeeks[questionId] = {};
        }
        updated.neuroPsych.overPastTwoWeeks[questionId].comment = value;
      }
      return updated;
    });
  };

  const renderRadioOptions = (section, value, handler, isPhq9 = false) => {
    const options = [
      { value: "notAtAll", label: "Not at all" },
      { value: "several", label: "Several Days" },
      { value: "moreThanHalfTheDays", label: "More than half the days" },
      { value: "nearlyEveryDay", label: "Nearly every day" },
    ];

    return (
      <RadioGroup
        value={value}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={
              <Radio
                size={isMobile ? "small" : "medium"}
                onClick={(e) => handler(section, option.value, isPhq9)}
              />
            }
            label={option.label}
            disabled={!isEditing}
            sx={{
              ...radioSx,
              "& .MuiTypography-root": {
                color: "#000000 !important",
              },
            }}
          />
        ))}
      </RadioGroup>
    );
  };

  const renderQuestion = (question, isPhq9 = false) => (
    <Card key={question.id} variant="outlined" sx={cardSx}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: "text.primary",
              fontSize: isMobile ? "0.875rem" : "1rem",
              lineHeight: 1.5,
              flex: 1,
            }}
          >
            {question.question}
          </Typography>
          <IconButton
            onClick={() => handleCommentClick(question.id)}
            sx={{
              color: "#52A1DB",
              ml: 1,
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {renderRadioOptions(
          question.id,
          isPhq9 ? getPhq9Value(question.id) : getSelectedValue(question.id),
          handleRadioChange,
          isPhq9,
        )}

        {/* Modified comment section rendering */}
        {commentVisibility[question.id] && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mb: 2,
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={getCommentForQuestion(question.id, isPhq9) || ""}
              onChange={(e) =>
                handleCommentChange(question.id, e.target.value, isPhq9)
              }
              disabled={!isEditing}
              sx={textFieldSx}
              variant="outlined"
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );

  const renderPhq9Questions = () => {
    const questions = [
      {
        id: "havingLittleInterest",
        question: "Having little interest or pleasure in doing things?",
      },
      {
        id: "feelingDownDepressed",
        question: "Feeling down, depressed or hopeless at times?",
      },
      {
        id: "troubleFalling",
        question:
          "Do you have trouble falling or staying asleep, sleeping too much?",
      },
      {
        id: "feelingTired",
        question: "Do you feeling tired or having little energy?",
      },
      {
        id: "poorAppetite",
        question: "Do you have a poor appetite or overeating?",
      },
      {
        id: "feelingBadAboutYourself",
        question:
          "Feeling bad about yourself or that you are a failure or have let yourself or your family down?",
      },
      {
        id: "troubleConcentratingOnThings",
        question:
          "Trouble concentrating on things, such as reading the newspaper or watching TV?",
      },
      {
        id: "movingOrSpeaking",
        question:
          "Moving or speaking so slowly that other people have noticed or opposite-being fidgety or restless that you have been moving around a lot more than usual?",
      },
      {
        id: "hurtingYourself",
        question:
          "Thoughts that you would be better off dead, or hurting yourself?",
      },
    ];

    return (
      <Stack spacing={2}>
        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: 1,
            p: 2,
            textAlign: "left",
          }}
        >
          {questions.map((question) => renderQuestion(question, true))}
        </Box>

        {hasNonNotAtAllAnswers() && (
          <Card variant="outlined" sx={{ mt: 2, ...cardSx }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    color: !isEditing ? "black" : "text.primary",
                    fontSize: isMobile ? "0.875rem" : "1rem",
                    lineHeight: 1.5,
                    flex: 1,
                  }}
                >
                  How difficult have these problems made it for you to do your
                  work, take care of things at home, or get along with other
                  people?
                </Typography>
                <IconButton
                  onClick={() => handleCommentClick("difficultProblems")}
                  sx={{
                    color: "#52A1DB",
                    ml: 1,
                  }}
                  disabled={!isEditing}
                >
                  <CommentIcon />
                </IconButton>
              </Box>
              <FormControl
                component="fieldset"
                disabled={!isEditing}
                sx={{ width: "100%" }}
              >
                <RadioGroup
                  value={getDifficultProblemsValue()}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  {[
                    {
                      value: "notDifficultAtAll",
                      label: "Not difficult at all",
                    },
                    { value: "someWhatDifficult", label: "Somewhat Difficult" },
                    { value: "veryDifficult", label: "Very Difficult" },
                    {
                      value: "extremelyDifficult",
                      label: "Extremely Difficult",
                    },
                    { value: "other", label: "Other" },
                    { value: "notApplicable", label: "Not Applicable" },
                  ].map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          size={isMobile ? "small" : "medium"}
                          onClick={(e) => handleDifficultProblemsChange(e)}
                        />
                      }
                      label={option.label}
                      sx={{
                        ...radioSx,
                        margin: 0,
                        width: "100%",
                        justifyContent: "flex-start",
                        "& .MuiTypography-root": {
                          color: "#000000 !important",
                          textAlign: "left",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {commentVisibility["difficultProblems"] && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    border: "1px solid #52A1DB",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    mt: 2,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                    Comments:
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={
                      formData?.neuroPsych?.overPastTwoWeeks?.phq2score
                        ?.threeOrMoreSubAnswer?.depressionScreening
                        ?.difficultProblems?.comment || ""
                    }
                    onChange={(e) =>
                      handleDifficultProblemsCommentChange(e.target.value)
                    }
                    disabled={!isEditing}
                    sx={textFieldSx}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        )}

        {/* PHQ-9 Severity Table */}
        <Card variant="outlined" sx={{ mt: 2, ...cardSx }}>
          <CardContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold", color: "#52A1DB" }}>
                    PHQ-9 Score
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#52A1DB" }}>
                    Depression Severity
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#52A1DB" }}>
                    Proposed Treatment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>0 - 4</TableCell>
                  <TableCell>Non-Minimal</TableCell>
                  <TableCell>None</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>5 - 9</TableCell>
                  <TableCell>Mild</TableCell>
                  <TableCell>
                    Watchful waiting; repeat PHQ-9 at follow-up
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10 - 14</TableCell>
                  <TableCell>Moderate</TableCell>
                  <TableCell>
                    Treatment plan, consider counseling, follow-up and/or
                    pharmacotherapy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>15 - 19</TableCell>
                  <TableCell>Moderately Severe</TableCell>
                  <TableCell>
                    Active treatment with pharmacotherapy and/or psychotherapy
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>20 - 27</TableCell>
                  <TableCell>Severe</TableCell>
                  <TableCell>
                    Immediate initiation of pharmocotherapy and, if severe
                    impairment or poor response to therapy, expedited referral
                    to a mental health specialist
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        {/* PHQ-9 Score Summary */}
        <Card variant="outlined" sx={{ mt: 2, ...cardSx }}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" sx={{ ...titleSx }}>
                  PHQ-9 Score: {calculatePhq9Score()}
                </Typography>
                <IconButton
                  onClick={() => handleCommentClick("phq9Score")}
                  sx={{
                    color: "#52A1DB",
                    ml: 1,
                  }}
                  disabled={!isEditing}
                >
                  <CommentIcon />
                </IconButton>
              </Box>
              <Tooltip title="If Score is Greater than 15, recommend additional treatment">
                <Typography
                  variant="caption"
                  sx={{
                    color: "text.secondary",
                    fontStyle: "italic",
                    display: "block",
                    mt: 1,
                  }}
                >
                  If Score is Greater than 15, recommend additional treatment
                </Typography>
              </Tooltip>
            </Box>

            {commentVisibility["phq9Score"] && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                  mt: 2,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={
                    formData?.neuroPsych?.overPastTwoWeeks?.phq2score
                      ?.threeOrMoreSubAnswer?.depressionScreening?.comment || ""
                  }
                  onChange={(e) => handlePhq9CommentChange(e)}
                  disabled={!isEditing}
                  sx={commentTextFieldSx}
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Stack>
    );
  };

  const renderInitialQuestions = () => (
    <Box>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#52A1DB",
          fontSize: isMobile ? "1rem" : "1.25rem",
          textAlign: "left",
          ...titleSx,
        }}
      >
        Over the past 2 weeks, how often have you been bothered by any of the
        following problems?
      </Typography>

      {["littleInterestOrPleasure", "feelingDownDepressed"].map((section) => (
        <Card key={section} variant="outlined" sx={{ mt: 2, mb: 2, ...cardSx }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{
                  color: "black",
                  fontSize: isMobile ? "0.875rem" : "1rem",
                  lineHeight: 1.5,
                  textAlign: "left",
                  flex: 1,
                }}
              >
                {section === "littleInterestOrPleasure"
                  ? "Little interest or pleasure in doing things"
                  : "Feeling down, depressed or hopeless"}
              </Typography>
              <IconButton
                onClick={() => handleCommentClick(section)}
                sx={{
                  color: "#52A1DB",
                  ml: 1,
                }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>
            <FormControl component="fieldset" disabled={!isEditing} fullWidth>
              {renderRadioOptions(
                section,
                getSelectedValue(section),
                handleRadioChange,
              )}
            </FormControl>

            {/* Modified comment section rendering */}
            {commentVisibility[section] && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                  mt: 2,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={
                    formData?.neuroPsych?.overPastTwoWeeks?.[section]
                      ?.comment || ""
                  }
                  onChange={(e) =>
                    handleCommentChange(section, e.target.value, false)
                  }
                  disabled={!isEditing}
                  sx={textFieldSx}
                />
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  // Add this helper function to check if any PHQ-2 questions are answered
  const hasPhq2Answers = () => {
    const littleInterest = getSelectedValue("littleInterestOrPleasure");
    const feelingDown = getSelectedValue("feelingDownDepressed");
    return littleInterest || feelingDown;
  };

  // Update the renderPhq2ScoreSection function
  const renderPhq2ScoreSection = () => {
    const phq2Score = calculatePhq2Score();

    return (
      <Card variant="outlined" sx={{ mt: 2, ...cardSx }}>
        <CardContent>
          <Typography variant="subtitle1" sx={{ mb: 2, ...titleSx }}>
            PHQ-2 Score: {phq2Score}
          </Typography>
          <RadioGroup value={phq2Score < 3 ? "lessThanThree" : "threeOrMore"}>
            <FormControlLabel
              value="lessThanThree"
              control={<Radio />}
              label="Less than 3"
              disabled={true} // Always disabled
              sx={radioSx}
            />
            <FormControlLabel
              value="threeOrMore"
              control={<Radio />}
              label="3 or more"
              disabled={true} // Always disabled
              sx={radioSx}
            />
          </RadioGroup>
        </CardContent>
      </Card>
    );
  };

  // Add these helper functions
  const getDifficultProblemsValue = () => {
    const difficultProblems =
      formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
        ?.depressionScreening?.difficultProblems;
    if (!difficultProblems) return "";

    const options = [
      "notDifficultAtAll",
      "someWhatDifficult",
      "veryDifficult",
      "extremelyDifficult",
      "other",
      "notApplicable",
    ];
    return options.find((option) => difficultProblems[option] === "1") || "";
  };

  const handleDifficultProblemsChange = (event) => {
    if (!isEditing) return;

    const value = event.target.value;

    setFormData((prev) => {
      const updated = { ...prev };
      if (
        !updated.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
          ?.depressionScreening?.difficultProblems
      ) {
        // Initialize the path if it doesn't exist
        updated.neuroPsych = updated.neuroPsych || {};
        updated.neuroPsych.overPastTwoWeeks =
          updated.neuroPsych.overPastTwoWeeks || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score =
          updated.neuroPsych.overPastTwoWeeks.phq2score || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer =
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer ||
          {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening =
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
            .depressionScreening || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems =
          {};
      }

      const difficultProblems =
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
          .depressionScreening.difficultProblems;

      // Check if clicking the same value (deselection)
      const isDeselecting = difficultProblems[value] === "1";

      // Reset all values and comment
      [
        "notDifficultAtAll",
        "someWhatDifficult",
        "veryDifficult",
        "extremelyDifficult",
        "other",
        "notApplicable",
      ].forEach((option) => {
        difficultProblems[option] = "";
      });
      difficultProblems.comment = "";

      // Set the new value only if not deselecting
      if (!isDeselecting) {
        difficultProblems[value] = "1";
      }

      return updated;
    });

    // Reset comment visibility for difficult problems
    setCommentVisibility((prev) => ({
      ...prev,
      difficultProblems: false,
    }));

    setChangedFields((prev) => [...new Set([...prev, "difficultProblems"])]);
    setIsEditing(true);
  };

  const handleDifficultProblemsCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const updated = { ...prev };
      if (
        !updated.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
          ?.depressionScreening?.difficultProblems
      ) {
        // Initialize the path if it doesn't exist
        updated.neuroPsych = updated.neuroPsych || {};
        updated.neuroPsych.overPastTwoWeeks =
          updated.neuroPsych.overPastTwoWeeks || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score =
          updated.neuroPsych.overPastTwoWeeks.phq2score || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer =
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer ||
          {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening =
          updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer
            .depressionScreening || {};
        updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems =
          {};
      }

      updated.neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.comment =
        value;
      return updated;
    });
  };

  // Add this helper function to check if any PHQ-9 answers are not "Not at all"
  const hasNonNotAtAllAnswers = () => {
    const depressionScreening =
      formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
        ?.depressionScreening;

    if (!depressionScreening) return false;

    const questions = [
      "havingLittleInterest",
      "feelingDownDepressed",
      "troubleFalling",
      "feelingTired",
      "poorAppetite",
      "feelingBadAboutYourself",
      "troubleConcentratingOnThings",
      "movingOrSpeaking",
      "hurtingYourself",
    ];

    return questions.some((question) => {
      const answer = getPhq9Value(question);
      return answer && answer !== "notAtAll";
    });
  };

  // Inside the component, create serviceButtonSx
  const serviceButtonSx = {
    ...baseServiceButtonSx,
  };

  // Add useEffect to log state changes
  useEffect(() => {
    console.log("Comment visibility state changed:", commentVisibility);
  }, [commentVisibility]);

  // Initialize commentVisibility with explicit false values, except for fields with comments
  useEffect(() => {
    const initializeCommentVisibility = () => {
      const newVisibility = {};

      // Helper function to check if a field has a comment
      const hasComment = (field, isPhq9 = false) => {
        if (isPhq9) {
          return !!formData?.neuroPsych?.overPastTwoWeeks?.phq2score
            ?.threeOrMoreSubAnswer?.depressionScreening?.[field]?.comment;
        }
        return !!formData?.neuroPsych?.overPastTwoWeeks?.[field]?.comment;
      };

      // Only initialize visibility for fields that have comments
      ["littleInterestOrPleasure", "feelingDownDepressed"].forEach(
        (section) => {
          if (hasComment(section)) {
            newVisibility[section] = true;
          }
        },
      );

      // Initialize for PHQ-9 questions with comments
      [
        "havingLittleInterest",
        "feelingDownDepressed",
        "troubleFalling",
        "feelingTired",
        "poorAppetite",
        "feelingBadAboutYourself",
        "troubleConcentratingOnThings",
        "movingOrSpeaking",
        "hurtingYourself",
      ].forEach((section) => {
        if (hasComment(section, true)) {
          newVisibility[section] = true;
        }
      });

      // Special cases with comments
      if (
        formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
          ?.depressionScreening?.difficultProblems?.comment
      ) {
        newVisibility["difficultProblems"] = true;
      }
      if (
        formData?.neuroPsych?.overPastTwoWeeks?.phq2score?.threeOrMoreSubAnswer
          ?.depressionScreening?.comment
      ) {
        newVisibility["phq9Score"] = true;
      }

      setCommentVisibility(newVisibility);
    };

    initializeCommentVisibility();
  }, []); // Only run on initial mount

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      {/* {changedFields.length > 0 && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Changed fields: {changedFields.join(", ")}
        </Alert>
      )} */}

      <Paper sx={{ p: isMobile ? 2 : 4 }}>
        <Stack spacing={4}>
          {renderInitialQuestions()}

          {renderPhq2ScoreSection()}

          {calculatePhq2Score() >= 3 && (
            <Box>
              <Typography
                variant="h6"
                sx={{
                  bgcolor: "#52A1DB",
                  color: "white",
                  p: 2,
                  borderRadius: 1,
                  fontSize: isMobile ? "1rem" : "1.25rem",
                  textAlign: "left",
                }}
              >
                DEPRESSION SCREENING PHQ-9
              </Typography>

              <Box sx={{ mt: 3 }}>{renderPhq9Questions()}</Box>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Edit
              </Button>
            ) : (
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                width={isMobile ? "100%" : "auto"}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                  fullWidth={isMobile}
                  sx={{ maxWidth: isMobile ? "100%" : "200px" }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                  fullWidth={isMobile}
                  sx={{ maxWidth: isMobile ? "100%" : "200px" }}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Phq2;
