import {
  getObjectData,
  getOptionsSelected,
  getOptionsSelectedYesNo,
  getOptionsSelectedYesNoDontKnow,
  getOptionsSelectedYesNoPartially,
  getOptionsSelectedYesNoUnknown,
  getSelectedCheckboxes,
  getOptionsSelectedYesNoWithThird,
  setObjectData,
  setOptionsSelected,
  setOptionsSelectedYesNo,
  setOptionsSelectedYesNoDontKnow,
  setOptionsSelectedYesNoPartially,
  setOptionsSelectedYesNoUnknown,
  setSelectedCheckboxes,
  setOptionsSelectedYesNoWithThird,
} from "../../../../../../utils/helpers.DynamicForm";

export const ros_flatmap_Eyes = [
  ["ros_eyes", "eyes.isEyes", "3"],
  ["ros_eyesDiagCataract", "eyes.isEyesSubAnswer.diagnoses.cataracts", "2"],
  [
    "ros_eyesDiagCataractSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagCataractSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagCataractSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagCataractSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.cataractsSubAnswer.describe.comment",
    "1",
  ],
  ["ros_eyesDiagGlaucoma", "eyes.isEyesSubAnswer.diagnoses.glaucoma", "2"],
  [
    "ros_eyesDiagGlaucomaSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagGlaucomaSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.glaucomaSubAnswer.describe.comment",
    "1",
  ],
  ["ros_eyesDiagHyperopia", "eyes.isEyesSubAnswer.diagnoses.hyperopia", "2"],
  [
    "ros_eyesDiagHyperopiaSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagHyperopiaSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.hyperopiaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_eyesDiagLegalBlind",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlind",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_eyesDiagLegalBlindSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.legallyBlindSubAnswer.lbDescribe",
    "1",
  ],
  [
    "ros_eyesDiagMascDegen",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegeneration",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagMascDegenSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2Wet",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.wet",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2Dry",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.dry",
    "2",
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2Comment",
    "eyes.isEyesSubAnswer.diagnoses.mascularDegenerationSubAnswer.mdDescribe.comment",
    "1",
  ],
  ["ros_eyesDiagMyopia", "eyes.isEyesSubAnswer.diagnoses.myopia", "2"],
  [
    "ros_eyesDiagMyopiaSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagMyopiaSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagMyopiaSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.myopiaSubAnswer.describe.comment",
    "1",
  ],
  ["ros_eyesDiagRetinal", "eyes.isEyesSubAnswer.diagnoses.retinalDisease", "2"],
  [
    "ros_eyesDiagRetinalSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagRetinalSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagRetinalSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagRetinalSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.retinalDiseaseSubAnswer.describe.comment",
    "1",
  ],
  ["ros_eyesDiagOther", "eyes.isEyesSubAnswer.diagnoses.others", "2"],
  [
    "ros_eyesDiagOtherSubWhEyeRight",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.rightEye",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeLeft",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.leftEye",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeBoth",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.both",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubWhEyeComment",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.whichEye.comment",
    "1",
  ],
  [
    "ros_eyesDiagOtherSubDescActive",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatMedic",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatOther",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatOtherSubDescText",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatNoTreat",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatGlass",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.glasses",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreatComment",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_eyesDiagOtherSubDescHistory",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_eyesDiagOtherSubDescComment",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_eyesDiagOtherSubOther",
    "eyes.isEyesSubAnswer.diagnoses.othersSubAnswer.other",
    "1",
  ],

  ["ros_eyesSympPain", "eyes.isEyesSubAnswer.symptoms.pain", "2"],
  ["ros_eyesSympDryEye", "eyes.isEyesSubAnswer.symptoms.dryEye", "2"],
  ["ros_eyesSympTear", "eyes.isEyesSubAnswer.symptoms.tearing", "2"],
  ["ros_eyesSympSee", "eyes.isEyesSubAnswer.symptoms.problemSeeing", "2"],
  ["ros_eyesSympFloat", "eyes.isEyesSubAnswer.symptoms.floaters", "2"],
  ["ros_eyesSympOther", "eyes.isEyesSubAnswer.symptoms.other", "2"],
  [
    "ros_eyesSympOtherSubDesc",
    "eyes.isEyesSubAnswer.symptoms.otherSubAnswer.describe",
    "1",
  ],
  ["ros_eyesSympNone", "eyes.isEyesSubAnswer.symptoms.none", "2"],
  ["ros_eyesSympComment", "eyes.isEyesSubAnswer.symptoms.comment", "1"],

  ["ros_eyesDiagComment", "eyes.isEyesSubAnswer.diagnoses.comment", "1"],
  ["ros_eyesComment", "eyes.comment", "1"],
];

export const ros_optionsTreeWrappers_Eyes = [
  [
    "ros_eyesDiag",
    [
      "ros_eyesDiagCataract",
      "ros_eyesDiagGlaucoma",
      "ros_eyesDiagHyperopia",
      "ros_eyesDiagLegalBlind",
      "ros_eyesDiagMascDegen",
      "ros_eyesDiagMyopia",
      "ros_eyesDiagRetinal",
      "ros_eyesDiagOther",
    ],
  ],

  [
    "ros_eyesDiagCataractSubWhEye",
    [
      "ros_eyesDiagCataractSubWhEyeRight",
      "ros_eyesDiagCataractSubWhEyeLeft",
      "ros_eyesDiagCataractSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagCataractSubDesc",
    ["ros_eyesDiagCataractSubDescActive", "ros_eyesDiagCataractSubDescHistory"],
  ],
  [
    "ros_eyesDiagCataractSubDescActiveSubTreat",
    [
      "ros_eyesDiagCataractSubDescActiveSubTreatMedic",
      "ros_eyesDiagCataractSubDescActiveSubTreatOther",
      "ros_eyesDiagCataractSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagCataractSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubWhEye",
    [
      "ros_eyesDiagGlaucomaSubWhEyeRight",
      "ros_eyesDiagGlaucomaSubWhEyeLeft",
      "ros_eyesDiagGlaucomaSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagGlaucomaSubDesc",
    ["ros_eyesDiagGlaucomaSubDescActive", "ros_eyesDiagGlaucomaSubDescHistory"],
  ],
  [
    "ros_eyesDiagGlaucomaSubDescActiveSubTreat",
    [
      "ros_eyesDiagGlaucomaSubDescActiveSubTreatMedic",
      "ros_eyesDiagGlaucomaSubDescActiveSubTreatOther",
      "ros_eyesDiagGlaucomaSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagGlaucomaSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubWhEye",
    [
      "ros_eyesDiagHyperopiaSubWhEyeRight",
      "ros_eyesDiagHyperopiaSubWhEyeLeft",
      "ros_eyesDiagHyperopiaSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubDesc",
    [
      "ros_eyesDiagHyperopiaSubDescActive",
      "ros_eyesDiagHyperopiaSubDescHistory",
    ],
  ],
  [
    "ros_eyesDiagHyperopiaSubDescActiveSubTreat",
    [
      "ros_eyesDiagHyperopiaSubDescActiveSubTreatMedic",
      "ros_eyesDiagHyperopiaSubDescActiveSubTreatOther",
      "ros_eyesDiagHyperopiaSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagHyperopiaSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubWhEye",
    [
      "ros_eyesDiagLegalBlindSubWhEyeRight",
      "ros_eyesDiagLegalBlindSubWhEyeLeft",
      "ros_eyesDiagLegalBlindSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubDesc",
    [
      "ros_eyesDiagLegalBlindSubDescActive",
      "ros_eyesDiagLegalBlindSubDescHistory",
    ],
  ],
  [
    "ros_eyesDiagLegalBlindSubDescActiveSubTreat",
    [
      "ros_eyesDiagLegalBlindSubDescActiveSubTreatMedic",
      "ros_eyesDiagLegalBlindSubDescActiveSubTreatOther",
      "ros_eyesDiagLegalBlindSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagLegalBlindSubDescActiveSubTreatGlass",
    ],
  ],

  [
    "ros_eyesDiagMascDegenSubWhEye",
    [
      "ros_eyesDiagMascDegenSubWhEyeRight",
      "ros_eyesDiagMascDegenSubWhEyeLeft",
      "ros_eyesDiagMascDegenSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubDesc",
    [
      "ros_eyesDiagMascDegenSubDescActive",
      "ros_eyesDiagMascDegenSubDescHistory",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubDescActiveSubTreat",
    [
      "ros_eyesDiagMascDegenSubDescActiveSubTreatMedic",
      "ros_eyesDiagMascDegenSubDescActiveSubTreatOther",
      "ros_eyesDiagMascDegenSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagMascDegenSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagMascDegenSubDesc2",
    ["ros_eyesDiagMascDegenSubDesc2Wet", "ros_eyesDiagMascDegenSubDesc2Dry"],
  ],
  [
    "ros_eyesDiagMyopiaSubWhEye",
    [
      "ros_eyesDiagMyopiaSubWhEyeRight",
      "ros_eyesDiagMyopiaSubWhEyeLeft",
      "ros_eyesDiagMyopiaSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagMyopiaSubDesc",
    ["ros_eyesDiagMyopiaSubDescActive", "ros_eyesDiagMyopiaSubDescHistory"],
  ],
  [
    "ros_eyesDiagMyopiaSubDescActiveSubTreat",
    [
      "ros_eyesDiagMyopiaSubDescActiveSubTreatMedic",
      "ros_eyesDiagMyopiaSubDescActiveSubTreatOther",
      "ros_eyesDiagMyopiaSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagMyopiaSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubWhEye",
    [
      "ros_eyesDiagRetinalSubWhEyeRight",
      "ros_eyesDiagRetinalSubWhEyeLeft",
      "ros_eyesDiagRetinalSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagRetinalSubDesc",
    ["ros_eyesDiagRetinalSubDescActive", "ros_eyesDiagRetinalSubDescHistory"],
  ],
  [
    "ros_eyesDiagRetinalSubDescActiveSubTreat",
    [
      "ros_eyesDiagRetinalSubDescActiveSubTreatMedic",
      "ros_eyesDiagRetinalSubDescActiveSubTreatOther",
      "ros_eyesDiagRetinalSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagRetinalSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesDiagOtherSubWhEye",
    [
      "ros_eyesDiagOtherSubWhEyeRight",
      "ros_eyesDiagOtherSubWhEyeLeft",
      "ros_eyesDiagOtherSubWhEyeBoth",
    ],
  ],
  [
    "ros_eyesDiagOtherSubDesc",
    ["ros_eyesDiagOtherSubDescActive", "ros_eyesDiagOtherSubDescHistory"],
  ],
  [
    "ros_eyesDiagOtherSubDescActiveSubTreat",
    [
      "ros_eyesDiagOtherSubDescActiveSubTreatMedic",
      "ros_eyesDiagOtherSubDescActiveSubTreatOther",
      "ros_eyesDiagOtherSubDescActiveSubTreatNoTreat",
      "ros_eyesDiagOtherSubDescActiveSubTreatGlass",
    ],
  ],
  [
    "ros_eyesSymp",
    [
      "ros_eyesSympPain",
      "ros_eyesSympDryEye",
      "ros_eyesSympTear",
      "ros_eyesSympSee",
      "ros_eyesSympFloat",
      "ros_eyesSympOther",
      "ros_eyesSympNone",
    ],
  ],
];

export const ros_flatmap_Ears = [
  ["ros_ears", "ears.isEars", "3"],
  [
    "ros_earsDiagDiffHear",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearing",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActive",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubWhichRight",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.whichEar.right",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubWhichLeft",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.whichEar.left",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubWhichBoth",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.whichEar.both",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubWhichComment",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.whichEar.comment",
    "1",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidNo",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.doesNotWear",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidYes",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.wears",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichRight",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.right",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichLeft",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.left",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichBoth",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.both",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichComment",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.comment",
    "1",
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidComment",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.activeSubAnswer.hearingAids.comment",
    "1",
  ],
  [
    "ros_earsDiagDiffHearSubDescHistory",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_earsDiagDiffHearSubDescComment",
    "ears.isEarsSubAnswer.diagnoses.difficultyHearingSubAnswer.describe.comment",
    "1",
  ],
  ["ros_earsDiagLegalDeaf", "ears.isEarsSubAnswer.diagnoses.legallyDeaf", "2"],
  [
    "ros_earsDiagLegalDeafSubDescActive",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubWhichRight",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.whichEar.right",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubWhichLeft",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.whichEar.left",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubWhichBoth",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.whichEar.both",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubWhichComment",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.whichEar.comment",
    "1",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidNo",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.doesNotWear",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidYes",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.wears",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichRight",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.right",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichLeft",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.left",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichBoth",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.both",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichComment",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.wearsSubAnswer.whichEar.comment",
    "1",
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidComment",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.activeSubAnswer.hearingAids.comment",
    "1",
  ],
  [
    "ros_earsDiagLegalDeafSubDescHistory",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_earsDiagLegalDeafSubDescComment",
    "ears.isEarsSubAnswer.diagnoses.legallyDeafSubAnswer.describe.comment",
    "1",
  ],
  ["ros_earsDiagTinnitus", "ears.isEarsSubAnswer.diagnoses.tinnitus", "2"],
  [
    "ros_earsDiagTinnitusSubDescActive",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympDizz",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.dizziness",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympRoomSpin",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.roomSpinning",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympLose",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.loseBalance",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympNausea",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.nausea",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympEarsRing",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.earsRinging",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympOther",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.other",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympOtherSubDesc",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympNone",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.none",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSympComment",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.symptoms.comment",
    "1",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreatMedic",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreatOther",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreatOtherSubDesc",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreatNo",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreatComment",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_earsDiagTinnitusSubDescHistory",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_earsDiagTinnitusSubDescComment",
    "ears.isEarsSubAnswer.diagnoses.tinnitusSubAnswer.describe.comment",
    "1",
  ],
  ["ros_earsDiagVertigo", "ears.isEarsSubAnswer.diagnoses.vertigo", "2"],
  [
    "ros_earsDiagVertigoSubDescActive",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympDizz",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.dizziness",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympRoomSpin",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.roomSpinning",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympLose",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.loseBalance",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympNausea",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.nausea",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympEarsRing",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.earsRinging",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympOther",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.other",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympOtherSubDesc",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympNone",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.none",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSympComment",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.symptoms.comment",
    "1",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreatMedic",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreatOther",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreatOtherSubDesc",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreatNo",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreatComment",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_earsDiagVertigoSubDescHistory",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_earsDiagVertigoSubDescComment",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_earsDiagVertigoSubLoseBal",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.isLoseYourBalance",
    "3",
  ],
  [
    "ros_earsDiagVertigoSubLoseBalComment",
    "ears.isEarsSubAnswer.diagnoses.vertigoSubAnswer.loseYourBalance.comment",
    "1",
  ],
  ["ros_earsDiagOther", "ears.isEarsSubAnswer.diagnoses.other", "2"],
  [
    "ros_earsDiagOtherSubDescActive",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreatMedic",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreatOther",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreatOtherSubDesc",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreatNo",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreatComment",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_earsDiagOtherSubDescHistory",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_earsDiagOtherSubDescComment",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_earsDiagOtherSubOther",
    "ears.isEarsSubAnswer.diagnoses.otherSubAnswer.other",
    "1",
  ],
  ["ros_earsDiagnosesComment", "ears.isEarsSubAnswer.diagnoses.comment", "1"],
  ["ros_earsComment", "ears.comment", "1"],
];

export const ros_optionsTreeWrappers_Ears = [
  [
    "ros_earsDiagnoses",
    [
      "ros_earsDiagDiffHear",
      "ros_earsDiagLegalDeaf",
      "ros_earsDiagTinnitus",
      "ros_earsDiagVertigo",
      "ros_earsDiagOther",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubDesc",
    ["ros_earsDiagDiffHearSubDescActive", "ros_earsDiagDiffHearSubDescHistory"],
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubWhich",
    [
      "ros_earsDiagDiffHearSubDescActiveSubWhichRight",
      "ros_earsDiagDiffHearSubDescActiveSubWhichLeft",
      "ros_earsDiagDiffHearSubDescActiveSubWhichBoth",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAid",
    [
      "ros_earsDiagDiffHearSubDescActiveSubHearAidNo",
      "ros_earsDiagDiffHearSubDescActiveSubHearAidYes",
    ],
  ],
  [
    "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhich",
    [
      "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichRight",
      "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichLeft",
      "ros_earsDiagDiffHearSubDescActiveSubHearAidSubWhichBoth",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubDesc",
    [
      "ros_earsDiagLegalDeafSubDescActive",
      "ros_earsDiagLegalDeafSubDescHistory",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubWhich",
    [
      "ros_earsDiagLegalDeafSubDescActiveSubWhichRight",
      "ros_earsDiagLegalDeafSubDescActiveSubWhichLeft",
      "ros_earsDiagLegalDeafSubDescActiveSubWhichBoth",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAid",
    [
      "ros_earsDiagLegalDeafSubDescActiveSubHearAidNo",
      "ros_earsDiagLegalDeafSubDescActiveSubHearAidYes",
    ],
  ],
  [
    "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhich",
    [
      "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichRight",
      "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichLeft",
      "ros_earsDiagLegalDeafSubDescActiveSubHearAidSubWhichBoth",
    ],
  ],
  [
    "ros_earsDiagTinnitusSubDesc",
    ["ros_earsDiagTinnitusSubDescActive", "ros_earsDiagTinnitusSubDescHistory"],
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubSymp",
    [
      "ros_earsDiagTinnitusSubDescActiveSubSympDizz",
      "ros_earsDiagTinnitusSubDescActiveSubSympRoomSpin",
      "ros_earsDiagTinnitusSubDescActiveSubSympLose",
      "ros_earsDiagTinnitusSubDescActiveSubSympNausea",
      "ros_earsDiagTinnitusSubDescActiveSubSympEarsRing",
      "ros_earsDiagTinnitusSubDescActiveSubSympOther",
      "ros_earsDiagTinnitusSubDescActiveSubSympNone",
    ],
  ],
  [
    "ros_earsDiagTinnitusSubDescActiveSubTreat",
    [
      "ros_earsDiagTinnitusSubDescActiveSubTreatMedic",
      "ros_earsDiagTinnitusSubDescActiveSubTreatOther",
      "ros_earsDiagTinnitusSubDescActiveSubTreatNo",
    ],
  ],
  [
    "ros_earsDiagVertigoSubDesc",
    ["ros_earsDiagVertigoSubDescActive", "ros_earsDiagVertigoSubDescHistory"],
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubSymp",
    [
      "ros_earsDiagVertigoSubDescActiveSubSympDizz",
      "ros_earsDiagVertigoSubDescActiveSubSympRoomSpin",
      "ros_earsDiagVertigoSubDescActiveSubSympLose",
      "ros_earsDiagVertigoSubDescActiveSubSympNausea",
      "ros_earsDiagVertigoSubDescActiveSubSympEarsRing",
      "ros_earsDiagVertigoSubDescActiveSubSympOther",
      "ros_earsDiagVertigoSubDescActiveSubSympNone",
    ],
  ],
  [
    "ros_earsDiagVertigoSubDescActiveSubTreat",
    [
      "ros_earsDiagVertigoSubDescActiveSubTreatMedic",
      "ros_earsDiagVertigoSubDescActiveSubTreatOther",
      "ros_earsDiagVertigoSubDescActiveSubTreatNo",
    ],
  ],
  [
    "ros_earsDiagOtherSubDesc",
    ["ros_earsDiagOtherSubDescActive", "ros_earsDiagOtherSubDescHistory"],
  ],
  [
    "ros_earsDiagOtherSubDescActiveSubTreat",
    [
      "ros_earsDiagOtherSubDescActiveSubTreatMedic",
      "ros_earsDiagOtherSubDescActiveSubTreatOther",
      "ros_earsDiagOtherSubDescActiveSubTreatNo",
    ],
  ],
];

export const ros_flatmap_Nose = [
  ["ros_nose", "nose.isNose", "3"],
  [
    "ros_noseDiagAllergy",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitis",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescActive",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagAllergySubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagAllergySubDescComment",
    "nose.isNoseSubAnswer.diagnoses.allergicRhinitisSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_noseDiagChronicNasal",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDrip",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActive",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagChronicNasalSubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagChronicNasalSubDescComment",
    "nose.isNoseSubAnswer.diagnoses.chronicNasalDripSubAnswer.describe.comment",
    "1",
  ],
  ["ros_noseDiagNoseBleed", "nose.isNoseSubAnswer.diagnoses.noseBleeds", "2"],
  [
    "ros_noseDiagNoseBleedSubDescActive",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagNoseBleedSubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagNoseBleedSubDescComment",
    "nose.isNoseSubAnswer.diagnoses.noseBleedsSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_noseDiagSeasonAllergy",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergies",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActive",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagSeasonAllergySubDescComment",
    "nose.isNoseSubAnswer.diagnoses.seasonalAllergiesSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_noseDiagSinusInfect",
    "nose.isNoseSubAnswer.diagnoses.sinusInfections",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActive",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagSinusInfectSubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubDescComment",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_noseDiagSinusInfectSubExuClear",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.clear",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubExuPurulent",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.purulent",
    "2",
  ],
  [
    "ros_noseDiagSinusInfectSubExuComment",
    "nose.isNoseSubAnswer.diagnoses.sinusInfectionsSubAnswer.exuDate.comment",
    "1",
  ],
  ["ros_noseDiagSinustis", "nose.isNoseSubAnswer.diagnoses.sinusitis", "2"],
  [
    "ros_noseDiagSinustisSubDescActive",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagSinustisSubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagSinustisSubDescComment",
    "nose.isNoseSubAnswer.diagnoses.sinusitisSubAnswer.describe.comment",
    "1",
  ],
  ["ros_noseDiagOther", "nose.isNoseSubAnswer.diagnoses.other", "2"],
  [
    "ros_noseDiagOtherSubDescActive",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_noseDiagOtherSubDescActiveSubTreatMedic",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_noseDiagOtherSubDescActiveSubTreatOther",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_noseDiagOtherSubDescActiveSubTreatOtherSubDesc",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_noseDiagOtherSubDescActiveSubTreatNo",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_noseDiagOtherSubDescActiveSubTreatComment",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_noseDiagOtherSubDescHistory",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_noseDiagOtherSubDescComment",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_noseDiagOtherSubOther",
    "nose.isNoseSubAnswer.diagnoses.otherSubAnswer.other",
    "1",
  ],
  ["ros_noseDiagComment", "nose.isNoseSubAnswer.diagnoses.comment", "1"],
  ["ros_noseComment", "nose.comment", "1"],
];

export const ros_optionsTreeWrappers_Nose = [
  [
    "ros_noseDiag",
    [
      "ros_noseDiagAllergy",
      "ros_noseDiagChronicNasal",
      "ros_noseDiagNoseBleed",
      "ros_noseDiagSeasonAllergy",
      "ros_noseDiagSinusInfect",
      "ros_noseDiagSinustis",
      "ros_noseDiagOther",
    ],
  ],
  [
    "ros_noseDiagAllergySubDesc",
    ["ros_noseDiagAllergySubDescActive", "ros_noseDiagAllergySubDescHistory"],
  ],
  [
    "ros_noseDiagAllergySubDescActiveSubTreat",
    [
      "ros_noseDiagAllergySubDescActiveSubTreatMedic",
      "ros_noseDiagAllergySubDescActiveSubTreatOther",
      "ros_noseDiagAllergySubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagChronicNasalSubDesc",
    [
      "ros_noseDiagChronicNasalSubDescActive",
      "ros_noseDiagChronicNasalSubDescHistory",
    ],
  ],

  [
    "ros_noseDiagChronicNasalSubDescActiveSubTreat",
    [
      "ros_noseDiagChronicNasalSubDescActiveSubTreatMedic",
      "ros_noseDiagChronicNasalSubDescActiveSubTreatOther",
      "ros_noseDiagChronicNasalSubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagNoseBleedSubDesc",
    [
      "ros_noseDiagNoseBleedSubDescActive",
      "ros_noseDiagNoseBleedSubDescHistory",
    ],
  ],

  [
    "ros_noseDiagNoseBleedSubDescActiveSubTreat",
    [
      "ros_noseDiagNoseBleedSubDescActiveSubTreatMedic",
      "ros_noseDiagNoseBleedSubDescActiveSubTreatOther",
      "ros_noseDiagNoseBleedSubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagSeasonAllergySubDesc",
    [
      "ros_noseDiagSeasonAllergySubDescActive",
      "ros_noseDiagSeasonAllergySubDescHistory",
    ],
  ],

  [
    "ros_noseDiagSeasonAllergySubDescActiveSubTreat",
    [
      "ros_noseDiagSeasonAllergySubDescActiveSubTreatMedic",
      "ros_noseDiagSeasonAllergySubDescActiveSubTreatOther",
      "ros_noseDiagSeasonAllergySubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagSinusInfectSubDesc",
    [
      "ros_noseDiagSinusInfectSubDescActive",
      "ros_noseDiagSinusInfectSubDescHistory",
    ],
  ],

  [
    "ros_noseDiagSinusInfectSubDescActiveSubTreat",
    [
      "ros_noseDiagSinusInfectSubDescActiveSubTreatMedic",
      "ros_noseDiagSinusInfectSubDescActiveSubTreatOther",
      "ros_noseDiagSinusInfectSubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagSinusInfectSubExu",
    [
      "ros_noseDiagSinusInfectSubExuClear",
      "ros_noseDiagSinusInfectSubExuPurulent",
    ],
  ],
  [
    "ros_noseDiagSinustisSubDesc",
    ["ros_noseDiagSinustisSubDescActive", "ros_noseDiagSinustisSubDescHistory"],
  ],
  [
    "ros_noseDiagSinustisSubDescActiveSubTreat",
    [
      "ros_noseDiagSinustisSubDescActiveSubTreatMedic",
      "ros_noseDiagSinustisSubDescActiveSubTreatOther",
      "ros_noseDiagSinustisSubDescActiveSubTreatNo",
    ],
  ],

  [
    "ros_noseDiagOtherSubDesc",
    ["ros_noseDiagOtherSubDescActive", "ros_noseDiagOtherSubDescHistory"],
  ],

  [
    "ros_noseDiagOtherSubDescActiveSubTreat",
    [
      "ros_noseDiagOtherSubDescActiveSubTreatMedic",
      "ros_noseDiagOtherSubDescActiveSubTreatOther",
      "ros_noseDiagOtherSubDescActiveSubTreatNo",
    ],
  ],
];

export const ros_flatmap_Mouth = [
  ["ros_mouth", "mouthAndThroat.isMouthAndThroat", "3"],
  [
    "ros_mouthDiagBleedGums",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGums",
    "2",
  ],
  [
    "ros_mouthDiagBleedGumsSubDescActive",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.descibe.active",
    "2",
  ],
  [
    "ros_mouthDiagBleedGumsSubDescHistory",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.descibe.historyOf",
    "2",
  ],
  [
    "ros_mouthDiagBleedGumsSubDescComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.bleedingGumsSubAnswer.descibe.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffChew",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewing",
    "2",
  ],
  [
    "ros_mouthDiagDiffChewSubDescActive",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_mouthDiagDiffChewSubDescHistory",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_mouthDiagDiffChewSubDescComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPain",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPain",
    "3",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubDentures",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.isDentures",
    "3",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainSubDenturesComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.isecauseOfPainSubAnswer.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffChewSubBcsPainComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultyChewingSubAnswer.becauseOfPain.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffSwallow",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowing",
    "2",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescActive",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescHistory",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_mouthDiagDiffSwallowSubDescComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStroke",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStroke",
    "3",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSubSpclDiet",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.eatSpecialDiet",
    "3",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeSubSpclDietComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.isHadStrokeSubAnswer.comment",
    "1",
  ],
  [
    "ros_mouthDiagDiffSwallowSubHadStrokeComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.difficultySwallowingSubAnswer.hadStroke.comment",
    "1",
  ],
  [
    "ros_mouthDiagOther",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.other",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubDescActive",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubDescHistory",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubDescComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_mouthDiagOtherSubSuppHistory",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppSymptoms",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppPhysFind",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppMedics",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppTestResults",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppImageStudy",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppBiopsy",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppDme",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppOther",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
    "2",
  ],
  [
    "ros_mouthDiagOtherSubSuppOtherSubDesc",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_mouthDiagOtherSubSuppComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
    "1",
  ],
  [
    "ros_mouthDiagOtherSubOther",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.otherSubAnswer.other",
    "1",
  ],
  [
    "ros_mouthDiagComment",
    "mouthAndThroat.isMouthAndThroatSubAnswer.diagnoses.comment",
    "1",
  ],
  ["ros_mouthComment", "mouthAndThroat.comment", "1"],
];

export const ros_optionsTreeWrappers_Mouth = [
  [
    "ros_mouthDiag",
    [
      "ros_mouthDiagBleedGums",
      "ros_mouthDiagDiffChew",
      "ros_mouthDiagDiffSwallow",
      "ros_mouthDiagOther",
    ],
  ],
  [
    "ros_mouthDiagBleedGumsSubDesc",
    [
      "ros_mouthDiagBleedGumsSubDescActive",
      "ros_mouthDiagBleedGumsSubDescHistory",
    ],
  ],
  [
    "ros_mouthDiagDiffChewSubDesc",
    [
      "ros_mouthDiagDiffChewSubDescActive",
      "ros_mouthDiagDiffChewSubDescHistory",
    ],
  ],
  [
    "ros_mouthDiagDiffSwallowSubDesc",
    [
      "ros_mouthDiagDiffSwallowSubDescActive",
      "ros_mouthDiagDiffSwallowSubDescHistory",
    ],
  ],
  [
    "ros_mouthDiagOtherSubDesc",
    ["ros_mouthDiagOtherSubDescActive", "ros_mouthDiagOtherSubDescHistory"],
  ],
  [
    "ros_mouthDiagOtherSubSupp",
    [
      "ros_mouthDiagOtherSubSuppHistory",
      "ros_mouthDiagOtherSubSuppSymptoms",
      "ros_mouthDiagOtherSubSuppPhysFind",
      "ros_mouthDiagOtherSubSuppMedics",
      "ros_mouthDiagOtherSubSuppTestResults",
      "ros_mouthDiagOtherSubSuppImageStudy",
      "ros_mouthDiagOtherSubSuppBiopsy",
      "ros_mouthDiagOtherSubSuppDme",
      "ros_mouthDiagOtherSubSuppOther",
    ],
  ],
];

export const ros_flatmap_Neck = [
  ["ros_neck", "neck.isNeck", "3"],
  [
    "ros_neckDiagCarotid",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosis",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescActive",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreatMedic",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreatOther",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreatOtherSubDesc",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreatNoTreat",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreatComment",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neckDiagCarotidSubDescHistory",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubDescComment",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neckDiagCarotidSubCsDescRight",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.right",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubCsDescLeft",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.left",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubCsDescBilateral",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.bilateral",
    "2",
  ],
  [
    "ros_neckDiagCarotidSubCsDescComment",
    "neck.isNeckSubAnswer.diagnoses.carotidStenosisSubAnswer.csdescribe.comment",
    "1",
  ],
  ["ros_neckDiagParotid", "neck.isNeckSubAnswer.diagnoses.parotidDisease", "2"],
  [
    "ros_neckDiagParotidSubDescActive",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreatMedic",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreatOther",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreatOtherSubDesc",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreatNoTreat",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreatComment",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neckDiagParotidSubDescHistory",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neckDiagParotidSubDescComment",
    "neck.isNeckSubAnswer.diagnoses.parotidDiseaseSubAnswer.describe.comment",
    "1",
  ],
  ["ros_neckDiagOther", "neck.isNeckSubAnswer.diagnoses.other", "2"],
  [
    "ros_neckDiagOtherSubDescActive",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreatMedic",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreatOther",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreatOtherSubDesc",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreatNoTreat",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreatComment",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neckDiagOtherSubDescHistory",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neckDiagOtherSubDescComment",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neckDiagOtherSubOther",
    "neck.isNeckSubAnswer.diagnoses.otherSubAnswer.other",
    "1",
  ],
  ["ros_neckDiagComment", "neck.isNeckSubAnswer.diagnoses.comment", "1"],
  ["ros_neckComment", "neck.comment", "1"],
];

export const ros_optionsTreeWrappers_Neck = [
  [
    "ros_neckDiag",
    ["ros_neckDiagParotid", "ros_neckDiagOther", "ros_neckDiagCarotid"],
  ],
  [
    "ros_neckDiagCarotidSubDesc",
    ["ros_neckDiagCarotidSubDescActive", "ros_neckDiagCarotidSubDescHistory"],
  ],
  [
    "ros_neckDiagCarotidSubDescActiveSubTreat",
    [
      "ros_neckDiagCarotidSubDescActiveSubTreatMedic",
      "ros_neckDiagCarotidSubDescActiveSubTreatOther",
      "ros_neckDiagCarotidSubDescActiveSubTreatNoTreat",
    ],
  ],
  [
    "ros_neckDiagCarotidSubCsDesc",
    [
      "ros_neckDiagCarotidSubCsDescRight",
      "ros_neckDiagCarotidSubCsDescLeft",
      "ros_neckDiagCarotidSubCsDescBilateral",
    ],
  ],
  [
    "ros_neckDiagParotidSubDesc",
    ["ros_neckDiagParotidSubDescHistory", "ros_neckDiagParotidSubDescActive"],
  ],
  [
    "ros_neckDiagParotidSubDescActiveSubTreat",
    [
      "ros_neckDiagParotidSubDescActiveSubTreatMedic",
      "ros_neckDiagParotidSubDescActiveSubTreatOther",
      "ros_neckDiagParotidSubDescActiveSubTreatNoTreat",
    ],
  ],
  [
    "ros_neckDiagOtherSubDescActiveSubTreat",
    [
      "ros_neckDiagOtherSubDescActiveSubTreatMedic",
      "ros_neckDiagOtherSubDescActiveSubTreatOther",
      "ros_neckDiagOtherSubDescActiveSubTreatNoTreat",
    ],
  ],
  [
    "ros_neckDiagOtherSubDesc",
    ["ros_neckDiagOtherSubDescActive", "ros_neckDiagOtherSubDescHistory"],
  ],
];

export const ros_flatmap_eyeENTNeckRecommendations = [
  [
    "ros_eyeENTNeckRecommendationshearingEvaluation",
    "recommendations.recommendationsSubAnswers[0].hearingEvaluation",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationsdentalExam",
    "recommendations.recommendationsSubAnswers[1].dentalExam",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationseyeExam",
    "recommendations.recommendationsSubAnswers[2].eyeExam",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationsswallowingEvaluation",
    "recommendations.recommendationsSubAnswers[3].swallowingEvaluation",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationstakeMedications",
    "recommendations.recommendationsSubAnswers[4].takeMedications",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationscaseManagement",
    "recommendations.recommendationsSubAnswers[5].caseManagement",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationsfollowUpClinician",
    "recommendations.recommendationsSubAnswers[6].followUpClinician",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationsDME",
    "recommendations.recommendationsSubAnswers[7].DME",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationsother",
    "recommendations.recommendationsSubAnswers[8].other",
    "7",
  ],
  [
    "ros_eyeENTNeckRecommendationshearingEvaluationComment",
    "recommendations.recommendationsSubAnswers[0].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationsdentalExamComment",
    "recommendations.recommendationsSubAnswers[1].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationseyeExamComment",
    "recommendations.recommendationsSubAnswers[2].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationsswallowingEvaluationComment",
    "recommendations.recommendationsSubAnswers[3].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationstakeMedicationsComment",
    "recommendations.recommendationsSubAnswers[4].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationscaseManagementComment",
    "recommendations.recommendationsSubAnswers[5].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationsfollowUpClinicianComment",
    "recommendations.recommendationsSubAnswers[6].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationsDMEComment",
    "recommendations.recommendationsSubAnswers[7].comment",
    "1",
  ],
  [
    "ros_eyeENTNeckRecommendationsotherComment",
    "recommendations.recommendationsSubAnswers[8].comment",
    "1",
  ],
];

export const ros_flatmap_BowelMovements = [
  ["ros_bowelNormal", "bowelMovements.normal", "2"],
  ["ros_bowelConsti", "bowelMovements.constipation", "2"],
  [
    "ros_bowelConstiSubIfConstiAcute",
    "bowelMovements.constipationSubAnswer.acute",
    "2",
  ],
  [
    "ros_bowelConstiSubIfConstiChronic",
    "bowelMovements.constipationSubAnswer.chronic",
    "2",
  ],
  [
    "ros_bowelConstiSubIfConstiComment",
    "bowelMovements.constipationSubAnswer.comment",
    "1",
  ],
  ["ros_bowelDiarr", "bowelMovements.diarrhea", "2"],
  [
    "ros_bowelDiarrSubIfDiarrAcute",
    "bowelMovements.diarrheaSubAnswer.isDiarrhea.acute",
    "2",
  ],
  [
    "ros_bowelDiarrSubIfDiarrChronic",
    "bowelMovements.diarrheaSubAnswer.isDiarrhea.chronic",
    "2",
  ],
  [
    "ros_bowelDiarrSubIfDiarrComment",
    "bowelMovements.diarrheaSubAnswer.isDiarrhea.comment",
    "1",
  ],
  [
    "ros_bowelDiarrSubIsHistoryOf",
    "bowelMovements.diarrheaSubAnswer.isHistoryOfCDifficile.isHistoryOfCDifficile",
    "3",
  ],
  [
    "ros_bowelDiarrSubIsHistoryOfComment",
    "bowelMovements.diarrheaSubAnswer.isHistoryOfCDifficile.comment",
    "1",
  ],
  ["ros_bowelInconti", "bowelMovements.bowelIncontinence", "2"],
  ["ros_bowelComment", "bowelMovements.comment", "1"],
];

export const ros_optionsTreeWrappers_BowelMovements = [
  [
    "ros_bowel",
    [
      "ros_bowelNormal",
      "ros_bowelConsti",
      "ros_bowelDiarr",
      "ros_bowelInconti",
    ],
  ],
  [
    "ros_bowelConstiSubIfConsti",
    ["ros_bowelConstiSubIfConstiAcute", "ros_bowelConstiSubIfConstiChronic"],
  ],
  [
    "ros_bowelDiarrSubIfDiarr",
    ["ros_bowelDiarrSubIfDiarrAcute", "ros_bowelDiarrSubIfDiarrChronic"],
  ],
];

export const ros_flatmap_AbdominalOpenings = [
  ["ros_abdominalIleosto", "abdominalOpenings.ileostomy", "2"],
  ["ros_abdominalColosto", "abdominalOpenings.colostomy", "2"],
  ["ros_abdominalUrostom", "abdominalOpenings.urostomy", "2"],
  ["ros_abdominalPeg", "abdominalOpenings.peg", "2"],
  ["ros_abdominalCrytost", "abdominalOpenings.crytostomy", "2"],
  ["ros_abdominalNone", "abdominalOpenings.none", "2"],
  ["ros_abdominalComment", "abdominalOpenings.comment", "1"],
];

export const ros_optionsTreeWrappers_AbdominalOpenings = [
  [
    "ros_abdominal",
    [
      "ros_abdominalIleosto",
      "ros_abdominalColosto",
      "ros_abdominalUrostom",
      "ros_abdominalPeg",
      "ros_abdominalCrytost",
      "ros_abdominalNone",
    ],
  ],
];

export const ros_flatmap_rectalProblems = [
  ["ros_rectal", "rectalProblems.isRectalProblems", "3"],
  [
    "ros_rectalIsrecIsyesfemHemor",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.hemorrhoids",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesfemFissu",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.fissure",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesfemMass",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.mass",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesfemComment",
    "rectalProblems.isRectalProblemsSubAnswer.isYesFemale.comment",
    "1",
  ],
  [
    "ros_rectalIsrecIsyesmalHemor",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.hemorrhoids",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesmalFissu",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.fissure",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesmalMass",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.mass",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesmalBph",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.bph",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesmalProst",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.prostateMass",
    "2",
  ],
  [
    "ros_rectalIsrecIsyesmalComment",
    "rectalProblems.isRectalProblemsSubAnswer.isYesMale.comment",
    "1",
  ],
  ["ros_rectalComment", "rectalProblems.comment", "1"],
];

export const ros_optionsTreeWrappers_rectalProblems = [
  [
    "ros_rectalIsrecIsyesfem",
    [
      "ros_rectalIsrecIsyesfemHemor",
      "ros_rectalIsrecIsyesfemFissu",
      "ros_rectalIsrecIsyesfemMass",
    ],
  ],
  [
    "ros_rectalIsrecIsyesmal",
    [
      "ros_rectalIsrecIsyesmalHemor",
      "ros_rectalIsrecIsyesmalFissu",
      "ros_rectalIsrecIsyesmalMass",
      "ros_rectalIsrecIsyesmalBph",
      "ros_rectalIsrecIsyesmalProst",
    ],
  ],
];

export const ros_flatmap_lastBowel = [
  ["ros_lastBowelToday", "lastBowelMovement.today", "2"],
  ["ros_lastBowelOneto", "lastBowelMovement.oneToThreeDaysAgo", "2"],
  ["ros_lastBowelGreat", "lastBowelMovement.greaterThanThreeDaysAgo", "2"],
  ["ros_lastBowelComment", "lastBowelMovement.comment", "1"],
];

export const ros_optionsTreeWrappers_lastBowel = [
  [
    "ros_lastBowel",
    ["ros_lastBowelToday", "ros_lastBowelOneto", "ros_lastBowelGreat"],
  ],
];

export const ros_flatmap_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrstNotAtAll",
    "neuroPsych.overPastTwoWeeks.littleInterestOrPleasure.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksLittleIntrstSeveralDays",
    "neuroPsych.overPastTwoWeeks.littleInterestOrPleasure.severalDays",
    "2",
  ],
  [
    "ros_over2WeeksLittleIntrstMoreHalfDays",
    "neuroPsych.overPastTwoWeeks.littleInterestOrPleasure.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksLittleIntrstNearlyEveryDay",
    "neuroPsych.overPastTwoWeeks.littleInterestOrPleasure.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksLittleIntrstComment",
    "neuroPsych.overPastTwoWeeks.littleInterestOrPleasure.comment",
    "1",
  ],

  [
    "ros_over2WeeksFeelDepressedNotAtAll",
    "neuroPsych.overPastTwoWeeks.feelingDownDepressed.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksFeelDepressedSeveralDays",
    "neuroPsych.overPastTwoWeeks.feelingDownDepressed.severalDays",
    "2",
  ],
  [
    "ros_over2WeeksFeelDepressedMoreHalfDays",
    "neuroPsych.overPastTwoWeeks.feelingDownDepressed.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksFeelDepressedNearlyEveryDay",
    "neuroPsych.overPastTwoWeeks.feelingDownDepressed.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksFeelDepressedComment",
    "neuroPsych.overPastTwoWeeks.feelingDownDepressed.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2LessThree",
    "neuroPsych.overPastTwoWeeks.phq2score.lessThanThree",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThree",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMore",
    "2",
  ],
  [
    "ros_over2WeeksPhq2Score",
    "neuroPsych.overPastTwoWeeks.phq2score.phq2score",
    "1",
  ],
  [
    "ros_over2WeeksPhq9Score",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.phq9score",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.havingLittleInterest.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingDownDepressed.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleFalling.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingTired.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.poorAppetite.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.feelingBadAboutYourself.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.troubleConcentratingOnThings.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.movingOrSpeaking.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.notAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrl",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.several",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.moreThanHalfTheDays",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDay",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.nearlyEveryDay",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.hurtingYourself.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbNotDiffAtAll",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.notDifficultAtAll",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbSomeDiff",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.someWhatDifficult",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbVeryDiff",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.veryDifficult",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbExtrDiff",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.extremelyDifficult",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbOther",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.other",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbNotApplicable",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.notApplicable",
    "2",
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.difficultProblems.comment",
    "1",
  ],

  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityComment",
    "neuroPsych.overPastTwoWeeks.phq2score.threeOrMoreSubAnswer.depressionScreening.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_over2Weeks = [
  [
    "ros_over2WeeksLittleIntrst",
    [
      "ros_over2WeeksLittleIntrstNotAtAll",
      "ros_over2WeeksLittleIntrstSeveralDays",
      "ros_over2WeeksLittleIntrstMoreHalfDays",
      "ros_over2WeeksLittleIntrstNearlyEveryDay",
    ],
  ],
  [
    "ros_over2WeeksFeelDepressed",
    [
      "ros_over2WeeksFeelDepressedNotAtAll",
      "ros_over2WeeksFeelDepressedSeveralDays",
      "ros_over2WeeksFeelDepressedMoreHalfDays",
      "ros_over2WeeksFeelDepressedNearlyEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq",
    ["ros_over2WeeksPhq2LessThree", "ros_over2WeeksPhq2MoreThree"],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrst",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepress",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFall",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTired",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetite",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBad",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConc",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeak",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourself",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNotAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrl",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubMoreHalfDay",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubNearEveryDay",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverity",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMini",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubMild",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySubModerate",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeverityModerateSevere",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDepSeveritySevere",
    ],
  ],
  [
    "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProb",
    [
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbNotDiffAtAll",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbSomeDiff",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbVeryDiff",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbExtrDiff",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbOther",
      "ros_over2WeeksPhq2MoreThreeSubDepScreenDiffProbNotApplicable",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],

  [
    "ros_neuroPsychSubDiagAlcoholSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeEpisodic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.episodic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeContinuous",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.continuous",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeRemission",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.remission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHist",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.drinkingHistory",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppHospital",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.hospitalizations",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppLabResult",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.labResults",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppDelerium",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.deleriumTremors",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppPsychosis",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.psychosis",
    "2",
  ],

  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSuppComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependenceSubAnswer.supportedBy.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagAlcohol = [
  [
    "ros_neuroPsychSubDiagAlcoholSubDesc",
    [
      "ros_neuroPsychSubDiagAlcoholSubDescActive",
      "ros_neuroPsychSubDiagAlcoholSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagAlcoholSubDescActiveSubTreatNoTreat",
    ],
  ],

  [
    "ros_neuroPsychSubDiagAlcoholSubType",
    [
      "ros_neuroPsychSubDiagAlcoholSubTypeEpisodic",
      "ros_neuroPsychSubDiagAlcoholSubTypeContinuous",
      "ros_neuroPsychSubDiagAlcoholSubTypeRemission",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAlcoholSubSupp",
    [
      "ros_neuroPsychSubDiagAlcoholSubSuppDrinkHist",
      "ros_neuroPsychSubDiagAlcoholSubSuppHospital",
      "ros_neuroPsychSubDiagAlcoholSubSuppLabResult",
      "ros_neuroPsychSubDiagAlcoholSubSuppDelerium",
      "ros_neuroPsychSubDiagAlcoholSubSuppPsychosis",
      "ros_neuroPsychSubDiagAlcoholSubSuppOther",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectProgWeak",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.progressiveWeekness",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectSlurSpeech",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.slurringOfSpeech",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectAbnormalGait",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.abnormalGait",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectDiffSwal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.difficultySwallowing",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectEmg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.emg",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateralSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagAmyotrop = [
  [
    "ros_neuroPsychSubDiagAmyotropSubDesc",
    [
      "ros_neuroPsychSubDiagAmyotropSubDescActive",
      "ros_neuroPsychSubDiagAmyotropSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubTreatNoTreat",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelect",
    [
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectProgWeak",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectSlurSpeech",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectAbnormalGait",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectDiffSwal",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectEmg",
      "ros_neuroPsychSubDiagAmyotropSubDescActiveSubSelectOther",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeGenAnxiety",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.generalizedAnxietyDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypePanicDisorder",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.panicDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOCD",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.obsessiveCompulsiveDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypePTSD",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.postTraumaticStressDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeSocialPhobia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.socialPhobia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxietySubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagAnxiety = [
  [
    "ros_neuroPsychSubDiagAnxietySubDesc",
    [
      "ros_neuroPsychSubDiagAnxietySubDescActive",
      "ros_neuroPsychSubDiagAnxietySubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeGenAnxiety",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypePanicDisorder",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOCD",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypePTSD",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeSocialPhobia",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTypeOther",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagAnxietySubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagAttDefOrder = [
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeInAttend",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.inattentive",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeHyperactive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.hyperactive",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeCombined",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.combined",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeUnspec",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.unspecified",
    "2",
  ],
  // [
  //   "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeOtherSubDesc",
  //   "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.otherSubAnswer.describe",
  //   "1",
  // ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficitSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagAttDefOrder = [
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDesc",
    [
      "ros_neuroPsychSubDiagAttDefOrderSubDescActive",
      "ros_neuroPsychSubDiagAttDefOrderSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeInAttend",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeHyperactive",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeCombined",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeUnspec",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeSocialPhobia",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTypeOther",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagAttDefOrderSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagAutism = [
  [
    "ros_neuroPsychSubDiagAutismSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autismSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagAutism = [
  [
    "ros_neuroPsychSubDiagAutismSubDesc",
    [
      "ros_neuroPsychSubDiagAutismSubDescActive",
      "ros_neuroPsychSubDiagAutismSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagAutismSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagAutismSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeManicDepress",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.type.manicDepression",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeBipolarDepress",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.type.bipolarDepression",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeMixed",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.type.mixed",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.type.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubMoodSwing",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.moodSwings.isMoodSwings",
    "3",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubMoodSwingComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.moodSwings.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorderSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagBipolar = [
  [
    "ros_neuroPsychSubDiagBipolarSubDesc",
    [
      "ros_neuroPsychSubDiagBipolarSubDescActive",
      "ros_neuroPsychSubDiagBipolarSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeManicDepress",
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeBipolarDepress",
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeMixed",
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTypeUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagBipolarSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficits",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysApha",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.aphasia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysApra",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.apraxia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysCran",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.cranialNerveParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLeg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArm",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemi",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysFuncQuad",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.functionalQuadriplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhageSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagCerebHemm = [
  [
    "ros_neuroPsychSubDiagCerebHemmDesc",
    [
      "ros_neuroPsychSubDiagCerebHemmDescActive",
      "ros_neuroPsychSubDiagCerebHemmDescHistory",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelect",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngo",
      "ros_neuroPsychSubDiagCerebHemmSelectNone",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhys",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysApha",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysApra",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysCran",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLeg",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArm",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemi",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysFuncQuad",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysOther",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLeg",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeft",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeft",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeftDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRight",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRightDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArm",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeft",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeft",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeftDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRight",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRightDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemi",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeft",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeft",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRight",
    [
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRightDom",
      "ros_neuroPsychSubDiagCerebHemmSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebHemmTreat",
    [
      "ros_neuroPsychSubDiagCerebHemmTreatMedic",
      "ros_neuroPsychSubDiagCerebHemmTreatOther",
      "ros_neuroPsychSubDiagCerebHemmTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectWeak",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.select.weakness",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectDiffSwallow",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.select.difficultySwallowing",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectMobility",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.select.mobilityIssue",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.select.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsySubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagCerebPalsy = [
  [
    "ros_neuroPsychSubDiagCerebPalsySubDesc",
    [
      "ros_neuroPsychSubDiagCerebPalsySubDescActive",
      "ros_neuroPsychSubDiagCerebPalsySubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelect",
    [
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectWeak",
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectDiffSwallow",
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectMobility",
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubSelectOther",
    ],
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagCerebPalsySubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDiseaseSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagDelusion = [
  [
    "ros_neuroPsychSubDiagDelusionSubDesc",
    [
      "ros_neuroPsychSubDiagDelusionSubDescActive",
      "ros_neuroPsychSubDiagDelusionSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagDelusionSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagDementia = [
  [
    "ros_neuroPsychSubDiagDementiaSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVasc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.vascular",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubTypeStrokes",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.vascularSubAnswer.supportedBy.historyOfStrokes",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubTypeRisk",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.vascularSubAnswer.supportedBy.riskFactors",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.vascularSubAnswer.supportedBy.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeAlz",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.alzheimersDisease",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeEti",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.etiologyUnknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.typeOfDementia.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelectBehave",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.select.behavioralDisturbances",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelectFunc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.select.functionalChanges",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementiaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagDementia = [
  [
    "ros_neuroPsychSubDiagDementiaSubDesc",
    [
      "ros_neuroPsychSubDiagDementiaSubDescActive",
      "ros_neuroPsychSubDiagDementiaSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVasc",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeAlz",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeEti",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubType",
    [
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubTypeStrokes",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTypeVascSubTypeRisk",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelect",
    [
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelectBehave",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubSelectFunc",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagDementiaSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajor",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.major",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubCause",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.cause",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiSingle",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.episodes.single",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiRecurr",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.episodes.recurrent",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.episodes.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevMild",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.mild",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevModerate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.moderate",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevModSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.unspecified",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevSeverePsych",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.severeWithoutPsychotic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevSevereNoPsych",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.severeWithPsychotic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.severity.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrl",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.underControl.isUnderControl",
    "8",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubUnderCtrlComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.underControl.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFree",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.symptomFree.isSymptomFree",
    "8",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSympFreeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.symptomFree.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectIn",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.select.inRemission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectPart",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.select.partialRemission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectFull",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.select.fullRemission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectNa",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.select.NA",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.majorSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeChronic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.chronic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.manic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevMild",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.mild",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevModerate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.moderate",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevModSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.moderatelySevere",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.severe",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.maniacSubAnswer.severity.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspec",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecified",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevMild",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.mild",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevModerate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.moderate",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevModSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.moderatelySevere",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.severe",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.depressionUnspecifiedSubAnswer.severity.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevMild",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.mild",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevModerate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.moderate",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevModSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.moderatelySevere",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevSevere",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.severe",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.otherSubAnswer.severity.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnkown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.typeofDepression.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depressionSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagDepress = [
  [
    "ros_neuroPsychSubDiagDepressDesc",
    [
      "ros_neuroPsychSubDiagDepressDescActive",
      "ros_neuroPsychSubDiagDepressDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubType",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajor",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeChronic",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManic",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspec",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOther",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnkown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpi",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiSingle",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubEpiRecurr",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSev",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevMild",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevModerate",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevModSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevSeverePsych",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubDepSevSevereNoPsych",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelect",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectIn",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectPart",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectFull",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeMajorSubSelectNa",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSev",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevMild",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevModerate",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevModSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeManicSubDepSevUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSev",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevMild",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevModerate",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevModSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeUnspecSubDepSevUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSev",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevMild",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevModerate",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevModSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevSevere",
      "ros_neuroPsychSubDiagDepressDescActiveSubTypeOtherSubDepSevUnknown",
    ],
  ],

  [
    "ros_neuroPsychSubDiagDepressDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagDepressDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagDepressDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagDepressDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeEpisodic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.type.episodic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeContinous",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.type.continuous",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeRemission",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.type.remission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubWhatDrugs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.whatDrugs",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubPsych",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.psychosis.isPsychosis",
    "3",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubPsychComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.psychosis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeEpisodic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.type.episodic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeContinous",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.type.continuous",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeRemission",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.type.remission",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubWhatDrugs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.whatDrugs",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubPsych",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.psychosis.isPsychosis",
    "3",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubPsychComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.psychosis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependenceSubAnswer.describe.historyOfSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagDrugs = [
  [
    "ros_neuroPsychSubDiagDrugsSubDesc",
    [
      "ros_neuroPsychSubDiagDrugsSubDescActive",
      "ros_neuroPsychSubDiagDrugsSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeEpisodic",
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeContinous",
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTypeRemission",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagDrugsSubDescActiveSubTreatNoTreat",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubType",
    [
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeEpisodic",
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeContinous",
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTypeRemission",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreat",
    [
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatMedic",
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatOther",
      "ros_neuroPsychSubDiagDrugsSubDescHistorySubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgiaSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagFibro = [
  [
    "ros_neuroPsychSubDiagFibroSubDesc",
    [
      "ros_neuroPsychSubDiagFibroSubDescActive",
      "ros_neuroPsychSubDiagFibroSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagFibroSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagFibroSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDiseaseSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagGuill = [
  [
    "ros_neuroPsychSubDiagGuillSubDesc",
    [
      "ros_neuroPsychSubDiagGuillSubDescActive",
      "ros_neuroPsychSubDiagGuillSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGuillSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagGuillSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.laterlity.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubCause",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesisSubAnswer.describe.activeSubAnswer.cause",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagHemi = [
  [
    "ros_neuroPsychSubDiagHemiSubDesc",
    [
      "ros_neuroPsychSubDiagHemiSubDescActive",
      "ros_neuroPsychSubDiagHemiSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateral",
    [
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeft",
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRight",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeft",
    [
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeftDom",
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralLeftSubLeftNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRight",
    [
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRightDom",
      "ros_neuroPsychSubDiagHemiSubDescActiveSubLateralRightSubRightNoDom",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelectChorea",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.select.choreaMovement",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelectUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.select.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChoreaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagHunting = [
  [
    "ros_neuroPsychSubDiagHuntingSubDesc",
    [
      "ros_neuroPsychSubDiagHuntingSubDescActive",
      "ros_neuroPsychSubDiagHuntingSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelect",
    [
      "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelectChorea",
      "ros_neuroPsychSubDiagHuntingSubDescActiveSubSelectUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagHuntingSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomniaSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagInsomnia = [
  [
    "ros_neuroPsychSubDiagInsomniaSubDesc",
    [
      "ros_neuroPsychSubDiagInsomniaSubDescActive",
      "ros_neuroPsychSubDiagInsomniaSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagInsomniaSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDescDown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.intellectualDescribe.downsSyndrome",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDescDiffMotor",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.intellectualDescribe.phychomotorRetardation",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDescOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.intellectualDescribe.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDescOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.intellectualDescribe.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.intellectualDescribe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisabilitySubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagIntel = [
  [
    "ros_neuroPsychSubDiagIntelSubDesc",
    [
      "ros_neuroPsychSubDiagIntelSubDescActive",
      "ros_neuroPsychSubDiagIntelSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubDesc",
    [
      "ros_neuroPsychSubDiagIntelSubDescActiveSubDescDown",
      "ros_neuroPsychSubDiagIntelSubDescActiveSubDescDiffMotor",
      "ros_neuroPsychSubDiagIntelSubDescActiveSubDescOther",
    ],
  ],
  [
    "ros_neuroPsychSubDiagIntelSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagIntelSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAuraYes",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.select.withAura",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAuraNo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.select.withoutAura",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAuraComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadachesSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagMigraine = [
  [
    "ros_neuroPsychSubDiagMigraineSubDesc",
    [
      "ros_neuroPsychSubDiagMigraineSubDescActive",
      "ros_neuroPsychSubDiagMigraineSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAura",
    [
      "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAuraYes",
      "ros_neuroPsychSubDiagMigraineSubDescActiveSubSelectAuraNo",
    ],
  ],
  [
    "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagMigraineSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympBowel",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.bowelDysfunction",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympVisual",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.visualDisturbances",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympWeak",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.weakness",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympNumb",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.numbness",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympDecease",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.deceasedCoordination",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.symptoms.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosisSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagSclerosis = [
  [
    "ros_neuroPsychSubDiagSclerosisSubDesc",
    [
      "ros_neuroPsychSubDiagSclerosisSubDescActive",
      "ros_neuroPsychSubDiagSclerosisSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSymp",
    [
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympBowel",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympVisual",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympWeak",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympNumb",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympDecease",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympOther",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubSympNone",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagSclerosisSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakYes",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.progressiveMuscleWeakness.yes",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakNo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.progressiveMuscleWeakness.no",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.progressiveMuscleWeakness.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.progressiveMuscleWeakness.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophySubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagDystrophy = [
  [
    "ros_neuroPsychSubDiagDystrophySubDesc",
    [
      "ros_neuroPsychSubDiagDystrophySubDescActive",
      "ros_neuroPsychSubDiagDystrophySubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeak",
    [
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakYes",
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakNo",
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubProgMuscWeakUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagDystrophySubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDoubleVision",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.doubleVision",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDiffChew",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.difficultyChewing",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDiffSwallow",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.difficultySwallowing",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravisSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagGravis = [
  [
    "ros_neuroPsychSubDiagGravisSubDesc",
    [
      "ros_neuroPsychSubDiagGravisSubDescActive",
      "ros_neuroPsychSubDiagGravisSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubSelect",
    [
      "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDoubleVision",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDiffChew",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectDiffSwallow",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectOther",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubSelectNone",
    ],
  ],
  [
    "ros_neuroPsychSubDiagGravisSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagGravisSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaYes",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.dementia.yes",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaNo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.dementia.no",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaUnknown",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.dementia.unknown",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.dementia.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDiseaseSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagParkinson = [
  [
    "ros_neuroPsychSubDiagParkinsonSubDesc",
    [
      "ros_neuroPsychSubDiagParkinsonSubDescActive",
      "ros_neuroPsychSubDiagParkinsonSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementia",
    [
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaYes",
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaNo",
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubDementiaUnknown",
    ],
  ],
  [
    "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagParkinsonSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathySubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagPeriNeuro = [
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDesc",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubDescActive",
      "ros_neuroPsychSubDiagPeriNeuroSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagPeriNeuroSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndromeSubAnswer.describe.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagRestlessLeg = [
  [
    "ros_neuroPsychSubDiagRestlessLegSubDesc",
    [
      "ros_neuroPsychSubDiagRestlessLegSubDescActive",
      "ros_neuroPsychSubDiagRestlessLegSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagRestlessLegSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActivePsychosis",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.active.psychosis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveParanoia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.active.paranoia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.active.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveUnspec",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.active.unspecified",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.active.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophreniaSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagSchizo = [
  [
    "ros_neuroPsychSubDiagSchizoSubDesc",
    [
      "ros_neuroPsychSubDiagSchizoSubDescActive",
      "ros_neuroPsychSubDiagSchizoSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubActive",
    [
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubActivePsychosis",
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveParanoia",
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveOther",
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubActiveUnspec",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagSchizoSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTypeDisorder",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.type.seizureDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTypeUnspecified",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.type.seizureUnspecified",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizuresSubAnswer.describe.activeSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagSeizure = [
  [
    "ros_neuroPsychSubDiagSeizureSubDesc",
    [
      "ros_neuroPsychSubDiagSeizureSubDescActive",
      "ros_neuroPsychSubDiagSeizureSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubType",
    [
      "ros_neuroPsychSubDiagSeizureSubDescActiveSubTypeDisorder",
      "ros_neuroPsychSubDiagSeizureSubDescActiveSubTypeUnspecified",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreat",
    [
      "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatMedic",
      "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatOther",
      "ros_neuroPsychSubDiagSeizureSubDescActiveSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeParesis",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.paresis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeLossSense",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.lossOfSensation",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeBowel",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.bowel",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeParaplegia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.paraplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeQuadriplegia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.quadriplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeAcs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.anteriorCordsyndrome",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypePcs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.posteriorCordsyndrome",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeCcs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.centralCordsyndrome",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubTypeComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.type.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToFracture",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.fracture",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToDislocate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.dislocation",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToCompLesson",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.compressiveLesion",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecToComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjurySubAnswer.secondaryTo.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagSpinalCord = [
  [
    "ros_neuroPsychSubDiagSpinalCordSubDesc",
    [
      "ros_neuroPsychSubDiagSpinalCordSubDescActive",
      "ros_neuroPsychSubDiagSpinalCordSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubType",
    [
      "ros_neuroPsychSubDiagSpinalCordSubTypeParesis",
      "ros_neuroPsychSubDiagSpinalCordSubTypeLossSense",
      "ros_neuroPsychSubDiagSpinalCordSubTypeBowel",
      "ros_neuroPsychSubDiagSpinalCordSubTypeParaplegia",
      "ros_neuroPsychSubDiagSpinalCordSubTypeQuadriplegia",
      "ros_neuroPsychSubDiagSpinalCordSubTypeAcs",
      "ros_neuroPsychSubDiagSpinalCordSubTypePcs",
      "ros_neuroPsychSubDiagSpinalCordSubTypeCcs",
      "ros_neuroPsychSubDiagSpinalCordSubTypeNone",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSpinalCordSubSecTo",
    [
      "ros_neuroPsychSubDiagSpinalCordSubSecToFracture",
      "ros_neuroPsychSubDiagSpinalCordSubSecToDislocate",
      "ros_neuroPsychSubDiagSpinalCordSubSecToCompLesson",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficits",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysApha",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.aphasia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysApra",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.apraxia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysCran",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.cranialNerveParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLeg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArm",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemi",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysFuncQuad",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.functionalQuadriplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematomaSubAnswer.select.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagSubdural = [
  [
    "ros_neuroPsychSubDiagSubduralDesc",
    [
      "ros_neuroPsychSubDiagSubduralDescActive",
      "ros_neuroPsychSubDiagSubduralDescHistory",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelect",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngo",
      "ros_neuroPsychSubDiagSubduralSelectNone",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhys",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysApha",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysApra",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysCran",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLeg",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArm",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemi",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysFuncQuad",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysOther",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLeg",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeft",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeft",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeftDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRight",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRightDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArm",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeft",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeft",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeftDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRight",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRightDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemi",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeft",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeft",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRight",
    [
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRightDom",
      "ros_neuroPsychSubDiagSubduralSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeDate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.date",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficits",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysApha",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.aphasia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysApra",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.apraxia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysCran",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.cranialNerveParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLeg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArm",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemi",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysFuncQuad",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.functionalQuadriplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.strokeSubAnswer.select.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagStroke = [
  [
    "ros_neuroPsychSubDiagStrokeDesc",
    [
      "ros_neuroPsychSubDiagStrokeDescActive",
      "ros_neuroPsychSubDiagStrokeDescHistory",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelect",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngo",
      "ros_neuroPsychSubDiagStrokeSelectNone",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhys",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysApha",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysApra",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysCran",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLeg",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArm",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemi",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysFuncQuad",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysOther",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLeg",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeft",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeft",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeftDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRight",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRightDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArm",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeft",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeft",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeftDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRight",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRightDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemi",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeft",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeft",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRight",
    [
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRightDom",
      "ros_neuroPsychSubDiagStrokeSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tiaSubAnswer.treatment.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagTia = [
  [
    "ros_neuroPsychSubDiagTiaSubDesc",
    [
      "ros_neuroPsychSubDiagTiaSubDescActive",
      "ros_neuroPsychSubDiagTiaSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTiaSubTreat",
    [
      "ros_neuroPsychSubDiagTiaSubTreatMedic",
      "ros_neuroPsychSubDiagTiaSubTreatOther",
      "ros_neuroPsychSubDiagTiaSubTreatNoTreat",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainDate",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.date",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficits",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysApha",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.aphasia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysApra",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.apraxia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysCran",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.cranialNerveParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLeg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.legParalysisSubAnswer.legParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArm",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.armParalysisSubAnswer.armParalysis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemi",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeft",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeftComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.leftSidedSubAnswer.leftSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRight",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSided",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRightDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.dominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.nonDominant",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRightComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.rightSidedSubAnswer.rightSided.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.hemiparesisSubAnswer.hemiParesis.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysFuncQuad",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.functionalQuadriplegia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.ongoingDeficitsSubAnswers.physicalFindings.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectNone",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.none",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.select.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.medication",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatPt",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.PT",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatDme",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.DME",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.otherTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatOtherSubDescText",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.otherTreatmentSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatNoTreat",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.noTreatment",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreatComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.treatment.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitNormal",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.normal",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitLimp",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.limp",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitWideBased",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.wideBased",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitAbduct",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.abductorLurch",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitParetic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.paretic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitShuffling",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.shuffling",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitAtaxic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.ataxic",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGaitComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjurySubAnswer.gait.comment",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagTraumaBrain = [
  [
    "ros_neuroPsychSubDiagTraumaBrainDesc",
    [
      "ros_neuroPsychSubDiagTraumaBrainDescActive",
      "ros_neuroPsychSubDiagTraumaBrainDescHistory",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelect",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngo",
      "ros_neuroPsychSubDiagTraumaBrainSelectNone",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhys",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysApha",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysApra",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysCran",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLeg",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArm",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemi",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysFuncQuad",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysOther",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLeg",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeft",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeft",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeftDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRight",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRightDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysLegSubLegRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArm",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeft",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeft",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeftDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRight",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRightDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysArmSubArmRightSubRightNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemi",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeft",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRight",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeft",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeftDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiLeftSubLeftNoDom",
    ],
  ],

  [
    "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRight",
    [
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRightDom",
      "ros_neuroPsychSubDiagTraumaBrainSelectOngoSubPhysHemiSubHemiRightSubRightNoDom",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainTreat",
    [
      "ros_neuroPsychSubDiagTraumaBrainTreatMedic",
      "ros_neuroPsychSubDiagTraumaBrainTreatPt",
      "ros_neuroPsychSubDiagTraumaBrainTreatDme",
      "ros_neuroPsychSubDiagTraumaBrainTreatOther",
      "ros_neuroPsychSubDiagTraumaBrainTreatNoTreat",
    ],
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrainGait",
    [
      "ros_neuroPsychSubDiagTraumaBrainGaitNormal",
      "ros_neuroPsychSubDiagTraumaBrainGaitLimp",
      "ros_neuroPsychSubDiagTraumaBrainGaitWideBased",
      "ros_neuroPsychSubDiagTraumaBrainGaitAbduct",
      "ros_neuroPsychSubDiagTraumaBrainGaitParetic",
      "ros_neuroPsychSubDiagTraumaBrainGaitShuffling",
      "ros_neuroPsychSubDiagTraumaBrainGaitAtaxic",
      "ros_neuroPsychSubDiagTraumaBrainGaitOther",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDescActive",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.active",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.historyOf",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubDescComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.describe.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppHistory",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.history",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppSymptoms",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.symptoms",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppPhysFind",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.physicalFindings",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppMedic",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.medications",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppTestResult",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.testResults",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppImageStudy",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.imageStudies",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppBiopsy",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.biopsy",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppDme",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.dme",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppOtherSubDesc",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.otherSubAnswer.describe",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSuppComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.supportedBy.comment",
    "1",
  ],
  [
    "ros_neuroPsychSubDiagOtherSubOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.otherSubAnswer.other",
    "1",
  ],
];

export const ros_optionsTreeWrappers_neuroPsychSubDiagOther = [
  [
    "ros_neuroPsychSubDiagOtherSubDesc",
    [
      "ros_neuroPsychSubDiagOtherSubDescActive",
      "ros_neuroPsychSubDiagOtherSubDescHistory",
    ],
  ],
  [
    "ros_neuroPsychSubDiagOtherSubSupp",
    [
      "ros_neuroPsychSubDiagOtherSubSuppHistory",
      "ros_neuroPsychSubDiagOtherSubSuppSymptoms",
      "ros_neuroPsychSubDiagOtherSubSuppPhysFind",
      "ros_neuroPsychSubDiagOtherSubSuppMedic",
      "ros_neuroPsychSubDiagOtherSubSuppTestResult",
      "ros_neuroPsychSubDiagOtherSubSuppImageStudy",
      "ros_neuroPsychSubDiagOtherSubSuppBiopsy",
      "ros_neuroPsychSubDiagOtherSubSuppDme",
      "ros_neuroPsychSubDiagOtherSubSuppOther",
    ],
  ],
];

export const ros_flatmap_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiagAlcohol",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.alcoholDependence",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAmyotrop",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.amyotrophicLateral",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAnxiety",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.anxiety",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAttDefOrder",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.attentionDeficit",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagAutism",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.autism",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagBipolar",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.bipolarDisorder",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebHemm",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralHemorrhage",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagCerebPalsy",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.cerebralPalsy",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDelusion",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.delusionalDisease",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDementia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.dementia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDepress",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.depression",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDrugs",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.drugDependence",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagFibro",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.fibromyalgia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGuill",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.guillainBarreDisease",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHemi",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.hemiparesis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagHunting",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.huntingtonsChorea",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagInsomnia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.insomnia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagIntel",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.developmentalDisability",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagMigraine",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.migraineHeadaches",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSclerosis",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.multipleSclerosis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagDystrophy",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.muscularDystrophy",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagGravis",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.myastheniaGravis",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagParkinson",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.parkinsonsDisease",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagPeriNeuro",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.peripheralNeuropathy",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagRestlessLeg",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.restlessLegSyndrome",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSchizo",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.schizophrenia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSeizure",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.seizures",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSpinalCord",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.spinalCordInjury",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagStroke",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.stroke",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagSubdural",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.subduralHematoma",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTia",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.tia",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagTraumaBrain",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.traumaticBrainInjury",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagOther",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.other",
    "2",
  ],
  [
    "ros_neuroPsychSubDiagComment",
    "neuroPsych.isNeuroPsychSubAnswer.diagnoses.comment",
    "1",
  ],
  ...ros_flatmap_neuroPsychSubDiagAlcohol,
  ...ros_flatmap_neuroPsychSubDiagAmyotrop,
  ...ros_flatmap_neuroPsychSubDiagAnxiety,
  ...ros_flatmap_neuroPsychSubDiagAttDefOrder,
  ...ros_flatmap_neuroPsychSubDiagAutism,
  ...ros_flatmap_neuroPsychSubDiagBipolar,
  ...ros_flatmap_neuroPsychSubDiagCerebHemm,
  ...ros_flatmap_neuroPsychSubDiagCerebPalsy,
  ...ros_flatmap_neuroPsychSubDiagDelusion,
  ...ros_flatmap_neuroPsychSubDiagDementia,
  ...ros_flatmap_neuroPsychSubDiagDepress,
  ...ros_flatmap_neuroPsychSubDiagDrugs,
  ...ros_flatmap_neuroPsychSubDiagFibro,
  ...ros_flatmap_neuroPsychSubDiagGuill,
  ...ros_flatmap_neuroPsychSubDiagHemi,
  ...ros_flatmap_neuroPsychSubDiagHunting,
  ...ros_flatmap_neuroPsychSubDiagInsomnia,
  ...ros_flatmap_neuroPsychSubDiagIntel,
  ...ros_flatmap_neuroPsychSubDiagMigraine,
  ...ros_flatmap_neuroPsychSubDiagSclerosis,
  ...ros_flatmap_neuroPsychSubDiagDystrophy,
  ...ros_flatmap_neuroPsychSubDiagGravis,
  ...ros_flatmap_neuroPsychSubDiagParkinson,
  ...ros_flatmap_neuroPsychSubDiagPeriNeuro,
  ...ros_flatmap_neuroPsychSubDiagRestlessLeg,
  ...ros_flatmap_neuroPsychSubDiagSchizo,
  ...ros_flatmap_neuroPsychSubDiagSeizure,
  ...ros_flatmap_neuroPsychSubDiagSpinalCord,
  ...ros_flatmap_neuroPsychSubDiagStroke,
  ...ros_flatmap_neuroPsychSubDiagSubdural,
  ...ros_flatmap_neuroPsychSubDiagTia,
  ...ros_flatmap_neuroPsychSubDiagTraumaBrain,
  ...ros_flatmap_neuroPsychSubDiagOther,
];

export const ros_optionsTreeWrappers_neuroPsychSubDiag = [
  [
    "ros_neuroPsychSubDiag",
    [
      "ros_neuroPsychSubDiagAlcohol",
      "ros_neuroPsychSubDiagAmyotrop",
      "ros_neuroPsychSubDiagAnxiety",
      "ros_neuroPsychSubDiagAttDefOrder",
      "ros_neuroPsychSubDiagAutism",
      "ros_neuroPsychSubDiagBipolar",
      "ros_neuroPsychSubDiagCerebHemm",
      "ros_neuroPsychSubDiagCerebPalsy",
      "ros_neuroPsychSubDiagDelusion",
      "ros_neuroPsychSubDiagDementia",
      "ros_neuroPsychSubDiagDepress",
      "ros_neuroPsychSubDiagDrugs",
      "ros_neuroPsychSubDiagFibro",
      "ros_neuroPsychSubDiagGuill",
      "ros_neuroPsychSubDiagHemi",
      "ros_neuroPsychSubDiagHunting",
      "ros_neuroPsychSubDiagInsomnia",
      "ros_neuroPsychSubDiagIntel",
      "ros_neuroPsychSubDiagMigraine",
      "ros_neuroPsychSubDiagSclerosis",
      "ros_neuroPsychSubDiagDystrophy",
      "ros_neuroPsychSubDiagGravis",
      "ros_neuroPsychSubDiagParkinson",
      "ros_neuroPsychSubDiagPeriNeuro",
      "ros_neuroPsychSubDiagRestlessLeg",
      "ros_neuroPsychSubDiagSchizo",
      "ros_neuroPsychSubDiagSeizure",
      "ros_neuroPsychSubDiagSpinalCord",
      "ros_neuroPsychSubDiagStroke",
      "ros_neuroPsychSubDiagSubdural",
      "ros_neuroPsychSubDiagTia",
      "ros_neuroPsychSubDiagTraumaBrain",
      "ros_neuroPsychSubDiagOther",
    ],
  ],
  ...ros_optionsTreeWrappers_neuroPsychSubDiagAlcohol,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagAmyotrop,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagAnxiety,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagAttDefOrder,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagAutism,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagBipolar,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagCerebHemm,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagCerebPalsy,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagDelusion,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagDementia,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagDepress,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagDrugs,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagFibro,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagGuill,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagHemi,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagHunting,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagInsomnia,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagIntel,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagMigraine,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagSclerosis,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagDystrophy,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagGravis,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagParkinson,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagPeriNeuro,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagRestlessLeg,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagSchizo,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagSeizure,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagSpinalCord,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagStroke,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagSubdural,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagTia,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagTraumaBrain,
  ...ros_optionsTreeWrappers_neuroPsychSubDiagOther,
];

export const ros_flatmap_neuroPsych = [
  ["ros_neuroPsych", "neuroPsych.isNeuroPsych", "3"],
  ["ros_neuroPsychComment", "neuroPsych.comment", "1"],
  ["ros_nervousAnxious", "neuroPsych.nervousFeel.isNervousFeel", "3"],
  ["ros_nervousAnxiousComment", "neuroPsych.nervousFeel.comment", "1"],
  ["ros_worryMuch", "neuroPsych.worryTooMuch.isWorryTooMuch", "3"],
  ["ros_worryMuchComment", "neuroPsych.worryTooMuch.comment", "1"],
  ["ros_feelAfraid", "neuroPsych.feelAfraid.isFeelAfraid", "3"],
  ["ros_feelAfraidComment", "neuroPsych.feelAfraid.comment", "1"],
  ...ros_flatmap_neuroPsychSubDiag,
];

export const ros_optionsTreeWrappers_neuroPsych = [
  ...ros_optionsTreeWrappers_neuroPsychSubDiag,
];

export const ros_flatmap_neuroPsychRecommendations = [
  [
    "ros_neuroRecommendationstakeMedications",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[0].takeMedications",
    "7",
  ],
  [
    "ros_neuroRecommendationscaseManagement",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[1].caseManagement",
    "7",
  ],
  [
    "ros_neuroRecommendationsfollowUpClinician",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[2].followUpClinician",
    "7",
  ],
  [
    "ros_neuroRecommendationsDME",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[3].DME",
    "7",
  ],
  [
    "ros_neuroRecommendationsother",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[4].other",
    "7",
  ],

  [
    "ros_neuroRecommendationstakeMedicationsComment",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[0].comment",
    "1",
  ],
  [
    "ros_neuroRecommendationscaseManagementComment",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[1].comment",
    "1",
  ],
  [
    "ros_neuroRecommendationsfollowUpClinicianComment",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[2].comment",
    "1",
  ],
  [
    "ros_neuroRecommendationsDMEComment",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[3].comment",
    "1",
  ],
  [
    "ros_neuroRecommendationsotherComment",
    "recommendationsNeuroPsych.recommendationsNeuroPsychSubAnswers[4].comment",
    "1",
  ],
];

export const ros_flatmap = [
  ...ros_flatmap_Eyes,
  ...ros_flatmap_Ears,
  ...ros_flatmap_Nose,
  ...ros_flatmap_Mouth,
  ...ros_flatmap_Neck,
  ...ros_flatmap_eyeENTNeckRecommendations,
  ...ros_flatmap_BowelMovements,
  ...ros_flatmap_AbdominalOpenings,
  ...ros_flatmap_rectalProblems,
  ...ros_flatmap_lastBowel,
  ...ros_flatmap_over2Weeks,
  ...ros_flatmap_neuroPsych,
  ...ros_flatmap_neuroPsychRecommendations,
];

export const ros_optionsTreeWrappers = [
  ...ros_optionsTreeWrappers_Eyes,
  ...ros_optionsTreeWrappers_Ears,
  ...ros_optionsTreeWrappers_Nose,
  ...ros_optionsTreeWrappers_Mouth,
  ...ros_optionsTreeWrappers_Neck,
  ...ros_optionsTreeWrappers_BowelMovements,
  ...ros_optionsTreeWrappers_AbdominalOpenings,
  ...ros_optionsTreeWrappers_rectalProblems,
  ...ros_optionsTreeWrappers_lastBowel,
  ...ros_optionsTreeWrappers_over2Weeks,
  ...ros_optionsTreeWrappers_neuroPsych,
];

export const getMashedData_ros_3 = (apiData) => {
  let optionsTreeDataMapper = getOptionsMapper();
  ros_flatmap.forEach((node) => {
    const [quesUIVariable, quesJsonPath, quesType] = node;
    if (quesType === "2") {
      let optionKeyPair = quesJsonPath.split(".").pop();
      optionsTreeDataMapper = {
        ...optionsTreeDataMapper,
        [quesUIVariable]: optionKeyPair,
      };
    }
  });
  optionsTreeDataMapper = {
    ...optionsTreeDataMapper,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrl: "several",
  };
  let nestedData = {};
  let flattenData = {};
  let miscData = {};

  ros_flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[2] || "1";
    const value = getObjectData(apiData, questionPair[1]);

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoDontKnow(value);
    } else if (quesType === "7") {
      miscData[quesUIVariable] = value;
    } else if (quesType === "8") {
      flattenData[quesUIVariable] = getOptionsSelectedYesNoWithThird(
        value,
        "na",
      );
    } else {
      miscData[quesUIVariable] = value;
    }
  });

  ros_optionsTreeWrappers.forEach((pointerLabels) => {
    flattenData[pointerLabels[0]] =
      getOptionsSelected(nestedData, pointerLabels[1], optionsTreeDataMapper) ||
      [];
  });

  const ros_eyeENTNeckRecommendations = getSelectedCheckboxes(
    [
      miscData["ros_eyeENTNeckRecommendationshearingEvaluation"],
      miscData["ros_eyeENTNeckRecommendationsdentalExam"],
      miscData["ros_eyeENTNeckRecommendationseyeExam"],
      miscData["ros_eyeENTNeckRecommendationsswallowingEvaluation"],
      miscData["ros_eyeENTNeckRecommendationstakeMedications"],
      miscData["ros_eyeENTNeckRecommendationscaseManagement"],
      miscData["ros_eyeENTNeckRecommendationsfollowUpClinician"],
      miscData["ros_eyeENTNeckRecommendationsDME"],
      miscData["ros_eyeENTNeckRecommendationsother"],
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );

  const ros_neuroRecommendations = getSelectedCheckboxes(
    [
      miscData["ros_neuroRecommendationstakeMedications"],
      miscData["ros_neuroRecommendationscaseManagement"],
      miscData["ros_neuroRecommendationsfollowUpClinician"],
      miscData["ros_neuroRecommendationsDME"],
      miscData["ros_neuroRecommendationsother"],
    ],
    ["takeMedications", "caseManagement", "followUpClinician", "DME", "other"],
  );

  flattenData = {
    ...flattenData,
    ros_eyeENTNeckRecommendations,
    ros_neuroRecommendations,
  };

  return flattenData;
};

export const setMashedData_ros_3 = (formData) => {
  let optionsTreeDataMapper = getOptionsMapper();

  let flattenData = {};
  let nestedData = {};
  let payloadData = {};
  let miscData = {};

  ros_flatmap.forEach((questionPair) => {
    const quesUIVariable = questionPair?.[0];
    const quesType = questionPair?.[2] || "1";
    const value = formData[quesUIVariable] || "";

    if (quesType === "1") {
      flattenData[quesUIVariable] = value;
    } else if (quesType === "2") {
      nestedData[quesUIVariable] = value;
    } else if (quesType === "3") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNo(value);
    } else if (quesType === "4") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoUnknown(value);
    } else if (quesType === "5") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoPartially(value);
    } else if (quesType === "6") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoDontKnow(value);
    } else if (quesType === "7") {
      miscData[quesUIVariable] = value;
    } else if (quesType === "8") {
      flattenData[quesUIVariable] = setOptionsSelectedYesNoWithThird(
        value,
        "na",
      );
    } else {
      miscData[quesUIVariable] = value;
    }
  });
  optionsTreeDataMapper = {
    ...optionsTreeDataMapper,
    ros_over2WeeksPhq2MoreThreeSubDepScreenHaveLittleIntrstSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelDepressSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenTrouFallSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelTiredSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenPoorAppetiteSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenFeelBadSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenTroubleConcSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenMoveOrSpeakSubSvrl: "several",
    ros_over2WeeksPhq2MoreThreeSubDepScreenHurtYourselfSubSvrl: "several",
  };

  ros_optionsTreeWrappers.forEach((pointerLabels) => {
    flattenData = {
      ...flattenData,
      ...setOptionsSelected(
        formData[pointerLabels[0]],
        pointerLabels[1],
        optionsTreeDataMapper,
      ),
    };
  });

  const ros_eyeENTNeckRecommendSelected =
    formData["ros_eyeENTNeckRecommendations"];
  const ros_eyeENTNeckRecommendations = setSelectedCheckboxes(
    ros_eyeENTNeckRecommendSelected,
    [
      "ros_eyeENTNeckRecommendationshearingEvaluation",
      "ros_eyeENTNeckRecommendationsdentalExam",
      "ros_eyeENTNeckRecommendationseyeExam",
      "ros_eyeENTNeckRecommendationsswallowingEvaluation",
      "ros_eyeENTNeckRecommendationstakeMedications",
      "ros_eyeENTNeckRecommendationscaseManagement",
      "ros_eyeENTNeckRecommendationsfollowUpClinician",
      "ros_eyeENTNeckRecommendationsDME",
      "ros_eyeENTNeckRecommendationsother",
    ],
    [
      "hearingEvaluation",
      "dentalExam",
      "eyeExam",
      "swallowingEvaluation",
      "takeMedications",
      "caseManagement",
      "followUpClinician",
      "DME",
      "other",
    ],
  );

  const ros_neuroRecommendSelected = formData["ros_neuroRecommendations"];
  const ros_neuroRecommendations = setSelectedCheckboxes(
    ros_neuroRecommendSelected,
    [
      "ros_neuroRecommendationstakeMedications",
      "ros_neuroRecommendationscaseManagement",
      "ros_neuroRecommendationsfollowUpClinician",
      "ros_neuroRecommendationsDME",
      "ros_neuroRecommendationsother",
    ],
    ["takeMedications", "caseManagement", "followUpClinician", "DME", "other"],
  );

  flattenData = {
    ...flattenData,
    ...ros_eyeENTNeckRecommendations,
    ...ros_neuroRecommendations,
  };

  ros_flatmap.forEach((questionPair) => {
    //if (tempData[questionPair[0]] !== "") {
    setObjectData(payloadData, questionPair[1], flattenData[questionPair[0]]);
    //}
  });

  return payloadData;
};

export const getOptionsMapper = () => {
  let treeMappers = {};
  ros_flatmap.forEach((node) => {
    const [quesUIVariable, quesJsonPath, quesType] = node;
    if (quesType === "2") {
      let optionKeyPair = quesJsonPath.split(".").pop();
      treeMappers = {
        ...treeMappers,
        [quesUIVariable]: optionKeyPair,
      };
    }
  });

  return treeMappers;
};
