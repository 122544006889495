import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Alert,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  useTheme,
  useMediaQuery,
  Stack,
  Card,
  CardContent,
  IconButton,
  Divider,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CommentIcon from "@mui/icons-material/Comment";
const styleConstants = {
  primary: "#52A1DB",
  secondary: "#0066B2",
  darkBlue: "#004d86",
  borderRadius: "4px",
};

// const familyMemberOptions = [
//   "Please select",
//   "Mother",
//   "Father",
//   "Sister",
//   "Brother",
//   "Grandmother (Maternal)",
//   "Grandmother (Paternal)",
//   "Grandfather (Maternal)",
//   "Grandfather (Paternal)",
//   "Aunt",
//   "Uncle",
//   "Other",
// ];
const familyMemberOptions = [
  "Please select",
  "Father",
  "Mother",
  "Sibling1",
  "Sibling2",
  "Sibling3",
  "Other",
];

const serviceButtonSx = {
  textTransform: "none",
  borderRadius: styleConstants.borderRadius,
  border: `1px solid ${styleConstants.secondary}`,
  color: styleConstants.secondary,
  "&.MuiButton-contained": {
    backgroundColor: styleConstants.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: styleConstants.darkBlue,
    },
  },
  "&.MuiButton-outlined": {
    "&:hover": {
      backgroundColor: "rgba(0, 102, 178, 0.04)",
    },
  },
};

const textFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: styleConstants.borderRadius,
    "& fieldset": {
      borderColor: styleConstants.primary,
    },
    "&:hover fieldset": {
      borderColor: "#4190c4",
    },
    "&.Mui-focused fieldset": {
      borderColor: styleConstants.primary,
    },
  },
};

const radioSx = {
  "&.MuiFormControlLabel-root": {
    margin: 0,
    "&.Mui-disabled": {
      color: "text.primary",
    },
  },
  "& .MuiRadio-root": {
    color: styleConstants.primary,
    "&.Mui-checked": {
      color: styleConstants.primary,
    },
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
    },
  },
};

const tableHeaderSx = {
  backgroundColor: styleConstants.primary,
  color: "white",
  fontWeight: "bold",
};

// Add this constant for the common TextField styling
const commentTextFieldSx = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    padding: "0px !important",
    "& fieldset": {
      borderColor: "#0066B2",
    },
  },
  "& textarea": {
    height: "100% !important",
    minHeight: "60px !important",
    lineHeight: "1.5",
    color: "black",
  },
  "& .Mui-disabled": {
    WebkitTextFillColor: "black !important",
    color: "black !important",
  },
};

const FamilyHis = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.auth.user);
  const [commentVisibility, setCommentVisibility] = useState(() => {
    const savedState = localStorage.getItem("familyHistoryCommentVisibility");
    return savedState
      ? JSON.parse(savedState)
      : {
          familyHistory: false,
        };
  });
  // Default state structure
  const defaultFormData = {
    familyHistory: {
      isFamilyHistory: "",
      familyHistorySubAnswers: [],
      comment: "",
    },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [editingIndex, setEditingIndex] = useState(-1);
  const [changedFields, setChangedFields] = useState([]);

  useEffect(() => {
    console.log("Incoming data:", data);
    if (data?.data) {
      // If data exists but is empty array, use default state
      if (Array.isArray(data.data) && data.data.length === 0) {
        console.log("Setting default state - empty array");
        setFormData(defaultFormData);
      } else {
        console.log("Setting data from props:", data.data);
        setFormData(data.data);
      }
    } else {
      console.log("No data property - using default state");
      setFormData(defaultFormData);
    }
  }, [data]);

  // Add new useEffect to fetch latest data
  useEffect(() => {
    const fetchLatestFamilyHistory = async () => {
      try {
        const token = localStorage.getItem("token");
        const yearToUse = user.year || new Date().getFullYear();

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-family-history`,
          {
            params: {
              memberId: user.memberId,
              year: yearToUse,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.data?.data) {
          const processedData = {
            ...defaultFormData,
            ...response.data.data,
            familyHistory: {
              ...defaultFormData.familyHistory,
              ...response.data.data.familyHistory,
              familyHistorySubAnswers: [
                ...(response.data.data.familyHistory?.familyHistorySubAnswers ||
                  []),
              ],
            },
          };
          setFormData(processedData);
          console.log("Fresh family history data loaded:", processedData);
        }
      } catch (error) {
        console.error("Error fetching latest family history:", error);
      }
    };

    fetchLatestFamilyHistory();
  }, []); // Run once when component mounts

  const handleFamilyHistoryChange = (event) => {
    if (!isEditing) return;

    // If clicking the same value that's already selected, clear the selection
    const newValue =
      formData.familyHistory.isFamilyHistory === event.target.value
        ? ""
        : event.target.value;

    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        isFamilyHistory: newValue,
        familyHistorySubAnswers:
          newValue === "2" ? [] : prev.familyHistory.familyHistorySubAnswers,
      },
    }));
  };

  const handleCommentChange = (e) => {
    if (!isEditing) return;
    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        comment: e.target.value,
      },
    }));
  };
  const handleCommentToggle = (key) => {
    // if (!isEditing) return;
    setCommentVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    localStorage.setItem(
      "familyHistoryCommentVisibility",
      JSON.stringify(commentVisibility),
    );
  }, [commentVisibility]);

  // Modify addNewRow to add an empty row to the table
  const addNewRow = () => {
    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        familyHistorySubAnswers: [
          ...prev.familyHistory.familyHistorySubAnswers,
          {
            familyMember: "",
            medicalCondition: "",
            causeofDeath: "",
          },
        ],
      },
    }));
  };

  const deleteRow = (index) => {
    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        familyHistorySubAnswers:
          prev.familyHistory.familyHistorySubAnswers.filter(
            (_, i) => i !== index,
          ),
      },
    }));
  };

  // Add validation before save
  const validateForm = () => {
    const subAnswers = formData.familyHistory.familyHistorySubAnswers || [];
    return subAnswers.every((row) => row.familyMember && row.medicalCondition);
  };

  // Update handleSubmit to include validation
  const handleSubmit = async () => {
    if (!validateForm()) {
      setMessage(
        "Please fill in all required fields (Family Member and Medical Condition)",
      );
      return;
    }

    const yearToUse = (function () {
      if (!user.year) return new Date().getFullYear();
      const validYears = [2023, 2024];
      const yearStr = String(user.year).trim();
      const yearNum = Number(yearStr);
      return validYears.includes(yearNum) ||
        validYears.map(String).includes(yearStr)
        ? yearNum
        : new Date().getFullYear();
    })();

    try {
      // Store current data for comparison
      const previousData = { ...formData };

      const payload = {
        memberId: user.memberId,
        year: yearToUse,
        name: "FamilyHistory",
        data: formData,
      };

      const token = localStorage.getItem("token");

      // PUT request to update family history
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-family-history`,
        payload,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        },
      );

      setMessage("Family history updated successfully!");
      setIsEditing(false);

      // GET request to fetch updated family history
      const getResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-family-history`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (getResponse.data?.data) {
        const newData = getResponse.data.data;

        // Track changed fields
        const changes = [];
        Object.keys(newData.familyHistory).forEach((key) => {
          if (
            JSON.stringify(newData.familyHistory[key]) !==
            JSON.stringify(previousData.familyHistory[key])
          ) {
            changes.push({
              field: key,
              previousValue: previousData.familyHistory[key],
              newValue: newData.familyHistory[key],
            });
          }
        });
        setChangedFields(changes);

        // Update form with new data
        const processedData = {
          ...defaultFormData,
          ...newData,
          familyHistory: {
            ...defaultFormData.familyHistory,
            ...newData.familyHistory,
            familyHistorySubAnswers: [
              ...(newData.familyHistory?.familyHistorySubAnswers || []),
            ],
          },
        };
        setFormData(processedData);
        console.log("Form updated with GET response data:", processedData);
        setCommentVisibility((prevState) => ({
          ...prevState,
          familyHistory: !!processedData?.familyHistory?.comment?.trim(), // Open if comment exists, close if empty
        }));
      }
    } catch (error) {
      console.error("Error updating family history:", error);
      setMessage("Failed to update family history. Please try again.");
    }
  };

  // Add this to monitor formData changes
  useEffect(() => {
    console.log("Current formData:", formData);
  }, [formData]);

  const handleCancel = () => {
    if (data?.data) {
      // If data exists and has familyHistory property, use it
      if (data.data.familyHistory) {
        setFormData({
          ...defaultFormData,
          ...data.data,
          familyHistory: {
            ...defaultFormData.familyHistory,
            ...data.data.familyHistory,
            familyHistorySubAnswers: [
              ...(data.data.familyHistory?.familyHistorySubAnswers || []),
            ],
          },
        });
      } else {
        // If no familyHistory in data, use default state
        setFormData(defaultFormData);
      }
    } else {
      setFormData(defaultFormData);
    }
    setIsEditing(false);
    setMessage("");
    setChangedFields([]);
  };

  // Update mobile render with error handling
  const renderMobileRecords = () => (
    <Stack spacing={2}>
      {formData.familyHistory.familyHistorySubAnswers?.map((record, index) => (
        <Card
          key={index}
          sx={{
            border: "1px solid #0066B2",
            borderRadius: "4px",
            backgroundColor: "white",
          }}
        >
          <CardContent>
            <Stack spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {record.familyMember}
                </Typography>
                {isEditing && (
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => deleteRow(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
              <Divider />
              <Typography variant="body2">
                <strong>Medical Condition:</strong> {record.medicalCondition}
              </Typography>
              {record.causeofDeath && (
                <Typography variant="body2">
                  <strong>Cause of Death:</strong> {record.causeofDeath}
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );

  // Update desktop render with similar table structure
  const renderDesktopView = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: "30%",
                fontWeight: "bold",
                color: "#000000",
                fontSize: "1.1rem",
              }}
            >
              Family Member
            </TableCell>
            <TableCell
              sx={{
                width: "35%",
                fontWeight: "bold",
                color: "#000000",
                fontSize: "1.1rem",
              }}
            >
              Medical Condition
            </TableCell>
            <TableCell
              sx={{
                width: "35%",
                fontWeight: "bold",
                color: "#000000",
                fontSize: "1.1rem",
              }}
            >
              Cause of Death
            </TableCell>
            {isEditing && <TableCell width="10%"></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {formData.familyHistory.familyHistorySubAnswers?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  select
                  fullWidth
                  value={row.familyMember || ""}
                  onChange={(e) =>
                    handleEditRow(index, "familyMember", e.target.value)
                  }
                  size="small"
                  disabled={!isEditing}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "transparent",
                      padding: "0px !important",
                    },
                    "& .MuiSelect-select": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                    "& .Mui-disabled": {
                      "-webkit-text-fill-color": "#000000 !important",
                      color: "#000000 !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      minHeight: "60px",
                      height: "60px",
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    Please select
                  </MenuItem>
                  {familyMemberOptions.slice(1).map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.medicalCondition || ""}
                  onChange={(e) =>
                    handleEditRow(index, "medicalCondition", e.target.value)
                  }
                  size="small"
                  disabled={!isEditing}
                  multiline
                  placeholder="Enter condition"
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      "&:hover, &:focus": {
                        overflow: "visible",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    },
                    "& .Mui-disabled": {
                      "-webkit-text-fill-color": "#000000 !important",
                      color: "#000000 !important",
                    },
                  }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.causeofDeath || ""}
                  onChange={(e) =>
                    handleEditRow(index, "causeofDeath", e.target.value)
                  }
                  size="small"
                  disabled={!isEditing}
                  multiline
                  placeholder="Enter if applicable"
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiInputBase-input": {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      "&:hover, &:focus": {
                        overflow: "visible",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    },
                    "& .Mui-disabled": {
                      "-webkit-text-fill-color": "#000000 !important",
                      color: "#000000 !important",
                    },
                  }}
                />
              </TableCell>
              {isEditing && (
                <TableCell align="center">
                  <IconButton
                    onClick={() => deleteRow(index)}
                    size="small"
                    sx={{ color: "#52A1DB" }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Update the Add Family Member button to use addNewRow directly
  const renderAddFamilyMemberButton = () => (
    <Button
      startIcon={<AddIcon />}
      onClick={addNewRow}
      disabled={!isEditing}
      sx={{
        mt: 2,
        color: "#52A1DB",
        "&:hover": {
          backgroundColor: "rgba(82, 161, 219, 0.04)",
        },
      }}
    >
      Add Family Member
    </Button>
  );

  // Add new handler function for editing rows
  const handleEditRow = (index, field, value) => {
    setFormData((prev) => ({
      ...prev,
      familyHistory: {
        ...prev.familyHistory,
        familyHistorySubAnswers: prev.familyHistory.familyHistorySubAnswers.map(
          (row, i) => (i === index ? { ...row, [field]: value } : row),
        ),
      },
    }));
  };

  // Update the comment rendering section
  const renderCommentField = () => {
    return (
      commentVisibility.familyHistory && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            border: "1px solid #0066B2",
            borderRadius: "4px",
            p: 2,
            mt: 2,
          }}
        >
          <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
            Comments:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={1}
            value={formData.familyHistory.comment || ""}
            onChange={handleCommentChange}
            disabled={!isEditing}
            sx={commentTextFieldSx}
          />
        </Box>
      )
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
      {message && (
        <Alert
          severity={message.includes("successfully") ? "success" : "error"}
          sx={{ mb: 3 }}
          onClose={() => setMessage("")}
        >
          {message}
        </Alert>
      )}

      {changedFields.length > 0 && (
        <Alert
          severity="info"
          sx={{
            mb: 3,
            "& .MuiAlert-message": {
              width: "100%",
            },
          }}
          onClose={() => setChangedFields([])}
        >
          <Typography variant="subtitle1" gutterBottom>
            Updated Fields:
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
            <Table size="small">
              <TableBody>
                {changedFields.map((change, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                      {change.field}
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Typography color="error" variant="body2">
                          Previous:{" "}
                          {JSON.stringify(change.previousValue, null, 2)}
                        </Typography>
                        <Typography color="success.main" variant="body2">
                          New: {JSON.stringify(change.newValue, null, 2)}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Alert>
      )}

      <Paper
        sx={{
          mt: 3,
          p: 3,
          border: `1px solid ${styleConstants.secondary}`,
          borderRadius: styleConstants.borderRadius,
          backgroundColor: "white",
        }}
      >
        <Stack spacing={3}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Typography variant="h6" gutterBottom align="left">
                Family History
              </Typography> */}
              <IconButton
                onClick={() => handleCommentToggle("familyHistory")}
                sx={{ color: "#0066B2" }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>

            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <RadioGroup
                value={formData.familyHistory.isFamilyHistory}
                onChange={handleFamilyHistoryChange}
                sx={{ alignItems: "flex-start" }}
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      onClick={(e) => {
                        if (formData.familyHistory.isFamilyHistory === "1") {
                          e.preventDefault();
                          handleFamilyHistoryChange({ target: { value: "" } });
                        }
                      }}
                    />
                  }
                  label="Yes"
                  disabled={!isEditing}
                  sx={radioSx}
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      onClick={(e) => {
                        if (formData.familyHistory.isFamilyHistory === "2") {
                          e.preventDefault();
                          handleFamilyHistoryChange({ target: { value: "" } });
                        }
                      }}
                    />
                  }
                  label="No"
                  disabled={!isEditing}
                  sx={radioSx}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {formData.familyHistory.isFamilyHistory === "1" && (
            <Box>
              {/* <Typography variant="h6" gutterBottom align="left">Family Medical Records</Typography> */}
              {isMobile ? (
                <>{renderMobileRecords()}</>
              ) : (
                <>
                  {renderDesktopView()}
                  {isEditing && renderAddFamilyMemberButton()}
                </>
              )}
            </Box>
          )}

          <Box>{renderCommentField()}</Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
                fullWidth={isMobile}
                sx={{ maxWidth: isMobile ? "100%" : "200px" }}
              >
                Edit
              </Button>
            ) : (
              <Stack
                direction={isMobile ? "column" : "row"}
                spacing={2}
                width={isMobile ? "100%" : "auto"}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                  fullWidth={isMobile}
                  sx={{ maxWidth: isMobile ? "100%" : "200px" }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                  fullWidth={isMobile}
                  sx={{ maxWidth: isMobile ? "100%" : "200px" }}
                >
                  Cancel
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
};

export default FamilyHis;
