import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "./store/actions/auth_action";
import {
  Box,
  Container,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  Alert,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Email, Lock, ArrowBack, Refresh, Login } from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 15,
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

function MPLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const theme = useTheme();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.login(email, password));
      navigate("/MemberPortal/Profile");
    } catch (error) {
      setErrorMessage(error.message || "Login failed");
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setOtpSent(false);
    setOtpValidated(false);
    setErrorMessage("");
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      await dispatch(authActions.sendOtp(email));
      setOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Error sending OTP");
    }
  };

  const handleValidateOtp = async (e) => {
    e.preventDefault();
    try {
      const formattedOtp = otp.toString().trim();
      await dispatch(authActions.validateOtp(email, formattedOtp));
      setOtpValidated(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Invalid OTP");
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      // Additional validation
      if (!newPassword || newPassword.trim() === "") {
        setErrorMessage("New password is required");
        return;
      }
      if (newPassword.length < 8) {
        setErrorMessage("Password must be at least 8 characters long.");
        return;
      }
      const numericOtp = Number(otp);
      if (isNaN(numericOtp)) {
        setErrorMessage("Invalid OTP format");
        return;
      }

      // Debug log to verify values
      console.log("Updating password with:", {
        email,
        newPassword: newPassword.trim(),
        otp: numericOtp,
        currentPassword: password,
      });

      await dispatch(
        authActions.updatePassword(
          email.trim(),
          newPassword.trim(),
          numericOtp,
          password,
        ),
      );

      // Reset form only after successful update
      setEmail("");
      setPassword("");
      setOtp("");
      setNewPassword("");
      setIsForgotPassword(false);
      setOtpSent(false);
      setOtpValidated(false);
      setErrorMessage("");
      alert(
        "Password updated successfully. Please login with your new password.",
      );
      navigate("/");
    } catch (error) {
      console.error("Password update error:", error);
      setErrorMessage(error.message || "Password update failed");
    }
  };

  const handleMembersLogin = () => {
    navigate("/MemberPortal/Register");
  };

  const renderForgotPasswordForm = () => {
    if (!otpSent) {
      return (
        <>
          <Box
            sx={{ border: 1, borderColor: "grey.300", borderRadius: 1, mb: 2 }}
          >
            <TextField
              fullWidth
              label="Email address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              InputProps={{
                startAdornment: (
                  <Email sx={{ color: "action.active", mr: 1 }} />
                ),
              }}
            />
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSendOtp}
            disabled={loading}
            sx={{ mt: 3, mb: 2, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : "Send OTP"}
          </Button>
        </>
      );
    }

    if (!otpValidated) {
      return (
        <>
          <Box
            sx={{ border: 1, borderColor: "grey.300", borderRadius: 1, mb: 2 }}
          >
            <TextField
              fullWidth
              label="Enter OTP"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              margin="normal"
              required
              inputProps={{
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
            />
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={handleValidateOtp}
            disabled={loading}
            sx={{ mt: 3, mb: 2, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : "Validate OTP"}
          </Button>
          <Box textAlign="center">
            <Button
              startIcon={<Refresh />}
              onClick={handleSendOtp}
              sx={{ mt: 2 }}
            >
              Resend OTP
            </Button>
          </Box>
        </>
      );
    }

    return (
      <>
        <Box
          sx={{ border: 1, borderColor: "grey.300", borderRadius: 1, mb: 2 }}
        >
          <TextField
            fullWidth
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
            required
          />
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={handleUpdatePassword}
          disabled={loading}
          sx={{ mt: 3, mb: 2, py: 1.5 }}
        >
          {loading ? <CircularProgress size={24} /> : "Update Password"}
        </Button>
      </>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)",
        py: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <StyledCard>
          <CardContent sx={{ p: { xs: 3, sm: 5 } }}>
            <Box textAlign="center" mb={4}>
              <img
                src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
                alt="Logo"
                style={{
                  maxWidth: "180px",
                  height: "auto",
                  marginBottom: "1rem",
                }}
              />
              {!isForgotPassword && (
                <Typography variant="h5" color="text.secondary">
                  Welcome Back
                </Typography>
              )}
            </Box>

            {errorMessage && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}

            {!isForgotPassword ? (
              <form onSubmit={handleLogin}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 1,
                    mb: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <Email sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    border: 1,
                    borderColor: "grey.300",
                    borderRadius: 1,
                    mb: 2,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    margin="normal"
                    required
                    InputProps={{
                      startAdornment: (
                        <Lock sx={{ color: "action.active", mr: 1 }} />
                      ),
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleForgotPassword}
                    underline="hover"
                  >
                    Forgot password?
                  </Link>
                </Box>

                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  sx={{ py: 1.5, mb: 3 }}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <Login />
                        Sign In
                      </Box>
                    </>
                  )}
                </Button>

                <Divider sx={{ my: 3 }} />

                <Box textAlign="center">
                  <Typography variant="body2" color="text.secondary" mb={2}>
                    Not a member?{" "}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={handleMembersLogin}
                      underline="hover"
                    >
                      Register
                    </Link>
                  </Typography>
                  <Link
                    href="/"
                    target="_blank"
                    variant="body2"
                    underline="hover"
                  >
                    Login as user
                  </Link>
                </Box>
              </form>
            ) : (
              <Box>
                {renderForgotPasswordForm()}
                <Divider sx={{ my: 3 }} />
                <Box textAlign="center">
                  <Button
                    startIcon={<ArrowBack />}
                    onClick={() => setIsForgotPassword(false)}
                    sx={{ mt: 2 }}
                  >
                    Back to Login
                  </Button>
                </Box>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      </Container>
    </Box>
  );
}

export default MPLogin;
