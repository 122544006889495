import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "./store/actions/auth_action";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Custom styled components
const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "rgb(108, 117, 125)",
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "rgba(0, 0, 0, 0.1)",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomColor: "rgba(0, 0, 0, 0.3)",
  },
  marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: "rgb(108, 117, 125)",
  },
  marginTop: theme.spacing(2),
}));

function Register() {
  const [formData, setFormData] = useState({
    memberId: "",
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    address: "",
    phoneNumber: "",
    userName: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === "dateOfBirth" ? `${value}T00:00:00` : value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.memberId) formErrors.memberId = "Member ID is required";
    if (!formData.firstName) formErrors.firstName = "First Name is required";
    if (!formData.lastName) formErrors.lastName = "Last Name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      formErrors.email = "Email is invalid";
    if (!formData.dateOfBirth)
      formErrors.dateOfBirth = "Date of Birth is required";
    if (!formData.gender) formErrors.gender = "Gender is required";
    if (!formData.address) formErrors.address = "Address is required";
    if (!formData.phoneNumber)
      formErrors.phoneNumber = "Phone Number is required";
    if (!formData.userName) formErrors.userName = "Username is required";
    if (!formData.password) formErrors.password = "Password is required";
    if (formData.password.length < 6)
      formErrors.password = "Password must be at least 6 characters";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await dispatch(authActions.register(formData));
        navigate("/MemberPortal/Login");
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          submit: "Registration failed. Please try again or contact support.",
        }));
      }
    }
  };

  const handleMembersAlreadyLogin = () => {
    navigate(`/MemberPortal/Login`);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Paper elevation={0} sx={{ bgcolor: "transparent" }}>
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <img
            src="https://focuscares.com/wp-content/uploads/elementor/thumbs/logo-pcsu71jmplrr1f3b7mtv083rbyula7p5imfik70y8o.png"
            alt="Logo"
            style={{ maxWidth: "180px", height: "auto" }}
          />
        </Box>

        {(error || errors.submit) && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {error || errors.submit}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                label="Member ID *"
                name="memberId"
                value={formData.memberId}
                onChange={handleChange}
                error={!!errors.memberId}
                helperText={errors.memberId}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                label="Username *"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                error={!!errors.userName}
                helperText={errors.userName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                label="First Name *"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                label="Last Name *"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                type="email"
                label="Email Address *"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                type="password"
                label="Password *"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="body2"
                  color="rgb(108, 117, 125)"
                  sx={{ mb: 1 }}
                >
                  Date of Birth *
                </Typography>
                <StyledTextField
                  fullWidth
                  variant="standard"
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth.split("T")[0]}
                  onChange={handleChange}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledTextField
                fullWidth
                variant="standard"
                label="Phone Number *"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledTextField
                fullWidth
                variant="standard"
                multiline
                rows={4}
                label="Address *"
                name="address"
                value={formData.address}
                onChange={handleChange}
                error={!!errors.address}
                helperText={errors.address}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledFormControl error={!!errors.gender}>
                <FormLabel>Gender *</FormLabel>
                <RadioGroup
                  row
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  {["Male", "Female", "Other"].map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
                {errors.gender && (
                  <Typography color="error" variant="caption">
                    {errors.gender}
                  </Typography>
                )}
              </StyledFormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loading}
              sx={{
                minWidth: 200,
                bgcolor: "#0d6efd",
                "&:hover": {
                  bgcolor: "#0b5ed7",
                },
              }}
            >
              {loading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                  Registering...
                </>
              ) : (
                "REGISTER MEMBER"
              )}
            </Button>

            <Typography sx={{ mt: 2 }}>
              Already have an account?{" "}
              <Button
                color="primary"
                onClick={handleMembersAlreadyLogin}
                sx={{ textTransform: "none" }}
              >
                Log In
              </Button>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Container>
  );
}

export default Register;
