import React, { useState } from "react";
import {
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { Comment as CommentIcon } from "@mui/icons-material";

export const LiteracySection = ({
  formData,
  isEditing,
  setFormData,
  updateCommentVisibility,
}) => {
  console.log("Formdata", formData);
  const [showCommentSection, setShowCommentSection] = useState(() => {
    const initialState = {};
    [
      "school",
      "fillingMedicalForm",
      "writtenInfo",
      "prescriptionBottle",
    ].forEach((field) => {
      initialState[field] = !!formData[field]?.comment;
    });
    return initialState;
  });

  const handleSchoolChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      school: {
        lessThanThirdGrade: "",
        lessThanEighthGrade: "",
        lessThanTwelthGrade: "",
        completedTwelthGrade: "",
        attendedOrgraduated: "",
        comment: prev.school?.comment || "",
        [value]: prev.school[value] === "1" ? "" : "1",
      },
    }));
  };

  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const handleCommentChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        comment: value,
      },
    }));
  };

  const handleDifficultyChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        veryDifficult: "",
        someWhatDifficult: "",
        easy: "",
        veryEasy: "",
        comment: prev[field]?.comment || "",
        [value]: prev[field][value] === "1" ? "" : "1",
      },
    }));
  };

  const handleConfidenceChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      [field]: {
        notAtAllConfident: "",
        notVeryConfident: "",
        confident: "",
        veryConfident: "",
        comment: prev[field]?.comment || "",
        [value]: prev[field][value] === "1" ? "" : "1",
      },
    }));
  };

  const handleCommentClick = (field) => {
    setShowCommentSection((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const renderCommentSection = (field) => {
    const isVisible = showCommentSection[field];
    //  || !!formData[field]?.comment;

    if (!isVisible) return null;

    return (
      <Box sx={commentBoxSx}>
        <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
          Comments:
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={formData[field]?.comment || ""}
          onChange={(e) => handleCommentChange(field, e.target.value)}
          disabled={!isEditing}
          sx={textFieldSx}
        />
      </Box>
    );
  };

  // Add the styles from MedicalHistory.js
  const baseServiceButtonSx = {
    borderColor: "#000000",
    "&.MuiButton-outlined": {
      color: "#000000",
      borderColor: "#000000",
      "&:hover": {
        backgroundColor: "rgba(82, 161, 219, 0.04)",
        borderColor: "#000000",
      },
    },
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#3d8bc2",
      },
    },
    "&.Mui-disabled": {
      color: "#000000 !important",
      borderColor: "#000000",
      backgroundColor: "transparent",
      "&.MuiButton-contained": {
        backgroundColor: "#52A1DB !important",
        color: "#ffffff !important",
        borderColor: "transparent",
      },
    },
  };

  const textFieldSx = {
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black",
      WebkitTextFillColor: "black",
      opacity: 1,
    },
    "& .MuiInputBase-input": {
      "&::placeholder": {
        color: "text.secondary",
        opacity: 1,
      },
    },
    "& .MuiOutlinedInput-root": {
      padding: "0px !important",
      "& fieldset": {
        borderColor: "#52A1DB",
      },
      "&:hover fieldset": {
        borderColor: "#4190c4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#52A1DB",
      },
    },
    "& textarea": {
      height: "100% !important",
      minHeight: "60px !important",
      lineHeight: "1.5",
    },
  };

  const commentBoxSx = {
    border: "1px solid #52A1DB",
    borderRadius: "4px",
    p: 2,
    mt: 2,
  };

  const radioStyle = {
    "&.Mui-checked": {
      color: "#52A1DB",
    },
    "&.Mui-disabled.Mui-checked": {
      color: "#52A1DB",
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  };

  const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      color: "#000000 !important",
    },
    "&.Mui-disabled": {
      color: "#000000 !important",
    },
  };

  return (
    <Box>
      {/* School Question */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ flex: 1, textAlign: "left", width: "100%" }}>
            How much school have you completed?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("school")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.school).find(
                (key) => formData.school[key] === "1" && key !== "comment",
              ) || ""
            }
          >
            <FormControlLabel
              value="lessThanThirdGrade"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSchoolChange("lessThanThirdGrade");
                  }}
                  checked={formData.school.lessThanThirdGrade === "1"}
                  sx={radioStyle}
                />
              }
              label="Completed Less than 3rd grade"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="lessThanEighthGrade"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSchoolChange("lessThanEighthGrade");
                  }}
                  checked={formData.school.lessThanEighthGrade === "1"}
                  sx={radioStyle}
                />
              }
              label="Completed Less than 8th grade"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="lessThanTwelthGrade"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSchoolChange("lessThanTwelthGrade");
                  }}
                  checked={formData.school.lessThanTwelthGrade === "1"}
                  sx={radioStyle}
                />
              }
              label="Completed Less than 12th grade"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="completedTwelthGrade"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSchoolChange("completedTwelthGrade");
                  }}
                  checked={formData.school.completedTwelthGrade === "1"}
                  sx={radioStyle}
                />
              }
              label="Completed 12th grade"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="attendedOrgraduated"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSchoolChange("attendedOrgraduated");
                  }}
                  checked={formData.school.attendedOrgraduated === "1"}
                  sx={radioStyle}
                />
              }
              label="Attended / Graduated College"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("school")}
      </Box>

      {/* Medical Forms Question */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ flex: 1, textAlign: "left", width: "100%" }}>
            How confident are you filling out medical forms by yourself?
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("fillingMedicalForm")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.fillingMedicalForm).find(
                (key) =>
                  formData.fillingMedicalForm[key] === "1" && key !== "comment",
              ) || ""
            }
          >
            <FormControlLabel
              value="notAtAllConfident"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfidenceChange(
                      "fillingMedicalForm",
                      "notAtAllConfident",
                    );
                  }}
                  checked={
                    formData.fillingMedicalForm.notAtAllConfident === "1"
                  }
                  sx={radioStyle}
                />
              }
              label="Not at all confident"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="notVeryConfident"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfidenceChange(
                      "fillingMedicalForm",
                      "notVeryConfident",
                    );
                  }}
                  checked={formData.fillingMedicalForm.notVeryConfident === "1"}
                  sx={radioStyle}
                />
              }
              label="Not very confident"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="confident"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfidenceChange("fillingMedicalForm", "confident");
                  }}
                  checked={formData.fillingMedicalForm.confident === "1"}
                  sx={radioStyle}
                />
              }
              label="Confident"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="veryConfident"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfidenceChange(
                      "fillingMedicalForm",
                      "veryConfident",
                    );
                  }}
                  checked={formData.fillingMedicalForm.veryConfident === "1"}
                  sx={radioStyle}
                />
              }
              label="Very confident"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("fillingMedicalForm")}
      </Box>

      {/* Written Information Question */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ flex: 1, textAlign: "left", width: "100%" }}>
            When you get written information at a doctor's office would you say
            it is:
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("writtenInfo")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.writtenInfo).find(
                (key) => formData.writtenInfo[key] === "1" && key !== "comment",
              ) || ""
            }
          >
            <FormControlLabel
              value="veryDifficult"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("writtenInfo", "veryDifficult");
                  }}
                  checked={formData.writtenInfo.veryDifficult === "1"}
                  sx={radioStyle}
                />
              }
              label="Very difficult"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="someWhatDifficult"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("writtenInfo", "someWhatDifficult");
                  }}
                  checked={formData.writtenInfo.someWhatDifficult === "1"}
                  sx={radioStyle}
                />
              }
              label="Somewhat difficult"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="easy"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("writtenInfo", "easy");
                  }}
                  checked={formData.writtenInfo.easy === "1"}
                  sx={radioStyle}
                />
              }
              label="Easy"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="veryEasy"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("writtenInfo", "veryEasy");
                  }}
                  checked={formData.writtenInfo.veryEasy === "1"}
                  sx={radioStyle}
                />
              }
              label="Very easy to understand"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("writtenInfo")}
      </Box>

      {/* Prescription Bottle Question */}
      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
          <Typography sx={{ flex: 1, textAlign: "left", width: "100%" }}>
            When you read the instructions on a prescription bottle would you
            say that it is:
          </Typography>
          <IconButton
            onClick={() => handleCommentClick("prescriptionBottle")}
            sx={{
              color: "#52A1DB",
              ml: 1,
              flexShrink: 0,
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            value={
              Object.keys(formData.prescriptionBottle).find(
                (key) =>
                  formData.prescriptionBottle[key] === "1" && key !== "comment",
              ) || ""
            }
          >
            <FormControlLabel
              value="veryDifficult"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange(
                      "prescriptionBottle",
                      "veryDifficult",
                    );
                  }}
                  checked={formData.prescriptionBottle.veryDifficult === "1"}
                  sx={radioStyle}
                />
              }
              label="Very difficult"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="someWhatDifficult"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange(
                      "prescriptionBottle",
                      "someWhatDifficult",
                    );
                  }}
                  checked={
                    formData.prescriptionBottle.someWhatDifficult === "1"
                  }
                  sx={radioStyle}
                />
              }
              label="Somewhat difficult"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="easy"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("prescriptionBottle", "easy");
                  }}
                  checked={formData.prescriptionBottle.easy === "1"}
                  sx={radioStyle}
                />
              }
              label="Easy"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
            <FormControlLabel
              value="veryEasy"
              control={
                <Radio
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDifficultyChange("prescriptionBottle", "veryEasy");
                  }}
                  checked={formData.prescriptionBottle.veryEasy === "1"}
                  sx={radioStyle}
                />
              }
              label="Very easy to understand"
              disabled={!isEditing}
              sx={formControlLabelStyle}
            />
          </RadioGroup>
        </FormControl>
        {renderCommentSection("prescriptionBottle")}
      </Box>
    </Box>
  );
};
