import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Alert,
  Radio,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Chip,
  TextField,
  Checkbox,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

// Add this constant near the top of your file
const specialistOptions = [
  "Allergist / Immunologist",
  "Anesthesiologist",
  "Cardiologist",
  "Dermatologist",
  "ENT",
  "Endocrinologist",
  "Gastroenterologist",
  "Neurologist",
  "Oncologist",
  "Ophthalmologist",
  "Orthopedist",
  "Psychiatrist",
  "Pulmonologist",
  "Rheumatologist",
  "Urologist",
  "Other",
];

// Add theme and style constants
const theme = createTheme({
  palette: {
    primary: {
      main: "#52A1DB",
      dark: "#4190c4",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.6)",
    },
  },
});

// Define button styles outside the component
const buttonSx = {
  backgroundColor: "#e3edf4",
  borderColor: "#52A1DB",
  color: "#000000",
  "&:hover": {
    backgroundColor: "rgba(16, 118, 188, 0.04)",
    borderColor: "#52A1DB",
  },
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    borderColor: "#52A1DB",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0d5ea3",
      borderColor: "#52A1DB",
    },
  },
  "&.Mui-disabled": {
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB !important", // Force the background color
      borderColor: "#52A1DB !important",
      color: "#ffffff !important",
      opacity: 1,
    },
    "&.MuiButton-outlined": {
      backgroundColor: "#e3edf4 !important",
      borderColor: "#52A1DB !important",
      color: "#000000 !important",
    },
  },
};

const baseServiceButtonSx = {
  ...buttonSx,
  borderColor: "#000000",
  "&.MuiButton-outlined": {
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
      borderColor: "#000000",
    },
  },
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#3d8bc2",
    },
  },
  "&.Mui-disabled": {
    color: "#000000 !important",
    borderColor: "#000000",
    backgroundColor: "transparent",
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB !important",
      color: "black !important",
      borderColor: "transparent",
    },
  },
};

const sectionBoxSx = {
  backgroundColor: "white",
  borderRadius: "8px",
  p: 3,
  mb: 3,
  border: "1px solid #2196f3", // Added blue border
  boxShadow: "none", // Removed shadow since we're using border
};

// For nested sections that might need different styling
const innerSectionBoxSx = {
  backgroundColor: "white",
  borderRadius: "4px",
  p: 2,
  mb: 2,
  border: "1px solid #2196f3",
};

const textFieldSx = {
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    borderRadius: "4px",
    "& fieldset": {
      borderColor: "#2196f3",
    },
  },
  // Add styles for disabled state
  "& .MuiInputBase-input.Mui-disabled": {
    color: "#000000",
    "-webkit-text-fill-color": "#000000",
  },
};

const subQuestionButtonSx = {
  ...buttonSx,
  borderColor: "#000000",
  "&.MuiButton-outlined": {
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "rgba(82, 161, 219, 0.04)",
      borderColor: "#000000",
    },
  },
  "&.MuiButton-contained": {
    backgroundColor: "#52A1DB",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#3d8bc2",
    },
  },
  "&.Mui-disabled": {
    color: "#000000 !important",
    borderColor: "#000000",
    backgroundColor: "transparent",
    "&.MuiButton-contained": {
      backgroundColor: "#52A1DB !important",
      color: "#ffffff !important",
      borderColor: "transparent",
    },
  },
};

// Create a function that returns the style object based on isEditing state
const createToggleButtonSx = (isEditing) => ({
  minWidth: "60px",
  px: 2,
  py: 1,
  mr: 1,
  textTransform: "none",
  borderColor: "#2196F3",
  "&.MuiButton-contained": {
    backgroundColor: "#2196F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#1976D2",
    },
  },
  "&.MuiButton-outlined": {
    color: "#2196F3",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "rgba(33, 150, 243, 0.04)",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: isEditing ? "inherit" : "#f5f5f5",
    borderColor: isEditing ? "#2196F3" : "#ccc",
    color: isEditing ? "#2196F3" : "#ccc",
  },
});

// Example usage in a component with Yes/No/NA options
const renderToggleButtons = (value, onChange, isEditing, disabled = false) => {
  const toggleButtonSx = createToggleButtonSx(isEditing);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <Button
        variant={value === "YES" ? "contained" : "outlined"}
        onClick={() => onChange("YES")}
        disabled={disabled || !isEditing}
        sx={toggleButtonSx}
      >
        YES
      </Button>
      <Button
        variant={value === "NO" ? "contained" : "outlined"}
        onClick={() => onChange("NO")}
        disabled={disabled || !isEditing}
        sx={toggleButtonSx}
      >
        NO
      </Button>
      <Button
        variant={value === "NA" ? "contained" : "outlined"}
        onClick={() => onChange("NA")}
        disabled={disabled || !isEditing}
        sx={toggleButtonSx}
      >
        NA
      </Button>
    </Box>
  );
};

const tableHeaderSx = {
  fontWeight: "bold",
  color: "#000000",
  fontSize: "1.1rem",
};

// Add this common TextField styling at the top with other styles
const commonTextFieldSx = {
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "4px",
    "& fieldset": {
      borderColor: "#52A1DB",
    },
  },
  "& .MuiInputLabel-root": {
    lineHeight: "1.4375em",
    color: "#000000",
    "&.Mui-focused, &.MuiFormLabel-filled": {
      fontSize: "1rem",
      color: "#000000",
    },
    "&.MuiInputLabel-shrink": {
      fontSize: "1rem",
      transform: "translate(14px, -9px) scale(0.85)",
      color: "#000000",
    },
    "&.Mui-disabled": {
      color: "#000000",
    },
  },
  "& .MuiInputBase-input": {
    padding: "14px",
    fontSize: "1rem",
    color: "#000000",
    "&.Mui-disabled": {
      color: "#000000",
      "-webkit-text-fill-color": "#000000",
      opacity: 1,
    },
  },
};

const MedicalHistory = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultFormData = {
    assistiveDevices: {
      isNone: "",
      comment: "",
      subAnswers: {
        comment: "",
        isCane: "",
        isWalker: "",
        isProsthesis: "",
        isOxygen: "",
        isWheelChair: "",
        isBedsideCommode: "",
        isurinal: "",
        isBedPan: "",
        isCPAP: "",
        isOther: "",
        oxygenSubAnswers: {
          describe: {
            isPRN: "",
            isContinuous: "",
            isDay: "",
            isNight: "",
            Litres: "",
            comment: "",
          },
        },
        otherSubAnswers: {
          describe: "",
        },
      },
    },
    specialists: {
      isSpecialists: "",
      specialistsSubAnswers: [
        {
          medicalSp: "",
          specialist: "",
          for: "",
        },
      ],
      comment: "",
    },
    pastTwelveMonths: {
      pastTwelveMonthsSubAnswers: {
        pcp: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
        },
        emergencyRoom: {
          isNone: "",
          isOne: "",
          isTwo: "",
          isThree: "",
          isFour: "",
          isFiveOrMore: "",
          comment: "",
          isOneSubAnswers: {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          },
          isTwoSubAnswers: {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          },
          isThreeSubAnswers: {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          },
          isFourSubAnswers: {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          },
          isFiveOrMoreSubAnswers: {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          },
        },
        hospitalOvernight: {
          isNone: "",
          isOne: "",
          isOneSubAnswers: {
            describe: "",
          },
          comment: "",
        },
        nursingHome: {
          isNone: "",
          isOne: "",
          isOneSubAnswers: {
            describe: "",
          },
          comment: "",
        },
        hadSurgery: {
          isNone: "",
          isOne: "",
          isOneSubAnswers: {
            describe: "",
          },
          comment: "",
        },
      },
    },
    hospitalizedPrior: {
      isHospitalizedPrior: "",
      hospitalizedPriorSubAnswers: {
        describe: "",
      },
      comment: "",
    },
    healthServices: {
      physicalTherapist: "",
      occupationalTherapist: "",
      dietician: "",
      socialWorker: "",
      pharmacist: "",
      speechTherapist: "",
      shiropractor: "",
      personalCareWorker: "",
      mealsOnWheels: "",
      adultDayCare: "",
      eyeExam: "",
      dentalExam: "",
      none: "",
      comment: "",
    },
    treatments: {
      chemotherapy: "",
      catheterCare: "",
      oxygen: "",
      woundCare: "",
      regularInjections: "",
      tubeFeedings: "",
      none: "",
      comment: "",
    },
    emergencyRoom: {
      isNone: "",
      isOne: "",
      isTwo: "",
      isThree: "",
      isFour: "",
      isFiveOrMore: "",
      describe: "",
      followUp: "",
      educationProvided: "",
      recommendations: {
        followUpAsIndicated: "",
      },
      comment: "",
    },
  };

  const initialERVisitSubAnswers = {
    describe: "",
    edVisit: "",
    comment: "",
    edVisitSubAnswers: {
      noSubAnswers: {
        isEducationProvided: "",
        edVisitFollowUp: "",
      },
      unknownSubAnswers: {
        isEducationProvided: "",
        edVisitFollowUp: "",
      },
    },
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState("");
  const [showDevices, setShowDevices] = useState(false);
  const [changedFields, setChangedFields] = useState([]);
  const [commentVisibility, setCommentVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem(
      "medicalHistoryCommentVisibility",
    );
    if (savedVisibility) {
      try {
        return JSON.parse(savedVisibility);
      } catch (e) {
        console.error("Error parsing comment visibility from localStorage:", e);
      }
    }
    return {
      assistiveDevices: false,
      specialists: false,
      "pastTwelveMonths-pcp": false, // Add this specific key
      "pastTwelveMonths-hospitalOvernight": false,
      "pastTwelveMonths-nursingHome": false,
      "pastTwelveMonths-hadSurgery": false,
      hospitalizedPrior: false,
      healthServices: false,
      treatments: false,
      emergencyRoom: false,
      assistiveDevicesIfYes: false,
    };
  });
  const [showOxygenDetails, setShowOxygenDetails] = useState(false);
  // Add this state to track active comment fields
  const [activeCommentField, setActiveCommentField] = useState(null);
  // Add this near the top of the component with other state declarations
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (data?.data) {
      // If data exists but is empty array, use default state
      if (Array.isArray(data.data) && data.data.length === 0) {
        setFormData(defaultFormData);
      } else {
        // Fix 3: Sanitize incoming data
        const sanitizedData = {
          ...defaultFormData,
          ...data.data,
          assistiveDevices: {
            ...defaultFormData.assistiveDevices,
            ...data.data.assistiveDevices,
            isNone:
              data.data.assistiveDevices.isNone === "2"
                ? "1"
                : data.data.assistiveDevices.isNone,
            subAnswers: {
              ...defaultFormData.assistiveDevices.subAnswers,
              ...data.data.assistiveDevices.subAnswers,
              // Reset oxygen fields if oxygen is not selected
              oxygenSubAnswers:
                data.data.assistiveDevices.subAnswers.isOxygen === "1"
                  ? data.data.assistiveDevices.subAnswers.oxygenSubAnswers
                  : defaultFormData.assistiveDevices.subAnswers
                      .oxygenSubAnswers,
            },
          },
        };
        setFormData(sanitizedData);
      }
    } else {
      setFormData(defaultFormData);
    }
  }, [data]);

  // Add new useEffect to fetch latest data
  useEffect(() => {
    const fetchLatestMedicalHistory = async () => {
      try {
        const token = localStorage.getItem("token");
        const yearToUse = user.year || new Date().getFullYear();

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-medical-history`,
          {
            params: {
              memberId: user.memberId,
              year: yearToUse,
            },
            headers: {
              Authorization: token,
            },
          },
        );

        if (response.data?.data) {
          setFormData(response.data.data);
          console.log("Fresh medical history data loaded:", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching latest medical history:", error);
      }
    };

    fetchLatestMedicalHistory();
  }, []); // Run once when component mounts

  // Save to localStorage when commentVisibility changes
  useEffect(() => {
    localStorage.setItem(
      "medicalHistoryCommentVisibility",
      JSON.stringify(commentVisibility),
    );
  }, [commentVisibility]);

  // Modify handleSubmit to fetch fresh data after update
  const handleSubmit = async () => {
    try {
      const yearToUse = (function () {
        if (!user?.year) return new Date().getFullYear();
        const validYears = [2023, 2024];
        const yearStr = String(user.year).trim();
        const yearNum = Number(yearStr);
        return validYears.includes(yearNum) ||
          validYears.map(String).includes(yearStr)
          ? yearNum
          : new Date().getFullYear();
      })();

      // Store current data for comparison
      const previousData = { ...formData };

      const payload = {
        memberId: user?.memberId,
        year: yearToUse,
        name: "MedicalHistory",
        data: formData,
      };

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token not found");
      }

      // PUT request to update medical history
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/update-medical-history`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        },
      );

      // Important: Update the UI state after successful save
      setMessage("Medical history updated successfully!");
      setIsEditing(false); // This line is crucial - make sure it's being called

      // GET request to fetch updated medical history
      const getResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/memberPortal/get-medical-history`,
        {
          params: {
            memberId: user.memberId,
            year: yearToUse,
          },
          headers: {
            Authorization: token,
          },
        },
      );

      if (getResponse.data?.data) {
        const newData = getResponse.data.data;
        setFormData(newData);
        setCommentVisibility((prevState) => ({
          ...prevState,
          ...(newData.assistiveDevices?.comment === "" && {
            assistiveDevices: false,
          }),
          ...(newData.assistiveDevices?.subAnswers?.comment === "" && {
            assistiveDevicesIfYes: false,
          }),
          ...(newData.specialists?.comment === "" && { specialists: false }),
          ...(newData.pastTwelveMonths?.pastTwelveMonthsSubAnswers
            ?.emergencyRoom?.comment === "" && {
            ["emergencyRoom"]: false,
          }),
          ...(newData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.pcp
            ?.comment === "" && {
            ["pastTwelveMonths-pcp"]: false,
          }),
          ...(newData.pastTwelveMonths?.pastTwelveMonthsSubAnswers
            ?.hospitalOvernight?.comment === "" && {
            ["pastTwelveMonths-hospitalOvernight"]: false,
          }),
          ...(newData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.nursingHome
            ?.comment === "" && {
            ["pastTwelveMonths-nursingHome"]: false,
          }),
          ...(newData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.hadSurgery
            ?.comment === "" && {
            ["pastTwelveMonths-hadSurgery"]: false,
          }),
          ...(newData.hospitalizedPrior?.comment === "" && {
            hospitalizedPrior: false,
          }),
          ...(newData.healthServices?.comment === "" && {
            healthServices: false,
          }),
          ...(newData.treatments?.comment === "" && { treatments: false }),
        }));
      }
    } catch (error) {
      console.error("Error updating medical history:", error);
      setMessage(
        error.response?.data?.message ||
          "Failed to update medical history. Please try again.",
      );
    }
  };

  const handleCancel = () => {
    // Make sure we have the original data to revert to
    if (data?.data) {
      // Handle empty array case during cancel
      if (Array.isArray(data.data) && data.data.length === 0) {
        setFormData(defaultFormData);
      } else {
        setFormData(data.data);
      }
    } else {
      setFormData(defaultFormData);
    }

    // Important: Update the UI state
    setIsEditing(false);
    setMessage("");

    // Log for debugging
    console.log("Cancel clicked - reverting form data");
  };

  const handleAssistiveDevicesChange = (value) => {
    if (!isEditing) return;

    // Create empty subAnswers structure with all fields reset
    const emptySubAnswers = {
      isCane: "",
      isWalker: "",
      isProsthesis: "",
      isOxygen: "",
      isWheelChair: "",
      isBedsideCommode: "",
      isurinal: "",
      isBedPan: "",
      isCPAP: "",
      isOther: "",
      otherSubAnswers: {
        describe: "",
      },
      oxygenSubAnswers: {
        describe: {
          isPRN: "",
          isContinuous: "",
          isDay: "",
          isNight: "",
          comment: "",
        },
        Litres: "",
      },
      comment: "",
    };

    setFormData((prev) => ({
      ...prev,
      assistiveDevices: {
        isNone: value === "None" ? "1" : "2",
        comment: "", // Reset main comment
        subAnswers: emptySubAnswers, // Reset all subAnswers
      },
    }));
  };

  const handleAssistiveDeviceOptionChange = (option) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const currentValue = prev.assistiveDevices.subAnswers[option];
      const isDeselecting = currentValue === "1";

      // If deselecting, just clear this option
      if (isDeselecting) {
        return {
          ...prev,
          assistiveDevices: {
            ...prev.assistiveDevices,
            subAnswers: {
              ...prev.assistiveDevices.subAnswers,
              [option]: "",
              // Clear related subAnswers if they exist
              ...(option === "isOxygen"
                ? {
                    oxygenSubAnswers: {
                      describe: {
                        isPRN: "",
                        isContinuous: "",
                        isDay: "",
                        isNight: "",
                        comment: "",
                      },
                      Litres: "",
                    },
                  }
                : {}),
              ...(option === "isOther"
                ? {
                    otherSubAnswers: {
                      describe: "",
                    },
                  }
                : {}),
            },
          },
        };
      }

      // If selecting, set this option and ensure isNone is "2"
      return {
        ...prev,
        assistiveDevices: {
          ...prev.assistiveDevices,
          isNone: "2", // Ensure "YES" is selected when any option is chosen
          subAnswers: {
            ...prev.assistiveDevices.subAnswers,
            [option]: "1",
          },
        },
      };
    });
  };

  const handleOxygenDescribeChange = (field, value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      // Get current oxygen describe state
      const currentDescribe =
        prev.assistiveDevices.subAnswers.oxygenSubAnswers.describe;

      // If the clicked option is already selected, clear it
      // Otherwise, clear all other options and set the clicked one
      const newDescribe = {
        isPRN: "",
        isContinuous: "",
        isDay: "",
        isNight: "",
        comment: currentDescribe.comment || "", // Preserve existing comment
        [field]: currentDescribe[field] === "1" ? "" : "1",
      };

      return {
        ...prev,
        assistiveDevices: {
          ...prev.assistiveDevices,
          subAnswers: {
            ...prev.assistiveDevices.subAnswers,
            oxygenSubAnswers: {
              ...prev.assistiveDevices.subAnswers.oxygenSubAnswers,
              describe: newDescribe,
            },
          },
        },
      };
    });
  };

  const handleOxygenLitresChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      assistiveDevices: {
        ...prev.assistiveDevices,
        subAnswers: {
          ...prev.assistiveDevices.subAnswers,
          oxygenSubAnswers: {
            ...prev.assistiveDevices.subAnswers.oxygenSubAnswers,
            Litres: value,
          },
        },
      },
    }));
  };

  const handleOtherDescribeChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      assistiveDevices: {
        ...prev.assistiveDevices,
        subAnswers: {
          ...prev.assistiveDevices.subAnswers,
          otherSubAnswers: {
            describe: value,
          },
        },
      },
    }));
  };

  const handleRadioChange = (section, subsection, value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      // Map the button value to the corresponding state key
      const valueToKey = {
        None: "isNone",
        1: "isOne",
        2: "isTwo",
        3: "isThree",
        4: "isFour",
        "5ormore": "isFiveOrMore",
      };

      const visitKey = valueToKey[value];

      // If no valid key mapping found, return unchanged state
      if (!visitKey) {
        console.error("Invalid value:", value);
        return prev;
      }

      const currentValue =
        prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section][visitKey];
      const isDeselecting = currentValue === "1";

      // Create new section state with all options cleared
      const newSectionState = {
        ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section],
        isNone: "",
        isOne: "",
        isTwo: "",
        isThree: "",
        isFour: "",
        isFiveOrMore: "",
      };

      // If we're not deselecting, set the new value
      if (!isDeselecting) {
        newSectionState[visitKey] = "1";
      }

      // Create the full new state
      const newState = {
        ...prev,
        pastTwelveMonths: {
          ...prev.pastTwelveMonths,
          pastTwelveMonthsSubAnswers: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
            [section]: {
              ...newSectionState,
              // Preserve any existing comments
              comment:
                prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section]
                  .comment,
            },
          },
        },
      };

      // Handle special cases for emergency room visits
      if (section === "emergencyRoom" && !isDeselecting) {
        const subAnswersKey = `${visitKey}SubAnswers`;
        if (visitKey !== "isNone") {
          newState.pastTwelveMonths.pastTwelveMonthsSubAnswers[section][
            subAnswersKey
          ] = {
            describe: "",
            edVisit: "",
            comment: "",
            edVisitSubAnswers: {
              noSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
              unknownSubAnswers: {
                isEducationProvided: "",
                edVisitFollowUp: "",
              },
            },
          };
        }
      }

      // Handle special cases for surgery
      if (section === "hadSurgery" && !isDeselecting) {
        if (visitKey !== "isNone") {
          newState.pastTwelveMonths.pastTwelveMonthsSubAnswers[
            section
          ].isOneSubAnswers = {
            describe: "",
          };
        }
      }

      return newState;
    });
  };

  // Add a handler for education provided checkbox
  const handleEducationProvidedChange = (visitType, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          emergencyRoom: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
            [`${visitType}SubAnswers`]: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
                `${visitType}SubAnswers`
              ],
              edVisitSubAnswers: {
                ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers
                  .emergencyRoom[`${visitType}SubAnswers`].edVisitSubAnswers,
                isEducationProvided: value ? "1" : "",
              },
            },
          },
        },
      },
    }));
  };

  const handleSpecialistsToggle = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      specialists: {
        ...prev.specialists,
        isSpecialists: value,
        // Reset the specialists data when switching options
        specialistsSubAnswers: value === "1" ? [] : [],
        comment: value === "2" ? "" : prev.specialists.comment, // Optionally reset comment for "No"
      },
    }));
  };

  const handleAddSpecialist = () => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      specialists: {
        ...prev.specialists,
        specialistsSubAnswers: [
          ...prev.specialists.specialistsSubAnswers,
          { medicalSp: "", specialist: "", for: "" },
        ],
      },
    }));
  };

  const handleRemoveSpecialist = (index) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      specialists: {
        ...prev.specialists,
        specialistsSubAnswers: prev.specialists.specialistsSubAnswers.filter(
          (_, i) => i !== index,
        ),
      },
    }));
  };

  const handleSpecialistChange = (index, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      specialists: {
        ...prev.specialists,
        specialistsSubAnswers: prev.specialists.specialistsSubAnswers.map(
          (item, i) => (i === index ? { ...item, [field]: value } : item),
        ),
      },
    }));
  };

  const handleServiceClick = (service) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const isDeselecting = prev.healthServices[service] === "1";

      // If clicking "none", clear all other selections
      if (service === "none") {
        return {
          ...prev,
          healthServices: {
            ...Object.keys(prev.healthServices).reduce(
              (acc, key) => ({
                ...acc,
                [key]: "",
              }),
              {},
            ),
            none: isDeselecting ? "" : "1",
            comment: prev.healthServices.comment, // Preserve comment
          },
        };
      }

      // If clicking any other service, clear "none" and toggle the selected service
      return {
        ...prev,
        healthServices: {
          ...prev.healthServices,
          [service]: isDeselecting ? "" : "1",
          none: "", // Clear "none" when selecting other options
        },
      };
    });
  };

  const handleTreatmentClick = (treatment) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const isDeselecting = prev.treatments[treatment] === "1";

      // If clicking "none", clear all other selections
      if (treatment === "none") {
        return {
          ...prev,
          treatments: {
            ...Object.keys(prev.treatments).reduce(
              (acc, key) => ({
                ...acc,
                [key]: "",
              }),
              {},
            ),
            none: isDeselecting ? "" : "1",
            comment: prev.treatments.comment, // Preserve comment
          },
        };
      }

      // If clicking any other treatment, clear "none" and toggle the selected treatment
      return {
        ...prev,
        treatments: {
          ...prev.treatments,
          [treatment]: isDeselecting ? "" : "1",
          none: "", // Clear "none" when selecting other options
        },
      };
    });
  };

  const handleHospitalizedPriorChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const isDeselecting =
        prev.hospitalizedPrior.isHospitalizedPrior === value;
      return {
        ...prev,
        hospitalizedPrior: {
          ...prev.hospitalizedPrior,
          isHospitalizedPrior: isDeselecting ? "" : value,
          hospitalizedPriorSubAnswers: {
            describe: "", // Reset description when deselecting
          },
          comment: isDeselecting ? "" : prev.hospitalizedPrior.comment,
        },
      };
    });
  };

  const handleHospitalizationDescriptionChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      hospitalizedPrior: {
        ...prev.hospitalizedPrior,
        hospitalizedPriorSubAnswers: {
          ...prev.hospitalizedPrior.hospitalizedPriorSubAnswers,
          describe: value,
        },
      },
    }));
  };

  const handleERVisitDescriptionChange = (visitKey, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          emergencyRoom: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
            [`${visitKey}SubAnswers`]: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
                `${visitKey}SubAnswers`
              ],
              describe: value, // Changed from description to describe
            },
          },
        },
      },
    }));
  };

  const handleERFollowUpVisit = (visitKey, value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const currentVisit =
        prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
          `${visitKey}SubAnswers`
        ];
      const isDeselecting = currentVisit.edVisit === value;

      return {
        ...prev,
        pastTwelveMonths: {
          ...prev.pastTwelveMonths,
          pastTwelveMonthsSubAnswers: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
            emergencyRoom: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
              [`${visitKey}SubAnswers`]: {
                ...currentVisit,
                edVisit: isDeselecting ? "" : value,
                // Reset subAnswers when deselecting or changing selection
                edVisitSubAnswers: {
                  noSubAnswers: {
                    isEducationProvided: "",
                    edVisitFollowUp: "",
                  },
                  unknownSubAnswers: {
                    isEducationProvided: "",
                    edVisitFollowUp: "",
                  },
                },
              },
            },
          },
        },
      };
    });
  };

  const handleEREducationProvided = (visitKey, answerType, field) => {
    if (!isEditing) return;

    setFormData((prev) => {
      const currentVisit = prev.pastTwelveMonths.pastTwelveMonthsSubAnswers
        .emergencyRoom[`${visitKey}SubAnswers`] || {
        ...initialERVisitSubAnswers,
      };
      const isDeselecting =
        currentVisit.edVisitSubAnswers[answerType]?.[field] === "1";

      return {
        ...prev,
        pastTwelveMonths: {
          ...prev.pastTwelveMonths,
          pastTwelveMonthsSubAnswers: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
            emergencyRoom: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
              [`${visitKey}SubAnswers`]: {
                ...currentVisit,
                edVisitSubAnswers: {
                  ...currentVisit.edVisitSubAnswers,
                  [answerType]: {
                    ...currentVisit.edVisitSubAnswers[answerType],
                    [field]: isDeselecting ? "" : "1",
                  },
                },
              },
            },
          },
        },
      };
    });
  };

  const handleERVisitCommentChange = (visitKey, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          emergencyRoom: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
            [`${visitKey}SubAnswers`]: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
                `${visitKey}SubAnswers`
              ],
              comment: value,
            },
          },
        },
      },
    }));
  };

  // Add this function to handle checkbox deselection
  const handleCheckboxChange = (visitKey, answerType, field) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          emergencyRoom: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom,
            [`${visitKey}SubAnswers`]: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
                `${visitKey}SubAnswers`
              ],
              edVisitSubAnswers: {
                ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers
                  .emergencyRoom[`${visitKey}SubAnswers`].edVisitSubAnswers,
                [answerType]: {
                  ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers
                    .emergencyRoom[`${visitKey}SubAnswers`].edVisitSubAnswers[
                    answerType
                  ],
                  [field]:
                    prev.pastTwelveMonths.pastTwelveMonthsSubAnswers
                      .emergencyRoom[`${visitKey}SubAnswers`].edVisitSubAnswers[
                      answerType
                    ][field] === "1"
                      ? ""
                      : "1",
                },
              },
            },
          },
        },
      },
    }));
  };

  // Update the JSX for the Education and Recommendations section
  const renderEducationAndRecommendations = (visitKey, answerType) => {
    const currentVisit =
      formData.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
        `${visitKey}SubAnswers`
      ];

    return (
      <Box sx={{ mt: 2 }}>
        {/* <Typography variant="subtitle2" gutterBottom>
          Education Provided
        </Typography> */}
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          {" "}
          {/* Added container */}
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  currentVisit?.edVisitSubAnswers?.[answerType]
                    ?.isEducationProvided === "1"
                }
                onChange={() =>
                  handleCheckboxChange(
                    visitKey,
                    answerType,
                    "isEducationProvided",
                  )
                }
                disabled={!isEditing}
              />
            }
            label="Education Provided"
            sx={{
              marginLeft: 0, // Remove default left margin
              "& .MuiFormControlLabel-label": {
                textAlign: "left",
              },
            }}
          />
        </Box>

        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{
            mt: 2,
            textAlign: "left",
            width: "100%",
          }}
        >
          Recommendations
        </Typography>

        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          {" "}
          {/* Added container */}
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  currentVisit?.edVisitSubAnswers?.[answerType]
                    ?.edVisitFollowUp === "1"
                }
                onChange={() =>
                  handleCheckboxChange(visitKey, answerType, "edVisitFollowUp")
                }
                disabled={!isEditing}
              />
            }
            label="Follow up as indicated by ED visit"
            sx={{
              marginLeft: 0, // Remove default left margin
              "& .MuiFormControlLabel-label": {
                textAlign: "left",
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  // Add or update the comment handler for specialists
  const handleSpecialistCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      specialists: {
        ...prev.specialists,
        comment: value,
      },
    }));
  };

  // Update the handleCommentToggle function
  const handleCommentToggle = (section) => {
    setCommentVisibility((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  useEffect(() => {
    console.log("commentvisi", commentVisibility);
  }, [commentVisibility]);
  const setAllCommentVisibility = (data, setCommentVisibility) => {
    const traverse = (obj) => {
      for (const key in obj) {
        if (key === "comment") {
          setCommentVisibility(!!obj[key]);
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverse(obj[key]);
        }
      }
    };
    traverse(data);
  };
  useEffect(() => {
    console.log("commentvisisi", commentVisibility);
  }, [formData]);
  // Update the comment field rendering to include the active field check
  const renderCommentField = (section, subsection = null) => {
    const commentKey = `${section}${subsection ? `-${subsection}` : ""}`;
    let commentValue = "";

    if (section === "assistiveDevices") {
      commentValue =
        subsection === "subAnswers"
          ? formData.assistiveDevices.subAnswers.comment
          : formData.assistiveDevices.comment;
    } else if (section === "pastTwelveMonths" && subsection) {
      commentValue =
        formData.pastTwelveMonths.pastTwelveMonthsSubAnswers[subsection]
          .comment;
    } else if (section === "healthServices" || section === "treatments") {
      commentValue = formData[section].comment;
    } else if (section === "specialists") {
      commentValue = formData.specialists.comment;
    }

    // Show comment box if either it's visible in state OR there's a comment value
    const shouldShowComment =
      commentVisibility[commentKey] || Boolean(commentValue);

    return (
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => handleCommentToggle(commentKey)}
            sx={{
              color: shouldShowComment ? "#52A1DB" : "rgba(0, 0, 0, 0.26)",
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        {shouldShowComment && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mt: 1,
            }}
          >
            <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={commentValue || ""}
              onChange={(e) =>
                handleCommentChange(section, subsection, e.target.value)
              }
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderVisitDetails = (section, visitKey) => {
    const sectionData =
      formData.pastTwelveMonths.pastTwelveMonthsSubAnswers[section];
    const subAnswersKey = `${visitKey}SubAnswers`;

    // Check if the section exists and has the selected option
    if (!sectionData || !sectionData[visitKey] || !sectionData[subAnswersKey])
      return null;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 2,
          border: "1px solid #52A1DB",
          borderRadius: "4px",
          p: 2,
          mb: 2,
          ml: 2,
          mt: 2,
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Typography variant="subtitle1" color="primary" gutterBottom>
          If one or more, describe
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={sectionData[subAnswersKey].describe || ""}
          onChange={(e) =>
            handleVisitDescriptionChange(section, visitKey, e.target.value)
          }
          disabled={!isEditing}
          sx={{ mb: 2 }}
        />
      </Box>
    );
  };

  const renderVisitSection = (title, section) => {
    const sectionData =
      formData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.[section] || {};
    const commentKey = `pastTwelveMonths-${section}`;
    const hasComment = Boolean(sectionData.comment);

    // Define options array
    const options = [
      { key: "isNone", label: "None", value: "None" },
      { key: "isOne", label: "1", value: "1" },
      { key: "isTwo", label: "2", value: "2" },
      { key: "isThree", label: "3", value: "3" },
      { key: "isFour", label: "4", value: "4" },
      { key: "isFiveOrMore", label: "5 or more", value: "5ormore" },
    ];

    // Check if any option is selected
    const hasSelectedOption = Object.keys(sectionData).some(
      (key) => key.startsWith("is") && sectionData[key] === "1",
    );

    return (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle(commentKey)}
            sx={{
              color:
                commentVisibility[commentKey] || sectionData.comment
                  ? "#52A1DB"
                  : "rgba(0, 0, 0, 0.26)",
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        {/* Rest of the existing render code */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 1,
            "& .MuiButton-root": {
              flex: "1 1 0",
              minWidth: "120px",
              maxWidth: "calc((100% - 40px) / 6)",
              backgroundColor: "#e3edf4", // Light blue background
              color: "#000000",
              borderColor: "#52A1DB", // Light blue border
              "&:hover": {
                backgroundColor: "#BBDEFB",
                borderColor: "#52A1DB",
              },
              "&.Mui-disabled": {
                backgroundColor: "#e3edf4",
                borderColor: "#52A1DB",
                color: "#000000",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB", // Darker blue when selected
                color: "#FFFFFF", // White text when selected
                borderColor: "#52A1DB",
                "&:hover": {
                  backgroundColor: "#4190c4",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#52A1DB",
                  color: "#FFFFFF",
                  borderColor: "#52A1DB",
                },
              },
            },
          }}
        >
          {options.map((option) => {
            const isSelected = sectionData[option.key] === "1";
            return (
              <Button
                key={option.key}
                variant={isSelected ? "contained" : "outlined"}
                onClick={() => handleRadioChange(section, null, option.value)}
                disabled={!isEditing}
              >
                {option.label}
              </Button>
            );
          })}
        </Box>
        {/* Add comment box rendering here */}
        {((!isEditing && commentVisibility[commentKey]) ||
          (isEditing &&
            (commentVisibility[commentKey] || sectionData.comment))) && (
          <Box
            sx={{
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mb: 2,
            }}
          >
            <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={sectionData.comment || ""}
              onChange={(e) =>
                handleCommentChange("pastTwelveMonths", section, e.target.value)
              }
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
              placeholder=""
            />
          </Box>
        )}
        {/* Description textbox for non-None selections */}
        {hasSelectedOption &&
          !sectionData.isNone && // Add this condition
          (section === "hospitalOvernight" ||
            section === "nursingHome" ||
            section === "hadSurgery") && (
            <Box
              sx={{
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                ml: 2,
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={2}
                value={sectionData.isOneSubAnswers?.describe || ""}
                onChange={(e) =>
                  handleVisitDescriptionChange(
                    section,
                    "describe",
                    e.target.value,
                  )
                }
                disabled={!isEditing}
                sx={{
                  ...commonTextFieldSx,
                  "& .MuiInputBase-root": {
                    fontSize: "1rem",
                    lineHeight: "1.5",
                    padding: "12px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "1rem",
                    lineHeight: "1.5",
                  },
                  "& .Mui-disabled": {
                    fontSize: "1rem",
                    color: "#000000 !important",
                    "-webkit-text-fill-color": "#000000 !important",
                  },
                  "& .MuiOutlinedInput-input::placeholder": {
                    fontSize: "1rem",
                  },
                }}
                placeholder="if one or more describe"
              />
            </Box>
          )}

        {/* Rest of the component... */}
      </Box>
    );
  };

  const renderEmergencyRoomSection = () => {
    const sectionData =
      formData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.emergencyRoom ||
      {};
    const hasComment = Boolean(sectionData.comment);

    // Helper function to map numeric values to display text
    const getEdVisitDisplayText = (value) => {
      switch (value) {
        case "1":
          return "YES";
        case "2":
          return "NO";
        case "3":
          return "UNKNOWN";
        default:
          return "";
      }
    };

    // Helper function to map display text to numeric values
    const getEdVisitValue = (text) => {
      switch (text) {
        case "YES":
          return "1";
        case "NO":
          return "2";
        case "UNKNOWN":
          return "3";
        default:
          return "";
      }
    };

    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            B. Visited the Emergency Room
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("emergencyRoom")}
            sx={{
              color: "#52A1DB",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
              },
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        {commentVisibility.emergencyRoom && (
          <Box
            sx={{
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mb: 2,
            }}
          >
            <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={sectionData.comment || ""}
              onChange={(e) =>
                handleCommentChange(
                  "pastTwelveMonths",
                  "emergencyRoom",
                  e.target.value,
                )
              }
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
              placeholder=""
            />
          </Box>
        )}

        {/* Options buttons */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: 2,
            "& .MuiButton-root": {
              flex: "1 1 0",
              minWidth: "120px",
              maxWidth: "calc((100% - 40px) / 6)", // For 6 buttons with gaps
            },
          }}
        >
          {[
            { key: "isNone", label: "None", value: "None" },
            { key: "isOne", label: "1", value: "1" },
            { key: "isTwo", label: "2", value: "2" },
            { key: "isThree", label: "3", value: "3" },
            { key: "isFour", label: "4", value: "4" },
            { key: "isFiveOrMore", label: "5 or more", value: "5ormore" },
          ].map((option) => (
            <Button
              key={option.key}
              variant={
                sectionData[option.key] === "1" ? "contained" : "outlined"
              }
              onClick={() =>
                handleRadioChange("emergencyRoom", null, option.value)
              }
              disabled={!isEditing}
              sx={{
                ...serviceButtonSx,
                width: "100%", // Ensure full width within flex container
              }}
            >
              {option.label}
            </Button>
          ))}
        </Box>

        {["isOne", "isTwo", "isThree", "isFour", "isFiveOrMore"].map(
          (visitKey) => {
            if (sectionData[visitKey] === "1") {
              const subAnswers = sectionData[`${visitKey}SubAnswers`] || {};

              return (
                <Box
                  key={visitKey}
                  sx={{
                    border: "1px solid #52A1DB",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    ml: 2,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{ textAlign: "left" }} // Added text alignment
                  >
                    Have you had a follow up visit within 7 Days of the ED
                    visit?
                  </Typography>

                  <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                    {[
                      { display: "YES", value: "1" },
                      { display: "NO", value: "2" },
                      { display: "UNKNOWN", value: "3" },
                    ].map((option) => (
                      <Button
                        key={option.display}
                        variant={
                          subAnswers.edVisit === option.value
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          handleERFollowUpVisit(visitKey, option.value)
                        }
                        disabled={!isEditing}
                        sx={{
                          ...serviceButtonSx,
                          backgroundColor: "#e3edf4",
                          borderColor: "#52A1DB",
                          color: "#000000",
                          "&:hover": {
                            backgroundColor: "rgba(16, 118, 188, 0.04)",
                            borderColor: "#52A1DB",
                          },
                          "&.MuiButton-contained": {
                            backgroundColor: "#52A1DB",
                            borderColor: "#52A1DB",
                            color: "#ffffff",
                            "&:hover": {
                              backgroundColor: "#0d5ea3",
                              borderColor: "#52A1DB",
                            },
                          },
                          "&.Mui-disabled": {
                            "&.MuiButton-contained": {
                              backgroundColor: "#52A1DB !important", // Force the background color
                              borderColor: "#52A1DB !important",
                              color: "#ffffff !important",
                              opacity: 1,
                            },
                            "&.MuiButton-outlined": {
                              backgroundColor: "#e3edf4 !important",
                              borderColor: "#52A1DB !important",
                              color: "#000000 !important",
                            },
                          },
                        }}
                      >
                        {option.display}
                      </Button>
                    ))}
                  </Box>

                  {/* Education and Follow-up sections based on response */}
                  {subAnswers.edVisit === "2" &&
                    renderEducationAndRecommendations(visitKey, "noSubAnswers")}
                  {subAnswers.edVisit === "3" &&
                    renderEducationAndRecommendations(
                      visitKey,
                      "unknownSubAnswers",
                    )}

                  {/* Visit-specific comment */}
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      sx={{
                        fontWeight: 500,
                        textAlign: "left", // Added text alignment
                      }}
                    >
                      Please describe the visit:
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                      }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        value={subAnswers.describe || ""}
                        onChange={(e) =>
                          handleERVisitDescriptionChange(
                            visitKey,
                            e.target.value,
                          )
                        }
                        disabled={!isEditing}
                        sx={{
                          ...commonTextFieldSx,
                          "& .MuiInputBase-root": {
                            fontSize: "1rem",
                            lineHeight: "1.5",
                            padding: "12px",
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "1rem",
                            lineHeight: "1.5",
                          },
                          "& .Mui-disabled": {
                            fontSize: "1rem",
                            color: "#000000 !important",
                            "-webkit-text-fill-color": "#000000 !important",
                          },
                          "& .MuiOutlinedInput-input::placeholder": {
                            fontSize: "1rem",
                          },
                        }}
                        placeholder="if one or more, describe"
                      />
                    </Box>

                    {/* Visit-specific comment */}
                    <Box sx={{ mt: 3 }}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 500,
                          textAlign: "left", // Added text alignment
                        }}
                      ></Typography>
                      <Box
                        sx={{
                          border: "1px solid #52A1DB",
                          borderRadius: "4px",
                          p: 2,
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{ minWidth: "80px", textAlign: "left" }}
                        >
                          Comments:
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          value={subAnswers.comment || ""}
                          onChange={(e) =>
                            handleERVisitCommentChange(visitKey, e.target.value)
                          }
                          disabled={!isEditing}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              color: "black",
                              WebkitTextFillColor: "black",
                              opacity: 1,
                            },
                            "& .MuiInputBase-input": {
                              "&::placeholder": {
                                color: "text.secondary",
                                opacity: 1,
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              padding: "0px !important",
                              "& fieldset": {
                                borderColor: "#52A1DB",
                              },
                              "&:hover fieldset": {
                                borderColor: "#4190c4",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#52A1DB",
                              },
                            },
                            "& textarea": {
                              height: "100% !important",
                              minHeight: "60px !important",
                              lineHeight: "1.5",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          },
        )}
      </Box>
    );
  };

  const renderPastTwelveMonthsDetails = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          In the past 12 months how many times have you?
        </Typography>
      </Box>

      {/* A. Seen your PCP */}
      {renderVisitSection("A. Seen your PCP", "pcp")}

      {/* B. Emergency Room */}
      {renderEmergencyRoomSection()}

      {renderVisitSection(
        "C. Stayed in the hospital overnight",
        "hospitalOvernight",
      )}

      {renderVisitSection("D. Been in a nursing home", "nursingHome")}

      {renderVisitSection("E. Had Surgery", "hadSurgery")}
    </Box>
  );

  // Modify desktop layout sections similarly
  const renderDesktopAssistiveDevices = () => {
    const hasCommentText =
      formData.assistiveDevices?.comment != null &&
      formData.assistiveDevices?.comment.trim() !== "";

    return (
      <Box sx={sectionBoxSx}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            Do you use any assistive devices or DME?
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("assistiveDevices")}
            sx={{
              color: "#52A1DB",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
              },
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
          <Button
            variant={
              formData.assistiveDevices.isNone === "2"
                ? "contained"
                : "outlined"
            }
            onClick={() => handleAssistiveDevicesChange("Yes")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            YES
          </Button>
          <Button
            variant={
              formData.assistiveDevices.isNone === "1"
                ? "contained"
                : "outlined"
            }
            onClick={() => handleAssistiveDevicesChange("None")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            NO
          </Button>
        </Box>
        {commentVisibility.assistiveDevices && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mt: 3, // Increased top margin for better spacing
            }}
          >
            <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={formData.assistiveDevices.comment || ""}
              onChange={(e) =>
                handleCommentChange(
                  "assistiveDevices",
                  "comment",
                  e.target.value,
                )
              }
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
            />
          </Box>
        )}
        <br />

        {formData.assistiveDevices.isNone === "2" && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                If Yes
              </Typography>
              <IconButton
                onClick={() => handleCommentToggle("assistiveDevicesIfYes")}
                sx={{
                  color:
                    commentVisibility.assistiveDevicesIfYes ||
                    formData.assistiveDevices.subAnswers.comment
                      ? "#52A1DB"
                      : "#52A1DB",
                }}
                disabled={!isEditing}
              >
                <CommentIcon />
              </IconButton>
            </Box>

            {commentVisibility.assistiveDevicesIfYes && (
              // ||formData.assistiveDevices.subAnswers.comment)
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                }}
              >
                <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                  Comments:
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  value={formData.assistiveDevices.subAnswers.comment || ""}
                  onChange={(e) =>
                    handleCommentChange(
                      "assistiveDevices",
                      "ifYesComment",
                      e.target.value,
                    )
                  }
                  disabled={!isEditing}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: "black",
                      WebkitTextFillColor: "black",
                      opacity: 1,
                    },
                    "& .MuiInputBase-input": {
                      "&::placeholder": {
                        color: "text.secondary",
                        opacity: 1,
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "0px !important",
                      "& fieldset": {
                        borderColor: "#52A1DB",
                      },
                      "&:hover fieldset": {
                        borderColor: "#4190c4",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#52A1DB",
                      },
                    },
                    "& textarea": {
                      height: "100% !important",
                      minHeight: "60px !important",
                      lineHeight: "1.5",
                    },
                  }}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                mb: 2,
              }}
            >
              {[
                { key: "isCane", label: "Cane" },
                { key: "isWalker", label: "Walker" },
                { key: "isProsthesis", label: "Prosthesis" },
                { key: "isOxygen", label: "Oxygen" },
                { key: "isWheelChair", label: "Wheel Chair" },
                { key: "isBedsideCommode", label: "Bedside Commode" },
                { key: "isurinal", label: "Urinal" },
                { key: "isBedPan", label: "Bed Pan" },
                { key: "isCPAP", label: "CPAP" },
                { key: "isOther", label: "Other" },
              ].map((device) => (
                <Button
                  key={device.key}
                  variant={
                    formData.assistiveDevices.subAnswers[device.key] === "1"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => handleAssistiveDeviceOptionChange(device.key)}
                  disabled={!isEditing}
                  sx={{
                    ...serviceButtonSx,
                    backgroundColor: "#e3edf4",
                    borderColor: "#52A1DB",
                    color: "#000000",
                    "&:hover": {
                      backgroundColor: "rgba(16, 118, 188, 0.04)",
                      borderColor: "#52A1DB",
                    },
                    "&.MuiButton-contained": {
                      backgroundColor: "#52A1DB",
                      borderColor: "#52A1DB",
                      color: "#ffffff",
                      "&:hover": {
                        backgroundColor: "#0d5ea3",
                        borderColor: "#52A1DB",
                      },
                    },
                    "&.Mui-disabled": {
                      "&.MuiButton-contained": {
                        backgroundColor: "#52A1DB !important", // Force the background color
                        borderColor: "#52A1DB !important",
                        color: "#ffffff !important",
                        opacity: 1,
                      },
                      "&.MuiButton-outlined": {
                        backgroundColor: "#e3edf4 !important",
                        borderColor: "#52A1DB !important",
                        color: "#000000 !important",
                      },
                    },
                  }}
                >
                  {device.label}
                </Button>
              ))}
            </Box>

            {formData.assistiveDevices.subAnswers.isOther === "1" &&
              renderOtherDetails()}
            {formData.assistiveDevices.subAnswers.isOxygen === "1" &&
              renderOxygenDetails()}
          </Box>
        )}

        {/* Comment box moved to the bottom, after all options */}
      </Box>
    );
  };

  const renderOtherDetails = () => {
    if (formData.assistiveDevices.subAnswers.isOther !== "1") return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Describe
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={2}
          value={formData.assistiveDevices.subAnswers.otherSubAnswers.describe}
          onChange={(e) => handleOtherDescribeChange(e.target.value)}
          disabled={!isEditing}
          sx={{
            ...commonTextFieldSx,
            "& .MuiInputBase-root": {
              backgroundColor: "rgba(16, 118, 188, 0.05)",
              borderBottom: "1px solid #52A1DB",
              borderRadius: "4px",
            },
          }}
          placeholder="Please describe other device..."
        />
      </Box>
    );
  };

  const renderOxygenDetails = () => {
    // Only show if Yes is selected AND oxygen is specifically selected
    if (
      formData.assistiveDevices.isNone !== "2" ||
      formData.assistiveDevices.subAnswers.isOxygen !== "1"
    ) {
      return null;
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1">
            Describe Oxygen Use (Select One)
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("oxygenUse")}
            sx={{
              color: "#52A1DB",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
              },
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {[
            { key: "isPRN", label: "PRN" },
            { key: "isContinuous", label: "Continuous" },
            { key: "isDay", label: "Day" },
            { key: "isNight", label: "Night" },
          ].map((option) => (
            <Button
              key={option.key}
              variant={
                formData.assistiveDevices.subAnswers.oxygenSubAnswers.describe[
                  option.key
                ] === "1"
                  ? "contained"
                  : "outlined"
              }
              onClick={() => handleOxygenDescribeChange(option.key, true)}
              disabled={!isEditing}
              sx={{
                ...subQuestionButtonSx,
                backgroundColor: "#e3edf4",
                borderColor: "#52A1DB",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "rgba(16, 118, 188, 0.04)",
                  borderColor: "#52A1DB",
                },
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB",
                  borderColor: "#52A1DB",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#0d5ea3",
                    borderColor: "#52A1DB",
                  },
                },
                "&.Mui-disabled": {
                  "&.MuiButton-contained": {
                    backgroundColor: "#52A1DB !important", // Force the background color
                    borderColor: "#52A1DB !important",
                    color: "#ffffff !important",
                    opacity: 1,
                  },
                  "&.MuiButton-outlined": {
                    backgroundColor: "#e3edf4 !important",
                    borderColor: "#52A1DB !important",
                    color: "#000000 !important",
                  },
                },
              }}
            >
              {option.label}
            </Button>
          ))}
        </Box>
        <br />
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Center vertically
            gap: 2,
            border: "1px solid #52A1DB",
            borderRadius: "4px",
            p: 2,
            mb: 2,
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <TextField
            label="Litres / Min"
            value={formData.assistiveDevices.subAnswers.oxygenSubAnswers.Litres}
            onChange={(e) => handleOxygenLitresChange(e.target.value)}
            disabled={!isEditing}
            size="medium"
            variant="outlined"
            sx={{
              ...commonTextFieldSx,
              "& .MuiInputBase-root": {
                height: "56px",
              },
            }}
          />
        </Box>

        {commentVisibility.oxygenUse && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              mt: 2,
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              backgroundColor: "white", // White background for comment box
              width: "100%", // Make the comment box full width
            }}
          >
            <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={
                formData.assistiveDevices.subAnswers.oxygenSubAnswers.describe
                  .comment || ""
              }
              onChange={(e) => handleOxygenCommentChange(e.target.value)}
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const handleOxygenCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      assistiveDevices: {
        ...prev.assistiveDevices,
        subAnswers: {
          ...prev.assistiveDevices.subAnswers,
          oxygenSubAnswers: {
            ...prev.assistiveDevices.subAnswers.oxygenSubAnswers,
            describe: {
              ...prev.assistiveDevices.subAnswers.oxygenSubAnswers.describe,
              comment: value,
            },
          },
        },
      },
    }));
  };

  const renderDesktopHealthServices = () => (
    <Box sx={sectionBoxSx}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Please choose the following health services you have received in the
          last year.
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("healthServices")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      {commentVisibility.healthServices && (
        <Box
          sx={{
            mt: 1,
            mb: 2,
            border: "1px solid #52A1DB",
            borderRadius: "4px",
            p: 2,
          }}
        >
          <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
            Comments:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={formData.healthServices?.comment || ""}
            onChange={(e) =>
              handleCommentChange("healthServices", "comment", e.target.value)
            }
            disabled={!isEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "black",
                WebkitTextFillColor: "black",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  color: "text.secondary",
                  opacity: 1,
                },
              },
              "& .MuiOutlinedInput-root": {
                padding: "0px !important",
                "& fieldset": {
                  borderColor: "#52A1DB",
                },
                "&:hover fieldset": {
                  borderColor: "#4190c4",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#52A1DB",
                },
              },
              "& textarea": {
                height: "100% !important",
                minHeight: "60px !important",
                lineHeight: "1.5",
              },
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {[
          { key: "physicalTherapist", label: "Physical Therapist" },
          { key: "occupationalTherapist", label: "Occupational Therapist" },
          { key: "dietician", label: "Dietician" },
          { key: "socialWorker", label: "Social Worker" },
          { key: "pharmacist", label: "Pharmacist" },
          { key: "speechTherapist", label: "Speech Therapist" },
          { key: "shiropractor", label: "Chiropractor" },
          {
            key: "personalCareWorker",
            label: "Personal Care Worker(HHA, CNA, PCA)",
          },
          { key: "mealsOnWheels", label: "Meals on Wheels" },
          { key: "adultDayCare", label: "Adult Day Care" },
          { key: "eyeExam", label: "Eye Exam" },
          { key: "dentalExam", label: "Dental Exam" },
          { key: "none", label: "None" },
        ].map((service) => (
          <Button
            key={service.key}
            variant={
              formData.healthServices[service.key] === "1"
                ? "contained"
                : "outlined"
            }
            onClick={() => handleServiceClick(service.key)}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            {service.label}
          </Button>
        ))}
      </Box>
    </Box>
  );

  const renderDesktopTreatments = () => (
    <Box sx={sectionBoxSx}>
      {/* Question and comment icon */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Please choose the following treatments you have received in the last
          year.
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("treatments")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>

      {/* Comment field immediately after question */}
      {commentVisibility["treatments"] && (
        <Box
          sx={{
            mt: 1,
            mb: 2,
            border: "1px solid #52A1DB",
            borderRadius: "4px",
            p: 2,
          }}
        >
          <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
            Comments:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={formData.treatments?.comment || ""}
            onChange={(e) =>
              handleCommentChange("treatments", "comment", e.target.value)
            }
            disabled={!isEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "black",
                WebkitTextFillColor: "black",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  color: "text.secondary",
                  opacity: 1,
                },
              },
              "& .MuiOutlinedInput-root": {
                padding: "0px !important",
                "& fieldset": {
                  borderColor: "#52A1DB",
                },
                "&:hover fieldset": {
                  borderColor: "#4190c4",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#52A1DB",
                },
              },
              "& textarea": {
                height: "100% !important",
                minHeight: "60px !important",
                lineHeight: "1.5",
              },
            }}
          />
        </Box>
      )}

      {/* Treatment buttons */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {[
          { key: "chemotherapy", label: "Chemotherapy" },
          { key: "catheterCare", label: "Catheter Care" },
          { key: "oxygen", label: "Oxygen" },
          { key: "woundCare", label: "Wound Care" },
          { key: "regularInjections", label: "Regular Injections" },
          { key: "tubeFeedings", label: "Tube Feedings" },
          { key: "none", label: "None" },
        ].map((treatment) => (
          <Button
            key={treatment.key}
            variant={
              formData.treatments[treatment.key] === "1"
                ? "contained"
                : "outlined"
            }
            onClick={() => handleTreatmentClick(treatment.key)}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            {treatment.label}
          </Button>
        ))}
      </Box>
    </Box>
  );

  const renderDesktopHospitalization = () => {
    // Add null check and default values
    if (!formData?.hospitalizedPrior) {
      return null;
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1">
            Have you been hospitalized prior to the last 12 months?
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("hospitalizedPrior")}
            sx={{
              color: "#52A1DB",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
              },
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>
        <br />

        <Box
          sx={{
            display: "flex",
            gap: 1,
            mb: 2,
          }}
        >
          <Button
            variant={
              formData.hospitalizedPrior.isHospitalizedPrior === "1"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleHospitalizedPriorChange("1")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            Yes
          </Button>
          <Button
            variant={
              formData.hospitalizedPrior.isHospitalizedPrior === "2"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleHospitalizedPriorChange("2")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            No
          </Button>
        </Box>

        {formData.hospitalizedPrior.isHospitalizedPrior === "1" && (
          <Box sx={{ ml: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: "primary.main",
                mb: 1,
                textAlign: "left", // Add this line to ensure left alignment
              }}
            >
              Describe
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                backgroundColor: "white", // White background for comment box
                width: "100%", // Make the comment box full width
              }}
            >
              <TextField
                fullWidth
                multiline
                rows={2}
                value={
                  formData.hospitalizedPrior.hospitalizedPriorSubAnswers
                    ?.describe || ""
                }
                onChange={(e) =>
                  handleHospitalizationDescriptionChange(e.target.value)
                }
                disabled={!isEditing}
                size="small"
                variant="outlined"
                sx={{
                  ...commonTextFieldSx,
                  "& .MuiInputBase-root": {
                    borderBottom: "1px solid #2196f3",
                    borderRadius: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#52A1DB",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        )}

        {commentVisibility.hospitalizedPrior && (
          <Box sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mt: 1,
              }}
            >
              <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
                Comments:
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={2}
                value={formData.hospitalizedPrior.comment || ""}
                onChange={(e) =>
                  handleCommentChange(
                    "hospitalizedPrior",
                    "comment",
                    e.target.value,
                  )
                }
                disabled={!isEditing}
                size="small"
                placeholder=""
                sx={{
                  "& .MuiInputBase-input.Mui-disabled": {
                    color: "black",
                    WebkitTextFillColor: "black",
                    opacity: 1,
                  },
                  "& .MuiInputBase-input": {
                    "&::placeholder": {
                      color: "text.secondary",
                      opacity: 1,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: "0px !important",
                    "& fieldset": {
                      borderColor: "#52A1DB",
                    },
                    "&:hover fieldset": {
                      borderColor: "#4190c4",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#52A1DB",
                    },
                  },
                  "& textarea": {
                    height: "100% !important",
                    minHeight: "60px !important",
                    lineHeight: "1.5",
                  },
                }}
              />
            </Box>
          </Box>
        )}

        <br />
      </Box>
    );
  };

  const { user = {} } = useSelector((state) => state?.auth ?? {});

  const renderMobileAssistiveDevices = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Do you use any assistive devices or DME?
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("assistiveDevices")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>

      <Box sx={{ mb: 3, display: "flex", gap: 1 }}>
        <Button
          variant={
            formData.assistiveDevices.isNone === "2" ? "contained" : "outlined"
          }
          color="primary"
          onClick={() => handleAssistiveDevicesChange("Yes")}
          disabled={!isEditing}
          sx={{
            ...serviceButtonSx,
            backgroundColor: "#e3edf4",
            borderColor: "#52A1DB",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgba(16, 118, 188, 0.04)",
              borderColor: "#52A1DB",
            },
            "&.MuiButton-contained": {
              backgroundColor: "#52A1DB",
              borderColor: "#52A1DB",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#0d5ea3",
                borderColor: "#52A1DB",
              },
            },
            "&.Mui-disabled": {
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB !important", // Force the background color
                borderColor: "#52A1DB !important",
                color: "#ffffff !important",
                opacity: 1,
              },
              "&.MuiButton-outlined": {
                backgroundColor: "#e3edf4 !important",
                borderColor: "#52A1DB !important",
                color: "#000000 !important",
              },
            },
          }}
        >
          YES
        </Button>
        <Button
          variant={
            formData.assistiveDevices.isNone === "1" ? "contained" : "outlined"
          }
          color="primary"
          onClick={() => handleAssistiveDevicesChange("None")}
          disabled={!isEditing}
          sx={{
            ...serviceButtonSx,
            backgroundColor: "#e3edf4",
            borderColor: "#52A1DB",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgba(16, 118, 188, 0.04)",
              borderColor: "#52A1DB",
            },
            "&.MuiButton-contained": {
              backgroundColor: "#52A1DB",
              borderColor: "#52A1DB",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#0d5ea3",
                borderColor: "#52A1DB",
              },
            },
            "&.Mui-disabled": {
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB !important", // Force the background color
                borderColor: "#52A1DB !important",
                color: "#ffffff !important",
                opacity: 1,
              },
              "&.MuiButton-outlined": {
                backgroundColor: "#e3edf4 !important",
                borderColor: "#52A1DB !important",
                color: "#000000 !important",
              },
            },
          }}
        >
          NO
        </Button>
      </Box>

      {formData.assistiveDevices.isNone === "2" && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {[
            { key: "isCane", label: "Cane" },
            { key: "isWalker", label: "Walker" },
            { key: "isProsthesis", label: "Prosthesis" },
            { key: "isOxygen", label: "Oxygen" },
            { key: "isWheelChair", label: "Wheel Chair" },
            { key: "isBedsideCommode", label: "Bedside Commode" },
            { key: "isurinal", label: "Urinal" },
            { key: "isBedPan", label: "Bed Pan" },
            { key: "isCPAP", label: "CPAP" },
            { key: "isOther", label: "Other" },
          ].map((device) => (
            <Button
              key={device.key}
              variant={
                formData.assistiveDevices.subAnswers[device.key] === "1"
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              onClick={() => handleAssistiveDeviceOptionChange(device.key)}
              disabled={!isEditing}
              sx={{
                ...serviceButtonSx,
                backgroundColor: "#e3edf4",
                borderColor: "#52A1DB",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "rgba(16, 118, 188, 0.04)",
                  borderColor: "#52A1DB",
                },
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB",
                  borderColor: "#52A1DB",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#0d5ea3",
                    borderColor: "#52A1DB",
                  },
                },
                "&.Mui-disabled": {
                  "&.MuiButton-contained": {
                    backgroundColor: "#52A1DB !important", // Force the background color
                    borderColor: "#52A1DB !important",
                    color: "#ffffff !important",
                    opacity: 1,
                  },
                  "&.MuiButton-outlined": {
                    backgroundColor: "#e3edf4 !important",
                    borderColor: "#52A1DB !important",
                    color: "#000000 !important",
                  },
                },
              }}
            >
              {device.label}
            </Button>
          ))}
        </Box>
      )}
      {formData.assistiveDevices.isNone === "2" && renderOtherDetails()}
      {formData.assistiveDevices.isNone === "2" &&
        formData.assistiveDevices.subAnswers.isOxygen === "1" &&
        renderOxygenDetails()}
      {renderCommentField("assistiveDevices")}
    </Box>
  );

  const renderMobileSpecialists = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Are you currently seeing any specialists?
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("specialists")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Button
          variant={
            formData.specialists.isSpecialists === "1"
              ? "contained"
              : "outlined"
          }
          color="primary"
          onClick={() => handleSpecialistsToggle("1")}
          disabled={!isEditing}
          sx={{
            ...serviceButtonSx,
            backgroundColor: "#e3edf4",
            borderColor: "#52A1DB",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgba(16, 118, 188, 0.04)",
              borderColor: "#52A1DB",
            },
            "&.MuiButton-contained": {
              backgroundColor: "#52A1DB",
              borderColor: "#52A1DB",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#0d5ea3",
                borderColor: "#52A1DB",
              },
            },
            "&.Mui-disabled": {
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB !important", // Force the background color
                borderColor: "#52A1DB !important",
                color: "#ffffff !important",
                opacity: 1,
              },
              "&.MuiButton-outlined": {
                backgroundColor: "#e3edf4 !important",
                borderColor: "#52A1DB !important",
                color: "#000000 !important",
              },
            },
          }}
        >
          Yes
        </Button>
        <Button
          variant={
            formData.specialists.isSpecialists === "2"
              ? "contained"
              : "outlined"
          }
          color="primary"
          onClick={() => handleSpecialistsToggle("2")}
          disabled={!isEditing}
          sx={{
            ...serviceButtonSx,
            backgroundColor: "#e3edf4",
            borderColor: "#52A1DB",
            color: "#000000",
            "&:hover": {
              backgroundColor: "rgba(16, 118, 188, 0.04)",
              borderColor: "#52A1DB",
            },
            "&.MuiButton-contained": {
              backgroundColor: "#52A1DB",
              borderColor: "#52A1DB",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#0d5ea3",
                borderColor: "#52A1DB",
              },
            },
            "&.Mui-disabled": {
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB !important", // Force the background color
                borderColor: "#52A1DB !important",
                color: "#ffffff !important",
                opacity: 1,
              },
              "&.MuiButton-outlined": {
                backgroundColor: "#e3edf4 !important",
                borderColor: "#52A1DB !important",
                color: "#000000 !important",
              },
            },
          }}
        >
          No
        </Button>
      </Box>

      {!formData.specialists.isSpecialists && (
        <Typography color="textSecondary" sx={{ mb: 2 }}>
          Please select Yes or No
        </Typography>
      )}

      {formData.specialists.isSpecialists === "1" && (
        <Box sx={{ mt: 2 }}>
          <TableContainer
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              borderRadius: 1,
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeaderSx}>Medical Specialty</TableCell>
                  <TableCell sx={tableHeaderSx}>Specialist Name</TableCell>
                  <TableCell sx={tableHeaderSx}>For</TableCell>
                  <TableCell sx={{ width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.specialists.specialistsSubAnswers.map(
                  (specialist, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          fullWidth
                          select
                          value={specialist.medicalSp || ""}
                          onChange={(e) =>
                            handleSpecialistChange(
                              index,
                              "medicalSp",
                              e.target.value,
                            )
                          }
                          disabled={!isEditing}
                          size="small"
                          SelectProps={{
                            displayEmpty: true,
                          }}
                          sx={{
                            ...commonTextFieldSx,
                            "& .MuiInputBase-root": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiSelect-select": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            },
                            "& .Mui-disabled": {
                              "-webkit-text-fill-color": "#000000 !important",
                              color: "#000000 !important",
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            Please select
                          </MenuItem>
                          {specialistOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              sx={{
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={specialist.specialist || ""}
                          onChange={(e) =>
                            handleSpecialistChange(
                              index,
                              "specialist",
                              e.target.value,
                            )
                          }
                          disabled={!isEditing}
                          size="small"
                          multiline
                          placeholder="Enter specialist name"
                          sx={{
                            ...commonTextFieldSx,
                            "& .MuiInputBase-root": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiInputBase-input": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              "&:hover, &:focus": {
                                overflow: "visible",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              },
                            },
                            "& .Mui-disabled": {
                              "-webkit-text-fill-color": "#000000 !important",
                              color: "#000000 !important",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={specialist.for || ""}
                          onChange={(e) =>
                            handleSpecialistChange(index, "for", e.target.value)
                          }
                          disabled={!isEditing}
                          size="small"
                          multiline
                          placeholder="Enter reason"
                          sx={{
                            ...commonTextFieldSx,
                            "& .MuiInputBase-root": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiInputBase-input": {
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              "&:hover, &:focus": {
                                overflow: "visible",
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              },
                            },
                            "& .Mui-disabled": {
                              "-webkit-text-fill-color": "#000000 !important",
                              color: "#000000 !important",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleRemoveSpecialist(index)}
                          disabled={!isEditing}
                          size="small"
                          sx={{ color: "#52A1DB" }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddSpecialist}
            disabled={!isEditing}
            sx={{
              mt: 2,
              color: "#52A1DB",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
              },
            }}
          >
            Add Specialist
          </Button>
        </Box>
      )}

      {commentVisibility.specialists && (
        <Box
          sx={{
            border: "1px solid #52A1DB",
            borderRadius: "4px",
            p: 2,
            mt: 2,
          }}
        >
          <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
            Comments:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={2}
            value={formData.specialists.comment || ""}
            onChange={(e) => handleSpecialistCommentChange(e.target.value)}
            disabled={!isEditing}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                color: "black",
                WebkitTextFillColor: "black",
                opacity: 1,
              },
              "& .MuiInputBase-input": {
                "&::placeholder": {
                  color: "text.secondary",
                  opacity: 1,
                },
              },
              "& .MuiOutlinedInput-root": {
                padding: "0px !important",
                "& fieldset": {
                  borderColor: "#52A1DB",
                },
                "&:hover fieldset": {
                  borderColor: "#4190c4",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#52A1DB",
                },
              },
              "& textarea": {
                height: "100% !important",
                minHeight: "60px !important",
                lineHeight: "1.5",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );

  const renderMobileHealthServices = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Please choose the following health services you have received in the
          last year.
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("healthServices")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "#52A1DB", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {[
          { key: "physicalTherapist", label: "Physical Therapist" },
          { key: "occupationalTherapist", label: "Occupational Therapist" },
          { key: "dietician", label: "Dietician" },
          { key: "socialWorker", label: "Social Worker" },
          { key: "pharmacist", label: "Pharmacist" },
          { key: "speechTherapist", label: "Speech Therapist" },
          { key: "shiropractor", label: "Chiropractor" },
          {
            key: "personalCareWorker",
            label: "Personal Care Worker(HHA, CNA, PCA)",
          },
          { key: "mealsOnWheels", label: "Meals on Wheels" },
          { key: "adultDayCare", label: "Adult Day Care" },
          { key: "eyeExam", label: "Eye Exam" },
          { key: "dentalExam", label: "Dental Exam" },
          { key: "none", label: "None" },
        ].map((service) => (
          <Button
            key={service.key}
            variant={
              formData.healthServices[service.key] === "1"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleServiceClick(service.key)}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            {service.label}
          </Button>
        ))}
      </Box>
      {renderCommentField("healthServices")}
    </Box>
  );

  const renderMobileTreatments = () => (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">
          Please choose the following treatments you have received in the last
          year.
        </Typography>
        <IconButton
          onClick={() => handleCommentToggle("treatments")}
          sx={{
            color: "#52A1DB",
            "&.Mui-disabled": {
              color: "#52A1DB", // Keep same color when disabled
            },
          }}
          disabled={!isEditing}
        >
          <CommentIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {[
          { key: "chemotherapy", label: "Chemotherapy" },
          { key: "catheterCare", label: "Catheter Care" },
          { key: "oxygen", label: "Oxygen" },
          { key: "woundCare", label: "Wound Care" },
          { key: "regularInjections", label: "Regular Injections" },
          { key: "tubeFeedings", label: "Tube Feedings" },
          { key: "none", label: "None" },
        ].map((treatment) => (
          <Button
            key={treatment.key}
            variant={
              formData.treatments[treatment.key] === "1"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleTreatmentClick(treatment.key)}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            {treatment.label}
          </Button>
        ))}
      </Box>
      {renderCommentField("treatments")}
    </Box>
  );

  // Add this calculation before using isAnyDeviceSelected
  const isAnyDeviceSelected = Object.values(
    formData.assistiveDevices.subAnswers,
  ).some((value) => value === "1" && typeof value === "string");

  // Add this function to render the additional fields
  const renderEmergencyRoomDetails = (visitKey) => {
    if (!visitKey || visitKey === "isNone") return null;

    return (
      <Box sx={{ mt: 2, ml: 2 }}>
        <TextField
          fullWidth
          multiline
          rows={2}
          label="Describe..."
          value={
            formData.pastTwelveMonths.pastTwelveMonthsSubAnswers.emergencyRoom[
              `${visitKey}SubAnswers`
            ]?.describe || ""
          }
          onChange={(e) => handleERDescriptionChange(visitKey, e.target.value)}
          disabled={!isEditing}
          sx={commonTextFieldSx}
        />

        <FormControl component="fieldset" sx={{ mt: 2 }}>
          <FormLabel component="legend">Follow up visit?</FormLabel>
          <RadioGroup
            value={
              formData.pastTwelveMonths.pastTwelveMonthsSubAnswers
                .emergencyRoom[`${visitKey}SubAnswers`]?.edVisit || ""
            }
            onChange={(e) =>
              handleRadioChange(
                "edVisit",
                `${visitKey}SubAnswers`,
                e.target.value,
              )
            }
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label="Yes"
              disabled={!isEditing}
            />
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="No"
              disabled={!isEditing}
            />
            <FormControlLabel
              value="unknown"
              control={<Radio />}
              label="Unknown"
              disabled={!isEditing}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    );
  };

  // Add these handler functions before the render methods

  const handleERDescribeChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      emergencyRoom: {
        ...prev.emergencyRoom,
        describe: value,
      },
    }));
  };

  const handleERCommentChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      emergencyRoom: {
        ...prev.emergencyRoom,
        comment: value,
      },
    }));
  };

  const handleERDescriptionChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      emergencyRoom: {
        ...prev.emergencyRoom,
        describe: value,
      },
    }));
  };

  // Move this function before the return statement,
  // after renderDesktopTreatments and before the return
  const renderSpecialistsSection = () => {
    return (
      <Box sx={sectionBoxSx}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">
            Are you currently seeing any specialists?
          </Typography>
          <IconButton
            onClick={() => handleCommentToggle("specialists")}
            sx={{
              color: "#52A1DB",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)", // Keep same color when disabled
              },
            }}
            disabled={!isEditing}
          >
            <CommentIcon />
          </IconButton>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Button
            variant={
              formData.specialists.isSpecialists === "1"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleSpecialistsToggle("1")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            Yes
          </Button>
          <Button
            variant={
              formData.specialists.isSpecialists === "2"
                ? "contained"
                : "outlined"
            }
            color="primary"
            onClick={() => handleSpecialistsToggle("2")}
            disabled={!isEditing}
            sx={{
              ...serviceButtonSx,
              backgroundColor: "#e3edf4",
              borderColor: "#52A1DB",
              color: "#000000",
              "&:hover": {
                backgroundColor: "rgba(16, 118, 188, 0.04)",
                borderColor: "#52A1DB",
              },
              "&.MuiButton-contained": {
                backgroundColor: "#52A1DB",
                borderColor: "#52A1DB",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0d5ea3",
                  borderColor: "#52A1DB",
                },
              },
              "&.Mui-disabled": {
                "&.MuiButton-contained": {
                  backgroundColor: "#52A1DB !important", // Force the background color
                  borderColor: "#52A1DB !important",
                  color: "#ffffff !important",
                  opacity: 1,
                },
                "&.MuiButton-outlined": {
                  backgroundColor: "#e3edf4 !important",
                  borderColor: "#52A1DB !important",
                  color: "#000000 !important",
                },
              },
            }}
          >
            No
          </Button>
        </Box>

        {!formData.specialists.isSpecialists && (
          <Typography color="textSecondary" sx={{ mb: 2 }}>
            Please select Yes or No
          </Typography>
        )}

        {formData.specialists.isSpecialists === "1" && (
          <Box sx={{ mt: 2 }}>
            <TableContainer
              sx={{
                border: "1px solid rgba(224, 224, 224, 1)",
                borderRadius: 1,
                overflowX: "auto",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={tableHeaderSx}>Medical Specialty</TableCell>
                    <TableCell sx={tableHeaderSx}>Specialist Name</TableCell>
                    <TableCell sx={tableHeaderSx}>For</TableCell>
                    <TableCell sx={{ width: "10%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.specialists.specialistsSubAnswers.map(
                    (specialist, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <TextField
                            fullWidth
                            select
                            value={specialist.medicalSp || ""}
                            onChange={(e) =>
                              handleSpecialistChange(
                                index,
                                "medicalSp",
                                e.target.value,
                              )
                            }
                            disabled={!isEditing}
                            size="small"
                            SelectProps={{
                              displayEmpty: true,
                            }}
                            sx={{
                              ...commonTextFieldSx,
                              "& .MuiInputBase-root": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiSelect-select": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              },
                              "& .Mui-disabled": {
                                "-webkit-text-fill-color": "#000000 !important",
                                color: "#000000 !important",
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              Please select
                            </MenuItem>
                            {specialistOptions.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                                sx={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                }}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={specialist.specialist || ""}
                            onChange={(e) =>
                              handleSpecialistChange(
                                index,
                                "specialist",
                                e.target.value,
                              )
                            }
                            disabled={!isEditing}
                            size="small"
                            multiline
                            placeholder="Enter specialist name"
                            sx={{
                              ...commonTextFieldSx,
                              "& .MuiInputBase-root": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                "&:hover, &:focus": {
                                  overflow: "visible",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                },
                              },
                              "& .Mui-disabled": {
                                "-webkit-text-fill-color": "#000000 !important",
                                color: "#000000 !important",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            fullWidth
                            value={specialist.for || ""}
                            onChange={(e) =>
                              handleSpecialistChange(
                                index,
                                "for",
                                e.target.value,
                              )
                            }
                            disabled={!isEditing}
                            size="small"
                            multiline
                            placeholder="Enter reason"
                            sx={{
                              ...commonTextFieldSx,
                              "& .MuiInputBase-root": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                "&:hover, &:focus": {
                                  overflow: "visible",
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                },
                              },
                              "& .Mui-disabled": {
                                "-webkit-text-fill-color": "#000000 !important",
                                color: "#000000 !important",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleRemoveSpecialist(index)}
                            disabled={!isEditing}
                            size="small"
                            sx={{ color: "#52A1DB" }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddSpecialist}
              disabled={!isEditing}
              sx={{
                mt: 2,
                color: "#52A1DB",
                "&:hover": {
                  backgroundColor: "rgba(16, 118, 188, 0.04)",
                },
              }}
            >
              Add Specialist
            </Button>
          </Box>
        )}

        {commentVisibility.specialists && (
          <Box
            sx={{
              border: "1px solid #52A1DB",
              borderRadius: "4px",
              p: 2,
              mt: 2,
            }}
          >
            <Typography sx={{ minWidth: "100px", textAlign: "left" }}>
              Comments:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={formData.specialists.comment || ""}
              onChange={(e) => handleSpecialistCommentChange(e.target.value)}
              disabled={!isEditing}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  color: "black",
                  WebkitTextFillColor: "black",
                  opacity: 1,
                },
                "& .MuiInputBase-input": {
                  "&::placeholder": {
                    color: "text.secondary",
                    opacity: 1,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  padding: "0px !important",
                  "& fieldset": {
                    borderColor: "#52A1DB",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4190c4",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#52A1DB",
                  },
                },
                "& textarea": {
                  height: "100% !important",
                  minHeight: "60px !important",
                  lineHeight: "1.5",
                },
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const buttonGroupSx = {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
    mb: 2,
  };

  const optionButtonSx = {
    flex: "1 1 auto",
    maxWidth: "200px",
    minWidth: "120px",
    borderRadius: "4px",
    textTransform: "none",
    ...buttonSx,
  };

  // Create serviceButtonSx inside component with isEditing dependency
  const serviceButtonSx = {
    ...baseServiceButtonSx,
    ...buttonSx,
  };

  // Add this handler to toggle oxygen details
  const handleOxygenDetailsToggle = () => {
    setShowOxygenDetails((prev) => !prev);
  };

  // Update useEffect to set initial comment visibility based on existing comments
  useEffect(() => {
    const initialVisibility = {};

    // Helper function to check if a comment exists
    const hasComment = (value) => Boolean(value && value.trim());

    // Set visibility for main sections
    initialVisibility.assistiveDevices = hasComment(
      formData.assistiveDevices?.comment,
    );
    initialVisibility.specialists = hasComment(formData.specialists?.comment);
    initialVisibility.hospitalizedPrior = hasComment(
      formData.hospitalizedPrior?.comment,
    );
    initialVisibility.healthServices = hasComment(
      formData.healthServices?.comment,
    );
    initialVisibility.treatments = hasComment(formData.treatments?.comment);

    // Set visibility for past twelve months subsections
    const pastTwelveMonthsSections = [
      "pcp",
      "emergencyRoom",
      "hospitalOvernight",
      "nursingHome",
      "hadSurgery",
    ];
    pastTwelveMonthsSections.forEach((section) => {
      initialVisibility[`pastTwelveMonths-${section}`] = hasComment(
        formData.pastTwelveMonths?.pastTwelveMonthsSubAnswers?.[section]
          ?.comment,
      );
    });

    setCommentVisibility((prev) => ({
      ...prev,
      ...initialVisibility,
    }));
  }, []); // Only run once on component mount

  // Add this handler with other handlers
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleVisitDescriptionChange = (section, field, value) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      pastTwelveMonths: {
        ...prev.pastTwelveMonths,
        pastTwelveMonthsSubAnswers: {
          ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
          [section]: {
            ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section],
            isOneSubAnswers: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[section]
                .isOneSubAnswers,
              describe: value,
            },
          },
        },
      },
    }));
  };

  const handleCommentChange = (section, subsection, value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      if (section === "assistiveDevices") {
        if (subsection === "ifYesComment") {
          return {
            ...prev,
            assistiveDevices: {
              ...prev.assistiveDevices,
              subAnswers: {
                ...prev.assistiveDevices.subAnswers,
                comment: value,
              },
            },
          };
        }
        return {
          ...prev,
          assistiveDevices: {
            ...prev.assistiveDevices,
            comment: value,
          },
        };
      }

      if (section === "pastTwelveMonths") {
        return {
          ...prev,
          pastTwelveMonths: {
            ...prev.pastTwelveMonths,
            pastTwelveMonthsSubAnswers: {
              ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers,
              [subsection]: {
                ...prev.pastTwelveMonths.pastTwelveMonthsSubAnswers[subsection],
                comment: value,
              },
            },
          },
        };
      }

      if (section === "specialists") {
        return {
          ...prev,
          specialists: {
            ...prev.specialists,
            comment: value,
          },
        };
      }

      if (section === "hospitalizedPrior") {
        return {
          ...prev,
          hospitalizedPrior: {
            ...prev.hospitalizedPrior,
            comment: value,
          },
        };
      }

      // For healthServices and treatments
      return {
        ...prev,
        [section]: {
          ...prev[section],
          comment: value,
        },
      };
    });
  };

  // Common IconButton style that can be reused
  const commentIconButtonSx = {
    color: "#52A1DB !important", // Force blue color as base
    opacity: "1 !important", // Maintain full opacity
    "& .MuiSvgIcon-root": {
      color: "#52A1DB !important", // Force blue color on the icon itself
    },
    "&.Mui-disabled": {
      color: "#52A1DB !important",
      opacity: "1 !important",
      "& .MuiSvgIcon-root": {
        color: "#52A1DB !important",
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        {/* <Typography variant="h5" gutterBottom>
          Medical History Assessment
        </Typography> */}

        {message && (
          <Alert
            severity={message.includes("successfully") ? "success" : "error"}
            sx={{ mb: 3 }}
            onClose={() => setMessage("")}
          >
            {message}
          </Alert>
        )}

        {/* {changedFields.length > 0 && (
          <Alert
            severity="info"
            sx={{
              mb: 3,
              "& .MuiAlert-message": {
                width: "100%",
              },
            }}
            onClose={() => setChangedFields([])}
          >
            <Typography variant="subtitle1" gutterBottom>
              Updated Fields:
            </Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table size="small">
                <TableBody>
                  {changedFields.map((change, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontWeight: "bold", width: "30%" }}>
                        {change.field}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography color="error" variant="body2">
                            Previous:{" "}
                            {JSON.stringify(change.previousValue, null, 2)}
                          </Typography>
                          <Typography color="success.main" variant="body2">
                            New: {JSON.stringify(change.newValue, null, 2)}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Alert>
        )} */}

        {isMobile ? (
          // Mobile Layout
          <Stack spacing={3} sx={{ mt: 3 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Assistive Devices
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderMobileAssistiveDevices()}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Specialists
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{renderMobileSpecialists()}</AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Past 12 Months
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderPastTwelveMonthsDetails()}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Prior Hospitalization
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderDesktopHospitalization()}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Health Services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderMobileHealthServices()}
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "left", width: "100%" }}
                >
                  Treatments
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{renderMobileTreatments()}</AccordionDetails>
            </Accordion>
          </Stack>
        ) : (
          // Desktop Layout
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}
            >
              <Tab label="Assistive Devices" />
              <Tab label="Specialists" />
              <Tab label="Past 12 Months" />
              <Tab label="Prior Hospitalization" />
              <Tab label="Health Services" />
              <Tab label="Treatments" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
              {activeTab === 0 && renderDesktopAssistiveDevices()}
              {activeTab === 1 && renderSpecialistsSection()}
              {activeTab === 2 && renderPastTwelveMonthsDetails()}
              {activeTab === 3 && renderDesktopHospitalization()}
              {activeTab === 4 && renderDesktopHealthServices()}
              {activeTab === 5 && renderDesktopTreatments()}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              {!isEditing ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  fullWidth={isMobile}
                  sx={{ maxWidth: isMobile ? "100%" : "200px" }}
                >
                  Edit
                </Button>
              ) : (
                <Stack
                  direction={isMobile ? "column" : "row"}
                  spacing={2}
                  width={isMobile ? "100%" : "auto"}
                >
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!isEditing}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    disabled={!isEditing}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default MedicalHistory;
