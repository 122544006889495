import React from "react";
import {
  Typography,
  Box,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Divider,
} from "@mui/material";
import AlcoholAssessment from "./AlcoholAssessment";
import EducationGuidelines from "./EducationGuidelines";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";

// Update radio style to always use blue for checked state
const radioStyle = {
  "&.Mui-checked": {
    color: "#52A1DB", // Blue for checked state (both edit and non-edit mode)
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#52A1DB", // Keep blue for checked state in non-edit mode
  },
  "&.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)", // For unchecked disabled state
  },
};

export const SubstanceSection = ({ formData, isEditing, setFormData }) => {
  // Add this new state for managing comment visibility
  const [visibleComments, setVisibleComments] = React.useState({});

  // Add this helper function to toggle comment visibility
  const toggleComment = (section) => {
    setVisibleComments((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Update the helper function to check if comment should be visible
  const isCommentVisible = (section, commentValue) => {
    // When editing, show if either the section is toggled or there's content
    if (isEditing) {
      return visibleComments[section];
      // || (commentValue && commentValue.trim() !== "")
    }
    // When not editing (after save), only show if there's content
    return visibleComments[section];
    // && commentValue.trim() !== "";
  };

  // Helper function to handle radio button clicks
  const handleRadioClick = (e, onChange) => {
    e.stopPropagation();
    onChange(e.target.value);
  };

  const getCurrentTobaccoStatus = () => {
    const { tobaccoUse } = formData.substanceUse;
    if (tobaccoUse.current === "1") return "current";
    if (tobaccoUse.former === "1") return "former";
    if (tobaccoUse.never === "1") return "never";
    return null;
  };

  const handleTobaccoStatusChange = (status) => {
    if (!isEditing) return;

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        tobaccoUse: {
          ...prev.substanceUse.tobaccoUse,
          current: status === "current" ? "1" : "",
          former: status === "former" ? "1" : "",
          never: status === "never" ? "1" : "",
        },
      },
    }));
  };

  const handlePreventingFactorsChange = (factor) => {
    if (!isEditing) return;

    setFormData((prev) => {
      // Ensure the nested path exists with default empty objects
      const currentState = {
        ...prev,
        substanceUse: {
          ...(prev.substanceUse || {}),
          tobaccoUse: {
            ...(prev.substanceUse?.tobaccoUse || {}),
            currentSubAnswer: {
              ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer || {}),
              type: {
                ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer?.type ||
                  {}),
                cigarettesSubAnswer: {
                  ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer?.type
                    ?.cigarettesSubAnswer || {}),
                  thingsPreventing: {
                    ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer?.type
                      ?.cigarettesSubAnswer?.thingsPreventing || {}),
                  },
                },
              },
            },
          },
        },
      };

      const thingsPreventing =
        currentState.substanceUse.tobaccoUse.currentSubAnswer.type
          .cigarettesSubAnswer.thingsPreventing;

      // If "None" is selected, clear all other selections
      if (factor === "none") {
        const updatedThingsPreventing = {
          educationNeeded: "",
          socialSupport: "",
          environmentalFactors: "",
          stress: "",
          selfConfidence: "",
          lackOfMotivation: "",
          notAPriority: "",
          other: "",
          none: thingsPreventing.none === "1" ? "" : "1", // Toggle None
          comment: thingsPreventing.comment || "",
        };

        return {
          ...currentState,
          substanceUse: {
            ...currentState.substanceUse,
            tobaccoUse: {
              ...currentState.substanceUse.tobaccoUse,
              currentSubAnswer: {
                ...currentState.substanceUse.tobaccoUse.currentSubAnswer,
                type: {
                  ...currentState.substanceUse.tobaccoUse.currentSubAnswer.type,
                  cigarettesSubAnswer: {
                    ...currentState.substanceUse.tobaccoUse.currentSubAnswer
                      .type.cigarettesSubAnswer,
                    thingsPreventing: updatedThingsPreventing,
                  },
                },
              },
            },
          },
        };
      }

      // If any other option is selected, clear "None"
      return {
        ...currentState,
        substanceUse: {
          ...currentState.substanceUse,
          tobaccoUse: {
            ...currentState.substanceUse.tobaccoUse,
            currentSubAnswer: {
              ...currentState.substanceUse.tobaccoUse.currentSubAnswer,
              type: {
                ...currentState.substanceUse.tobaccoUse.currentSubAnswer.type,
                cigarettesSubAnswer: {
                  ...currentState.substanceUse.tobaccoUse.currentSubAnswer.type
                    .cigarettesSubAnswer,
                  thingsPreventing: {
                    ...thingsPreventing,
                    [factor]: thingsPreventing[factor] === "1" ? "" : "1",
                    none: "", // Clear "None" when any other option is selected
                  },
                },
              },
            },
          },
        },
      };
    });
  };

  const handleTobaccoTypeChange = (value) => {
    if (!isEditing) return;

    const typeSection =
      formData.substanceUse.tobaccoUse.current === "1"
        ? "currentSubAnswer"
        : "formerSubAnswer";
    const currentType =
      formData.substanceUse.tobaccoUse[typeSection].type[value];
    const isSelected = currentType === "1";

    const updatedType = {
      cigarettes: "",
      cigars: "",
      chewingTobacco: "",
      vaping: "",
      other: "",
      otherSubAnswer: {
        describe: "",
      },
    };

    if (!isSelected) {
      updatedType[value] = "1";
    }

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        tobaccoUse: {
          ...prev.substanceUse.tobaccoUse,
          [typeSection]: {
            ...prev.substanceUse.tobaccoUse[typeSection],
            type: {
              ...prev.substanceUse.tobaccoUse[typeSection].type,
              ...updatedType,
            },
          },
        },
      },
    }));
  };

  const handleCigaretteAmountChange = (value) => {
    if (!isEditing) return;

    setFormData((prev) => {
      // Ensure all nested objects exist
      const currentState = {
        ...prev,
        substanceUse: {
          ...(prev.substanceUse || {}),
          tobaccoUse: {
            ...(prev.substanceUse?.tobaccoUse || {}),
            currentSubAnswer: {
              ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer || {}),
              type: {
                ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer?.type ||
                  {}),
                cigarettesSubAnswer: {
                  ...(prev.substanceUse?.tobaccoUse?.currentSubAnswer?.type
                    ?.cigarettesSubAnswer || {}),
                },
              },
            },
          },
        },
      };

      // Get current cigarettesSubAnswer or empty object
      const currentCigarettesSubAnswer =
        currentState.substanceUse?.tobaccoUse?.currentSubAnswer?.type
          ?.cigarettesSubAnswer || {};

      const isSelected = currentCigarettesSubAnswer[value] === "1";

      const updatedAmount = {
        oneToThree: "",
        halfAPack: "",
        onePack: "",
        moreThanOne: "",
        other: "",
      };

      if (!isSelected) {
        updatedAmount[value] = "1";
      }

      return {
        ...currentState,
        substanceUse: {
          ...currentState.substanceUse,
          tobaccoUse: {
            ...currentState.substanceUse.tobaccoUse,
            currentSubAnswer: {
              ...currentState.substanceUse.tobaccoUse.currentSubAnswer,
              type: {
                ...currentState.substanceUse.tobaccoUse.currentSubAnswer.type,
                cigarettesSubAnswer: {
                  ...currentState.substanceUse.tobaccoUse.currentSubAnswer.type
                    .cigarettesSubAnswer,
                  ...updatedAmount,
                },
              },
            },
          },
        },
      };
    });
  };

  const handleCommentChange = (path, value) => {
    if (!isEditing) return;

    const updateNestedState = (obj, path, value) => {
      const pathArray = path.split(".");
      let current = obj;

      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]];
      }

      current[pathArray[pathArray.length - 1]] = {
        ...current[pathArray[pathArray.length - 1]],
        comment: value,
      };

      return { ...obj };
    };

    setFormData((prev) => ({
      ...prev,
      substanceUse: updateNestedState(prev.substanceUse, path, value),
    }));
  };

  const handleQuitTimeChange = (value) => {
    if (!isEditing) return;

    // Check if clicking the same option that's already selected
    const isCurrentlySelected =
      formData.substanceUse.tobaccoUse.formerSubAnswer.when[value] === "1";

    setFormData((prev) => ({
      ...prev,
      substanceUse: {
        ...prev.substanceUse,
        tobaccoUse: {
          ...prev.substanceUse.tobaccoUse,
          formerSubAnswer: {
            ...prev.substanceUse.tobaccoUse.formerSubAnswer,
            when: {
              withinLastYear: "",
              withinLastThreeYear: "",
              moreYearAgo: "",
              ...(isCurrentlySelected ? {} : { [value]: "1" }),
            },
          },
        },
      },
    }));
  };

  const validValues = [1, 2, 3, 4, ""]; // or ["1", "2", "3", "4", ""] if using strings

  // Add this helper function at the top of your component
  const getNestedValue = (obj, path, defaultValue = "") => {
    try {
      return (
        path.split(".").reduce((current, key) => current?.[key], obj) ??
        defaultValue
      );
    } catch (e) {
      return defaultValue;
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      {/* Tobacco Use Section */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1" gutterBottom align="left">
            Tobacco Use
          </Typography>
          <IconButton
            onClick={() => toggleComment("tobaccoUse")}
            size="small"
            disabled={!isEditing}
            sx={{
              color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
            }}
          >
            {/* <CommentIcon
              color={
                formData.substanceUse.tobaccoUse.comment ? "primary" : "action"
              }
            /> */}
            <CommentIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <ToggleButtonGroup
            exclusive
            value={getCurrentTobaccoStatus()}
            onChange={(e, newValue) => handleTobaccoStatusChange(newValue)}
            disabled={!isEditing}
            sx={{ alignSelf: "flex-start" }}
          >
            <ToggleButton
              value="current"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#52A1DB",
                  },
                },
                "&.Mui-selected.Mui-disabled": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                },
                "&.Mui-disabled": {
                  color: "black",
                },
                color: "black",
              }}
            >
              Current
            </ToggleButton>
            <ToggleButton
              value="former"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#52A1DB",
                  },
                },
                "&.Mui-selected.Mui-disabled": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                },
                "&.Mui-disabled": {
                  color: "black",
                },
                color: "black",
              }}
            >
              Former
            </ToggleButton>
            <ToggleButton
              value="never"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#52A1DB",
                  },
                },
                "&.Mui-selected.Mui-disabled": {
                  backgroundColor: "#52A1DB",
                  color: "white",
                },
                "&.Mui-disabled": {
                  color: "black",
                },
                color: "black",
              }}
            >
              Never
            </ToggleButton>
          </ToggleButtonGroup>
          {/* Modify the comment box to be conditionally visible */}
          {isCommentVisible(
            "tobaccoUse",
            formData.substanceUse.tobaccoUse.comment,
          ) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: 2,
                border: "1px solid #52A1DB",
                borderRadius: "4px",
                p: 2,
                mb: 2,
                backgroundColor: "white",
                width: "100%",
              }}
            >
              <Typography sx={{ minWidth: "80px", textAlign: "left" }}>
                Comments:
              </Typography>

              <TextField
                fullWidth
                multiline
                rows={2}
                label=""
                value={formData.substanceUse.tobaccoUse.comment}
                onChange={(e) =>
                  handleCommentChange("tobaccoUse", e.target.value)
                }
                disabled={!isEditing}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "4px",
                    "& fieldset": {
                      borderColor: "#52A1DB",
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>

        {/* Former User Section - Moved up */}
        {formData.substanceUse.tobaccoUse.former === "1" && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle2" gutterBottom align="left">
              When
            </Typography>
            <RadioGroup
              value={
                Object.entries(
                  formData.substanceUse.tobaccoUse.formerSubAnswer.when,
                ).find(([key, value]) => value === "1")?.[0] || ""
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                alignItems: "flex-start",
              }}
            >
              {[
                { value: "withinLastYear", label: "Stopped within last year" },
                {
                  value: "withinLastThreeYear",
                  label: "Stopped within last 3 years",
                },
                { value: "moreYearAgo", label: "Stopped 5 or more years ago" },
              ].map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={
                    <Radio
                      checked={
                        formData.substanceUse.tobaccoUse.formerSubAnswer.when[
                          option.value
                        ] === "1"
                      }
                      onClick={(e) =>
                        handleRadioClick(e, () =>
                          handleQuitTimeChange(option.value),
                        )
                      }
                      disabled={!isEditing}
                      sx={radioStyle}
                    />
                  }
                  label={option.label}
                  disabled={!isEditing}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "black",
                    },
                    "& .MuiFormControlLabel-label.Mui-disabled": {
                      color: "black",
                    },
                  }}
                />
              ))}
            </RadioGroup>
          </Box>
        )}

        {/* Tobacco Type Section */}
        {(formData.substanceUse.tobaccoUse.current === "1" ||
          formData.substanceUse.tobaccoUse.former === "1") && (
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography variant="subtitle2" gutterBottom align="left">
                Type
              </Typography>
              <IconButton
                onClick={() => toggleComment("tobaccoType")}
                size="small"
                disabled={!isEditing}
                sx={{
                  color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                }}
              >
                <CommentIcon
                  color={
                    formData.substanceUse.tobaccoUse[
                      formData.substanceUse.tobaccoUse.current === "1"
                        ? "currentSubAnswer"
                        : "formerSubAnswer"
                    ].type.comment
                      ? "#52A1DB"
                      : "rgba(0, 0, 0, 0.38)"
                  }
                />
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-start",
              }}
            >
              <FormControl>
                <RadioGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    { value: "cigarettes", label: "Cigarettes" },
                    { value: "cigars", label: "Cigars" },
                    { value: "chewingTobacco", label: "Chewing Tobacco" },
                    { value: "vaping", label: "Vaping" },
                    { value: "other", label: "Other" },
                  ].map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          checked={
                            formData.substanceUse.tobaccoUse[
                              formData.substanceUse.tobaccoUse.current === "1"
                                ? "currentSubAnswer"
                                : "formerSubAnswer"
                            ].type[option.value] === "1"
                          }
                          onClick={(e) =>
                            handleRadioClick(e, () =>
                              handleTobaccoTypeChange(option.value),
                            )
                          }
                          disabled={!isEditing}
                          sx={radioStyle}
                        />
                      }
                      label={option.label}
                      disabled={!isEditing}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "black",
                        },
                        "& .MuiFormControlLabel-label.Mui-disabled": {
                          color: "black",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {/* Modified comment box to be conditionally visible */}
              {isCommentVisible(
                "tobaccoType",
                formData.substanceUse.tobaccoUse[
                  formData.substanceUse.tobaccoUse.current === "1"
                    ? "currentSubAnswer"
                    : "formerSubAnswer"
                ].type.comment,
              ) && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    border: "1px solid #52A1DB",
                    borderRadius: "4px",
                    p: 2,
                    mb: 2,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    label=""
                    value={
                      formData.substanceUse.tobaccoUse[
                        formData.substanceUse.tobaccoUse.current === "1"
                          ? "currentSubAnswer"
                          : "formerSubAnswer"
                      ].type.comment
                    }
                    onChange={(e) =>
                      handleCommentChange(
                        formData.substanceUse.tobaccoUse.current === "1"
                          ? "tobaccoUse.currentSubAnswer.type"
                          : "tobaccoUse.formerSubAnswer.type",
                        e.target.value,
                      )
                    }
                    disabled={!isEditing}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                        "& fieldset": {
                          borderColor: "#52A1DB",
                        },
                      },
                    }}
                  />
                </Box>
              )}
            </Box>

            {/* Other Description TextField */}
            {formData.substanceUse.tobaccoUse[
              formData.substanceUse.tobaccoUse.current === "1"
                ? "currentSubAnswer"
                : "formerSubAnswer"
            ].type.other === "1" && (
              <TextField
                fullWidth
                variant="standard"
                label="Please describe"
                value={
                  formData.substanceUse.tobaccoUse[
                    formData.substanceUse.tobaccoUse.current === "1"
                      ? "currentSubAnswer"
                      : "formerSubAnswer"
                  ].type.otherSubAnswer.describe
                }
                onChange={(e) => {
                  if (!isEditing) return;
                  const typeSection =
                    formData.substanceUse.tobaccoUse.current === "1"
                      ? "currentSubAnswer"
                      : "formerSubAnswer";
                  setFormData((prev) => ({
                    ...prev,
                    substanceUse: {
                      ...prev.substanceUse,
                      tobaccoUse: {
                        ...prev.substanceUse.tobaccoUse,
                        [typeSection]: {
                          ...prev.substanceUse.tobaccoUse[typeSection],
                          type: {
                            ...prev.substanceUse.tobaccoUse[typeSection].type,
                            otherSubAnswer: {
                              describe: e.target.value,
                            },
                          },
                        },
                      },
                    },
                  }));
                }}
                disabled={!isEditing}
                sx={{ mt: 2 }}
              />
            )}

            {/* Amount for cigarette users */}
            {(formData.substanceUse.tobaccoUse.current === "1" ||
              formData.substanceUse.tobaccoUse.former === "1") &&
              formData.substanceUse.tobaccoUse[
                formData.substanceUse.tobaccoUse.current === "1"
                  ? "currentSubAnswer"
                  : "formerSubAnswer"
              ].type.cigarettes === "1" && (
                <Box sx={{ mt: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom align="left">
                      How many?
                    </Typography>
                    <IconButton
                      onClick={() => toggleComment("cigarettesAmount")}
                      size="small"
                      disabled={!isEditing}
                      sx={{
                        color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                      }}
                    >
                      <CommentIcon
                        color={
                          formData.substanceUse.tobaccoUse.currentSubAnswer.type
                            .cigarettesSubAnswer.comment
                            ? "#52A1DB"
                            : "rgba(0, 0, 0, 0.38)"
                        }
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      alignItems: "flex-start",
                    }}
                  >
                    <RadioGroup
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        alignItems: "flex-start",
                      }}
                    >
                      {[
                        { value: "oneToThree", label: "1-3 a day" },
                        { value: "halfAPack", label: "1/2 a pack" },
                        { value: "onePack", label: "1 pack" },
                        { value: "moreThanOne", label: "More than 1 pack" },
                        { value: "other", label: "Other" },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          control={
                            <Radio
                              checked={
                                formData.substanceUse.tobaccoUse
                                  .currentSubAnswer.type.cigarettesSubAnswer[
                                  option.value
                                ] === "1"
                              }
                              onClick={(e) =>
                                handleRadioClick(e, () =>
                                  handleCigaretteAmountChange(option.value),
                                )
                              }
                              disabled={!isEditing}
                              sx={radioStyle}
                            />
                          }
                          label={option.label}
                          disabled={!isEditing}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              color: "black",
                            },
                            "& .MuiFormControlLabel-label.Mui-disabled": {
                              color: "black",
                            },
                          }}
                        />
                      ))}
                    </RadioGroup>

                    {/* Other Description TextField */}
                    {formData.substanceUse.tobaccoUse.currentSubAnswer.type
                      .cigarettesSubAnswer.other === "1" && (
                      <TextField
                        fullWidth
                        variant="standard"
                        label="Please describe"
                        value={
                          formData.substanceUse.tobaccoUse.currentSubAnswer.type
                            .cigarettesSubAnswer.otherSubAnswer?.describe || ""
                        }
                        onChange={(e) => {
                          if (!isEditing) return;
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              tobaccoUse: {
                                ...prev.substanceUse.tobaccoUse,
                                currentSubAnswer: {
                                  ...prev.substanceUse.tobaccoUse
                                    .currentSubAnswer,
                                  type: {
                                    ...prev.substanceUse.tobaccoUse
                                      .currentSubAnswer.type,
                                    cigarettesSubAnswer: {
                                      ...prev.substanceUse.tobaccoUse
                                        .currentSubAnswer.type
                                        .cigarettesSubAnswer,
                                      otherSubAnswer: {
                                        describe: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        sx={{ mt: 1 }}
                      />
                    )}

                    {/* Modified comment box to be conditionally visible */}
                    {isCommentVisible(
                      "cigarettesAmount",
                      formData.substanceUse.tobaccoUse.currentSubAnswer.type
                        .cigarettesSubAnswer.comment,
                    ) && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                          border: "1px solid #52A1DB",
                          borderRadius: "4px",
                          p: 2,
                          mb: 2,
                          backgroundColor: "white",
                          width: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          label=""
                          value={
                            formData.substanceUse.tobaccoUse.currentSubAnswer
                              .type.cigarettesSubAnswer.comment || ""
                          }
                          onChange={(e) =>
                            handleCommentChange(
                              "tobaccoUse.currentSubAnswer.type.cigarettesSubAnswer",
                              e.target.value,
                            )
                          }
                          disabled={!isEditing}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              "& fieldset": {
                                borderColor: "#52A1DB",
                              },
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}

            {/* Smoking Cessation Section */}
            {formData.substanceUse.tobaccoUse.current === "1" &&
              formData.substanceUse.tobaccoUse.currentSubAnswer.type
                .cigarettes === "1" && (
                <Box sx={{ mt: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom align="left">
                      Are you interested in smoking cessation?
                    </Typography>
                    <IconButton
                      onClick={() => toggleComment("smokingCessation")}
                      size="small"
                      disabled={!isEditing}
                      sx={{
                        color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                      }}
                    >
                      <CommentIcon
                        color={
                          formData.substanceUse.tobaccoUse.currentSubAnswer
                            .smokingCessation.comment
                            ? "#52A1DB"
                            : "rgba(0, 0, 0, 0.38)"
                        }
                      />
                    </IconButton>
                  </Box>

                  <FormControl fullWidth>
                    <Select
                      value={
                        formData.substanceUse.tobaccoUse.currentSubAnswer.type
                          .cigarettesSubAnswer.interestedInSmoking
                          ?.interestedInSmoking || ""
                      }
                      displayEmpty
                      renderValue={(value) =>
                        value === ""
                          ? "Please Select"
                          : [
                              "I want to work on it.",
                              "I am already working on it but need some help",
                              "I am already working on it and don't need any help",
                              "I am not interested at this time",
                            ][parseInt(value) - 1]
                      }
                      onChange={(e) => {
                        if (!isEditing) return;
                        const value =
                          e.target.value === "" ? "" : String(e.target.value); // Ensure value is string
                        setFormData((prev) => ({
                          ...prev,
                          substanceUse: {
                            ...prev.substanceUse,
                            tobaccoUse: {
                              ...prev.substanceUse.tobaccoUse,
                              currentSubAnswer: {
                                ...prev.substanceUse.tobaccoUse
                                  .currentSubAnswer,
                                type: {
                                  ...prev.substanceUse.tobaccoUse
                                    .currentSubAnswer.type,
                                  cigarettesSubAnswer: {
                                    ...prev.substanceUse.tobaccoUse
                                      .currentSubAnswer.type
                                      .cigarettesSubAnswer,
                                    interestedInSmoking: {
                                      ...prev.substanceUse.tobaccoUse
                                        .currentSubAnswer.type
                                        .cigarettesSubAnswer
                                        .interestedInSmoking,
                                      interestedInSmoking: value,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        }));
                      }}
                      disabled={!isEditing}
                    >
                      <MenuItem value="">Please Select</MenuItem>
                      <MenuItem value="1">I want to work on it.</MenuItem>
                      <MenuItem value="2">
                        I am already working on it but need some help
                      </MenuItem>
                      <MenuItem value="3">
                        I am already working on it and don't need any help
                      </MenuItem>
                      <MenuItem value="4">
                        I am not interested at this time
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {/* Modify the comment box to be conditionally visible */}
                  {isCommentVisible(
                    "smokingCessation",
                    formData.substanceUse.tobaccoUse.currentSubAnswer
                      .smokingCessation.comment,
                  ) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 2,
                        border: "1px solid #52A1DB",
                        borderRadius: "4px",
                        p: 2,
                        mb: 2,
                        backgroundColor: "white",
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label=""
                        value={
                          formData.substanceUse.tobaccoUse.currentSubAnswer.type
                            .cigarettesSubAnswer.interestedInSmoking?.comment ||
                          ""
                        }
                        onChange={(e) => {
                          if (!isEditing) return;
                          setFormData((prev) => ({
                            ...prev,
                            substanceUse: {
                              ...prev.substanceUse,
                              tobaccoUse: {
                                ...prev.substanceUse.tobaccoUse,
                                currentSubAnswer: {
                                  ...prev.substanceUse.tobaccoUse
                                    .currentSubAnswer,
                                  type: {
                                    ...prev.substanceUse.tobaccoUse
                                      .currentSubAnswer.type,
                                    cigarettesSubAnswer: {
                                      ...prev.substanceUse.tobaccoUse
                                        .currentSubAnswer.type
                                        .cigarettesSubAnswer,
                                      interestedInSmoking: {
                                        ...prev.substanceUse.tobaccoUse
                                          .currentSubAnswer.type
                                          .cigarettesSubAnswer
                                          .interestedInSmoking,
                                        comment: e.target.value,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          }));
                        }}
                        disabled={!isEditing}
                        variant="outlined"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "4px",
                            "& fieldset": {
                              borderColor: "#52A1DB",
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

            {/* Preventing Factors - Show only for current cigarette users */}
            {formData.substanceUse.tobaccoUse.current === "1" &&
              formData.substanceUse.tobaccoUse.currentSubAnswer.type
                .cigarettes === "1" && (
                <Box sx={{ mt: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle2" gutterBottom align="left">
                      Are there things preventing you from making changes?
                    </Typography>
                    <IconButton
                      onClick={() => toggleComment("preventingFactors")}
                      size="small"
                      disabled={!isEditing}
                      sx={{
                        color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                      }}
                    >
                      <CommentIcon
                        color={
                          formData.substanceUse.tobaccoUse.currentSubAnswer.type
                            .cigarettesSubAnswer.thingsPreventing?.comment
                            ? "#52A1DB"
                            : "rgba(0, 0, 0, 0.38)"
                        }
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                      alignItems: "flex-start",
                    }}
                  >
                    {[
                      { key: "educationNeeded", label: "Education Needed" },
                      { key: "socialSupport", label: "Social Support" },
                      {
                        key: "environmentalFactors",
                        label: "Environmental Factors",
                      },
                      { key: "stress", label: "Stress" },
                      { key: "selfConfidence", label: "Self Confidence" },
                      { key: "lackOfMotivation", label: "Lack of Motivation" },
                      {
                        key: "notAPriority",
                        label: "Not a priority at this time",
                      },
                      { key: "other", label: "Other" },
                      { key: "none", label: "None" },
                    ].map((factor) => {
                      const thingsPreventing =
                        formData.substanceUse.tobaccoUse.currentSubAnswer.type
                          .cigarettesSubAnswer.thingsPreventing || {};

                      return (
                        <FormControlLabel
                          key={factor.key}
                          control={
                            <Checkbox
                              checked={thingsPreventing[factor.key] === "1"}
                              onChange={() =>
                                handlePreventingFactorsChange(factor.key)
                              }
                              disabled={!isEditing}
                              sx={{
                                "&.Mui-checked": {
                                  color: "#52A1DB",
                                },
                                "&.Mui-disabled.Mui-checked": {
                                  color: "#52A1DB",
                                },
                                "&.Mui-disabled": {
                                  color: "rgba(0, 0, 0, 0.38)",
                                },
                              }}
                            />
                          }
                          label={factor.label}
                          disabled={!isEditing}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              color: "black",
                            },
                            "& .MuiFormControlLabel-label.Mui-disabled": {
                              color: "black",
                            },
                          }}
                        />
                      );
                    })}

                    {/* Modified comment box to be conditionally visible */}
                    {isCommentVisible(
                      "preventingFactors",
                      formData.substanceUse.tobaccoUse.currentSubAnswer.type
                        .cigarettesSubAnswer.thingsPreventing?.comment,
                    ) && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 2,
                          border: "1px solid #52A1DB",
                          borderRadius: "4px",
                          p: 2,
                          mb: 2,
                          backgroundColor: "white",
                          width: "100%",
                        }}
                      >
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          label=""
                          value={
                            formData.substanceUse.tobaccoUse.currentSubAnswer
                              .type.cigarettesSubAnswer.thingsPreventing
                              ?.comment || ""
                          }
                          onChange={(e) => {
                            if (!isEditing) return;
                            setFormData((prev) => ({
                              ...prev,
                              substanceUse: {
                                ...prev.substanceUse,
                                tobaccoUse: {
                                  ...prev.substanceUse.tobaccoUse,
                                  currentSubAnswer: {
                                    ...prev.substanceUse.tobaccoUse
                                      .currentSubAnswer,
                                    type: {
                                      ...prev.substanceUse.tobaccoUse
                                        .currentSubAnswer.type,
                                      cigarettesSubAnswer: {
                                        ...prev.substanceUse.tobaccoUse
                                          .currentSubAnswer.type
                                          .cigarettesSubAnswer,
                                        thingsPreventing: {
                                          ...(prev.substanceUse.tobaccoUse
                                            .currentSubAnswer.type
                                            .cigarettesSubAnswer
                                            .thingsPreventing || {}),
                                          comment: e.target.value,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            }));
                          }}
                          disabled={!isEditing}
                          variant="outlined"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                              "& fieldset": {
                                borderColor: "#52A1DB",
                              },
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
          </Box>
        )}

        {/* Show checkbox for current users only */}
        {formData.substanceUse.tobaccoUse.current === "1" && (
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      formData.substanceUse.tobaccoUse.currentSubAnswer.type
                        .cigarettesSubAnswer.interestedInSmoking
                        ?.interestedInSmoking === "1"
                    }
                    onChange={(e) => {
                      if (!isEditing) return;
                      setFormData((prev) => ({
                        ...prev,
                        substanceUse: {
                          ...prev.substanceUse,
                          tobaccoUse: {
                            ...prev.substanceUse.tobaccoUse,
                            currentSubAnswer: {
                              ...prev.substanceUse.tobaccoUse.currentSubAnswer,
                              type: {
                                ...prev.substanceUse.tobaccoUse.currentSubAnswer
                                  .type,
                                cigarettesSubAnswer: {
                                  ...prev.substanceUse.tobaccoUse
                                    .currentSubAnswer.type.cigarettesSubAnswer,
                                  interestedInSmoking: {
                                    ...prev.substanceUse.tobaccoUse
                                      .currentSubAnswer.type.cigarettesSubAnswer
                                      .interestedInSmoking,
                                    interestedInSmoking: e.target.checked
                                      ? "1"
                                      : "",
                                  },
                                },
                              },
                            },
                          },
                        },
                      }));
                    }}
                    disabled={!isEditing}
                    sx={{
                      "&.Mui-checked": {
                        color: "#52A1DB",
                      },
                      "&.Mui-disabled.Mui-checked": {
                        color: "#52A1DB",
                      },
                      "&.Mui-disabled": {
                        color: "rgba(0, 0, 0, 0.38)",
                      },
                    }}
                  />
                }
                label="Discussed smoking cessation options, member verbalized understanding"
              />
              <IconButton
                onClick={() => toggleComment("smokingCessationCheckbox")}
                size="small"
                disabled={!isEditing}
                sx={{
                  color: isEditing ? "#52A1DB" : "rgba(0, 0, 0, 0.38)",
                }}
              >
                <CommentIcon
                  color={
                    formData.substanceUse.tobaccoUse.currentSubAnswer.type
                      .cigarettesSubAnswer.interestedInSmoking?.comment
                      ? "#52A1DB"
                      : "rgba(0, 0, 0, 0.38)"
                  }
                />
              </IconButton>
            </Box>

            {/* Modified comment box to be conditionally visible */}
            {isCommentVisible(
              "smokingCessationCheckbox",
              formData.substanceUse.tobaccoUse.currentSubAnswer.type
                .cigarettesSubAnswer.interestedInSmoking?.comment,
            ) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  border: "1px solid #52A1DB",
                  borderRadius: "4px",
                  p: 2,
                  mb: 2,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  label=""
                  value={
                    formData.substanceUse.tobaccoUse.currentSubAnswer.type
                      .cigarettesSubAnswer.interestedInSmoking?.comment || ""
                  }
                  onChange={(e) => {
                    if (!isEditing) return;
                    setFormData((prev) => ({
                      ...prev,
                      substanceUse: {
                        ...prev.substanceUse,
                        tobaccoUse: {
                          ...prev.substanceUse.tobaccoUse,
                          currentSubAnswer: {
                            ...prev.substanceUse.tobaccoUse.currentSubAnswer,
                            type: {
                              ...prev.substanceUse.tobaccoUse.currentSubAnswer
                                .type,
                              cigarettesSubAnswer: {
                                ...prev.substanceUse.tobaccoUse.currentSubAnswer
                                  .type.cigarettesSubAnswer,
                                interestedInSmoking: {
                                  ...prev.substanceUse.tobaccoUse
                                    .currentSubAnswer.type.cigarettesSubAnswer
                                    .interestedInSmoking,
                                  comment: e.target.value,
                                },
                              },
                            },
                          },
                        },
                      },
                    }));
                  }}
                  disabled={!isEditing}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "4px",
                      "& fieldset": {
                        borderColor: "#52A1DB",
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Paper>

      <AlcoholAssessment
        formData={formData}
        isEditing={isEditing}
        setFormData={setFormData}
      />

      <EducationGuidelines
        formData={formData}
        isEditing={isEditing}
        setFormData={setFormData}
      />
    </Box>
  );
};
